import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Modal from "react-bootstrap/Modal";
import parse from "html-react-parser";
import Button from "react-bootstrap/Button";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Include CSS
import { useSelector, useDispatch } from "react-redux";
import * as constantMessages from "../../constants/constatntMessages";
import { updateMinute } from "../../redux/actions/minuteActions/MinuteAction";
import { fetchCurrentAttendeesList } from "../../redux/actions/meetingActions/MeetingAction";
import { getEmployeeList } from "../../redux/actions/userAction/userAction";
import Select from "react-select";
import { textBoxStrLength } from "../../config/config";
import {
  checkCharacterLeft,
  convertFirstLetterOfFullNameToCapital,
  convertFirstLetterToCapital,
  getStringLengthAfterHTMLParse,
} from "../../helpers/commonHelpers";
//import ReactQuill from "../../../node_modules/react-quill/lib/index";

const ViewMinuteModal = (props) => {
  console.log(props);
  const { minuteData } = props;
  return (
    <>
      <Modal
        size="lg"
        show={props.IsViewMinuteModal}
        onHide={(e) => props.setIsViewMinuteModal(false)}
        backdrop="static"
      >
        <div className="modal-header attendees-modal">
          <div>
            <h4 className="modal-title">View Minute</h4>
          </div>
          <div>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={(e) => props.setIsViewMinuteModal(false)}
            ></button>
          </div>
        </div>

        <Modal.Body className="attendees-popup modal-margin">
          <div className="open-div">
            <form className="addminutesboxfrom">
              <div
              // className={
              //   props.isMinuteShow && props.index === props.currentIndex
              //     ? "minutes-box no-border show"
              //     : !props.isMinuteShow && props.index === props.currentIndex
              //     ? "minutes-box no-border"
              //     : "minutes-box no-border"
              // }
              >
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-12">
                      <label className="mb-1">Minutes Title</label>
                    </div>
                    <div className="col-md-12">
                      <p>{minuteData?.title}</p>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-md-12">
                      <label className="mb-1">Description</label>
                    </div>
                    <div className="col-md-82">
                      {minuteData?.description
                        ? parse(minuteData?.description)
                        : null}
                    </div>
                  </div>
                </div>
                <div className=" form-group ">
                  <div className="row">
                    <div className="col-md-4">
                      <label className="mt-1 mb-1">Status</label>
                    </div>
                    <div className="col-md-6">
                      <p>
                        {minuteData.status === "DRAFT" ? (
                          <span className="badge bg-opacity-10 draft-color">
                            {minuteData?.status
                              ? convertFirstLetterToCapital(minuteData?.status)
                              : null}
                          </span>
                        ) : (
                          <span className="badge bg-opacity-10 scheduled-color">
                            {minuteData?.status
                              ? convertFirstLetterToCapital(minuteData?.status)
                              : null}
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="add-action">
                  {minuteData.isAction ? (
                    <>
                      <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                          <div className="form-group">
                            <div className="position-relative">
                              <label className="pb-2 input-date">
                                {" "}
                                Due Date
                              </label>
                              {minuteData?.dueDate}
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                          <div className="form-group">
                            <div className="position-relative">
                              <label className="pb-2 input-date">
                                Priority
                              </label>
                              <div>{minuteData?.dueDate}</div>
                            </div>
                          </div>
                        </div>
                        <div className="form-group pb-3 border-bottom">
                          <label className="pb-2">Responsible Person</label>
                          <div className="w-100">
                            {minuteData?.assignedUserDetails?.name
                              ? convertFirstLetterOfFullNameToCapital(
                                  minuteData?.assignedUserDetails?.name
                                )
                              : null}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewMinuteModal;
