import React, { useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import Header from "../Common/Header/Header";
import Sidebar from "../Common/Sidebar/Sidebar";
import ViewActionActivities from "./ViewActionActivities";
import ViewActionDetails from "./ViewActionDetails";
import { useSelector, useDispatch } from "react-redux";
import { logOut } from "../../redux/actions/authActions/authAction";
import NoDataFound from "../Common/NoDataFound";
import Loader from "../Common/Loader";
import { fetchSingleAction } from "../../redux/actions/actionAction/ActionAction";
import Alert from "../Common/Alert";

const ViewActionPage = () => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);
  useEffect(() => {
    document.title = "View Action: MinutesVault";
    if (!authData.isInValidUser && accessToken) {
      dispatch(fetchSingleAction(id, accessToken));
    }
    return () => {};
  }, [id,authData?.isInValidUser]);

  const actionData = useSelector((state) => state.action);

  const navigate = useNavigate();
  if (authData.isInValidUser && authData.isInvalidUserChecked) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }

  const accessToken = localStorage.getItem("accessToken");

  return (
    <>
      {/* <Header />
      <Sidebar /> */}
      {actionData?.singleActionDetails !== null ? (
        <div className="main-content">
          <div className="meeting-header-text">
            <h4>Action Details</h4>
          </div>
          <div className="row">
            <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 detail-col">
              <ViewActionDetails actionId={id} />
            </div>
            <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 detail-col">
              <ViewActionActivities
                actionId={id}
                action={actionData?.singleActionDetails}
              />
            </div>
          </div>
        </div>
      ) : actionData?.singleActionDetails === null &&
        !actionData.loading &&
        !actionData.isSuccess ? (
        <div className="main-content">
          <div className="meeting-header-text">
            <h4>Action Details</h4>
          </div>
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 detail-col">
              <form className="mt-3 details-form details-form-right">
                <div className="row">
                  <Alert
                    status={"info"}
                    message={"No data available."}
                    timeoutSeconds={0}
                  />
                  <NoDataFound dataType={"meeting"} />
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div className="main-content">
          <div className="meeting-header-text">
            <h4>Action Details</h4>
          </div>
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 detail-col">
              <form className="mt-3 details-form details-form-right">
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Loader />
                </div>
              </form>
            </div>
          </div>
        </div>
      )}


    </>
  );
};

export default ViewActionPage;
