import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { logOut } from "../../redux/actions/authActions/authAction";
import NoDataFound from "../Common/NoDataFound";
import Loader from "../Common/Loader";
import {
  Button,
  Popover,
  OverlayTrigger,
  Accordion,
  Tooltip,
} from "react-bootstrap";
import Alert from "../Common/Alert";
import {
  convertFirstLetterOfFullNameToCapital,
  convertFirstLetterToCapital,
  customName,
} from "../../helpers/commonHelpers";
import {
  getactionActivitiesDetails,
  processReassign,
} from "../../redux/actions/actionAction/ActionAction";
import ReassignModal from "./ReassignModal";
import OtherModal from "./OtherModal";

const ViewActionActivities = (props) => {
  const mainActionData = useSelector((state) => state.action);
  const location = useLocation();
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  if (authData.isInValidUser && authData.isInvalidUserChecked) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }
  console.log(mainActionData);
  const accessToken = localStorage.getItem("accessToken");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activityDetails, setActivityDetails] = useState(null);
  const [isReassignModalOpen, setIsReassignModalOpen] = useState(false);
  const employeeData = useSelector((state) => state.user);
  const actionData = useSelector((state) => state.action);
  const [actionId, setActionId] = useState(null);
  const [actionDatas, setActionDatas] = useState(null);
  const [activityId, setActivityId] = useState(null);
  useEffect(() => {
    if (accessToken) {
      dispatch(getactionActivitiesDetails(props.actionId, accessToken));
    }

    return () => {
      // Anything in here is fired on component unmount.
    };
  }, [actionData?.actionActivitiesDetails?.length, actionData?.isSuccess]);

  const handleModal = (activity) => {
    setActivityDetails(activity);
    setIsModalOpen(true);
  };
  const handleReassignModal = (action, activity, actId) => {
    setActivityId(actId);
    setActivityDetails(activity);
    setActionId(action._id);
    setActionDatas(action);
    setIsReassignModalOpen(true);
  };

  const handleReassign = (data, status) => {
    console.log(data);
    if (status) {
      dispatch(
        processReassign(
          actionId,
          {
            organizationId: userData.organizationId,
            reAssignedId: data.attendeeId !== "" ? data.attendeeId : undefined,
            reAssignReason: data.reason,
            isNewUser: data.name !== "" && data.email !== "" ? true : false,
            name: data.name,
            email: data.email,
            dueDate: data.date ? data.date : undefined,
            priority: data.priority,

            lastActionActivityId: data?.lastActionActivityId
              ? data?.lastActionActivityId
              : undefined,
          },
          accessToken
        )
      );
    } else {
      dispatch(
        processReassign(
          actionId,
          {
            organizationId: userData.organizationId,
            reAssignedId: data.attendeeId !== "" ? data.attendeeId : undefined,
            reAssignReason: data.reason,
            reassignedUserName: data.reassignedUserName
              ? data.reassignedUserName
              : undefined,
            isNewUser: data.name !== "" && data.email !== "" ? true : false,
            name: data.name,
            email: data.email,
            dueDate: data.date ? data.date : undefined,
            priority: data.priority,
            lastActionActivityId: data?.lastActionActivityId
              ? data?.lastActionActivityId
              : undefined,
          },
          accessToken
        )
      );
    }
    setActionId(null);
  };
  console.log(props.action);

  const tooltip = (attendee) => {
    return (
      <Tooltip id="tooltip">
        {attendee.name} / {attendee?.email}
      </Tooltip>
    );
  };
  console.log(actionData?.actionActivitiesDetails);
  return (
    <form className="mt-2 details-form details-form-right">
      <div className="form-group agenda">
        <div className="left-detailbox">
          <h4 className="Detailsheading">Action Activities</h4>

          {/* Debasis */}

          {/* <div className="timeline timeline-border-dashed mt-4">
            <div className="timeline-item">
              <div className="timeline-line"></div>
              <div className="timeline-icon">
                <div className="attendee-list pk">SM</div>
              </div>
              <div className="timeline-content mb-8 mt-n1">
                <div className="pe-3 mb-3">
                  <div className="fs-6 heading mb-1">
                    Action forwarded to Satya Maharana
                  </div>
                  <div className="d-flex align-items-center fs-6">
                    <div className="text-muted me-2 fs-7">
                      10 Jan 2025, 4:56 pm
                    </div>
                  </div>
                  <div className="mt-3">
                    <button className="reset" type="button">
                      <p>View Details</p>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="timeline-item">
              <div className="timeline-line"></div>
              <div className="timeline-icon me-4">
                <div className="attendee-list pk">AS</div>
              </div>
              <div className="timeline-content mb-8 mt-n2">
                <div className="pe-3 mb-3">
                  <div className="fs-6 heading mb-1">
                    Action forwarded by Soumya Ranjan Mishra
                  </div>
                  <div className="d-flex align-items-center fs-6">
                    <div className="text-muted me-2 fs-7">
                      10 Jan 2025, 4:56 pm
                    </div>
                  </div>
                  <div className="mt-3">
                    <button className="reset" type="button">
                      <p>View Details</p>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="timeline-item">
              <div className="timeline-line"></div>
              <div className="timeline-icon me-4">
                <div className="attendee-list pk">HN</div>
              </div>
              <div className="timeline-content mb-8 mt-n2">
                <div className="pe-3 mb-3">
                  <div className="fs-6 heading mb-1">
                    Action forwarded by Soumya Ranjan Mishra
                  </div>
                  <div className="d-flex align-items-center fs-6">
                    <div className="text-muted me-2 fs-7">
                      10 Jan 2025, 4:56 pm
                    </div>
                  </div>
                  <div className="mt-3">
                    <button className="reset" type="button">
                      <p>View Details</p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* Debasis */}

          <div className="timeline timeline-border-dashed mt-4">
            {actionData?.actionActivitiesDetails?.length > 0 &&
            !actionData.loading ? (
              <>
                {actionData?.actionActivitiesDetails.map((activity) => {
                  return (
                    <>
                      {activity?.reAssignedUserDetails?.length > 0 &&
                      activity.activityTitle !== "ACTION REOPENED" ? (
                        <>
                          <div className="timeline-item">
                            <div className="timeline-line"></div>
                            <OverlayTrigger
                              placement="top"
                              overlay={tooltip(
                                activity?.reAssignedUserDetails[0]
                              )}
                              trigger={["hover", "focus"]}
                            >
                              <div className="timeline-icon me-4">
                                <div className="attendee-list pk">
                                  {" "}
                                  {customName(
                                    activity?.reAssignedUserDetails[0]?.name
                                  )}
                                </div>
                              </div>
                            </OverlayTrigger>
                            <div className="timeline-content mb-8 mt-n2">
                              <div className="pe-3 mb-3">
                                <div className="fs-6 heading mb-1">
                                  {" "}
                                  {convertFirstLetterToCapital(
                                    activity?.activityTitle
                                  )}{" "}
                                  to{" "}
                                  {convertFirstLetterOfFullNameToCapital(
                                    activity?.reAssignedUserDetails[0]?.name
                                  )}
                                </div>
                                <div className="d-flex align-items-center fs-6">
                                  <div className="text-muted me-2 fs-7">
                                    {activity.date}
                                  </div>
                                </div>
                                <div className="mt-3">
                                  <button
                                    className="reset"
                                    type="button"
                                    onClick={() => {
                                      handleModal(activity);
                                    }}
                                  >
                                    <p>View Details</p>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="timeline-item">
                            <div className="timeline-line"></div>
                            <div className="timeline-icon me-4">
                              <div className="attendee-list pk">
                                {" "}
                                {customName(activity.employeeDetails?.name)}
                              </div>
                            </div>
                            <div className="timeline-content mb-8 mt-n2">
                              <div className="pe-3 mb-3">
                                <div className="fs-6 heading mb-1">
                                  {" "}
                                  {convertFirstLetterToCapital(
                                    activity.activityTitle
                                  )}{" "}
                                  by{" "}
                                  {convertFirstLetterOfFullNameToCapital(
                                    activity.employeeDetails?.name
                                  )}
                                </div>
                                <div className="d-flex align-items-center fs-6">
                                  <div className="text-muted me-2 fs-7">
                                    {activity.date}
                                  </div>
                                </div>
                                <div className="mt-3">
                                  <button
                                    className="reset"
                                    type="button"
                                    onClick={() => {
                                      handleModal(activity);
                                    }}
                                  >
                                    <p>View Details</p>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* <div className="timeline-item">
                            <div className="timeline-line"></div>
                            <div className="timeline-icon">
                              <div className="attendee-list pk">
                                {" "}
                                {customName(activity.employeeDetails?.name)}
                              </div>
                            </div>
                            <div className="text">
                              <div className="heading">
                                {" "}
                                {convertFirstLetterToCapital(
                                  activity?.activityTitle
                                )}{" "}
                                to{" "}
                                {convertFirstLetterOfFullNameToCapital(
                                  activity?.reAssignedUserDetails[0]?.name
                                )}
                              </div>
                              <div className="date">{activity.date}</div>
                            </div>
                          </div> */}
                          {/* <br></br>
                          <div className="inner-notification req-by">
                            <div>
                              <div className="attendee-list pk me-3">
                                {" "}
                                {customName(activity.employeeDetails?.name)}
                              </div>
                            </div>
                            <div className="text">
                              <div className="heading">
                                {" "}
                                {convertFirstLetterToCapital(
                                  activity.activityTitle
                                )}{" "}
                                by{" "}
                                {convertFirstLetterOfFullNameToCapital(
                                  activity.employeeDetails?.name
                                )}
                              </div>
                              <div className="date">{activity.date}</div>
                              {activity.activityDetails ? (
                                <div className="mt-3">
                                  <button
                                    className="reset"
                                    type="button"
                                    onClick={() => {
                                      handleModal(activity);
                                    }}
                                  >
                                    <p>View Details</p>
                                  </button>
                                </div>
                              ) : null}
                            </div>
                          </div> */}
                        </>
                      ) : (
                        <>
                          <div className="timeline-item">
                            <div className="timeline-line"></div>
                            <div className="timeline-icon">
                              <div className="attendee-list pk">
                                {" "}
                                {customName(activity.employeeDetails?.name)}
                              </div>
                            </div>
                            <div className="timeline-content mb-8 mt-n1">
                              <div className="pe-3 mb-3">
                                <div className="fs-6 heading mb-1">
                                  {" "}
                                  {convertFirstLetterToCapital(
                                    activity.activityTitle
                                  )}{" "}
                                  by{" "}
                                  {convertFirstLetterOfFullNameToCapital(
                                    activity.employeeDetails?.name
                                  )}
                                </div>
                                <div className="d-flex align-items-center fs-6">
                                  <div className="text-muted me-2 fs-7">
                                    {activity.date}
                                  </div>
                                </div>

                                {activity.activityDetails ? (
                                  <div className="mt-3">
                                    {activity.activityTitle !==
                                      "ACTION REOPENED" &&
                                    actionData?.singleActionDetails
                                      ?.isRequested === true &&
                                    activity.activityTitle !==
                                      "ACTION COMPLETED" &&
                                    (employeeData.userData?.isAdmin ||
                                      employeeData?.userData?._id?.toString() ===
                                        props?.action?.meetingDetail?.createdById?.toString()) &&
                                    actionData?.singleActionDetails
                                      ?.isComplete === false &&
                                    actionData?.singleActionDetails
                                      ?.assignedUserId ===
                                      actionData?.singleActionDetails?.reassigneRequestDetails[
                                        actionData?.singleActionDetails
                                          ?.reassigneRequestDetails?.length - 1
                                      ]?.userId?.toString() &&
                                    activity?.isRead === false ? (
                                      <button
                                        className="reset"
                                        type="button"
                                        onClick={() => {
                                          handleReassignModal(
                                            props.action,
                                            activity.activityDetails,
                                            activity?._id
                                          );
                                        }}
                                      >
                                        <p>View & Take Action</p>
                                      </button>
                                    ) : (
                                      <button
                                        className="reset"
                                        type="button"
                                        onClick={() => {
                                          handleModal(activity);
                                        }}
                                      >
                                        <p>View Details</p>
                                      </button>
                                    )}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  );
                })}
              </>
            ) : actionData?.actionActivitiesDetails?.length === 0 &&
              !actionData.loading ? (
              <div className="no-data-img">
                <Alert
                  status={"info"}
                  message={"No data available."}
                  timeoutSeconds={0}
                />
                <NoDataFound dataType={"meetingActivities"} />
              </div>
            ) : (
              <div
                className="meeting-page "
                style={{ textAlign: "center", paddingTop: 20 }}
              >
                <Loader />
              </div>
            )}
          </div>
        </div>

        <ReassignModal
          setIsReassignModalOpen={setIsReassignModalOpen}
          isModalOpen={isReassignModalOpen}
          handleSubmit={handleReassign}
          activity={activityDetails}
          actionId={props.actionId}
          actionData={actionDatas}
          meetingDate={mainActionData?.singleActionDetails?.meetingDetail?.date}
          activityId={activityId}
        />

        <OtherModal
          setIsModalOpen={setIsModalOpen}
          IsModalOpen={isModalOpen}
          activity={activityDetails}
        />
      </div>
    </form>
  );
};

export default ViewActionActivities;
