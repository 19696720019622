import Header from "../Common/Header/Header";
import Sidebar from "../Common/Sidebar/Sidebar";
import "./style/CreateMeeting.css";
import React, { useEffect, useState } from "react";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
//added for meeting dropdown
import Dropdown from "react-bootstrap/Dropdown";
import MeetingDropDown from "../Meeting/MeetingDropDown";
import "./style/meetings-css.css";
import EditMeeting from "./EditMeeting";
import {
  processCloseMeeting, //added for meeting dropdown
  processCancelMeeting, //added for meeting dropdown
} from "../../redux/actions/meetingActions/MeetingAction";
import {
  getSingleMeetingDetails,
  unSetSingleMeetingDetails,
  updateStep,
} from "../../redux/actions/meetingActions/MeetingAction";
import ViewMeeting from "./ViewMeeting";
import { logOut } from "../../redux/actions/authActions/authAction";
import RescheduleMeetingModal from "./RescheduleMeetingModal";
import ResendMeetingDetailsModal from "./ResendMeetingDetailsModal";
import CancelMeetingModal from "./CancelMeetingModal";

const EditMeetingPage = () => {
  const location = useLocation();
  const stateData = location.state;
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);
  const meetingData = useSelector((state) => state.meeting);
  const meeting = meetingData?.singleMeetingDetails;
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isCloseModalOpen, setIsCloseModalOpen] = useState(false);
  const [meetingId, setMeetingId] = useState(null);
  const [meetingDetails, setMeetingDetails] = useState(null);
  const [singleMeetingDetails, setSingleMeetingDetails] = useState(null);
  const [isResendMeetingDetailsModalOpen, setIsResendMeetingDetailsModalOpen] =
    useState(false);
  const [isRescheduleModalOpen, setIsRescheduleModalOpen] = useState(false);
  const navigate = useNavigate();
  if (authData.isInValidUser && authData.isInvalidUserChecked) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }
  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    if (stateData?.meetingId) {
      dispatch(getSingleMeetingDetails(stateData?.meetingId, accessToken));
    }

    return () => {
      dispatch(unSetSingleMeetingDetails());

      dispatch(updateStep(0));
    };
  }, []);
  const handleCancelModal = (meetingId) => {
    setMeetingId(meetingId);
    setIsCancelModalOpen(true);
  };
  const handleResendMeetingDetailsModal = (singleMeetingDetails) => {
    // alert("handleResendMeetingDetailsModal")
    setSingleMeetingDetails(singleMeetingDetails);

    setIsResendMeetingDetailsModalOpen(true);
  };
  const handleCloseModal = (meetingId) => {
    setMeetingId(meetingId);
    setIsCloseModalOpen(true);
  };

  const handleRescheduleModal = (singleMeetingDetails) => {
    // alert("handleRescheduleModal")
    setSingleMeetingDetails(singleMeetingDetails);

    setIsRescheduleModalOpen(true);
  };
  //modals for meeting dropdown
  const handleCancelMeeting = (remarks) => {
    dispatch(processCancelMeeting(meetingId, { remarks }, accessToken));
    setMeetingId(null);
    setIsCancelModalOpen(false);
  };
  return (
    <>
      {!stateData?.meetingId ? <Navigate to="/meeting-list" /> : null}
      {/* <Header />

      <Sidebar /> */}
      <div className="main-content">
        <div className="row">
          <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 detail-col">
            <div className="meeting-header-text mb-3">
              <h4>Meeting Preview</h4>
            </div>
            <ViewMeeting />
          </div>
          <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 detail-col">
            <div className="meeting-header-text mb-3 respmt-20">
              <h4>Edit Meeting Details</h4>
              {/* Added for Meeting dropdown */}
              {meetingData?.singleMeetingDetails && (
                
                
                <div className="d-inline-block menu-dropdown custom-dropdown">
                  <Dropdown>
                    <MeetingDropDown
                      meetingDetails={meetingData?.singleMeetingDetails}
                      meetingId={meetingData?.singleMeetingDetails?._id}
                      status={
                        meetingData?.singleMeetingDetails?.meetingStatus?.status
                      }
                      handleCancelModal={(meetingId) => {
                        handleCancelModal(
                          meetingData?.singleMeetingDetails?._id
                        );
                      }}
                      handleResendMeetingDetailsModal={() => {
                        handleResendMeetingDetailsModal(
                          meetingData?.singleMeetingDetails
                        );
                      }}
                      handleRescheduleModal={() => {
                        handleRescheduleModal(
                          meetingData?.singleMeetingDetails
                        );
                      }}
                      handleCloseModal={() => {
                        handleCloseModal(
                          meetingData?.singleMeetingDetails?._id
                        );
                      }}
                    />
                  </Dropdown>
                </div>
              )}
            </div>
            <EditMeeting />
          </div>
        </div>
      </div>
      <RescheduleMeetingModal
        setIsRescheduleModalOpen={setIsRescheduleModalOpen}
        isRescheduleModalOpen={isRescheduleModalOpen}
        meetingData={singleMeetingDetails}
        isFetchedMeetingList={meetingData.isFetchedMeetingList}
      />
      <ResendMeetingDetailsModal
        isResendMeetingDetailsModalOpen={isResendMeetingDetailsModalOpen}
        setIsResendMeetingDetailsModalOpen={setIsResendMeetingDetailsModalOpen}
        meetingDetail={singleMeetingDetails}
      />
       <CancelMeetingModal
        setIsModalOpen={setIsCancelModalOpen}
        isModalOpen={isCancelModalOpen}
        handleSubmit={handleCancelMeeting}
      />
    </>
  );
};

export default EditMeetingPage;
