import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import {
  downloadMOM,
  getAgendaWithMinutesDetails,
  setMeetingDetails,
} from "../../redux/actions/meetingActions/MeetingAction";
import Header from "../Common/Header/Header";
import Sidebar from "../Common/Sidebar/Sidebar";
import NoDataFound from "../Common/NoDataFound";
import Loader from "../Common/Loader";
import MinutesRsvpStatusModal from "./MinutesRsvpStatusModal";
import AttendeesModal from "../Meeting/AttendeesModal";
import LoaderButton from "../Common/LoaderButton";
import AmendmentRequestModal from "./AmendmentRequestModal";
import AmendmentRequestViewModal from "./AmendmentRequestViewModal";
import MeetingActivities from "../Meeting/MeetingActivities";
import EditAgendaModal from "./EditAgendaModal";
import DeleteAgendaModal from "./DeleteAgendaModal";
import EditMinuteModal from "./EditMinuteModal";
import DeleteMeetingModal from "./DeleteMeetingModal";
import ViewMinutes from "./ViewMinutes";
import MeetingAttendance from "../Meeting/MeetingAttendance";
import ViewParentActions from "./ViewParentActions";
import MOMAcceptanceDetails from "./MOMAcceptanceDetails";
import { logOut } from "../../redux/actions/authActions/authAction";
import MeetingDropDown from "../Meeting/MeetingDropDown";

const ViewMinutePage = (props) => {
  let { meetingId } = useParams();
  const location = useLocation();

  const accessToken = localStorage.getItem("accessToken");
  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  if (authData.isInValidUser && authData.isInvalidUserChecked) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }

  const employeeData = useSelector((state) => state.user);
  const minuteData = useSelector((state) => state.minute);
  const meetingData = useSelector((state) => state.meeting);

  useEffect(() => {
    document.title = "View Minutes: MinutesVault";
    if (meetingId && accessToken) {
      dispatch(getAgendaWithMinutesDetails(meetingId, accessToken));
    }
    return () => {
      // dispatch(setMeetingDetails(null))
    };
  }, []);
  console.log(meetingData?.meetingDetails?._id);
  const isMeetingOrganiser =
    meetingData?.meetingDetails?.createdById?.toString() ===
    employeeData?.userData?._id?.toString()
      ? true
      : false;
  console.log(meetingId);
  return (
    <>
      {/* <Header />
      <Sidebar /> */}
      {meetingData?.meetingDetails !== null ? (
        <div className="main-content">
          <div className="page-heading d-flex justify-content-between align-items-center">
            <div >
            {meetingId ? <h4>MOM Details</h4> : <h4>MOM Preview</h4>}
            </div>
            <div className="agenda-btn-icon">
              {meetingData?.loading ? (
                <LoaderButton />
              ) : (
                <button
                  type="button"
                  id="open-form-btn"
                  className="Mom-btn"
                  onClick={() => {
                    dispatch(downloadMOM(meetingId, accessToken));
                  }}
                >
                  <p>Download MOM</p>
                </button>
              )}
              <div className="d-inline-block menu-dropdown custom-dropdown">
                <div className="dropdown">
                  <div className="d-inline-block menu-dropdown custom-dropdown">
                    {meetingData?.meetingDetails && (
                      <div className="d-inline-block menu-dropdown custom-dropdown inner-mt">
                        <div className="d-inline-block menu-dropdown custom-dropdown">
                          <Dropdown>
                            <MeetingDropDown
                              meetingDetails={meetingData?.meetingDetails}
                              meetingId={meetingData?.meetingDetails?._id}
                              status={
                                meetingData?.meetingDetails?.meetingStatus
                                  ?.status
                              }
                            />
                          </Dropdown>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            {/* {meetingData?.meetingDetails!==null?( */}
            <>
              <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 detail-col">
                <ViewMinutes meetingId={meetingId} />
                {meetingData?.meetingDetails?.parentMeetingId ? (
                  <form className="mt-4">
                    <ViewParentActions
                      parentMeetingId={
                        meetingData?.meetingDetails?.parentMeetingId
                      }
                      childMeetingId={meetingData?.meetingDetails?._id}
                      isHideOptions={true}
                    />
                  </form>
                ) : null}
              </div>
              <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 detail-col res-mt-20"> 
                
                <form className=" details-form details-form-right">
                  <MeetingAttendance />
                </form>
                {minuteData ? (
                  <form className="mt-4 details-form details-form-right">
                    <MOMAcceptanceDetails meetingId={meetingId} />
                  </form>
                ) : null}
                {isMeetingOrganiser || employeeData.userData.isAdmin ? (
                  <form className="mt-4 details-form details-form-right">
                    <MeetingActivities meetingId={meetingId} />
                  </form>
                ) : null}
              </div>
            </>
          </div>
        </div>
      ) : meetingData?.meetingDetails === null &&
        !meetingData.loading &&
        !meetingData.isSuccess ? (
        <div className="main-content">
          <div className="row">
            <NoDataFound />
          </div>
        </div>
      ) : (
        <div>
          <Loader />
        </div>
      )}
    </>
  );
};

export default ViewMinutePage;
