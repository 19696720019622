import React, { useState, useEffect } from "react";
import "../../../App.css";
import Header from "../../Common/Header/Header";
import Sidebar from "../../Common/Sidebar/Sidebar";
import { toast } from "react-toastify";
import { Modal, Button, Table, Dropdown, Form } from "react-bootstrap";
import axios from "../../../../node_modules/axios/index";
import Loader from "../../Common/Loader";
import LoaderButton from "../../Common/LoaderButton";
import NoDataFound from "../../Common/NoDataFound";
import "react-toastify/dist/ReactToastify.css";
import Alert from "../../Common/Alert";
import CustomizedSwitches from "./switch";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import DesignationDropdown from "./DesignationDropdown";
import DepartmentDropdown from "./DepartmentDropdown";
import UnitDropdown from "./UnitDropdown";
import EditDesignationDropdown from "./EditDesignationDropdown";
import EditDepartmentDropdown from "./EditDepartmentDropdown";
import EditUnitDropdown from "./EditUnitDropdown";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Navigate, Link } from "react-router-dom";
import {
  logOut,
  setInValidUser,
} from "../../../redux/actions/authActions/authAction";
import constatntMessages from "../../../constants/constatntMessages";
import { fetchIpAddress } from "../../../helpers/commonHelpers";
import * as XLSX from "xlsx";
import xlsxFile from "../../../../src/assets/EmployeeData.xlsx";
import download from "../../../../src/assets/images/download.svg";
import upload from "../../../../src/assets/images/upload.svg";
import { useRef } from "react";
const Employee = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));

  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  if (authData.isInValidUser && authData.isInvalidUserChecked) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }

  const userId = userData?._id;
  const employeeDatas = useSelector((state) => state?.user);
  useEffect(() => {
    console.log(employeeDatas);
    if (employeeDatas?.userData) {
      //  alert("innnn");
      if (employeeDatas?.userData?.isAdmin === false) {
        // alert("innnn2222");
        navigate("/page-not-found");
      }
    }
  }, [employeeDatas?.userData]);
  const accessToken = localStorage.getItem("accessToken");
  const organizationId = userData?.organizationId;
  //Create Employee
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [units, setUnits] = useState([]);
  const [formValues, setFormValues] = useState({
    name: "",
    empId: "",
    email: "",
    designationId: "",
    departmentId: "",
    unitId: "",
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  //Editttttt
  const [name, setName] = useState("");

  const [empId, setEmpId] = useState("");
  const [email, setEmail] = useState("");
  const [designationId, setDesignationId] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  const [unitId, setUnitId] = useState("");
  const [isAdmin, setIsAdmin] = useState();
  const [isMeetingOrganiser, setIsMeetingOrganiser] = useState();
  //List Employee
  const [employees, setEmployees] = useState([]);

  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [order, setOrder] = useState(-1);
  const [isFetching, setIsFetching] = useState(false);

  const [employeeData, setEmployeeData] = useState({
    name: "",
    organizationId: organizationId,
    empId: "",
    email: "",
    designationId: "",
    departmentId: "",
    unitId: "",
    isMeetingOrganiser: false,
    isAdmin: false,
  });

  //DELETE Employee
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [employeeToDelete, setEmployeeToDelete] = useState(null);
  //EDIT Employee
  const [showEditModal, setShowEditModal] = useState(false);
  const [editEmployeeData, setEditEmployeeData] = useState({});

  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [editEmail, setEditEmail] = useState(employeeData.email || "");
  const [editIsAdmin, setEditIsAdmin] = useState(employeeData.isAdmin || false);
  const [editIsMeetingOrganiser, setEditIsMeetingOrganiser] = useState(
    employeeData.isMeetingOrganiser || false
  );

  const employeesStatus = [
    /* Replace this with your actual employee data */
    { _id: "1", isActive: true },
    { _id: "2", isActive: false },
  ];

  //Viewe Employee
  const [showViewModal, setShowViewModal] = useState(false);
  const [viewEmployee, setViewEmployee] = useState({
    name: "",
    organizationId: organizationId,
    empId: "",
    email: "",
    designationId: "",
    departmentId: "",
    unitId: "",
    isAdmin: false,
    isMeetingOrganiser: false,
    isActive: true,
  });

  //Import Employee
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [uploadError, setUploadError] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [uploadCompleted, setUploadCompleted] = useState(false);

  const isValidate = () => {
    let isValid = true;
    const errors = {};

    // Name Validation
    const excludeHtmlForName = /^[0-9a-zA-Z ,&,/-]+$/;
    if (!employeeData.name.trim()) {
      errors.name = "Employee Name is required";
      isValid = false;
    } else if (!excludeHtmlForName.test(employeeData.name)) {
      errors.name = constatntMessages.invalidInput;
      isValid = false;
    } else if (
      employeeData.name.trim().length < 2 ||
      employeeData.name.trim().length > 100
    ) {
      errors.name = "Name must be between 2-100 characters long";
      isValid = false;
    }

    // Employee ID Validation
    const empIdValidation = /^[0-9a-zA-Z -.(),-,_/]+$/;
    const trimmedEmpId = employeeData.empId.trim();
    if (!trimmedEmpId) {
      errors.empId = "Employee ID is required";
      isValid = false;
    } else if (!empIdValidation.test(employeeData.empId)) {
      errors.empId = constatntMessages.invalidInput;
      isValid = false;
    } else if (
      employeeData.empId.trim().length < 2 ||
      employeeData.empId.trim().length > 20
    ) {
      errors.empId = "Employee ID must be  2 - 20  characters";
      isValid = false;
    }

    // Email Validation
    const emailValidation = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!employeeData.email.trim()) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!emailValidation.test(employeeData.email)) {
      errors.email = "Please enter a valid email address";
      isValid = false;
    }

    // Designation ID Validation
    if (!employeeData.designationId.trim()) {
      errors.designationId = "Designation is required";
      isValid = false;
    }

    if (!employeeData.departmentId || !employeeData.departmentId.trim()) {
      errors.departmentId = "Department is required";
      isValid = false;
    }

    // Unit ID Validation
    if (!employeeData.unitId.trim()) {
      errors.unitId = "Unit is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  //Fetch mod
  const [selectedOption, setSelectedOption] = useState([
    { label: "Select Designations", value: "" },
  ]);
  const [selectedDepartmentOption, setSelectedDepartmentOption] = useState([
    { label: "Select Departments", value: "" },
  ]);
  const [selectedUnitOption, setSelectedUnitOption] = useState([
    { label: "Select Unit", value: "" },
  ]);

  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedDesignation, setSelectedDesignation] = useState(null);
  const [selectedUnit, SetSelectedUnit] = useState(null);
  const [searchData, setSearchData] = useState({
    unit: "",
    department: "",
    designation: "",
  });
  const fetchMasterData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/employee/masterData/${organizationId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: accessToken,
          },
        }
      );
      if (response?.data?.data?.isInValidUser) {
        dispatch(setInValidUser(true));
        // toast.error(response.data.message, {
        //   position: "top-right",
        //   autoClose: 4000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "colored",
        // });
      } else {
        dispatch(setInValidUser(false));
      }
      if (response.data.success) {
        const { departmentList, designationList, unitList } =
          response.data.data;
        setDepartments(departmentList || []);
        setDesignations(designationList || []);
        setUnits(unitList || []);
      } else {
        toast.error(response.data.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      console.error("Error fetching master data:", error);

      toast.error(error.message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  useEffect(() => {
    document.title = "Employee: MinutesVault";

    if (!accessToken) {
      navigate("/login");
    }
    setSearchData({});
    setSelectedOption([
      {
        label: employeeData?.designationId,
        value: employeeData?.name,
      },
    ]);
    setSelectedDepartmentOption([
      {
        label: employeeData?.departmentId,
        value: employeeData?.name,
      },
    ]);
    setSelectedUnitOption([
      {
        label: employeeData?.unitId,
        value: employeeData?.name,
      },
    ]);
    setEmployeeData({
      ...employeeData,
      isMeetingOrganiser: employeeData?.isMeetingOrganiser,
      isAdmin: employeeData?.isAdmin,
    });
    if (!authData?.isInValidUser) {
      fetchMasterData();
    }
  }, [authData?.isInValidUser]);

  const handleDesignationChange = (e) => {
    setDesignationId(e.value);
  };

  const handleDepartmentChange = (e) => {
    setDepartmentId(e.value);
  };

  const handleUnitChange = (e) => {
    setUnitId(e.value);
  };

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setErrors({});

    if (name === "isMeetingOrganiser" && type === "checkbox") {
      if (checked) {
        setEmployeeData({
          ...employeeData,
          isMeetingOrganiser: true,
          isAdmin: false,
        });
      } else {
        setEmployeeData({
          ...employeeData,
          isMeetingOrganiser: false,
        });
      }
    } else if (name === "isAdmin" && type === "checkbox") {
      if (checked) {
        setEmployeeData({
          ...employeeData,
          isMeetingOrganiser: false,
          isAdmin: true,
        });
      } else {
        setEmployeeData({
          ...employeeData,
          isAdmin: false,
        });
      }
    } else {
      setEmployeeData({
        ...employeeData,
        [name]: type === "checkbox" ? checked : value,
      });
    }

    setFormValues({
      ...formValues,
      [name]: type === "checkbox" ? checked : value,
    });
    setEditEmployeeData({
      ...editEmployeeData,
      [name]: type === "checkbox" ? checked : value,
    });

    // Reset errors for all fields
    setErrors({
      ...errors,
      [name]: "",
      empId: "",
      email: "",
      designationId: "",
      departmentId: "",
      unitId: "",
    });
  };

  const editHandleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setErrors({});

    if (type === "checkbox") {
      if (name === "editIsMeetingOrganiser" && checked) {
        setEditEmployeeData({
          ...employeeData,
          editIsMeetingOrganiser: true,
          editIsAdmin: false,
        });
        console.log(name, checked);
        setEditIsMeetingOrganiser(true);
        setEditIsAdmin(false);
      } else if (name === "editIsAdmin" && checked) {
        setEditEmployeeData({
          ...employeeData,
          editIsMeetingOrganiser: false,
          editIsAdmin: true,
        });
        setEditIsMeetingOrganiser(false);
        setEditIsAdmin(true);
      } else if (!checked) {
        setEditEmployeeData({
          ...employeeData,
          editIsMeetingOrganiser: false,
          editIsAdmin: false,
        });
        setEditIsMeetingOrganiser(false);
        setEditIsAdmin(false);
      }
    } else {
      setEditEmployeeData({
        ...editEmployeeData,
        [name]: type === "checkbox" ? checked : value,
      });
    }
  };

  // This function updates the errors state without resetting it
  const updateErrors = (field, message) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (message) {
        newErrors[field] = message;
      } else {
        delete newErrors[field];
      }
      return newErrors;
    });
  };

  const nameValidationCheck = () => {
    const excludeHtml = /^[0-9a-zA-Z ,]+$/;

    if (!employeeData.name.trim()) {
      updateErrors("name", "Employee Name is required");
    } else if (!excludeHtml.test(employeeData.name)) {
      updateErrors("name", constatntMessages.invalidInput);
    } else if (
      employeeData.name.trim().length < 2 ||
      employeeData.name.trim().length > 100
    ) {
      updateErrors("name", "Name must be  2 - 100  characters");
    } else {
      updateErrors("name", null);
    }
  };

  const emailValidationCheck = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!employeeData.email.trim()) {
      updateErrors("email", "Email is required");
    } else if (!emailRegex.test(employeeData.email)) {
      updateErrors("email", "Invalid email format");
    } else {
      updateErrors("email", null);
    }
  };

  const empIdValidationCheck = () => {
    const excludeHtml = /^[0-9a-zA-Z -.(),-,_/]+$/;

    if (!employeeData.empId.trim()) {
      updateErrors("empId", "Employee ID is required");
    } else if (!excludeHtml.test(employeeData.empId)) {
      updateErrors("empId", constatntMessages.invalidInput);
    } else if (
      employeeData.empId.trim().length < 2 ||
      employeeData.empId.trim().length > 20
    ) {
      updateErrors("empId", "Employee ID must be  2 - 20  characters");
    } else {
      updateErrors("empId", null);
    }
  };

  //Create EMPLOYEE
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!isValidate()) {
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/employee/createEmployee`,
        employeeData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: accessToken,
            ip: await fetchIpAddress(),
          },
        }
      );
      if (response?.data?.data?.isInValidUser) {
        dispatch(setInValidUser(true));
      } else {
        dispatch(setInValidUser(false));
      }
      if (response.data.success) {
        setEmployeeData({
          name: "",
          empId: "",
          email: "",
          designationId: "",
          departmentId: "",
          unitId: "",
          isMeetingOrganiser: false,
          isAdmin: false,
          organizationId: organizationId,
        });

        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        fetchEmployeeData();
      } else {
        toast.error(response.data.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.error("Error creating employee:", error);
    } finally {
      setIsLoading(false);
    }
  };

  //Fetch Employee
  const fetchEmployees = async (bodyData) => {
    try {
      setIsFetching(true);
      const headerObject = {
        headers: {
          "Content-Type": "application/json",
          Authorization: accessToken,
        },
        params: { limit, page, order },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/V1/employee/listEmployee`,
        bodyData,
        headerObject
      );
      if (response?.data?.data?.isInValidUser) {
        dispatch(setInValidUser(true));
        // toast.error(response.data.message, {
        //   position: "top-right",
        //   autoClose: 4000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "colored",
        // });
      } else {
        dispatch(setInValidUser(false));
      }
      const data = response.data.data || {};

      setEmployees(data.employeeData);
      setTotalCount(data.totalCount || 0);
    } catch (error) {
      console.error("Error fetching employees:", error);
      setLoading(false);
    } finally {
      setIsFetching(false);
    }
  };
  useEffect(() => {
    if (!authData?.isInValidUser) {
      fetchEmployeeData();
    }
  }, [searchKey, page, limit, order, authData?.isInValidUser]);
  const handleSearch = (event) => {
    setSearchKey(event.target.value);
    setPage(1);
  };
  const fetchEmployeeData = async () => {
    const bodyData = searchKey
      ? { searchKey, organizationId }
      : { organizationId };
    if (!authData?.isInValidUser) {
      await fetchEmployees(bodyData);
    }
  };
  const handleRowsPerPageChange = (event) => {
    setLimit(event.target.value);
    setPage(1);
  };

  const totalPage = Math.ceil(totalCount / limit);
  const maxOption = Math.ceil(totalCount / 5) * 5;

  const handleClearSearch = () => {
    setSearchKey("");
    setPage(1);
    fetchEmployeeData();
  };

  //DELETE EMPLOYEE
  const formatDateTimeFormat = (date) => {
    const dateInDFormat = new Date(date);
    // Get day, month, and year
    let day = dateInDFormat.getUTCDate();
    const month = dateInDFormat.toLocaleString("en-US", { month: "short" });
    const year = dateInDFormat.getUTCFullYear();
    let dayFormatted = day < 10 ? "0" + day : day;
    // Get hours and minutes in 12-hour format
    let hours = dateInDFormat.getUTCHours();
    const minutes = dateInDFormat.getUTCMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    // Format minutes
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    const formattedDate = `${dayFormatted} ${month} ${year}`;
    const formattedTime = `${hours}:${formattedMinutes} ${ampm}`;
    return { formattedTime, formattedDate };
  };

  const handleDeleteConfirm = async () => {
    try {
      if (employeeToDelete) {
        await deleteEmployee(employeeToDelete?._id);
        setShowDeleteModal(false);
        setEmployeeToDelete(null);
      }
    } catch (error) {
      console.error("Error while deleting unit:", error);
    }
  };
  const deleteEmployee = async (employeeId) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/V1/employee/deleteEmployee/${employeeId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: accessToken,
            ip: await fetchIpAddress(),
          },
        }
      );
      if (response?.data?.data?.isInValidUser) {
        dispatch(setInValidUser(true));
        toast.error(response.data.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        dispatch(setInValidUser(false));
      }
      if (response.data.success) {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        const totalRemainingItems = totalCount - 1;
        const maxPages = Math.ceil(totalRemainingItems / limit);
        if (page > maxPages) {
          setPage(page - 1);
        }
        fetchEmployeeData();
        return response.data;
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.error("Error deleting Employee:", error);
      throw error;
    }
  };

  //Update Employee

  useEffect(() => {
    setEmpId(employeeData.empId || "");
    setEmail(employeeData.email || "");
    setDesignationId(employeeData.designationId || "");
    setDepartmentId(employeeData.departmentId || "");
    setUnitId(employeeData.unitId || "");
    setEditIsAdmin(employeeData.isAdmin || false);

    setEditIsMeetingOrganiser(employeeData.isMeetingOrganiser || false);

    setSelectedDesignation({
      label: designations.find((dep) => dep?._id === employeeData.designationId)
        ?.name,
      value: designations.find((dep) => dep?._id === employeeData.designationId)
        ?._id,
    });
    setSelectedDepartment({
      label: departments.find((dep) => dep?._id === employeeData.departmentId)
        ?.name,
      value: departments.find((dep) => dep?._id === employeeData.departmentId)
        ?._id,
    });
    SetSelectedUnit({
      label: units.find((dep) => dep?._id === employeeData.unitId)?.name,
      value: units.find((dep) => dep?._id === employeeData.unitId)?._id,
    });
  }, [employeeData, authData?.isInValidUser]);

  const updateNameValidationCheck = () => {
    const errors = {};
    const excludeHtml = /^[0-9a-zA-Z ,&,/-]+$/;
    if (!name.trim()) {
      errors.editName = "Employee Name is required";
    } else if (!excludeHtml.test(name)) {
      errors.editName = constatntMessages.invalidInput;
    }
    setErrors(errors);
  };

  const updateEmailValidationCheck = () => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim()) {
      errors.editEmail = "Email is required";
    } else if (!emailRegex.test(email)) {
      errors.editEmail = "Invalid email format";
    }
    setErrors(errors);
  };

  const updateEmpIdValidationCheck = () => {
    const errors = {};
    const excludeHtml = /^[0-9a-zA-Z -.(),_/]+$/;
    if (!empId.trim()) {
      errors.editEmpId = "Employee ID is required";
    } else if (!excludeHtml.test(empId)) {
      errors.editEmpId = constatntMessages.invalidInput;
    }
    setErrors(errors);
  };

  const handleEditClick = (emp) => {
    setSelectedEmployee(emp);
    console.log("unit---", emp);
    setIsConfirming(false);
    setName(emp.name);

    setEmpId(emp.empId);
    setEmail(emp.email);
    setDesignationId(emp.designationId);
    setDepartmentId(emp.departmentId);
    setUnitId(emp.unitId);
    setShowEditModal(true);
    setIsMeetingOrganiser(emp.isMeetingOrganiser);
    setEditIsMeetingOrganiser(emp.isMeetingOrganiser);
    setIsAdmin(emp.setIsAdmin);
    setEditIsAdmin(emp.isAdmin);
    setSelectedOption([
      {
        label: emp?.designationDetails?.name,
        value: emp?.designationDetails?._id,
      },
    ]);
    setSelectedDepartmentOption([
      {
        label: emp?.departmentDetails?.name,
        value: emp?.departmentDetails?._id,
      },
    ]);
    setSelectedUnitOption([
      {
        label: emp?.unitDetails?.name,
        value: emp?.unitDetails?._id,
      },
    ]);
    setErrors(false);
  };

  const editIsValidate = () => {
    const errors = {};
    const excludeHtml = /^[0-9a-zA-Z -.(),-,_/]+$/;

    if (!name.trim()) {
      errors.editName = "Employee Name is required";
    } else if (!excludeHtml.test(name)) {
      errors.editName = constatntMessages.invalidInput;
    } else if (name.trim().length < 2 || name.trim().length > 100) {
      errors.editName = "Name must be  2 - 100  characters";
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim()) {
      errors.editEmail = "Email is required";
    } else if (!emailRegex.test(email)) {
      errors.editEmail = "Invalid email format";
    }

    if (!empId.trim()) {
      errors.editEmpId = "Employee ID is required";
    } else if (!excludeHtml.test(empId)) {
      errors.editEmpId = constatntMessages.invalidInput;
    }

    setErrors(errors);
    return errors;
  };

  const handleEditSave = async (e) => {
    const newErrors = editIsValidate();
    setIsEditLoading(true);
    if (Object.keys(newErrors).length === 0) {
      try {
        const updateEmployee = {
          name,
          empId,
          email,
          departmentId,
          designationId,
          unitId,
          organizationId,
          isAdmin: editIsAdmin,
          isMeetingOrganiser: true,
          //  isMeetingOrganiser: editIsMeetingOrganiser,
        };

        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/api/V1/employee/editEmployee/${selectedEmployee?._id}`,
          updateEmployee,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: accessToken,
              ip: await fetchIpAddress(),
            },
          }
        );
        if (response?.data?.data?.isInValidUser) {
          dispatch(setInValidUser(true));
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          dispatch(setInValidUser(false));
        }
        if (response.data.success) {
          setName("");
          setEmpId("");
          setEmail("");
          setDepartmentId("");
          setDesignationId("");
          setUnitId("");
          setEditIsMeetingOrganiser("");
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setSelectedEmployee(null);
          setShowEditModal(false);
          fetchEmployeeData();
          setShowEditModal(false);
          setErrors({ ...errors, editEmployeeData: "" });
        } else {
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setShowEditModal(true);
        }
      } catch (error) {
        toast.error(error.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        console.log("Error while updating Employee:", error);
      } finally {
        setIsEditLoading(false);
      }
    } else {
      setIsEditLoading(false);
    }
  };

  const [isConfirming, setIsConfirming] = useState(false);

  const handleSaveClick = () => {
    const newErrors = editIsValidate();
    if (Object.keys(newErrors).length === 0) {
      setIsConfirming(true);
    } else {
      setShowEditModal(true);
      setIsConfirming(false);
    }
  };

  const handleConfirmSave = () => {
    handleEditSave();
    setShowEditModal(false);
    setIsConfirming(false);
  };

  const handleCancel = () => {
    setIsConfirming(false);
  };
  const handleClose = () => {
    setShowEditModal(false);
    setIsConfirming(false);
  };
  const [employeeList, setEmployeeList] = useState(employees);

  console.log(employeeData.empId);
  const handleToggle = (empId, newStatus) => {
    setEmployees((prevEmployees) =>
      prevEmployees.map((employee) =>
        employee?._id === empId
          ? { ...employee, isActive: newStatus }
          : employee
      )
    );
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  //View Single Employee Details
  const handleViewClick = async (employee) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/V1/employee/viewSingleEmployee/${employee?._id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: accessToken,
          },
        }
      );
      if (response?.data?.data?.isInValidUser) {
        dispatch(setInValidUser(true));
        toast.error(response.data.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        dispatch(setInValidUser(false));
      }
      setViewEmployee(response.data.data);

      setShowViewModal(true);
    } catch (error) {
      console.error("Error fetching single employee details", error);
    }
  };

  //XLSX file Importing
  const fileInputRef = useRef(null);
  const handleFileChange = (event) => {
    const selectedFile = event.target.files?.[0];
    if (!selectedFile) {
      setFile(null);
      setFileName("");
      setUploadError("No file selected. Please choose a file.");
      return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        setFile(selectedFile);
        setFileName(selectedFile.name);
        setUploadError("");
      } catch (error) {
        setUploadError("Error processing the file. Ensure it's valid.");
        console.error(error);
      }
    };

    reader.onerror = () => {
      setUploadError("Error reading the file.");
      console.error(reader.error);
    };

    reader.readAsArrayBuffer(selectedFile);
  };

  const handleFileUpload = async () => {
    if (!file) {
      setUploadError("No file selected. Please choose a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    // formData.append("organizationId", organizationId);
    try {
      setIsUploading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/V1/employee/import-employee/${organizationId}`,
        formData,
        {
          headers: {
            Authorization: accessToken,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Response Data:", response.data);
      if (response?.data?.data?.isInValidUser) {
        dispatch(setInValidUser(true));
        toast.error(response.data.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        dispatch(setInValidUser(false));
      }
      if (response.data.success) {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setFile(null);
        setFileName("");
        setUploadCompleted(true);
        fetchEmployeeData();
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }

        setTimeout(() => setResponseMessage(""), 6000);
      } else {
        fetchEmployeeData();
        toast.error(response.data.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }

      if (response.data.errorFileUrl) {
        const fileUrl = `${process.env.REACT_APP_API_URL}${response.data.errorFileUrl}`;
        await downloadErrorFile(fileUrl);
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.response?.data ||
        error.message ||
        "Error during file upload.";
      setUploadError(errorMessage);
      setTimeout(() => setUploadError(""), 6000);
      console.error("Error during file upload:", errorMessage);
    } finally {
      setIsUploading(false);
    }
  };

  const downloadErrorFile = async (fileUrl) => {
    try {
      const response = await axios.get(fileUrl, { responseType: "blob" });

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "error_report.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);

      setFile(null);
      setFileName("");

      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } catch (error) {
      console.error("Error downloading the error file:", error);
    }
  };

  return (
    <div>
      {!accessToken ? <Navigate to="/login" /> : null}
      {/* <Header />
      <Sidebar /> */}
      <div className="main-content">
        <div className="Action-list-page">
          <div className="meeting-header-text">
            <h4>Add Employee</h4>
          </div>
          <div className="mt-3 table-box responsive-table">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="mb-1">
                      Employee Name<span className="star-mark"> *</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Employee"
                      name="name"
                      value={employeeData.name}
                      onChange={handleChange}
                      onBlur={nameValidationCheck}
                    />
                    {errors.name && (
                      <span className="error-message">{errors.name}</span>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="mb-1">
                      Employee ID<span className="star-mark"> *</span>
                    </label>
                    <input
                      name="empId"
                      value={employeeData.empId}
                      onChange={handleChange}
                      type="text"
                      placeholder="Enter Employee ID"
                      onBlur={empIdValidationCheck}
                    />
                    {errors.empId && (
                      <span className="error-message">{errors.empId}</span>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="mb-1">
                      Email<span className="star-mark"> *</span>
                    </label>
                    <input
                      type="email"
                      placeholder="Enter Email"
                      name="email"
                      value={employeeData.email}
                      onChange={handleChange}
                      onBlur={emailValidationCheck}
                    />{" "}
                    {errors.email && (
                      <span className="error-message">{errors.email}</span>
                    )}
                  </div>
                </div>

                <DesignationDropdown
                  designations={designations}
                  employeeData={employeeData}
                  handleChange={handleChange}
                  errors={errors}
                />

                <DepartmentDropdown
                  departments={departments}
                  employeeData={employeeData}
                  handleChange={handleChange}
                  errors={errors}
                />

                <div className="col-md-4 col-sm-6">
                  {" "}
                  <UnitDropdown
                    units={units}
                    employeeData={employeeData}
                    handleChange={handleChange}
                    errors={errors}
                  />
                </div>
              </div>

              <div className="meeting-organiser d-flex align-items-center form-group">
                {/* <input
                  type="checkbox"
                  name="isMeetingOrganiser"
                  checked={employeeData.isMeetingOrganiser}
                  onChange={handleChange}
                />{" "}
                <span>Set as Meeting Organizer </span>{" "} */}
                {/* <span className="me-2">Or</span> */}
                <input
                  type="checkbox"
                  name="isAdmin"
                  checked={employeeData.isAdmin}
                  onChange={handleChange}
                />
                <span>Set as Admin</span>
              </div>
              {isLoading ? (
                <LoaderButton />
              ) : (
                <button
                  className="create-meeting-button Mom-btn"
                  disabled={isLoading}
                >
                  <p>Submit</p>
                </button>
              )}
            </form>
            <div className="row d-flex align-items-center mt-3">
              {/* <div className="col-xl-4 ">
                <button
                  className="download-sample"
                  onClick={() => {
                    const link = document.createElement("a");
                    link.href = xlsxFile;
                    link.download = "EmployeeData.xlsx";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }}
                >
                  <img src={download} className="material-icons" alt="Download Icon" />
                  <p>Download The Sample XLSX File</p>
                </button>

              </div> */}

              {/* <div className="col-xl-4">
                <div className="row d-flex align-items-center">
                  <div className="col-xl-9">
                    <div className="file file--upload form-group mb-0">
                      <label
                        htmlFor="input-file"
                        className="complaint-label-text height-input"
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        {/* <i className="material-icons">
                          <img
                            alt="Upload"
                            title="Upload"
                            src="https://intranet.ntspl.co.in/assets/images/upload-file.svg"
                          />
                         
                        </i> */}
              {/* <img src={upload} className="material-icons"></img>
                        Import employee data */}
              {/* </label> */}
              {/* <input
                        id="input-file"
                        type="file"
                        accept=".csv, .xlsx"
                        ref={fileInputRef}
                        aria-label="Upload"
                        className="ng-untouched ng-pristine ng-valid"
                        style={{ display: "none" }}
                        onChange={(event) => {
                          handleFileChange(event);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-xl-3">
                    {file && (
                      <button
                        onClick={handleFileUpload}
                        disabled={isUploading}

                        className="create-meeting-button Mom-btn d-flex align-items-center"
                      // style={{
                      //   marginTop: "10px",
                      //   padding: "10px 20px",
                      //   backgroundColor: "#007bff",
                      //   color: "#fff",
                      //   border: "none",
                      //   borderRadius: "4px",
                      //   cursor: isUploading ? "not-allowed" : "pointer",
                      // }}
                      >

                        <p>{isUploading ? "Uploading..." : "Upload"}</p>
                      </button>
                    )}
                  </div>

                </div> */}

              {/* <div
                  className="dropzone"
                  style={{
                    border: "1px dashed #ccc",
                    borderRadius: "4px",
                    padding: "20px",
                    textAlign: "center",
                  }}
                  >
                  <input
                    type="file"
                    accept=".csv, .xlsx"
                    ref={(input) => input && (input.value = "")}
                    onChange={handleFileChange}
                    style={{
                      display: "none",
                    }}
                    id="fileInput"
                  />
                  <label
                    htmlFor="fileInput"
                    style={{
                      cursor: "pointer",
                      color: "#007bff",
                      textDecoration: "underline",
                    }}
                  >
                    Drag 'n' drop a file here, or click to select a file
                  </label>
                  </div>
                  {uploadError && (
                  <p style={{ color: "red", marginTop: "10px" }}>{uploadError}</p>
                  )}
                  {responseMessage && (
                  <p style={{ color: "green", marginTop: "10px" }}>{responseMessage}</p>
                  )}
                   {file && !uploadCompleted && (
                  <button
                    onClick={handleFileUpload}
                    disabled={isUploading}
                    style={{
                      marginTop: "10px",
                      padding: "10px 20px",
                      backgroundColor: "#007bff",
                      color: "#fff",
                      border: "none",
                      borderRadius: "4px",
                      cursor: isUploading ? "not-allowed" : "pointer",
                    }}
                  >
                    {isUploading ? "Uploading..." : "Upload"}
                  </button>
                  )} */}

              {/* Show the upload button only if a file is selected */}
              {/* {file && (
                  <button
                    onClick={handleFileUpload}
                    disabled={isUploading}
                    style={{
                      marginTop: "10px",
                      padding: "10px 20px",
                      backgroundColor: "#007bff",
                      color: "#fff",
                      border: "none",
                      borderRadius: "4px",
                      cursor: isUploading ? "not-allowed" : "pointer",
                    }}
                  >
                    {isUploading ? "Uploading..." : "Upload"}
                  </button>
                )} */}

              {/* Display error or success messages */}
              {/* {uploadError && (
                  <p style={{ color: "red", marginTop: "10px" }}>{uploadError}</p>
                )}
                {responseMessage && (
                  <p style={{ color: "green", marginTop: "10px" }}>{responseMessage}</p>
                )}

              </div> */}
            </div>

            {/* <div className="meeting-organiser d-flex align-items-center form-group">
              <a href={xlsxFile} download>
                <button>Download The Sample XLSX File</button>
              </a>
            </div> */}
          </div>

          {/* <div className="mt-2 table-box responsive-table">
            <div className="row">
              <div className="col-xl-4">
                <div className="form-group">
                  <div className="file file--upload form-group mb-0"><label  for="input-file" className="complaint-label-text height-input"><i className="material-icons"><img alt="Upload" title="Upload" src="https://intranet.ntspl.co.in//assets/images/upload-file.svg"/></i>Import employee data</label><input  id="input-file" type="file"  aria-label="Upload" className="ng-untouched ng-pristine ng-valid"/></div>
                </div>
              </div>
            </div>
            <div className="meeting-organiser d-flex align-items-center form-group">
              <a href={xlsxFile} download>
                <button>Download The Sample XLSX File</button>
              </a>
            </div>
          </div> */}

          {/* <div className="meeting-header-text respmt-20">
            <h4>Manage Employees</h4>
          </div> */}
          <div className="meeting-header-text mt-4">
            <h4 className="mt-2">Manage Employees</h4>
          </div>
          <div className="mt-2 table-box responsive-table">
            <div className="tbl-text-search">
              <div className="left-tbl-text">
                {totalCount > 0 ? (
                  <p>
                    Showing {(page - 1) * limit + 1} to{" "}
                    {Math.min(page * limit, totalCount)} of {totalCount}{" "}
                    Employee(s)
                  </p>
                ) : null}
              </div>
              <div className="search-box">
                <input
                  type="search"
                  autoComplete="off"
                  placeholder="Search By Employee"
                  value={searchKey}
                  onChange={handleSearch}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#4F2CC8"
                  className="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                </svg>
              </div>
            </div>

            {isFetching ? (
              <div className="tbl-text-search">
                <div className="meeting-page loader-cont">
                  <Loader />
                </div>
              </div>
            ) : employees?.length > 0 ? (
              <>
                <table className="mt-4 table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Employee Name</th>
                      <th>Employee ID</th>

                      <th>Updated At</th>
                      {/* <th>Is Meeting Organizer</th> */}
                      <th>Is Admin</th>
                      <th>Status</th>
                      <th className="action-col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {employees.map((employee) => (
                      <tr key={employee?._id}>
                        <td data-label="Employee Name" className="word-break">
                          {employee.isAdmin === true
                            ? employee.name + "(Admin)"
                            : employee.name}
                        </td>
                        <td data-label="Employee ID">{employee.empId}</td>

                        <td
                          data-label="Updated At"
                          className="meeting-td-width-5"
                        >
                          {employee.formattedDate}
                          <p className="detail-date-time">
                            {employee.formattedTime}
                          </p>
                        </td>

                        <td
                          data-label="Is Admin"
                          className="meeting-td-width-5"
                        >
                          {employee.isAdmin ? "Yes" : "No"}
                        </td>
                        <td
                          data-label="Status"
                          className="meeting-td-width-5 metting-sts"
                        >
                          <CustomizedSwitches
                            key={employee._id}
                            employee={employee}
                            onToggle={() => {
                              console.log(`Toggled ${employee._id}`);
                              fetchEmployeeData();
                            }}
                            disableCondition={userId == employee._id}
                          />
                        </td>

                        <td
                          data-label="Action"
                          className="text-align-center meeting-td-width-7"
                        >
                          <Dropdown>
                            <div className="dropdown-opt d-inline-block menu-dropdown custom-dropdown">
                              <Dropdown.Toggle id="dropdown-basic">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="#000"
                                  className="bi bi-three-dots-vertical"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                </svg>
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => handleViewClick(employee)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    className="bi bi-eye me-2"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                  </svg>
                                  View
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => handleEditClick(employee)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    className="me-2 bi bi-pencil-square"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                    <path
                                      fillRule="evenodd"
                                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                                    />
                                  </svg>
                                  Edit
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </div>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="tbl-bottom">
                  <div className="left-tbl-bottom">
                    {/* Left Arrow Button */}
                    {page !== 1 ? (
                      <button
                        className="left-arrow"
                        onClick={() => setPage(page > 1 ? page - 1 : 1)}
                        disabled={page === 1}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#fff"
                          className="bi bi-chevron-left"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                          />
                        </svg>
                      </button>
                    ) : null}

                    {/* Pagination Component from MUI without arrows */}
                    <Stack spacing={2}>
                      <Pagination
                        count={totalPage}
                        page={page}
                        siblingCount={0}
                        boundaryCount={2}
                        onChange={handlePageChange}
                        hidePrevButton
                        hideNextButton
                        sx={{
                          "& .Mui-selected": {
                            backgroundColor: "#388d27 !important",
                            color: "#fff !important",
                          },
                        }}
                      />
                    </Stack>

                    {/* Right Arrow Button */}
                    {page < totalPage ? (
                      <button
                        className="right-arrow"
                        onClick={() =>
                          setPage(page * limit < totalCount ? page + 1 : page)
                        }
                        disabled={page * limit >= totalCount}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#fff"
                          className="bi bi-chevron-right"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                          />
                        </svg>
                      </button>
                    ) : null}
                  </div>

                  {/* Rows Per Page Selector */}
                  <div className="right-tbl-bottom">
                    <p>Rows Per Page</p>
                    <select
                      className="no-opt-box"
                      name="limit"
                      onChange={handleRowsPerPageChange}
                      value={limit}
                    >
                      {Array.from({ length: maxOption / 5 }).map((_, index) => {
                        const value = (index + 1) * 5;
                        return (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </>
            ) : (
              <div className="no-data-img">
                <>
                  <Alert
                    status={"info"}
                    message={"No data available."}
                    timeoutSeconds={0}
                  />
                  <NoDataFound dataType={"meeting"} />
                  <div className="button-outer">
                    <Button variant="primary" onClick={handleClearSearch}>
                      Clear
                    </Button>
                  </div>
                </>
              </div>
            )}
          </div>
          <Modal
            show={showViewModal}
            onHide={() => setShowViewModal(false)}
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title>View Employee Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <table className="table table-bordered modal-table">
                <tbody>
                  <tr>
                    <th>Employee Name</th>
                    <td>{viewEmployee?.name}</td>
                  </tr>
                  <tr>
                    <th>Employee ID</th>
                    <td>{viewEmployee?.empId}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{viewEmployee?.email}</td>
                  </tr>
                  <tr>
                    <th>Designation</th>
                    <td>{viewEmployee?.designationDetails?.name}</td>
                  </tr>
                  <tr>
                    <th>Department</th>
                    <td>{viewEmployee?.departmentDetails?.name}</td>
                  </tr>
                  <tr>
                    <th>Unit</th>
                    <td>{viewEmployee?.unitDetails?.name}</td>
                  </tr>
                  {/* <tr>
                    <th>Is Meeting Organizer</th>
                    <td>{viewEmployee?.isMeetingOrganiser ? "Yes" : "No"}</td>
                  </tr> */}
                  <tr>
                    <th>Admin</th>
                    <td>{viewEmployee?.isAdmin ? "Yes" : "No"}</td>
                  </tr>
                  <tr>
                    <th>Status</th>
                    <td>{viewEmployee?.isActive ? "Active" : "Deactive"}</td>
                  </tr>
                </tbody>
              </table>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant=""
                className="add-minutes Mom-btn"
                onClick={() => setShowViewModal(false)}
              >
                <p> Close</p>
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={showEditModal}
            onHide={() => setShowEditModal(false)}
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit Employee</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {isConfirming ? (
                <p>Are you sure you want to update this employee?</p>
              ) : (
                <Form className="employee-form">
                  <Form.Group
                    controlId="designationName"
                    className="form-group"
                  >
                    <Form.Label className="mb-1">
                      Employee Name<span className="star-mark"> *</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Employee Name"
                      value={name}
                      autoComplete="off"
                      onBlur={updateNameValidationCheck}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Group>
                  {errors?.editName && (
                    <span className="error-message">{errors?.editName}</span>
                  )}

                  <Form.Group controlId="employeeId" className="form-group">
                    <Form.Label className="mb-1">
                      Employee ID<span className="star-mark"> *</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Employee ID"
                      value={empId}
                      autoComplete="off"
                      onBlur={updateEmpIdValidationCheck}
                      onChange={(e) => setEmpId(e.target.value)}
                    />
                  </Form.Group>
                  {errors?.editEmpId && (
                    <span className="error-message">{errors?.editEmpId}</span>
                  )}

                  <Form.Group controlId="email" className="form-group">
                    <Form.Label className="mb-1">
                      Email<span className="star-mark"> *</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Email"
                      value={email}
                      autoComplete="off"
                      onBlur={updateEmailValidationCheck}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                  {errors?.editEmail && (
                    <span className="error-message">{errors?.editEmail}</span>
                  )}
                  <EditDesignationDropdown
                    designations={designations}
                    selectedDesignationId={selectedOption}
                    handleChange={handleDesignationChange}
                    value={selectedOption}
                  />
                  <EditDepartmentDropdown
                    departments={departments}
                    selectedDepartmentId={selectedDepartmentOption}
                    handleChange={handleDepartmentChange}
                    value={selectedDepartmentOption}
                  />
                  <EditUnitDropdown
                    units={units}
                    selectedUnitId={selectedUnitOption}
                    handleChange={handleUnitChange}
                    value={selectedUnitOption}
                    errors={errors}
                  />
                  {selectedEmployee?._id?.toString() ===
                  employeeDatas?.userData?._id?.toString() ? null : (
                    <div className="meeting-organiser d-flex align-items-center">
                      {/* <input
                      type="checkbox"
                      name="editIsMeetingOrganiser"
                      value={isMeetingOrganiser}
                      checked={editIsMeetingOrganiser ? true : false}
                      onChange={editHandleChange}
                    />{" "}
                    <span>Set as Meeting Organizer </span>{" "} */}
                      {/* <span className="me-2">Or</span> */}
                      <input
                        type="checkbox"
                        name="editIsAdmin"
                        value={isAdmin}
                        checked={editIsAdmin ? true : false}
                        onChange={editHandleChange}
                      />
                      <span>Set as Admin</span>
                    </div>
                  )}
                </Form>
              )}
            </Modal.Body>
            <Modal.Footer>
              {isConfirming ? (
                <>
                  <Button
                    variant=""
                    className="add-minutes Mom-btn"
                    onClick={handleCancel}
                  >
                    <p>Cancel</p>
                  </Button>
                  <Button
                    variant=""
                    className="create-meeting-button Mom-btn "
                    onClick={handleConfirmSave}
                  >
                    <p> Confirm Update</p>
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant=""
                    className="add-minutes Mom-btn"
                    onClick={() => setShowEditModal(false)}
                  >
                    <p>Close</p>
                  </Button>
                  {isEditLoading ? (
                    <LoaderButton />
                  ) : (
                    <Button
                      variant=""
                      className="create-meeting-button Mom-btn"
                      onClick={handleSaveClick}
                    >
                      <p>Save Changes</p>
                    </Button>
                  )}
                </>
              )}
            </Modal.Footer>
          </Modal>
          <Modal
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirm Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete this Department?
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant=""
                className="add-minutes Mom-btn"
                onClick={handleDeleteConfirm}
              >
                <p>Delete</p>
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

// const handleImport = async (file) => {
//   const formData = new FormData();
//   formData.append("file", file);

//   try {
//       const response = await axios.post("/api/import-employee", formData, {
//           headers: { "Content-Type": "multipart/form-data" },
//       });

//       if (response.data.errorFileUrl) {
//           const errorFileUrl = response.data.errorFileUrl;
//           window.open(errorFileUrl, "_blank");
//       } else {
//           console.log("Import Successful:", response.data.savedData);
//       }
//   } catch (error) {
//       console.error("Import Error:", error.response?.data?.message || error.message);
//   }
// };

export default Employee;
