import React, { useState, useEffect } from "react";
import profileImage from "../../../assets/images/profile.png";
import "./Header.css";
import { useNavigate, Navigate, Link } from "react-router-dom";
// @ts-ignore
import {
  Button,
  Popover,
  OverlayTrigger,
  Accordion,
  Tooltip,
  Dropdown,
} from "react-bootstrap";
// @ts-ignore
import {
  logOut,
  processLogout,
  setInValidUser,
} from "../../../redux/actions/authActions/authAction";
import { useSelector, useDispatch } from "react-redux";
import { viewSingleUser } from "../../../redux/actions/userAction/userAction";
import CommonModal from "../CommonModal";
import { logOutMessage } from "../../../constants/constatntMessages";
import { setCreateNewMeetingPage } from "../../../redux/actions/meetingActions/MeetingAction";
import io from "socket.io-client";
import { viewAllNotifications } from "../../../redux/actions/notificationAction/NotificationAction";
import { openSidebar } from "../../../redux/actions/commonActions/commonAction";
import { setOrganizationData } from "../../../redux/actions/organizationAction/OrganizationAction";
import { toast } from "react-toastify";
import axios from "axios";

const Header = () => {
  document.body.classList.remove("landing-page");
  // const socket = io.connect("http://192.168.1.125:8080", {
  //   transports: ["websocket"],
  // });
  console.log(process.env.REACT_APP_API_URL);
  const socket = io.connect(process.env.REACT_APP_API_URL, {
    transports: ["websocket"],
  });

  const [name, setName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const authData = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const userData = JSON.parse(localStorage.getItem("userData"));
  console.log(localStorage.getItem("userData"));
  const accessToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const handleLogout = () => {
    //  alert("logout")
    if (localStorage.getItem("accessToken")) {
      dispatch(processLogout(localStorage.getItem("accessToken")));
    }

    setIsModalOpen(false);
    socket.emit("notification", "message from frontend");
  };

  const employeeData = useSelector((state) => state.user);
  const notificationData = useSelector((state) => state.notification);
  const [isToolTipOpen, setIsToolTipOpen] = useState(true);
  const [cameFromNotification, setCameFromNotification] = useState(false);

  let notificationCalled = false;
  useEffect(() => {
    if (userData && localStorage.getItem("accessToken")) {
      setName(employeeData.userData?.name);
      dispatch(
        viewSingleUser(userData._id, localStorage.getItem("accessToken"))
      );
    } else {
      navigate("/login");
    }

    socket.on("connection", () => {
      console.log(`I'm connected with the back-end`);
    });

    socket.on("notification", (message) => {
      console.log(`notification is ${message}`);
      notificationCalled = true;

      if (localStorage.getItem("accessToken")) {
        //  alert(3)
        //  alert(accessToken)

        if (!authData.isInValidUser) {
          //alert(1)
          dispatch(
            viewAllNotifications(
              { organizationId: userData.organizationId },
              localStorage.getItem("accessToken")
            )
          );
        }
      }
    });

    if (notificationCalled === false && localStorage.getItem("accessToken")) {
      if (!authData.isInValidUser) {
        // alert(2)
        dispatch(
          viewAllNotifications(
            { organizationId: userData?.organizationId },
            localStorage.getItem("accessToken")
          )
        );
      }
    }
    return () => {};
  }, [notificationData.isSuccess, authData.isInValidUser]);

  useEffect(() => {
    if (!authData.isInValidUser && userData) {
      fetchOrganization(userData?.organizationId);
    }
  }, [authData.isInValidUser]);

  const fetchOrganization = async (organizationId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/V1/organization/viewSingleOrganization/${organizationId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: accessToken,
          },
        }
      );

      if (response?.data?.data?.isInValidUser) {
        dispatch(setInValidUser(true));
        toast.error(response.data.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        dispatch(setInValidUser(false));
      }
      dispatch(setOrganizationData(response.data.data));
      return response.data;
    } catch (error) {
      console.error("Error fetching organization data:", error);
      throw error;
    }
  };

  const nitificationDatalength =
    employeeData?.userData?.isAdmin === true
      ? notificationData?.notificationList?.filter(
          (notification) => notification?.isRead === false
        )?.length
      : notificationData?.notificationList?.filter(
          (notification) =>
            notification?.allowedUsers?.includes(userData?._id?.toString()) &&
            notification?.isRead === false
        )?.length;

  const tooltip = (attendee) => {
    return (
      <Tooltip id="tooltip">
        {attendee?.name} / {attendee?.email}
      </Tooltip>
    );
  };

  const renderTooltip = (nitificationDatalength) => (
    <Tooltip id="notification-tooltip">{nitificationDatalength}</Tooltip>
  );

  const handleNotificationClick = () => {
    setCameFromNotification(true);
  };

  return (
    <>
      {!localStorage.getItem("accessToken") ||
      employeeData?.userData === null ? (
        <Navigate to="/login" />
      ) : null}
      {!accessToken ? <Navigate to="/login" /> : null}
      <section className="topbar">
        <div
          className="topbar-1"
          onMouseEnter={() => {
            setIsToolTipOpen(true);
          }}
        >
          <div className="topbar1-content">
            <div className="d-flex align-items-center">
              <button
                className="ps-0 sidebar-open-btn "
                onClick={() => dispatch(openSidebar())}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  fill="#000"
                  className="bi bi-list"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
                  />
                </svg>
              </button>
              <div className="meeting">MinutesVault</div>
            </div>

            <div className="top-right-svg headerpprofile">
              {employeeData?.userData?.isMeetingOrganiser === true ||
              employeeData?.userData?.isAdmin ? (
                <>
                  <div className="create-meeting-button">
                    <Link
                      to="/create-meeting"
                      state={{ isNewMeeting: true }}
                      style={{ textDecoration: "none" }}
                    >
                      <button
                        className="create-meeting-button Mom-btn"
                        onClick={() => {
                          dispatch(setCreateNewMeetingPage(true));
                        }}
                      >
                        <p>Create Meeting</p>
                      </button>
                    </Link>
                  </div>
                  <div className="line"></div>
                </>
              ) : null}

              <Link to="/notification" onClick={handleNotificationClick} state={{ cameFromNotification: true }} >
                {/* <div className="bell">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="#000"
                    className="bi bi-bell "
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2M8 1.918l-.797.161A4 4 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4 4 0 0 0-3.203-3.92zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5 5 0 0 1 13 6c0 .88.32 4.2 1.22 6" />
                  </svg>

                  <span>
                  {nitificationDatalength > 99
                    ? 99 + "+"
                     : nitificationDatalength}
                </span>
                </div> */}

                {nitificationDatalength > 99 ? (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      isToolTipOpen ? (
                        renderTooltip(nitificationDatalength)
                      ) : (
                        <></>
                      )
                    }
                    trigger={["hover", "focus"]}
                  >
                    <div className="bell">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        fill="#000"
                        className="bi bi-bell"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2M8 1.918l-.797.161A4 4 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4 4 0 0 0-3.203-3.92zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5 5 0 0 1 13 6c0 .88.32 4.2 1.22 6" />
                      </svg>
                      <span>99+</span>
                    </div>
                  </OverlayTrigger>
                ) : (
                  <div className="bell">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      fill="#000"
                      className="bi bi-bell"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2M8 1.918l-.797.161A4 4 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4 4 0 0 0-3.203-3.92zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5 5 0 0 1 13 6c0 .88.32 4.2 1.22 6" />
                    </svg>
                    <span>{nitificationDatalength}</span>
                  </div>
                )}
              </Link>
              <div className="line"></div>

              <Dropdown
                onToggle={() => {
                  setIsToolTipOpen(false);
                }}
              >
                <Dropdown.Toggle>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      isToolTipOpen ? tooltip(employeeData?.userData) : <></>
                    }
                    trigger={["hover", "focus"]}
                  >
                    <div className="d-flex admin-box">
                      <img src={profileImage} className="user" />
                      <span>
                        Hi,{" "}
                        {employeeData?.userData?.name?.split(" ")?.length > 0
                          ? employeeData?.userData?.name?.split(" ")[0]
                          : name}{" "}
                        {employeeData?.userData?.isAdmin ? "(Admin)" : null}
                      </span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-chevron-down"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                        />
                      </svg>
                    </div>
                  </OverlayTrigger>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Link
                    to="#"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Dropdown.Item
                      eventKey="4"
                      onClick={() => {
                        setIsModalOpen(true);
                        setIsToolTipOpen(false);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="19"
                        fill="currentColor"
                        className="bi bi-box-arrow-left me-2"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5.5 0 0 1 6.5 2h8A1.5.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0z"
                        />
                        <path
                          fill-rule="evenodd"
                          d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708z"
                        />
                      </svg>
                      Logout
                    </Dropdown.Item>
                  </Link>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        <CommonModal
          message={logOutMessage}
          title={"Logout"}
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          handleSubmit={handleLogout}
          buttonName={"Logout"}
        />
      </section>
    </>
  );
};

export default Header;
