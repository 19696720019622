import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  getMeetingRoomList,
  listAllUnitForMeetingForRoomCheck,
} from "../../redux/actions/meetingRoomAction/meetingRoomAction";
import { useSelector, useDispatch } from "react-redux";
import CommonStepper from "../Common/CommonStepper";
import { useLocation, useNavigate } from "react-router-dom";
import {
  setErrorMessage,
  updateIsCreateMeetingProcessed,
  updateIsNewMeetingPage,
  updateMeetingDetails,
  updateStep,
} from "../../redux/actions/meetingActions/MeetingAction";
import * as constantMessages from "../../constants/constatntMessages";
import "../Login/style/Login.css";
import LoaderButton from "../Common/LoaderButton";
import AddAttendees from "./AddAttendees";
import AddAgendas from "./AddAgendas";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { logOut, setInValidUser } from "../../redux/actions/authActions/authAction";
import { checkCharacterLeft } from "../../helpers/commonHelpers";
import { textBoxStrLength } from "../../config/config";
import Select from "react-select";
import ConfirmModal from "./ConfirmModal";
import { toast } from "react-toastify";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const EditMeeting = () => {
  const [isAmPm, setIsAmPm] = useState(null);

  useEffect(() => {
    const testTime = new Date().toLocaleTimeString();
    const is12Hour = testTime.includes("AM") || testTime.includes("PM");

    console.log("Detected Time Format:", is12Hour ? "12-hour" : "24-hour");
    setIsAmPm(is12Hour);
  }, []);
  const employeeData = useSelector((state) => state.user);
  const excludeHtml = /^[0-9a-zA-Z .,:;()/\-_\n]+$/;
  const regularExpression = /^[0-9a-zA-Z .,:@!#&$%;()/\-_&\n]+$/;
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  if (authData.isInValidUser && authData.isInvalidUserChecked) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }
  const accessToken = localStorage.getItem("accessToken");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const meetingRoomData = useSelector((state) => state.meetingRoom);
  const meetingData = useSelector((state) => state.meeting);
  const organizationData = useSelector((state) => state.organization);
  console.log(
    organizationData?.organizationData?.hostingDetails?.zoomCredentials
  );
  const zoomCred =
    organizationData?.organizationData?.hostingDetails?.zoomCredentials;
  const [selectedRoomOption, setSelectedRoomOption] = useState([
    { label: "Select Room", value: "" },
  ]);
  const [selectedUnitOption, setSelectedUnitOption] = useState([
    { label: "Select Unit", value: "" },
  ]);
  const [roomErrors, setRoomErrors] = useState({});

  const [step, setStep] = useState(0);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState(
    "Do you want to save the changes ?"
  );
  const [confirmTitle, setConfirmTitle] = useState("Update Meeting");
  const [errors, setErrors] = useState({});
  const [unitId, setUnitId] = useState(null);
  const [zoomError, setZoomError] = useState(null);

  const [formData, setFormData] = useState({
    title: "",
    mode: "physical",
    location: "manual",
    date: "",
    link: "",
    fromTime: "",
    toTime: "",
    roomId: "",
    locationData: "",
    linkType: null,
  });
  const location = useLocation();
  console.log(meetingData.step);
  const stateData = location.state;
  console.log(meetingData?.singleMeetingDetails?.locationDetails);

  useEffect(() => {
    if (formData.toTime) {
      toDateFieldValidationCheck(formData.toTime);
    }
  }, [formData.toTime]); // Runs whenever toTime updates
  useEffect(() => {
    if (formData.fromTime) {
      fromDateFieldValidationCheck(formData.fromTime);
    }
  }, [formData.fromTime]); // Runs whenever toTime updates

  useEffect(() => {
    //  alert("check");
    dispatch(updateIsNewMeetingPage(false));
    if (stateData?.isMeetingDataUpdate) {
      document.title = "Edit Meeting: MinutesVault";
    }

    if (meetingData?.singleMeetingDetails?.date) {
      var date = new Date(meetingData?.singleMeetingDetails?.date);
      var dateString = new Date(
        date?.getTime() - date?.getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0];
    }

    setStep(meetingData.step + 1);
    if (meetingData.singleMeetingDetails && stateData.isMeetingDataUpdate) {
      setFormData({
        ...formData,
        title: meetingData.singleMeetingDetails.title,
        mode: meetingData.singleMeetingDetails.mode.toLowerCase(),
        location: meetingData.singleMeetingDetails.locationDetails.isMeetingRoom
          ? "meetingroom"
          : "manual",

        link: meetingData.singleMeetingDetails.link
          ? meetingData.singleMeetingDetails.link
          : "",
        toTime: meetingData?.singleMeetingDetails?.actualToTime,
        fromTime: meetingData?.singleMeetingDetails?.actualFromTime,
        roomId: meetingData.singleMeetingDetails.locationDetails.roomId,
        locationData: meetingData.singleMeetingDetails.locationDetails.location
          ? meetingData.singleMeetingDetails.locationDetails.location
          : "",
        date: dateString ? dateString : null,
        linkType: meetingData?.singleMeetingDetails?.linkType
          ? meetingData?.singleMeetingDetails?.linkType
          : meetingData?.singleMeetingDetails?.hostDetails?.hostType
          ? meetingData?.singleMeetingDetails?.hostDetails?.hostType
          : null,
      });
      if (
        (meetingData.singleMeetingDetails.locationDetails.isMeetingRoom &&
          meetingData.step === 1) ||
        3
      ) {
        const unitPayload = {
          organizationId: userData.organizationId,
        };
        dispatch(listAllUnitForMeetingForRoomCheck(unitPayload, accessToken));
        if (meetingData?.singleMeetingDetails?.unitDetail) {
          const payload = {
            unitId: meetingData?.singleMeetingDetails?.unitDetail?._id,
            organizationId: userData?.organizationId,
          };

          dispatch(getMeetingRoomList(payload, accessToken));
        }
      }

      if (meetingData?.singleMeetingDetails?.locationDetails?.isMeetingRoom) {
        //   alert(3)

        setSelectedRoomOption({
          value: meetingData.singleMeetingDetails?.roomDetail[0]._id,
          label: meetingData.singleMeetingDetails?.roomDetail[0].title,
          name: "roomId",
        });
        if (meetingData?.singleMeetingDetails?.unitDetail) {
          setSelectedUnitOption({
            value: meetingData.singleMeetingDetails?.unitDetail._id,
            label: meetingData.singleMeetingDetails?.unitDetail.name,
            name: "unitId",
          });
          setUnitId(meetingData.singleMeetingDetails?.unitDetail._id);
        }
      }
      if (
        meetingData?.singleMeetingDetails?.locationDetails?.isMeetingRoom ===
        false
      ) {
        setSelectedRoomOption([{ label: "*Select Room", value: "" }]);
        setSelectedUnitOption([{ label: "*Select Unit", value: "" }]);
      }
    }
    return () => {
      setFormData({
        ...formData,
        title: "",
        mode: "physical",
        location: "manual",
        date: "",
        link: "",
        fromTime: "",
        toTime: "",
        roomId: "",
        locationData: "",
      });
    };
  }, [
    meetingData.step,
    meetingData.singleMeetingDetails,
    // meetingData.isSuccess,
  ]);
  console.log(meetingData.singleMeetingDetails);
  console.log(formData);

  //pratishruti add code here
  // const handledateChange = async (e) => {
  //   dispatch(setErrorMessage(null));
  //   dispatch(updateIsCreateMeetingProcessed(false));

  //   const { name, value } = e.target;
  //   console.log(`Changed Field: ${name}, Value: ${value}`);

  //   setFormData((prev) => {
  //     const updatedFormData = { ...prev, [name]: value };
  //     console.log("Updated formData:", updatedFormData);

  //     setErrors((prevErrors) => ({
  //       ...prevErrors,
  //       [name]: value ? "" : prevErrors[name],
  //     }));

  //     if (
  //       updatedFormData.linkType === "ZOOM" &&
  //       updatedFormData.date &&
  //       updatedFormData.fromTime &&
  //       updatedFormData.toTime
  //     ) {
  //       console.log("All fields filled, calling API...");
  //       checkZoomMeeting(updatedFormData);
  //     } else {
  //       console.log("Waiting for all required fields before calling API.");
  //       setZoomError(null); // Clear Zoom error if no Zoom meeting
  //     }

  //     return updatedFormData;
  //   });
  // };

  const handledateChange = async (name, value) => {
    dispatch(setErrorMessage(null));
    dispatch(updateIsCreateMeetingProcessed(false));
    setErrors({});
    let formattedValue = value; // Default to the provided value

    // Ensure the value is formatted correctly if it's a Day.js object

    if (dayjs.isDayjs(value)) {
      if (name === "date") {
        formattedValue = value
          .startOf("day")
          .format("YYYY-MM-DDT00:00:00.000[Z]"); // ✅ Keep Local Date
        console.log("Selected Date (ISO Format, Local Time):", formattedValue);
      } else if (name.includes("Time")) {
        formattedValue = value.format("HH:mm"); // ✅ Store Time in 24-hour format
        console.log(
          `Selected Time (${name} in 24-hour format):`,
          formattedValue
        );
      }
    }
    // if (dayjs.isDayjs(value)) {
    //   if (name === "date") {
    //     formattedValue = value.format("YYYY-MM-DD").toISOString(); // 🔹 Convert to ISO String (Keeps Local Date)
    //     console.log("Selected Date (ISO Format):", formattedValue);
    //   } else if (name.includes("Time")) {
    //     formattedValue = value.format("HH:mm"); // 🔹 Store Time in 24-hour format
    //     console.log(`Selected Time (${name} in 24-hour format):`, formattedValue);
    //   }
    // }

    console.log(`Changed Field: ${name}, Value: ${formattedValue}`);

    setFormData((prev) => {
      const updatedFormData = { ...prev, [name]: formattedValue };

      console.log("Updated formData:", updatedFormData);

      // Ensure zoomError state is updated correctly
      setZoomError((prevErrors) => ({
        ...prevErrors,
        [name]: formattedValue ? "" : prevErrors[name],
      }));

      // If `linkType` is changed, update it
      if (name === "linkType") {
        updatedFormData.linkType = formattedValue; // Ensure correct string storage
      }

      // ✅ Check Zoom meeting only when all required fields are filled
      if (
        updatedFormData.linkType === "ZOOM" &&
        updatedFormData.date &&
        updatedFormData.fromTime &&
        updatedFormData.toTime
      ) {
        console.log("All fields filled, calling Zoom API...");
        checkZoomMeeting(updatedFormData);
      } else {
        console.log("Waiting for all required fields before calling Zoom API.");
        setZoomError(null);
      }

      return updatedFormData;
    });
  };

  // API call function
  const checkZoomMeeting = async (formData) => {
    try {
      const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/check-zoom-meeting`;
      const headerObject = {
        headers: {
          "Content-Type": "application/json",
          Authorization: accessToken,
        },
      };

      const payload = {
        date: formData.date,
        fromTime: formData.fromTime,
        toTime: formData.toTime,
        organizationId: userData.organizationId,
      };

      console.log("Sending API request with payload:", payload);
      const response = await axios.post(webApiUrl, payload, headerObject);
      console.log("API Response:", response.data);
      if (response?.data?.data?.isInValidUser) {
        dispatch(setInValidUser(true));
        toast.error(response?.data?.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        dispatch(setInValidUser(false));
      }
      if (!response.data.success || response.data.data.existingZoomMeeting) {
        const errorMessage =
          response.data.message || "A Zoom meeting is already scheduled.";

        setZoomError(errorMessage);
        setErrors((prevErrors) => ({
          ...prevErrors,
          zoomError: errorMessage,
        }));
      } else {
        setZoomError(null);
        setErrors((prevErrors) => ({
          ...prevErrors,
          zoomError: "",
        }));
      }
    } catch (error) {
      console.error("API Error:", error);
      const errorMessage =
        error.response?.data?.message || "Something went wrong.";

      setZoomError(errorMessage);
      setErrors((prevErrors) => ({
        ...prevErrors,
        zoomError: errorMessage,
      }));
    }
  };

  //pratishruti add code here

  const handleRoomChange = async (e) => {
    // alert(1)
    dispatch(setErrorMessage(null));
    setErrors({});
    dispatch(updateIsCreateMeetingProcessed(false));
    setRoomErrors({});
    const { name, value } = e;
    setFormData({
      ...formData,
      [name]: value,
    });
    setSelectedRoomOption(e);
    if (!formData.date || !formData.fromTime || !formData.toTime) {
      return;
    }
    try {
      const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/check-meetingroom-availability`;
      const headerObject = {
        headers: {
          "Content-Type": "application/json",
          Authorization: accessToken,
        },
      };
      const payload = {
        roomId: value,
        date: formData.date,
        fromTime: formData.fromTime,
        toTime: formData.toTime,
        organizationId: userData.organizationId,
        meetingId: meetingData.singleMeetingDetails?._id
          ? meetingData.singleMeetingDetails?._id
          : undefined,
      };
      const response = await axios.post(webApiUrl, payload, headerObject);
      const resData = response.data;
      if (resData.success === false) {
        setRoomErrors((prevErrors) => ({
          ...prevErrors,
          room: resData.message,
        }));
      } else {
        setRoomErrors({});
      }
    } catch (error) {
      setErrors(error.response?.data?.message);
    }
  };

  const handleRoomChangeForDate = async (e) => {
    dispatch(updateIsCreateMeetingProcessed(false));
    setErrors({});
    const { name, value } = e.target;
    // alert(77)
    if (!formData.roomId) {
      //  alert(11)
      return;
    }
    try {
      //  alert(22)
      const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/check-meetingroom-availability`;
      const headerObject = {
        headers: {
          "Content-Type": "application/json",
          Authorization: accessToken,
        },
      };
      const payload = {
        roomId: formData.roomId,
        date: name === "date" ? value : formData.date,
        fromTime: name === "fromTime" ? value : formData.fromTime,
        toTime: name === "toTime" ? value : formData.toTime,
        organizationId: userData.organizationId,
      };
      const response = await axios.post(webApiUrl, payload, headerObject);
      const resData = response.data;
      if (resData.success === false) {
        setRoomErrors((prevErrors) => ({
          ...prevErrors,
          room: resData.message,
        }));
      } else {
        setRoomErrors({});
      }
    } catch (error) {
      setErrors(error.response?.data?.message);
    }
  };
  console.log("roomErrors--", roomErrors);
  console.log("roomErrors--", meetingData?.errorMessage);
  const submitMeetingDetails = (e) => {
    // alert(2)
    // alert("clicked")
    e.preventDefault();

    let sendNotification = false;
    let reScheduled = false;
    if (errors.room) newErrors.room = errors.room;
    if (errors.date) newErrors.date = errors.date;
    if (errors.room) {
      newErrors.room = errors.room;
    }
    const newErrors = validateForm(formData);
    setErrors(newErrors);
    console.log(newErrors);
    if (formData.linkType === "ZOOM" && zoomError) {
      console.error("Zoom Validation Error:", zoomError);
      setErrors((prevErrors) => ({
        ...prevErrors,
        zoomError: zoomError,
      }));
      return;
    }
    // console.log('roomErrors--', Object.keys(roomErrors).length)
    // console.log('roomErrors--', meetingData?.errorMessage)
    if (
      Object.keys(roomErrors).length !== 0 ||
      meetingData?.errorMessage !== null
    ) {
      // alert('ddd')
      setErrors(newErrors);
      return;
    }

    dispatch(setErrorMessage(null));
    setRoomErrors({});

    if (Object.keys(newErrors).length === 0) {
      let locationDetails = {};
      if (formData.location === "meetingroom") {
        locationDetails["isMeetingRoom"] = true;
        locationDetails["roomId"] = formData.roomId;
      } else {
        locationDetails["isMeetingRoom"] = false;
        locationDetails["location"] = formData.locationData;
      }

      if (meetingData.singleMeetingDetails || stateData.isMeetingDataUpdate) {
        const meetingId = meetingData?.singleMeetingDetails?._id;
        const payload = {
          isEditMeeting: stateData.isMeetingDataUpdate
            ? stateData.isMeetingDataUpdate
            : false,
          date: new Date(formData.date),
          locationDetails,
          organizationId: userData.organizationId,
          mode: formData.mode.toUpperCase(),
          fromTime: formData.fromTime,
          toTime: formData.toTime,
          title: formData.title,
          link: formData.link,
          step: 1,
          sendNotification,
          reScheduled,
          isUpdate:
            stateData.isMeetingDataUpdate &&
            meetingData.singleMeetingDetails.step === 3
              ? true
              : false,

          linkType: formData.linkType ? formData.linkType : undefined,
        };
        const fromUpdate =
          meetingData?.singleMeetingDetails?.step === 3 ? true : false;
        console.log(fromUpdate);
        dispatch(
          updateMeetingDetails(
            meetingId,
            payload,
            accessToken,
            "updateMeeting",
            false,
            false,
            true
          )
        );
      }

      if (meetingData.isSuccess) {
        setStep(2);
      }
    }
  };

  const submitMeetingDetailsByConfirmModal = () => {
    // alert("clicked")

    let sendNotification = false;
    let reScheduled = false;
    const newErrors = validateForm(formData);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      let locationDetails = {};
      if (formData.location === "meetingroom") {
        locationDetails["isMeetingRoom"] = true;
        locationDetails["roomId"] = formData.roomId;
      } else {
        locationDetails["isMeetingRoom"] = false;
        locationDetails["location"] = formData.locationData;
      }

      if (meetingData.singleMeetingDetails || stateData.isMeetingDataUpdate) {
        const meetingId = meetingData?.singleMeetingDetails?._id;
        const payload = {
          isEditMeeting: stateData.isMeetingDataUpdate
            ? stateData.isMeetingDataUpdate
            : false,
          date: new Date(formData.date),
          locationDetails,
          organizationId: userData.organizationId,
          mode: formData.mode.toUpperCase(),
          fromTime: formData.fromTime,
          toTime: formData.toTime,
          title: formData.title,
          link: formData.link,
          step: 1,
          sendNotification,
          reScheduled,
          isUpdate:
            stateData.isMeetingDataUpdate &&
            meetingData.singleMeetingDetails.step === 3
              ? true
              : false,

          linkType: formData.linkType ? formData.linkType : undefined,
        };

        dispatch(
          updateMeetingDetails(
            meetingId,
            payload,
            accessToken,
            "updateMeeting",
            false,
            false,
            false
          )
        );
      }

      if (meetingData.isSuccess) {
        setStep(2);
      }
    } else {
      console.log(newErrors);
      //  alert("error");
    }
  };

  const handleUnitChange = (e) => {
    dispatch(updateIsCreateMeetingProcessed(false));
    setErrors({});
    setRoomErrors({});
    const { name, value } = e;
    setUnitId(value);
    setFormData({
      ...formData,
      [name]: value,
    });
    setSelectedUnitOption(e);
    const payload = {
      organizationId: userData.organizationId,
      unitId: value,
    };

    dispatch(getMeetingRoomList(payload, accessToken));

    setFormData({
      ...formData,
      roomId: "",
    });
    setSelectedRoomOption([{ label: "Select Room", value: "" }]);
  };

  const handleChange = (e) => {
    dispatch(setErrorMessage(null));
    dispatch(updateIsCreateMeetingProcessed(false));
    setErrors({});
    const { name, value } = e.target;

    //  alert(name)
    if (name === "date" || name === "fromTime" || name === "toTime") {
      // alert(2223)
      handleRoomChangeForDate(e);
    }

    if (name === "locationData") {
      if (value.length <= textBoxStrLength) {
        setFormData({
          ...formData,

          [name]: value,
        });
      }
    } else {
      setFormData({
        ...formData,

        [name]: value,
      });
    }
    if (value === "manual") {
      dispatch(setErrorMessage(null));
      setRoomErrors({});
    }

    if (value === "meetingroom") {
      const payload = {
        organizationId: userData.organizationId,
      };

      dispatch(listAllUnitForMeetingForRoomCheck(payload, accessToken));
    }

    // if (value === "manual") {
    //   setUnitId(null);
    //   setSelectedRoomOption([{ label: "Select Room(s)", value: "" }]);
    //   setSelectedUnitOption([{ label: "Select Unit(s)", value: "" }]);
    // }
    if (!meetingData?.singleMeetingDetails?.unitDetail) {
      if (employeeData?.userData?.unitDetails) {
        setUnitId(employeeData?.userData?.unitDetails?._id);
        // setFormData({
        //   ...formData,
        //   unitId: employeeData?.userData?.unitDetails?._id,
        // });
        const payload = {
          organizationId: userData.organizationId,
          unitId: employeeData?.userData?.unitDetails?._id,
        };
        setSelectedUnitOption(e);
        dispatch(getMeetingRoomList(payload, accessToken));
        setSelectedUnitOption([
          {
            label: employeeData?.userData?.unitDetails?.name,
            value: employeeData?.userData?.unitDetails?._id,
          },
        ]);
        setSelectedRoomOption([{ label: "*Select Room", value: "" }]);
      } else {
        setUnitId(null);
        setSelectedUnitOption([{ label: "*Select Unit", value: "" }]);
      }
    }
    if (name === "mode" && value === "physical") {
      setFormData({
        ...formData,
        linkType: "MANUAL",
        link: "",
        mode: value,
      });
    }
    if (name === "mode" && value === "virtual") {
      setFormData({
        ...formData,
        linkType: null,
        link: "",
        mode: value,
      });
    }
  };

  const validateForm = (data) => {
    console.error(data);
    const errors = {};
    if (!data.title.trim()) {
      errors.title = constantMessages.titleRequired;
    } else if (!regularExpression.test(data.title)) {
      errors.title = constantMessages.titleRegexError;
    } else if (data.title.trim().length < 5 || data.title.trim().length > 100) {
      errors.title = constantMessages.stringLengthError;
    }

    if (data.locationData.trim()) {
      if (!regularExpression.test(data.locationData)) {
        errors.locationData = constantMessages.titleRegexError;
      } else if (
        data.locationData.trim().length < 5 ||
        data.title.trim().length > 100
      ) {
        errors.locationData = constantMessages.stringLengthError;
      }
    }

    // if (
    //   !data.date.trim() &&
    //   meetingData?.singleMeetingDetails?.meetingStatus?.status === "draft"
    // ) {
    //   errors.date = constantMessages.dateRequired;
    // } else if (
    //   data.date.trim() &&
    //   meetingData?.singleMeetingDetails?.meetingStatus?.status === "draft"
    // ) {
    //   const currentDate = new Date();
    //   const inputDate = new Date(data.date);
    //   let differenceInTime = inputDate.getTime() - currentDate.getTime();
    //   let differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
    //   if (differenceInDays < 0) {
    //     errors.date = constantMessages.invalidDate;
    //   }
    // }

    if (!formData.date.trim()) {
      errors.date = constantMessages.dateRequired;
    } else if (
      meetingData?.singleMeetingDetails?.parentMeetingDetails[0]?.actualDate
    ) {
      const actualDate =
        meetingData?.singleMeetingDetails?.parentMeetingDetails[0]?.actualDate;
      const inputDate = new Date(formData.date);
      console.log(inputDate);
      const parentMeetingDate = new Date(actualDate);
      console.log(parentMeetingDate);
      let differenceInTime = inputDate.getTime() - parentMeetingDate.getTime();
      let differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
      console.log(differenceInDays);
      differenceInDays =
        differenceInDays === 0 ? differenceInDays + 1 : differenceInDays;
      console.log(differenceInDays);

      if (differenceInDays < 0) {
        errors.date = constantMessages.invalidDate;
      }
    }

    // else if (formData.date.trim()) {
    //   const currentDate = new Date();
    //   const inputDate = new Date(formData.date);
    //   let differenceInTime = inputDate.getTime() - currentDate.getTime();
    //   let differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));

    //   differenceInDays =
    //     differenceInDays === -1 ? differenceInDays + 1 : differenceInDays;

    //   // if (differenceInDays < 0) {
    //   //   errors.date = constantMessages.invalidDate;
    //   // }
    // }

    if (!data.fromTime.trim()) {
      errors.fromTime = constantMessages.timeRequired;
    } else if (
      meetingData?.singleMeetingDetails?.parentMeetingDetails[0]?.actualToTime
    ) {
      const parentMeetingToTime =
        meetingData?.singleMeetingDetails?.parentMeetingDetails[0]
          ?.actualToTime;
      //  alert(3);
      ///  const parentMeetingToTime=stateData?.parentMeetingData?.actualToTime;
      //  let differenceInTime = inputDate.getTime() - parentMeetingDate.getTime();
      //let differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
      const fromTimeArray = formData.fromTime.split(":");
      const fromTimeHour = fromTimeArray[0];
      const fromTimeMinute = fromTimeArray[1];
      const parentMeetingToTimeArray = parentMeetingToTime.split(":");
      const parentMeetingToHour = parentMeetingToTimeArray[0];
      const parentMeetingToMinute = parentMeetingToTimeArray[1];
      console.log(formData?.date);
      console.log(
        meetingData?.singleMeetingDetails?.parentMeetingDetails[0]?.actualDate
      );
      if (
        formData?.date ===
        new Date(
          meetingData?.singleMeetingDetails?.parentMeetingDetails[0]?.actualDate
        )
          .toISOString()
          .split("T")[0]
      ) {
        // alert(4);
        if (fromTimeHour < parentMeetingToHour) {
          errors.fromTime = constantMessages.invalidFollowOnMeetingFromHour;
        } else if (
          errors.date &&
          fromTimeHour === parentMeetingToHour &&
          fromTimeMinute <= parentMeetingToMinute
        ) {
          errors.fromTime = constantMessages.invalidFollowOnMeetingFromMinute;
        }
      }
    } else if (formData.toTime.trim()) {
      const fromTimeArray = formData.fromTime.split(":");
      const fromTimeHour = fromTimeArray[0];
      const fromTimeMinute = fromTimeArray[1];
      const toTimeArray = formData.toTime.split(":");
      const toTimeHour = toTimeArray[0];
      const toTimeMinute = toTimeArray[1];

      if (fromTimeHour > toTimeHour) {
        errors.fromTime = constantMessages.invalidFromHour;
      } else if (fromTimeHour === toTimeHour && fromTimeMinute > toTimeMinute) {
        errors.fromTime = constantMessages.invalidFromMinute;
      }
    }
    if (!data.toTime.trim()) {
      errors.toTime = constantMessages.timeRequired;
    } else if (formData.fromTime.trim()) {
      const fromTimeArray = formData.fromTime.split(":");
      const fromTimeHour = fromTimeArray[0];
      const fromTimeMinute = fromTimeArray[1];
      const toTimeArray = formData.toTime.split(":");
      const toTimeHour = toTimeArray[0];
      const toTimeMinute = toTimeArray[1];

      if (fromTimeHour > toTimeHour) {
        errors.toTime = constantMessages.invalidToHour;
      } else if (
        fromTimeHour === toTimeHour &&
        fromTimeMinute >= toTimeMinute
      ) {
        errors.toTime = constantMessages.invalidToMinute;
      }
    }

    if (data?.location === "meetingroom") {
      if (!unitId) {
        errors.unitId = constantMessages.unitRequired;
      }

      if (!data?.roomId?.trim()) {
        errors.roomId = constantMessages.roomRequired;
      }
    } else {
      if (data.location === "meetingroom") {
        if (!unitId?.trim()) {
          errors.unitId = constantMessages.unitRequired;
        }
        if (!data?.roomId?.trim()) {
          errors.roomId = constantMessages.roomRequired;
        }
      } else {
        if (!data?.locationData?.trim()) {
          errors.locationData = constantMessages.locationRequired;
        } else if (!excludeHtml.test(data.locationData)) {
          errors.locationData = constantMessages.invalidInput;
        }
      }
    }
    // if (formData?.mode?.trim() === "virtual") {
    //   if (!formData?.link?.trim()) {
    //     errors.link = constantMessages.linkRequired;
    //   }
    // }

    if (
      formData?.mode?.trim() === "virtual" &&
      formData?.linkType === "MANUAL"
    ) {
      if (!formData?.link?.trim()) {
        errors.link = constantMessages.linkRequired;
      }
    }

    if (formData?.mode?.trim() === "virtual") {
      if (!formData?.linkType) {
        errors.linkType = "Please add link type";
      }
    }

    const regexp =
      /^(?:https?:\/\/)(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    if (formData?.link?.trim()) {
      if (!regexp.test(formData?.link?.trim())) {
        errors.link = constantMessages.invalidLink;
      }
    }
    setErrors(errors);
    return errors;
  };

  console.log(errors);

  // const fromDateFieldValidationCheck = (e) => {
  // //  alert(22)
  //   const errors = {};
  //   if (!formData.fromTime.trim()) {
  //     errors.fromTime = constantMessages.timeRequired;
  //   } else if (
  //     meetingData?.singleMeetingDetails?.parentMeetingDetails[0]?.actualToTime
  //   ) {
  //     const parentMeetingToTime =
  //       meetingData?.singleMeetingDetails?.parentMeetingDetails[0]
  //         ?.actualToTime;
  //     //   alert(3)
  //     ///  const parentMeetingToTime=stateData?.parentMeetingData?.actualToTime;
  //     //  let differenceInTime = inputDate.getTime() - parentMeetingDate.getTime();
  //     //let differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
  //     const fromTimeArray = formData.fromTime.split(":");
  //     const fromTimeHour = fromTimeArray[0];
  //     const fromTimeMinute = fromTimeArray[1];
  //     const parentMeetingToTimeArray = parentMeetingToTime.split(":");
  //     const parentMeetingToHour = parentMeetingToTimeArray[0];
  //     const parentMeetingToMinute = parentMeetingToTimeArray[1];
  //     if (
  //       formData?.date ===
  //       new Date(
  //         meetingData?.singleMeetingDetails?.parentMeetingDetails[0]?.actualDate
  //       )
  //         .toISOString()
  //         .split("T")[0]
  //     ) {
  //     if (fromTimeHour < parentMeetingToHour) {
  //       errors.fromTime = constantMessages.invalidFollowOnMeetingFromHour;
  //     } else if (
  //       fromTimeHour === parentMeetingToHour &&
  //       fromTimeMinute <= parentMeetingToMinute
  //     ) {
  //       errors.fromTime = constantMessages.invalidFollowOnMeetingFromMinute;
  //     }
  //   }
  //   } else if (formData.toTime.trim()) {
  //     const fromTimeArray = formData.fromTime.split(":");
  //     const fromTimeHour = fromTimeArray[0];
  //     const fromTimeMinute = fromTimeArray[1];
  //     const toTimeArray = formData.toTime.split(":");
  //     const toTimeHour = toTimeArray[0];
  //     const toTimeMinute = toTimeArray[1];
  //     if (
  //       formData?.date ===
  //       meetingData?.singleMeetingDetails?.parentMeetingDetails[0]?.actualDate
  //     ) {
  //       if (fromTimeHour > toTimeHour) {
  //         errors.fromTime = constantMessages.invalidFromHour;
  //       } else if (
  //         fromTimeHour === toTimeHour &&
  //         fromTimeMinute >= toTimeMinute
  //       ) {
  //         errors.fromTime = constantMessages.invalidFromMinute;
  //       }
  //     }
  //   }
  //   setErrors(errors);
  // };

  // const fromDateFieldValidationCheck = (e) => {
  //   setErrors((prevErrors) => {
  //     const errors = { ...prevErrors }; // Preserve existing errors

  //     if (!formData.fromTime.trim()) {
  //       errors.fromTime = constantMessages.timeRequired;
  //     } else if (
  //       meetingData?.singleMeetingDetails?.parentMeetingDetails[0]?.actualToTime
  //     ) {
  //       const parentMeetingToTime =
  //         meetingData?.singleMeetingDetails?.parentMeetingDetails[0]
  //           ?.actualToTime;
  //       const fromTimeArray = formData.fromTime.split(":");
  //       const fromTimeHour = fromTimeArray[0];
  //       const fromTimeMinute = fromTimeArray[1];
  //       const parentMeetingToTimeArray = parentMeetingToTime.split(":");
  //       const parentMeetingToHour = parentMeetingToTimeArray[0];
  //       const parentMeetingToMinute = parentMeetingToTimeArray[1];

  //       if (
  //         formData?.date ===
  //         new Date(
  //           meetingData?.singleMeetingDetails?.parentMeetingDetails[0]?.actualDate
  //         )
  //           .toISOString()
  //           .split("T")[0]
  //       ) {
  //         if (fromTimeHour < parentMeetingToHour) {
  //           errors.fromTime = constantMessages.invalidFollowOnMeetingFromHour;
  //         } else if (
  //           fromTimeHour === parentMeetingToHour &&
  //           fromTimeMinute <= parentMeetingToMinute
  //         ) {
  //           errors.fromTime = constantMessages.invalidFollowOnMeetingFromMinute;
  //         }
  //       }
  //     } else if (formData.toTime.trim()) {
  //       const fromTimeArray = formData.fromTime.split(":");
  //       const fromTimeHour = fromTimeArray[0];
  //       const fromTimeMinute = fromTimeArray[1];
  //       const toTimeArray = formData.toTime.split(":");
  //       const toTimeHour = toTimeArray[0];
  //       const toTimeMinute = toTimeArray[1];

  //       if (
  //         formData?.date ===
  //         meetingData?.singleMeetingDetails?.parentMeetingDetails[0]?.actualDate
  //       ) {
  //         if (fromTimeHour > toTimeHour) {
  //           errors.fromTime = constantMessages.invalidFromHour;
  //         } else if (
  //           fromTimeHour === toTimeHour &&
  //           fromTimeMinute >= toTimeMinute
  //         ) {
  //           errors.fromTime = constantMessages.invalidFromMinute;
  //         }
  //       }
  //     }

  //     // ✅ Remove the error only when it's fixed
  //     if (!errors.fromTime) {
  //       delete errors.fromTime;
  //     }

  //     return errors;
  //   });
  // };

  const locationDetailsFieldValidationCheck = (e) => {
    const errors = {};
    if (!formData.locationData.trim()) {
      errors.locationData = constantMessages.locationRequired;
      setErrors(errors);
    } else if (!regularExpression.test(formData.locationData)) {
      errors.locationData = constantMessages.titleRegexError;
    } else if (
      formData.locationData.trim().length < 5 ||
      formData.title.trim().length > 100
    ) {
      errors.locationData = constantMessages.stringLengthError;
    }
  };
  // const toDateFieldValidationCheck = (e) => {
  //   const errors = {};
  //   if (!formData.toTime.trim()) {
  //     errors.toTime = constantMessages.timeRequired;
  //   } else if (formData.fromTime.trim()) {
  //     const fromTimeArray = formData.fromTime.split(":");
  //     const fromTimeHour = fromTimeArray[0];
  //     const fromTimeMinute = fromTimeArray[1];
  //     const toTimeArray = formData.toTime.split(":");
  //     const toTimeHour = toTimeArray[0];
  //     const toTimeMinute = toTimeArray[1];

  //     if (fromTimeHour > toTimeHour) {
  //       errors.toTime = constantMessages.invalidToHour;
  //     } else if (
  //       fromTimeHour === toTimeHour &&
  //       fromTimeMinute >= toTimeMinute
  //     ) {
  //       errors.toTime = constantMessages.invalidToMinute;
  //     }
  //   }
  //   setErrors(errors);
  // };
  const toDateFieldValidationCheck = (e) => {
    // alert(1)
    const errors = {};
    if (!formData.toTime.trim()) {
      errors.toTime = constantMessages.timeRequired;
    } else if (formData.fromTime.trim()) {
      const fromTimeArray = formData.fromTime.split(":");
      const fromTimeHour = fromTimeArray[0];
      const fromTimeMinute = fromTimeArray[1];
      const toTimeArray = formData.toTime.split(":");
      const toTimeHour = toTimeArray[0];
      const toTimeMinute = toTimeArray[1];

      if (fromTimeHour > toTimeHour) {
        errors.toTime = constantMessages.invalidToHour;
      } else if (
        fromTimeHour === toTimeHour &&
        fromTimeMinute >= toTimeMinute
      ) {
        errors.toTime = constantMessages.invalidToMinute;
      }
    }
    setErrors(errors);
  };
  const fromDateFieldValidationCheck = (e) => {
    // alert(2)
    const errors = {};
    if (!formData.fromTime.trim()) {
      errors.fromTime = constantMessages.timeRequired;
    } else if (stateData?.parentMeetingData?.actualToTime) {
      //   alert(3)
      const parentMeetingToTime = stateData?.parentMeetingData?.actualToTime;
      //  let differenceInTime = inputDate.getTime() - parentMeetingDate.getTime();
      //let differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
      const fromTimeArray = formData.fromTime.split(":");
      const fromTimeHour = fromTimeArray[0];
      const fromTimeMinute = fromTimeArray[1];
      const parentMeetingToTimeArray = parentMeetingToTime.split(":");
      const parentMeetingToHour = parentMeetingToTimeArray[0];
      const parentMeetingToMinute = parentMeetingToTimeArray[1];
      if (
        formData?.date ===
        new Date(stateData?.parentMeetingData?.actualDate)
          .toISOString()
          .split("T")[0]
      ) {
        if (fromTimeHour < parentMeetingToHour) {
          errors.fromTime = constantMessages.invalidFollowOnMeetingFromHour;
        } else if (
          fromTimeHour === parentMeetingToHour &&
          fromTimeMinute <= parentMeetingToMinute
        ) {
          errors.fromTime = constantMessages.invalidFollowOnMeetingFromMinute;
        }
      }
    } else if (formData.toTime.trim()) {
      const fromTimeArray = formData.fromTime.split(":");
      const fromTimeHour = fromTimeArray[0];
      const fromTimeMinute = fromTimeArray[1];
      const toTimeArray = formData.toTime.split(":");
      const toTimeHour = toTimeArray[0];
      const toTimeMinute = toTimeArray[1];

      if (fromTimeHour > toTimeHour) {
        errors.fromTime = constantMessages.invalidFromHour;
      } else if (
        fromTimeHour === toTimeHour &&
        fromTimeMinute >= toTimeMinute
      ) {
        errors.fromTime = constantMessages.invalidFromMinute;
      }
    }

    setErrors(errors);
  };
  // const dateFieldValidationCheck = (e) => {
  //   const errors = {};
  //   if (
  //     !formData.date.trim() &&
  //     meetingData?.singleMeetingDetails?.meetingStatus?.status === "draft"
  //   ) {
  //     errors.date = constantMessages.dateRequired;
  //   } else if (
  //     formData.date.trim() &&
  //     meetingData?.singleMeetingDetails?.meetingStatus?.status === "draft"
  //   ) {
  //     const currentDate = new Date();
  //     const inputDate = new Date(formData.date);
  //     let differenceInTime = inputDate.getTime() - currentDate.getTime();
  //     let differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
  //     if (differenceInDays < 0) {
  //       errors.date = constantMessages.invalidDate;
  //     }
  //   }
  //   setErrors(errors);
  // };

  const dateFieldValidationCheck = (e) => {
    const errors = {};
    if (!formData.date.trim()) {
      errors.date = constantMessages.dateRequired;
    } else if (
      meetingData?.singleMeetingDetails?.parentMeetingDetails[0]?.actualDate
    ) {
      const actualDate =
        meetingData?.singleMeetingDetails?.parentMeetingDetails[0]?.actualDate;
      const inputDate = new Date(formData.date);
      console.log(inputDate);
      const parentMeetingDate = new Date(actualDate);
      console.log(parentMeetingDate);
      let differenceInTime = inputDate.getTime() - parentMeetingDate.getTime();
      let differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
      console.log(differenceInDays);
      differenceInDays =
        differenceInDays === 0 ? differenceInDays + 1 : differenceInDays;
      console.log(differenceInDays);

      if (differenceInDays < 0) {
        errors.date = constantMessages.invalidDate;
      }
    }

    // else if (formData.date.trim()) {
    //   const currentDate = new Date();
    //   const inputDate = new Date(formData.date);
    //   let differenceInTime = inputDate.getTime() - currentDate.getTime();
    //   let differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));

    //   differenceInDays =
    //     differenceInDays === -1 ? differenceInDays + 1 : differenceInDays;

    //   // if (differenceInDays < 0) {
    //   //   errors.date = constantMessages.invalidDate;
    //   // }
    // }
    setErrors(errors);
  };

  const titleFieldValidationCheck = (e) => {
    const errors = {};
    if (!formData.title.trim()) {
      errors.title = constantMessages.titleRequired;
      setErrors(errors);
    } else if (!regularExpression.test(formData.title)) {
      errors.title = constantMessages.titleRegexError;
    } else if (
      formData.title.trim().length < 5 ||
      formData.title.trim().length > 100
    ) {
      errors.title = constantMessages.stringLengthError;
    }
  };

  const meetinRoomFieldValidationCheck = (e) => {
    const errors = {};
    if (!formData.roomId) {
      errors.roomId = constantMessages.roomRequired;
      setErrors(errors);
    }
    if (!unitId) {
      errors.unitId = constantMessages.unitRequired;
      setErrors(errors);
    }
  };

  const urlFieldValidationCheck = (e) => {
    const errors = {};
    const regexp =
      /^(?:https?:\/\/)(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    if (formData.link.trim()) {
      if (!regexp.test(formData.link.trim())) {
        errors.link = constantMessages.invalidLink;
        setErrors(errors);
      }
    }
  };

  if (meetingRoomData?.meetingRoomList?.length !== 0) {
    var roomOptions = meetingRoomData?.meetingRoomList?.map((room) => {
      const returnData = {
        value: room._id,
        label: room.title,
        name: "roomId",
      };

      return returnData;
    });
  }

  if (meetingRoomData?.unitList?.length !== 0) {
    var unitOptions = meetingRoomData?.unitList?.map((unit) => {
      const returnData = {
        value: unit._id,
        label: unit.name,
        name: "unitId",
      };

      return returnData;
    });
  }

  const stepCount = meetingData.isNewMeetingPage
    ? 1
    : meetingData?.singleMeetingDetails?.step;

  // title: "",
  // mode: "physical",
  // location: "manual",
  // date: "",
  // link: "",
  // fromTime: "10.55",
  // toTime: "",
  // roomId: "",
  // locationData: "",
  // linkType: null,
  console.log(meetingData?.singleMeetingDetails);
  console.log(formData);
  console.log(meetingData);
  console.log(meetingData);
  const updateStepWithCheck = () => {
    // alert(meetingData?.singleMeetingDetails?.roomId)
    const locationData = meetingData.singleMeetingDetails.locationDetails
      .location
      ? meetingData.singleMeetingDetails.locationDetails.location
      : "";
    const location = meetingData?.singleMeetingDetails?.locationDetails
      ?.isMeetingRoom
      ? "meetingroom"
      : "manual";
    const link = meetingData.singleMeetingDetails.link
      ? meetingData.singleMeetingDetails.link
      : "";
    const linkType = meetingData?.singleMeetingDetails?.linkType
      ? meetingData?.singleMeetingDetails?.linkType
      : meetingData?.singleMeetingDetails?.hostDetails?.hostType
      ? meetingData?.singleMeetingDetails?.hostDetails?.hostType
      : null;
    if (
      formData?.title === meetingData?.singleMeetingDetails?.title &&
      formData?.mode ===
        meetingData?.singleMeetingDetails?.mode?.toLowerCase() &&
      formData?.location === location &&
      formData?.link === link &&
      ((meetingData?.singleMeetingDetails?.locationDetails?.isMeetingRoom ===
        false &&
        meetingData?.singleMeetingDetails?.locationDetails?.location ===
          formData?.locationData) ||
        (meetingData?.singleMeetingDetails?.locationDetails?.isMeetingRoom ===
          true &&
          meetingData?.singleMeetingDetails?.locationDetails?.roomId ===
            formData?.roomId)) &&
      formData?.linkType === linkType
      // &&
      //formData?.locationData === locationData
    ) {
      // alert(1)
      dispatch(updateStep(1));
    } else {
      // alert(2)
      console.log(formData?.roomId);
      console.log(meetingData?.singleMeetingDetails?.roomDetail[0]?._id);
      setIsConfirmModalOpen(true);
    }
  };

  const handleConfirmSubmit = (status) => {
    if (status) {
      const newErrors = validateForm(formData);
      setErrors(newErrors);
      if (Object.keys(newErrors)?.length === 0) {
        submitMeetingDetailsByConfirmModal();

        setIsConfirmModalOpen(false);
      } else {
        setIsConfirmModalOpen(false);
      }
    } else {
      dispatch(updateStep(1));
      setIsConfirmModalOpen(false);
    }
  };
  console.log(meetingData.step, meetingData.isNewMeetingPage);

  return (
    <div className="mt-2 details-form add-meetings">
      <ConfirmModal
        message={confirmMessage}
        title={confirmTitle}
        buttonName={"Cancel"}
        isCalledFromOthers={true}
        setIsConfirmModalOpen={setIsConfirmModalOpen}
        isConfirmModalOpen={isConfirmModalOpen}
        handleConfirmSubmit={handleConfirmSubmit}
      />
      <CommonStepper step={stepCount} fromEditMeeting={true}/>
      <br></br>

      {meetingData?.step + 1 == 1 ||
      meetingData?.isNewMeetingPage === true ||
      (stateData?.isMeetingDataUpdate && meetingData?.step === 3) ? (
        <form className="mt-0 p-0 details-form" onSubmit={submitMeetingDetails}>
          <div className="inner-detail-form">
            <div className="mb-3">
              <label className="mb-1" for="title">
                Title
              </label>
              <span className="star-mark">*</span>

              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                placeholder="Enter Meeting Title"
                onBlur={titleFieldValidationCheck}
              />
              {errors.title && (
                <span className="error-message">{errors.title}</span>
              )}
            </div>

            <div className="mb-3">
              <div className="row">
                {/* <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 ">
                  <div className="position-relative resp-input-margin-bottom">
                    <label className="mb-1 input-date">Date</label>
                    <span className="star-mark">*</span>
                   
                  <div
                    className="relative"
                    onClick={() => document.getElementById("dateInput").showPicker()}
                  >
                    <input
                      disabled={
                        meetingData?.singleMeetingDetails?.meetingStatus
                          ?.status === "draft"
                          ? false
                          : true
                      }
                      type="date"
                      name="date"
                      id="dateInput"
                      value={formData.date}
                       //onChange={handleChange}
                       onChange={handledateChange}
                       //Pratishruti add here
                      onBlur={dateFieldValidationCheck}
                    />
                  </div>

                   
                  </div>
                </div> */}

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="position-relative resp-input-margin-bottom">
                      <label className="mb-1 input-date">Date</label>
                      <span className="star-mark">*</span>
                      <DatePicker
                        name="date"
                        disabled={
                          meetingData?.singleMeetingDetails?.meetingStatus
                            ?.status !== "draft"
                        }
                        value={formData.date ? dayjs(formData.date) : null}
                        onChange={(newValue) =>
                          handledateChange("date", newValue)
                        }
                        className="svgg"
                        onBlur={dateFieldValidationCheck}
                        format="DD-MM-YYYY"
                        slotProps={{
                          textField: {
                            variant: "outlined",
                            fullWidth: true,
                            size: "small",
                            placeholder: "dd-mm-yyyy",
                          },
                          day: (params) => ({
                            sx: {
                              backgroundColor: dayjs().isSame(params.day, "day")
                                ? ""
                                : "inherit",
                              color: dayjs().isSame(params.day, "day")
                                ? "blue"
                                : "inherit",
                              borderRadius: "0%",
                              "&:hover": {
                                backgroundColor: dayjs().isSame(
                                  params.day,
                                  "day"
                                ),
                              },
                            },
                          }),
                        }}
                      />
                    </div>
                    {errors.date && (
                      <span className="error-message">{errors.date}</span>
                    )}
                  </div>
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                    <div className="position-relative resp-input-margin-bottom">
                      <label className="mb-1">From Time</label>
                      <span className="star-mark">*</span>
                      <TimePicker
                        name="fromTime"
                        value={
                          formData.fromTime
                            ? dayjs(`2000-01-01T${formData.fromTime}`)
                            : null
                        }
                        //  value={
                        //    formData.fromTime
                        //      ? dayjs(`2000-01-01T${formData.fromTime}`)
                        //      :   dayjs() // Set current time as default
                        //  }
                        onChange={(newValue) =>
                          handledateChange("fromTime", newValue)
                        }
                        // onBlur={fromDateFieldValidationCheck}

                        ampm={true}
                        disabled={
                          meetingData?.singleMeetingDetails?.meetingStatus
                            ?.status !== "draft"
                        }
                        // ampm={isAmPm}
                        timeSteps={{ minutes: 1 }}
                        // format={isAmPm ? "hh:mm A" : "HH:mm"}
                        format="HH:mm"
                        slotProps={{
                          textField: {
                            variant: "outlined",
                            fullWidth: true,
                            size: "small",
                            placeholder: "hh:mm",
                          },
                        }}
                      />
                    </div>
                    {errors.fromTime && (
                      <span className="error-message">{errors.fromTime}</span>
                    )}
                  </div>
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                    <div className="position-relative resp-input-margin-bottom">
                      <label className="mb-1">To Time</label>
                      <span className="star-mark">*</span>
                      <TimePicker
                        name="toTime"
                        value={
                          formData.toTime
                            ? dayjs(`2000-01-01T${formData.toTime}`)
                            : null
                        }
                        onChange={(newValue) =>
                          handledateChange("toTime", newValue)
                        }
                        //  value={
                        //    formData.toTime
                        //      ? dayjs(`2000-01-01T${formData.toTime}`)
                        //      :   dayjs() // Set current time as default
                        //  }

                        // ampm={isAmPm}
                        disabled={
                          meetingData?.singleMeetingDetails?.meetingStatus
                            ?.status !== "draft"
                        }
                        ampm={true}
                        timeSteps={{ minutes: 1 }}
                        // format={isAmPm ? "hh:mm A" : "HH:mm"}
                        format="HH:mm"
                        slotProps={{
                          textField: {
                            variant: "outlined",
                            fullWidth: true,
                            size: "small",
                            placeholder: "hh:mm",
                          },
                        }}
                      />
                    </div>
                    {errors.toTime && (
                      <span className="error-message">{errors.toTime}</span>
                    )}
                  </div>
                </LocalizationProvider>

                {/* <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                  <div className="position-relative resp-input-margin-bottom">
                    <label className="mb-1"> From Time</label>
                    <span className="star-mark">*</span>
            

                    <div
                      className="relative"
                      onClick={() => document.getElementById("timeInput").showPicker()}
                    >
                      <input
                        disabled={
                          meetingData?.singleMeetingDetails?.meetingStatus
                            ?.status === "draft"
                            ? false
                            : true
                        }
                        type="time"
                        className="input-time"
                        name="fromTime"
                        id="timeInput"
                        value={formData?.fromTime?.toString()}
                         //onChange={handleChange}
                         onChange={handledateChange}
                         //Pratishruti add here
                        onBlur={fromDateFieldValidationCheck}
                      />
                    </div>

                    {errors.fromTime && (
                      <span className="error-message">{errors.fromTime}</span>
                    )}
                  </div>
                </div> */}

                {/* <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                  <div className="position-relative resp-input-margin-bottom">
                    <label className="mb-1">To Time</label>
                    <span className="star-mark">*</span>
                 

                    <div
                      className="relative"
                      onClick={() => document.getElementById("timeInput2").showPicker()}
                    >
                      <input
                        disabled={
                          meetingData?.singleMeetingDetails?.meetingStatus
                            ?.status === "draft"
                            ? false
                            : true
                        }
                        type="time"
                        className="input-time2"
                        name="toTime"
                        id="timeInput2"
                        value={formData.toTime}
                        //onChange={handleChange}
                        onChange={handledateChange}
                        //Pratishruti add here
                        onBlur={toDateFieldValidationCheck}
                      />
                    </div>

                    {errors.toTime && (
                      <span className="error-message">{errors.toTime}</span>
                    )}
                  </div>
                </div> */}
              </div>
              {errors.zoomError && (
                <span className="error-message">{errors.zoomError}</span>
              )}
            </div>

            <div className="mb-3">
              <label className="mb-1">Meeting Mode</label>
              <span className="star-mark">*</span>
              <div className="w-100 resp-grid">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="mode"
                    value="virtual"
                    onChange={handleChange}
                    checked={formData.mode === "virtual"}
                  />
                  <label
                    className="form-check-label"
                    // htmlFor="flexRadioDefault1"
                  >
                    Virtual Meeting
                  </label>
                </div>
                <div className="form-check form-check-inline padding-left-0">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="mode"
                      value="physical"
                      onChange={handleChange}
                      checked={formData.mode === "physical"}
                    />
                    <label
                      className="form-check-label"
                      // htmlFor="flexRadioDefault1"
                    >
                      Physical Meeting
                    </label>
                  </div>
                </div>
              </div>
            </div>

            {/* {formData.mode === "virtual" ? (
              <div className="mb-3">
                <label className="mb-1">Select Link Type</label>
                <span className="star-mark">*</span>
                <select
                  className="fltr-opt"
                  aria-placeholder="Select Link Type"
                  //onChange={handleChange}
                  onChange={handledateChange}
                  //Pratishruti add here
                  name="linkType"
                  value={formData.linkType}
                >
                  <option value="null">Select Link Type</option>
                  <option value="MANUAL">My Link</option>
                  {zoomCred?.isActive === true &&
                  zoomCred?.clientId &&
                  zoomCred?.clientId !== "" &&
                  zoomCred?.accountId &&
                  zoomCred?.accountId !== "" &&
                  zoomCred?.secretToken &&
                  zoomCred?.secretToken !== "" ? (
                    <option value="ZOOM"> Zoom</option>
                  ) : null}
                </select>
                {errors.linkType && (
                  <span className="error-message">{errors.linkType}</span>
                )}
              </div>
            ) : null} */}
            {formData.mode === "virtual" ? (
              <div className="mb-3">
                <label className="mb-1">Select Link Type</label>
                <span className="star-mark">*</span>
                <select
                  className="fltr-opt"
                  aria-placeholder="Select Link Type"
                  name="linkType"
                  value={formData.linkType || ""}
                  onChange={(e) => handledateChange("linkType", e.target.value)} // Ensure correct parameters
                >
                  <option value="">Select Link Type</option>{" "}
                  {/* Corrected default value */}
                  <option value="MANUAL">My Link</option>
                  {zoomCred?.isActive === true &&
                  zoomCred?.clientId &&
                  zoomCred?.clientId !== "" &&
                  zoomCred?.accountId &&
                  zoomCred?.accountId !== "" &&
                  zoomCred?.secretToken &&
                  zoomCred?.secretToken !== "" ? (
                    <option value="ZOOM">Zoom</option>
                  ) : null}
                </select>
                {errors.linkType && (
                  <span className="error-message">{errors.linkType}</span>
                )}
              </div>
            ) : null}
            {formData.linkType === "MANUAL" || formData.mode === "physical" ? (
              <div className="mb-3">
                <label className="mb-1">Meeting Link</label>
                {formData.linkType === "MANUAL" &&
                formData.mode === "virtual" ? (
                  <span className="star-mark">*</span>
                ) : null}
                <input
                  type="text"
                  placeholder="Enter Meeting Link"
                  name="link"
                  value={formData.link}
                  onChange={handleChange}
                  onBlur={urlFieldValidationCheck}
                  autoComplete="off"
                />
                {errors.link && (
                  <span className="error-message">{errors.link}</span>
                )}
              </div>
            ) : null}

            {/* <div className="mb-3">
              <label className="mb-1">Meeting Link</label>
              {formData.mode === "virtual" ? (
                <span className="star-mark">*</span>
              ) : null}
              <input
                type="text"
                placeholder="Enter Meeting Link"
                name="link"
                value={formData.link}
                onChange={handleChange}
                onBlur={urlFieldValidationCheck}
                autoComplete="off"
              />
              {errors.link && (
                <span className="error-message">{errors.link}</span>
              )}
            </div> */}
            <div className="mb-3">
              <label className="mb-1" for="location">
                Location
              </label>
              <span className="star-mark">*</span>
              <div className="w-100 resp-grid mb-2">
                <div className="form-check form-check-inline">
                  {}
                  <input
                    id="flexRadioDefault1"
                    className="form-check-input"
                    type="radio"
                    name="location"
                    value="manual"
                    onChange={handleChange}
                    checked={formData.location === "manual"}
                  />
                  <label className="form-check-label" for="locationtype">
                    Enter Manually
                  </label>
                </div>
                <div className="form-check form-check-inline padding-left-0">
                  <div className="form-check">
                    <input
                      id="flexRadioDefault1"
                      className="form-check-input"
                      type="radio"
                      name="location"
                      value="meetingroom"
                      onChange={handleChange}
                      checked={formData.location === "meetingroom"}
                      onBlur={meetinRoomFieldValidationCheck}
                    />
                    <label className="form-check-label" for="locationtype">
                      Select A Meeting Room
                    </label>
                  </div>
                </div>
              </div>

              {formData.location === "meetingroom" ? (
                <div className="mb-3">
                  <label className="mb-1">Select Unit</label>
                  <span className="star-mark">*</span>
                  <Select
                    className="fltr-opt"
                    defaultValue={selectedUnitOption}
                    name="unitId"
                    options={unitOptions}
                    onChange={handleUnitChange}
                    value={selectedUnitOption}
                  />
                  {errors.unitId && (
                    <span className="error-message">{errors.unitId}</span>
                  )}
                </div>
              ) : null}

              {formData.location !== "meetingroom" ? (
                <>
                  <textarea
                    className="mt-1"
                    placeholder="Enter Location"
                    id=""
                    cols="56"
                    rows="3"
                    onChange={handleChange}
                    name="locationData"
                    value={formData.locationData}
                    onBlur={locationDetailsFieldValidationCheck}
                  ></textarea>

                  <p className="success-message">
                    {checkCharacterLeft(formData.locationData)} Characters left
                  </p>
                  {errors.locationData && (
                    <span className="error-message">{errors.locationData}</span>
                  )}
                </>
              ) : (
                <>
                  {unitId ? (
                    <div className="mb-3">
                      <label className="mb-1">Select Room</label>
                      <span className="star-mark">*</span>
                      <Select
                        className="fltr-opt"
                        defaultValue={selectedRoomOption}
                        name="roomId"
                        options={roomOptions}
                        onChange={handleRoomChange}
                        value={selectedRoomOption}
                      />

                      {errors.roomId && (
                        <span className="error-message">{errors.roomId}</span>
                      )}
                    </div>
                  ) : null}
                </>
              )}
            </div>

            {errors.room && <div className="error-message">{errors.room}</div>}

            {roomErrors.room && (
              <div className="error-message">{roomErrors.room}</div>
            )}

            <div className="button-outer">
              {!meetingData.updateLoading && stateData?.isMeetingDataUpdate ? (
                <button className="create-meeting-button Mom-btn" type="submit">
                  <p>Update</p>
                </button>
              ) : (
                <LoaderButton />
              )}

              {!meetingData.updateLoading && stateData?.isMeetingDataUpdate ? (
                <button
                  type="button"
                  className="add-minutes Mom-btn"
                  // onClick={(e) => dispatch(updateStep(1))}

                  onClick={(e) => {
                    setErrors({});
                    updateStepWithCheck();
                    dispatch(setErrorMessage(null));
                  }}
                >
                  <p>Next</p>
                </button>
              ) : !meetingData.updateLoading &&
                !stateData?.isMeetingDataUpdate ? (
                <button
                  className="add-minutes Mom-btn"
                  onClick={(e) => {
                    setErrors({});
                    dispatch(updateStep(1));
                    dispatch(setErrorMessage(null));
                  }}
                >
                  <p>Next2</p>
                </button>
              ) : meetingData.updateLoading &&
                !stateData?.isMeetingDataUpdate ? (
                <LoaderButton />
              ) : null}
            </div>

            {meetingData?.errorMessage && (
              <div className="attendee-array-error-messages mt-3">
                <ul className="error-list error-message">
                  {(Array.isArray(meetingData.errorMessage)
                    ? meetingData.errorMessage
                    : [meetingData.errorMessage]
                  ).map((error, index) => (
                    <li key={index} className="error-item">
                      {error}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </form>
      ) : meetingData.step + 1 === 2 && !meetingData.isNewMeetingPage ? (
        <>
          <AddAttendees />
        </>
      ) : meetingData.step + 1 === 3 && !meetingData.isNewMeetingPage ? (
        <>
          <AddAgendas showRemoveButton={false} />
        </>
      ) : null}
    </div>
  );
};

export default EditMeeting;
