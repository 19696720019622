import React, { useEffect, useState } from "react";
import Header from "../../Common/Header/Header";
import Sidebar from "../../Common/Sidebar/Sidebar";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "../../../../node_modules/axios/index";
import LoaderButton from "../../Common/LoaderButton";
import Loader from "../../Common/Loader";
import { Modal, Button, Table, Dropdown, Form } from "react-bootstrap";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NoDataFound from "../../Common/NoDataFound";
import Alert from "../../Common/Alert";
import {
  logOut,
  setInValidUser,
} from "../../../redux/actions/authActions/authAction";
import constatntMessages from "../../../constants/constatntMessages";
import CustomizedSwitches from "./switch";
import { fetchIpAddress } from "../../../helpers/commonHelpers";
import { ShimmerTable } from "react-shimmer-effects";

const Unit = () => {
  //Create
  const userData = JSON.parse(localStorage.getItem("userData"));
  const authData = useSelector((state) => state.auth);
  console.log(authData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const employeeData = useSelector((state) => state.user);
  useEffect(() => {
    console.log(employeeData);
    if (employeeData?.userData) {
      //  alert("innnn");
      if (employeeData.userData.isAdmin === false) {
        // alert("innnn2222");
        navigate("/page-not-found");
      }
    }
  }, [employeeData?.userData, authData?.isInValidUser]);
  if (authData.isInValidUser && authData.isInvalidUserChecked) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }
  console.log(employeeData);

  const organizationId = userData?.organizationId;
  const accessToken = localStorage.getItem("accessToken");
  const [unitData, setUnitData] = useState({ name: "", address: "" });
  const [formValues, setFormValues] = useState({ name: "", address: "" });
  const [errors, setErrors] = useState({});
  const [ip, setIp] = useState();
  //List
  const [units, setUnits] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [order, setOrder] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isGetApiRes, setIsGetApiRes] = useState(false);
  const [apiResData, setApiResData] = useState({
    isSuccess: false,
    message: "",
  });

  // Edit Unit
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [unitName, setUnitName] = useState("");
  const [unitAddress, setUnitAddress] = useState("");
  const [isConfirming, setIsConfirming] = useState(false);

  console.log(unitAddress);
  const [editformValues, setEditFormValues] = useState({
    name: "",
    address: "",
  });
  //Delete Unit
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [unitToDelete, setUnitToDelete] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUnitData((prevUnitData) => ({
      ...prevUnitData,
      [name]: value,
    }));

    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const isValidate = () => {
    let isValid = true;
    const errors = {};
    const excludeHtml = /^[0-9a-zA-Z .,:\n;@!#&$()/\-_]+$/;

    if (!formValues.name.trim()) {
      errors.name = "Unit is required";
      isValid = false;
    } else if (!excludeHtml.test(formValues.name)) {
      errors.name = constatntMessages.invalidInput;
      isValid = false;
    } else if (
      formValues.name.trim().length < 2 ||
      formValues.name.trim().length > 100
    ) {
      errors.name = "Unit must be between 2-100  characters";
      isValid = false;
    }

    if (!formValues.address.trim()) {
      errors.address = "Unit Address is required";
      isValid = false;
    } else if (!excludeHtml.test(formValues.address)) {
      errors.address = constatntMessages.invalidInput;
      isValid = false;
    } else if (
      formValues.address.trim().length < 2 ||
      formValues.address.trim().length > 500
    ) {
      errors.address = "Address must be between 2-500  characters";
      isValid = false;
    }
    setErrors(errors);
    return isValid;
  };

  const unitNameValidationCheck = () => {
    const newErrors = { ...errors };
    const excludeHtml = /^[0-9a-zA-Z .,:\n;@!#&$()/\-_]+$/;

    let isValid = true;

    if (!formValues.name.trim()) {
      isValid = false;
      newErrors.name = "Unit  is required";
    } else if (!excludeHtml.test(formValues.name)) {
      isValid = false;
      newErrors.name = constatntMessages.invalidInput;
    } else if (
      formValues.name.trim().length < 2 ||
      formValues.name.trim().length > 100
    ) {
      isValid = false;
      newErrors.name = "Unit must be between 2-100  characters";
    }

    setErrors(newErrors);
    return newErrors;
  };

  const unitAddressValidationCheck = () => {
    const newErrors = { ...errors };
    const excludeHtml = /^[0-9a-zA-Z .,:\n;@!#&$()/\-_]+$/;
    let isValid = true;

    if (!formValues.address.trim()) {
      isValid = false;
      newErrors.address = "Unit Address is required";
    } else if (!excludeHtml.test(formValues.address)) {
      isValid = false;
      newErrors.address = constatntMessages.invalidInput;
    } else if (
      formValues.address.trim().length < 2 ||
      formValues.address.trim().length > 500
    ) {
      isValid = false;
      newErrors.address = "Address must be between 2-500 characters";
    } else {
      delete newErrors.address;
    }

    setErrors(newErrors);
    return isValid; // Return a boolean for better usability.
  };

  const handleSaveClick = () => {
    const newErrors = editIsValid();
    if (Object.keys(newErrors).length === 0) {
      setIsConfirming(true);
    } else {
      setShowEditModal(true);
      setIsConfirming(false);
    }
  };

  const handleConfirmSave = () => {
    handleEditSave();
    setShowEditModal(false);
    setIsConfirming(false);
  };

  const handleCancel = () => {
    setIsConfirming(false);
  };

  const editIsValid = () => {
    const errors = {};
    const excludeHtml = /^[0-9a-zA-Z .,:\n;@!#&$()/\-_]+$/;

    if (!unitName.trim()) {
      errors.unitName = "Unit  is required";
    } else if (!excludeHtml.test(unitName)) {
      errors.unitName = "Allowed Inputs: (a-z, A-Z, 0-9, space, comma, dash)";
    } else if (unitName.trim().length < 2 || unitName.trim().length > 100) {
      errors.unitName = "Unit must be between 2-100  characters";
    }

    if (!unitAddress.trim()) {
      errors.unitAddress = "Unit Address is required";
    } else if (!excludeHtml.test(unitAddress)) {
      errors.unitAddress =
        "Allowed Inputs: (a-z, A-Z, 0-9, space, comma, dash)";
    } else if (
      unitAddress.trim().length < 2 ||
      unitAddress.trim().length > 500
    ) {
      errors.unitAddress = "Address must be between 2-500  characters";
    }

    setErrors(errors);
    return errors;
  };

  const editNameValidationCheck = () => {
    const newErrors = { ...errors };
    const excludeHtml = /^[0-9a-zA-Z .,:\n;@!#&$()/\-_]+$/;

    if (!unitName.trim()) {
      newErrors.unitName = "Unit is required";
    } else if (!excludeHtml.test(unitName)) {
      newErrors.unitName =
        "Allowed Inputs: (a-z, A-Z, 0-9, space, comma, dash)";
    } else if (unitName.trim().length < 2 || unitName.trim().length > 100) {
      newErrors.unitName = "Unit must be between 2-100  characters";
    } else {
      delete newErrors.unitName;
    }
    setErrors(newErrors);
    return newErrors;
  };

  const editAddressValidationCheck = () => {
    const newErrors = { ...errors };
    const excludeHtml = /^[0-9a-zA-Z .,:\n;@!#&$()/\-_]+$/;
    
    if (!unitAddress.trim()) {
      newErrors.unitAddress = "Unit address is required";
    } else if (!excludeHtml.test(unitAddress)) {
      newErrors.unitAddress =
        "Allowed Inputs: (a-z, A-Z, 0-9, space, comma, dash)";
    } else if (
      unitAddress.trim().length < 2 ||
      unitAddress.trim().length > 500
    ) {
      newErrors.unitAddress = "Address must be between 2-500  characters";
    } else {
      delete newErrors.unitAddress;
    }
    setErrors(newErrors);
    return newErrors;
  };

  //Create Emplouee
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsGetApiRes(false);
    setIsLoading(true);

    const trimmedUnitData = {
      ...unitData,
      name: unitData.name.trim(),
      address: unitData.address.trim(),
    };

    if (isValidate()) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/V1/unit/createUnit`,
          { ...trimmedUnitData, organizationId, ip },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: accessToken,
              ip: await fetchIpAddress(),
            },
          }
        );
        if (response?.data?.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        } else {
          dispatch(setInValidUser(false));
        }
        if (response.data.success) {
          setFormValues({ name: "", address: "" });
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          fetchUnitData();
        } else {
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }

        setApiResData({
          isSuccess: response.data.success,
          message: response.data.message,
        });
        setIsGetApiRes(true);
      } catch (error) {
        toast.error(error.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      return;
    }
  };

  //List Units
  const fetchUnits = async (bodyData) => {
    try {
      setIsFetching(true);
      const headerObject = {
        headers: {
          "Content-Type": "application/json",
          Authorization: accessToken,
        },
        params: { limit, page, order },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/V1/unit/listUnit`,
        bodyData,
        headerObject
      );
      if (response?.data?.data?.isInValidUser) {
        dispatch(setInValidUser(true));
        toast.error(response.data.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        dispatch(setInValidUser(false));
      }
      const data = response.data.data || {};

      setUnits(data.unitData || []);
      setTotalCount(data.totalCount || 0);
      setIsFetching(false);
    } catch (error) {
      console.log("Error while Fetching Unit:", error);
    } finally {
      setIsFetching(false);
    }
  };

  const fetchIpAddress1 = async () => {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      setIp(data.ip);
    } catch (error) {
      console.error("Error fetching IP address:", error);
    }
  };

  useEffect(() => {
    document.title = "Unit: MinutesVault";
    fetchUnitData();
    fetchIpAddress1();
  }, [searchKey, page, limit, order, authData?.isInValidUser]);

  const handleSearch = (event) => {
    setSearchKey(event.target.value);
    setPage(1);
  };
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  const fetchUnitData = async () => {
    const bodyData = searchKey
      ? { searchKey, organizationId }
      : { organizationId };

    await fetchUnits(bodyData);
  };
  const handleClearSearch = () => {
    setSearchKey("");
    setPage(1);
    fetchUnitData();
  };
  //Edit Units
  const handleEditClick = (unit) => {
    setIsConfirming(false);
    setSelectedUnit(unit);
    setUnitName(unit.name);
    setUnitAddress(unit.address);
    setShowEditModal(true);
    setErrors(false);
  };

  const handleEditSave = async (e) => {
    setIsEditLoading(true);
    const newErrors = editIsValid();
    if (Object.keys(newErrors).length === 0) {
      try {
        setErrors({});
        const updatedUnit = {
          name: unitName.trim(),
          address: unitAddress,
          organizationId,
          // ipAddress: ip,
        };
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/api/V1/unit/editUnit/${selectedUnit._id}`,
          updatedUnit,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: accessToken,
              // ip: await fetchIpAddress(),
            },
          }
        );
        if (response?.data?.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        } else {
          dispatch(setInValidUser(false));
        }
        if (response.data.success) {
          setEditFormValues({ name: "", address: "" });
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setSelectedUnit(null);
          setShowEditModal(false);
          fetchUnitData();
        } else {
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setShowEditModal(true);
        }
        setErrors({ ...errors, [editformValues.name]: "" });
      } catch (error) {
        toast.error(error.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        console.log("Error while updating units:", error);
      } finally {
        setIsEditLoading(false);
      }
    } else {
      setIsEditLoading(false);
    }
  };

  //Row per page
  const handleRowsPerPageChange = (e) => {
    setLimit(parseInt(e.target.value, 10));
    setPage(1);
  };

  const handleToggle = (unitId, newStatus) => {
    setUnits((prevEmployees) =>
      prevEmployees.map((unit) =>
        unit?._id === unitId ? { ...unit, isActive: newStatus } : unit
      )
    );
  };

  const fromDataCount = units.length === 0 ? 0 : (page - 1) * limit + 1;
  const toDataCount = (page - 1) * limit + units.length;
  const totalPage = Math.ceil(totalCount / limit);
  const maxOption = Math.ceil(totalCount / 5) * 5;

  //Delete Unit
  const handleDeleteClick = (unit) => {
    setUnitToDelete(unit);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      if (unitToDelete) {
        await deleteUnit(unitToDelete._id);
        setShowDeleteModal(false);
        setUnitToDelete(null);
      }
    } catch (error) {
      console.error("Error while deleting unit:", error);
    }
  };

  const deleteUnit = async (unitId) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/V1/unit/deleteUnit/${unitId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: accessToken,
            ip: await fetchIpAddress(),
          },
        }
      );
      if (response?.data?.data?.isInValidUser) {
        dispatch(setInValidUser(true));
      } else {
        dispatch(setInValidUser(false));
      }
      if (response.data.success) {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      const totalRemainingItems = totalCount - 1;
      const maxPages = Math.ceil(totalRemainingItems / limit);
      if (page > maxPages) {
        setPage(page - 1);
      }
      fetchUnitData();
      return response.data;
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.error("Error deleting unit:", error);
      throw error;
    }
  };

  return (
    <div>
      {/* <Header />
      <Sidebar /> */}
      <div className="main-content">
        <div className="Action-list-page input-width">
          <div className="meeting-header-text">
            <h4>Add Unit</h4>
          </div>
          <div className="mt-3 table-box responsive-table">
            <form className="meeting-form" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-xl-4">
                  <div className="form-group">
                    <label className="mb-1">
                      Unit <span className="star-mark"> *</span>
                    </label>
                    <input
                      type="text"
                      name="name"
                      autoComplete="off"
                      placeholder="Enter Unit"
                      onBlur={unitNameValidationCheck}
                      onChange={handleChange}
                      value={formValues.name}
                    />
                    {errors.name && (
                      <span className="error-message">{errors.name}</span>
                    )}
                  </div>

                  <div className="form-group">
                    <label className="mb-1">
                      Unit Address
                      <span className="star-mark"> *</span>
                    </label>
                    <textarea
                      name="address"
                      cols="3"
                      rows="3"
                      placeholder="Enter Unit Address"
                      onBlur={unitAddressValidationCheck}
                      onChange={handleChange}
                      value={formValues.address}
                    ></textarea>
                    {errors.address && (
                      <span className="error-message">{errors.address}</span>
                    )}
                  </div>
                </div>
              </div>
              {isLoading ? (
                <LoaderButton />
              ) : (
                <button
                  className="create-meeting-button Mom-btn"
                  disabled={isLoading}
                >
                  <p>Submit</p>
                </button>
              )}
            </form>
          </div>
          {/* ////////////////////////// */}
          {/* <div className="meeting-header-text respmt-20">
            <h4>Manage Unit(s)</h4>
          </div> */}
          <div className="meeting-header-text mt-4">
            <h4 className="mt-2">Manage Unit(s)</h4>
          </div>
          <div className="mt-2 table-box responsive-table">
            <div className="tbl-text-search">
              <div className="left-tbl-text">
                {totalCount > 0 ? (
                  <p>
                    Showing {fromDataCount} to {toDataCount} of {totalCount}{" "}
                    unit(s)
                  </p>
                ) : null}
              </div>
              <div className="search-box">
                <input
                  type="search"
                  autoComplete="off"
                  placeholder="Search by Unit name"
                  value={searchKey}
                  onChange={handleSearch}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#4F2CC8"
                  className="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                </svg>
              </div>
            </div>

            {isFetching ? (
              <ShimmerTable row={5} col={5} className="mt-4" />
            ) : units.length > 0 ? (
              <>
                <Table className="mt-4 table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Unit </th>
                      <th>Unit Address</th>
                      {/* <th>Unit ID</th> */}
                      <th>Updated At</th>
                      <th>Status</th>
                      <th className="action-col">Action</th>
                    </tr>
                  </thead>{" "}
                  <tbody>
                    {units.map((unit, index) => {
                      return (
                        <tr key={index}>
                          <td
                            data-label="Unit"
                            className="meeting-td-width-2 word-break"
                          >
                            {unit.name}
                          </td>
                          <td data-label="Unit Address" className="word-break">
                            {unit.address}
                          </td>
                          {/* <td data-label="Unit Address" className="word-break">
                            {unit._id}
                          </td> */}
                          <td
                            data-label="Updated At"
                            className="meeting-td-width-6"
                          >
                            {unit.formattedDate}
                            <p className="detail-date-time">
                              {unit.formattedTime}
                            </p>
                          </td>
                          <td
                            data-label="Status"
                            className="meeting-td-width-5 metting-sts"
                          >
                            {/* <td> */}
                            <CustomizedSwitches
                              unit={unit}
                              onToggle={() => {
                                handleToggle(unit?._id);
                              }}
                            />
                            {/* {unit?.isActive ? "Active" : "Deactive"} */}
                            {/* </td> */}
                          </td>
                          <td
                            data-label="Action"
                            className="text-align-center meeting-td-width-7"
                          >
                            <Dropdown>
                              <div className="dropdown-opt d-inline-block menu-dropdown custom-dropdown">
                                <Dropdown.Toggle id="dropdown-basic">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="#000"
                                    className="bi bi-three-dots-vertical"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                  </svg>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() => handleEditClick(unit)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      fill="currentColor"
                                      className="me-2 bi bi-pencil-square"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                      <path
                                        fillRule="evenodd"
                                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                                      />
                                    </svg>
                                    Edit
                                  </Dropdown.Item>
                                  {/* <Dropdown.Item
                                    onClick={() => handleDeleteClick(unit)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      fill="currentColor"
                                      className="me-2 bi bi-trash3"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                                    </svg>
                                    Delete
                                  </Dropdown.Item> */}
                                </Dropdown.Menu>
                              </div>
                            </Dropdown>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <div className="tbl-bottom">
                  <div className="left-tbl-bottom">
                    {/* Left Arrow Button */}
                    {page !== 1 ? (
                      <button
                        className="left-arrow"
                        onClick={() => setPage(page > 1 ? page - 1 : 1)}
                        disabled={page === 1}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#fff"
                          className="bi bi-chevron-left"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                          />
                        </svg>
                      </button>
                    ) : null}

                    {/* Pagination Component from MUI without arrows */}
                    <Stack spacing={2}>
                      <Pagination
                        count={totalPage}
                        page={page}
                        siblingCount={0}
                        boundaryCount={2}
                        onChange={handlePageChange}
                        hidePrevButton
                        hideNextButton
                        sx={{
                          "& .Mui-selected": {
                            backgroundColor: "#388d27 !important",
                            color: "#fff !important",
                          },
                        }}
                      />
                    </Stack>

                    {/* Right Arrow Button */}
                    {page < totalPage ? (
                      <button
                        className="right-arrow"
                        onClick={() =>
                          setPage(page * limit < totalCount ? page + 1 : page)
                        }
                        disabled={page * limit >= totalCount}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#fff"
                          className="bi bi-chevron-right"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                          />
                        </svg>
                      </button>
                    ) : null}
                  </div>

                  {/* Rows Per Page Selector */}
                  <div className="right-tbl-bottom">
                    <p>Rows Per Page</p>
                    <select
                      className="no-opt-box"
                      name="limit"
                      onChange={handleRowsPerPageChange}
                      value={limit}
                    >
                      {Array.from({ length: maxOption / 5 }).map((_, index) => {
                        const value = (index + 1) * 5;
                        return (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </>
            ) : (
              <div className="no-data-img" style={{ minHeight: "20PX" }}>
                <>
                  <Alert
                    status={"info"}
                    message={"No data available."}
                    timeoutSeconds={0}
                  />
                  <NoDataFound dataType={"meeting"} />
                  <div className="button-outer">
                    <Button variant="primary" onClick={handleClearSearch}>
                      Clear
                    </Button>
                  </div>
                </>
              </div>
            )}
          </div>
          <Modal
            show={showEditModal}
            onHide={() => setShowEditModal(false)}
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit Unit</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {isConfirming ? (
                // Confirmation message view
                <p>Are you sure you want to update this unit?</p>
              ) : (
                // Edit form view
                <Form>
                  <Form.Group controlId="unitName">
                    <Form.Label>
                      Unit<span className="star-mark"> *</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Unit"
                      value={unitName}
                      autoComplete="off"
                      onChange={(e) => setUnitName(e.target.value)}
                      onBlur={editNameValidationCheck}
                    />
                  </Form.Group>
                  {errors.unitName && (
                    <span className="error-message">{errors.unitName}</span>
                  )}
                  <Form.Group controlId="unitAddress" className="mt-3">
                    <Form.Label>
                      Unit Address<span className="star-mark"> *</span>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Enter Unit Address"
                      value={unitAddress}
                      autoComplete="off"
                      onChange={(e) => setUnitAddress(e.target.value)}
                      onBlur={editAddressValidationCheck}
                      rows={3}
                    />
                  </Form.Group>
                  {errors.unitAddress && (
                    <span className="error-message">{errors.unitAddress}</span>
                  )}
                </Form>
              )}
            </Modal.Body>
            <Modal.Footer>
              {isConfirming ? (
                // Confirmation buttons
                <>
                  <Button
                    variant=""
                    className="add-btn Mom-btn"
                    onClick={handleCancel}
                  >
                    <p>Cancel</p>
                  </Button>
                  <Button
                    variant=""
                    className="create-meeting-button Mom-btn"
                    onClick={handleConfirmSave}
                  >
                    <p>Confirm Update</p>
                  </Button>
                </>
              ) : (
                // Initial "Save Changes" button
                <>
                  <Button
                    variant=""
                    className="add-btn Mom-btn"
                    onClick={() => setShowEditModal(false)}
                  >
                    <p>Close</p>
                  </Button>
                  {isEditLoading ? (
                    <LoaderButton />
                  ) : (
                    <Button
                      variant=""
                      className="create-meeting-button Mom-btn"
                      onClick={handleSaveClick}
                    >
                      <p>Save Changes </p>
                    </Button>
                  )}
                </>
              )}
            </Modal.Footer>
          </Modal>

          <Modal
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirm Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete this unit?</Modal.Body>
            <Modal.Footer>
              {isLoading ? (
                <LoaderButton />
              ) : (
                <Button
                  variant=""
                  className="add-minutes Mom-btn"
                  onClick={handleDeleteConfirm}
                >
                  <p>Delete</p>
                </Button>
              )}
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Unit;
