import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import parse from "html-react-parser";
import Header from "../Common/Header/Header";
import Sidebar from "../Common/Sidebar/Sidebar";
import NoDataFound from "../Common/NoDataFound";
import Loader from "../Common/Loader";
import axios from "axios";
import { Button, Table } from "react-bootstrap";
import Alert from "../Common/Alert";
import { useSelector, useDispatch } from "react-redux";
import { convertFirstLetterOfFullNameToCapital } from "../../helpers/commonHelpers";
import { useNavigate, Navigate, Link } from "react-router-dom";
import { logOut, setInValidUser } from "../../redux/actions/authActions/authAction";
const ActivityLogs = () => {
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("userData"));
   const authData = useSelector((state) => state.auth);
    const dispatch = useDispatch();
  const organizationId = userData?.organizationId;
  const accessToken = localStorage.getItem("accessToken");
  const [logList, setLogList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState(-1);
  const [limit, setLimit] = useState(50);
  const [isFetching, setIsFetching] = useState(false);
  const employeeData = useSelector((state) => state.user);
  console.log(authData)
    useEffect(() => {
      if (authData.isInValidUser && authData.isInvalidUserChecked) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userData");
        localStorage.removeItem("rememberMe");
        dispatch(logOut());
        navigate("/login");
      }
    },[authData.isInValidUser])


  useEffect(() => {
    console.log(employeeData);
    if (employeeData?.userData) {
      //  alert("innnn");
      if (employeeData.userData.isAdmin === false) {
        // alert("innnn2222");
        navigate("/page-not-found");
      }
    }
  }, [employeeData?.userData]);
  const fetchLogs = async (bodyData) => {
    try {
      setIsFetching(true);
      const headerObject = {
        headers: {
          "Content-Type": "application/json",
          Authorization: accessToken,
        },
        params: { limit, page, order: -1 },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/V1/log/viewLogs`,
        bodyData,
        headerObject
      );
console.log(response?.data?.data)
 if (response?.data?.data?.isInValidUser) {
 
          dispatch(setInValidUser(true));
        }
        else{
         
          dispatch(setInValidUser(false));
        }

      const logsData = response.data.data || {};
      setLogList(logsData.logsDatas || []);
      setTotalCount(logsData.totalCount || 0);
      setIsFetching(false);
    } catch (error) {
      console.log("Error while Fetching Logs:", error);
    } finally {
      setIsFetching(false);
    }
  };

  const fetchLogData = async () => {
    const bodyData = searchKey
      ? { searchKey, organizationId }
      : { organizationId };
    await fetchLogs(bodyData);
  };

  useEffect(() => {
    document.title = "Activity Logs: MinutesVault";
    if(organizationId){
      fetchLogData();
    }
  
  }, [searchKey, page, limit, order,authData?.isInValidUser]);

  const handleSearch = (event) => {
    setSearchKey(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchKey("");
    setPage(1);
    if(organizationId){
      fetchLogData();
    }
    
  };

  const handleRowsPerPageChange = (e) => {
    setLimit(parseInt(e.target.value, 10));
    setPage(1);
  };
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  const fromDataCount = logList.length === 0 ? 0 : (page - 1) * limit + 1;
  const toDataCount = (page - 1) * limit + logList.length;
  const totalOption = Math.round(totalCount / 5 + 0.5);
  const totalRemainingItems = totalCount;
  const maxPages = Math.max(1, Math.ceil(totalRemainingItems / limit));

  return (
    <>
      {/* <Header />
      <Sidebar /> */}
        {!accessToken ? <Navigate to="/login" /> : null}
      <div className="main-content">
        <div className="Action-list-page input-width">
          <div className="meeting-header-text">
            <h4>Activity Logs</h4>
          </div>

          <div className="mt-2 table-box responsive-table">
            <div className="tbl-text-search">
              <div className="left-tbl-text">
                <p>
                  Showing {fromDataCount} to {toDataCount} of {totalCount}{" "}
                  activities
                </p>
              </div>
              <div className="search-box">
                <input
                  type="search"
                  placeholder="Search By Activity"
                  autoComplete="off"
                  value={searchKey}
                  onChange={handleSearch}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#4F2CC8"
                  className="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                </svg>
              </div>
            </div>
            {isFetching ? (
              <div className="meeting-page loader-cont position-relative">
                <Loader />
              </div>
            ) : logList.length > 0 ? (
              <>
                <div className="table-responsive">
                  <Table className="mt-4 table table-bordered  table-striped">
                    <thead>
                      <tr>
                        <th>Sl. No.</th>
                        <th>Menu</th>
                        <th>User Name & IP</th>
                        <th>Action</th>

                        <th>Activity</th>
                        <th>Action Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {logList.map((log, index) => (
                        <tr key={log._id}>
                          <td data-label="Sl. No.">{fromDataCount + index}</td>
                          <td data-label="Menu Name">{log.moduleName}</td>
                          <td data-label="User Name">
                            {" "}
                            <p className="ip_address">
                              {log?.userDetail?.name?convertFirstLetterOfFullNameToCapital(
                                log?.userDetail?.name
                              ):null}
                            </p>
                            <p className="detail-date-time">
                              IP Address - {log.ipAddress}
                            </p>
                          </td>
                          <td data-label="Action">
                            <i className="actionTitle"> {log.action}</i>
                          </td>

                          <td
                            data-label="Description"
                            className="resp-padding-left word-break"
                          >
                            {/* <div className="text-editor">{parse(
                              `${log.details.toString()}`
                            )}</div> */}
                            <div className="text-editor">
                              {log.details ? parse(`${log.details.toString()}`) : null}
                            </div>
                            {/* <div className="text-editor"> {log.subDetails ? parse(`${log.details.toString()}`) : null}</div> */}
                            <div className="text-editor">
                              {log.subDetails
                                ? parse(
                                  `</br><p className="detail-date-time">${log.subDetails.toString()}</p>`
                                )
                                : null}
                            </div>
                          </td>
                          <td
                            data-label="Action Date"
                            className="meeting-td-width-5"
                          >
                            {" "}
                            {log.formattedDate}
                            <p className="detail-date-time">
                              {log.formattedTime}
                            </p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                <div className="tbl-bottom">
                  <div className="left-tbl-bottom">
                    {/* Left Arrow Button */}
                    {page !== 1 ? (
                      <button
                        className="left-arrow"
                        onClick={() => setPage(page > 1 ? page - 1 : 1)}
                        disabled={page === 1}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#fff"
                          className="bi bi-chevron-left"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                          />
                        </svg>
                      </button>
                    ) : null}

                    <Stack spacing={2}>
                      <Pagination
                        count={maxPages}
                        page={page}
                        siblingCount={0}
                        boundaryCount={2}
                        onChange={handlePageChange}
                        hidePrevButton
                        hideNextButton
                        sx={{
                          "& .Mui-selected": {
                            backgroundColor: "#388d27 !important",
                            color: "#fff !important",
                          },
                        }}
                      />
                    </Stack>

                    {/* Right Arrow Button */}
                    {page < maxPages ? (
                      <button
                        className="right-arrow"
                        onClick={() => setPage(page + 1)}
                        disabled={page >= maxPages}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#fff"
                          className="bi bi-chevron-right"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                          />
                        </svg>
                      </button>
                    ) : null}
                  </div>

                  {/* Rows Per Page Selector */}
                  <div className="right-tbl-bottom">
                    <p>Rows Per Page</p>
                    <select
                      className="no-opt-box"
                      name="limit"
                      onChange={handleRowsPerPageChange}
                      value={limit}
                    >
                      {Array(totalOption)
                        .fill()
                        .map((_, option) => (
                          <option key={option} value={(option + 1) * 5}>
                            {(option + 1) * 5}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </>
            ) : (
              <div className="no-data-img">
                <>
                  <Alert
                    status={"info"}
                    message={"No data available."}
                    timeoutSeconds={0}
                  />
                  <NoDataFound dataType={"meeting"} />
                  <div className="button-outer">
                    <Button variant="primary" onClick={handleClearSearch}>
                      Clear
                    </Button>
                  </div>
                </>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityLogs;
