import React, { useState, useEffect } from "react";
import { logOut } from "../../redux/actions/authActions/authAction";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Tooltip, OverlayTrigger, Popover } from "react-bootstrap";
import AddMinute from "./AddMinute";
import AddMinuteAgenda from "./AddMinuteAgenda";
import parse from "html-react-parser";
import {
  addAgendaDetails,
  setAgendaData,
  setSuccess,
} from "../../redux/actions/minuteActions/MinuteAction";
import EditAgendaModal from "./EditAgendaModal";
import DeleteAgendaModal from "./DeleteAgendaModal";
import ActionDropDown from "./ActionDropDown";
import Accordion from "react-bootstrap/Accordion";
import AddMinuteModal from "./AddMinuteModal";
import {
  checkEmptyString,
  getStringLengthAfterHTMLParse,
  sliceHtmlEncodedString,
} from "../../helpers/commonHelpers";
import ViewAgendaModal from "./ViewAgendaModal";
import AddAgendaModal from "../Meeting/AddAgendaModal";
import ActionNotifyModal from "./ActionNotifyModal";

const CreateMinutes = (props) => {
  const dispatch = useDispatch();
  const employeeData = useSelector((state) => state.user);
  const minuteData = useSelector((state) => state.minute);
  const authData = useSelector((state) => state.auth);
  const meetingData = useSelector((state) => state.meeting);
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(null);
  const [isAddAgendaModal, setIsAddAgendaModal] = useState(false);
  const [isActionsForNotifyModal, setIsActionsForNotifyModal] = useState(false);
  const [isMinuteShow, setIsMinuteShow] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState([false, false]);
  const [isAgendaShow, setIsAgendaShow] = useState(false);
  const [isEditAgendaModal, setIsEditAgendaModal] = useState(false);
  const [agendaDatas, setAgendaDatas] = useState(null);
  const [isAddMinuteModal, setIsAddMinuteModal] = useState(false);
  const [isDeleteAgendaModal, setIsDeleteAgendaModal] = useState(false);
  const [isViewAgendaModal, setIsViewAgendaModal] = useState(false);
  const accessToken = localStorage.getItem("accessToken");
  if (authData.isInValidUser && authData.isInvalidUserChecked) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }
  useEffect(() => {
    document.title = "Write Minutes: MinutesVault";
    if (minuteData.isSuccess) {
      setIsAgendaShow(false);
      setIsMinuteShow(false);
      props.closeAgendaForm(false);
    }
  }, [minuteData.isSuccess]);

  const setModalStatus = (value) => {
    setIsAgendaShow(value);
    if (!value) {
      dispatch(setAgendaData(null));
    }
  };

  const handleActionsForNotifyModal = (value) => {
    setIsActionsForNotifyModal(value);
  };

  const setEditAgendaModalStatus = (status, agenda) => {
    dispatch(setSuccess(false));
    setAgendaDatas(agenda);
    setIsEditAgendaModal(status);
  };
  const setViewAgendaModalStatus = (value, agenda) => {
    setIsViewAgendaModal(value);
    setAgendaDatas(agenda);
  };
  const setDeleteAgendaModalStatus = (status, agenda) => {
    dispatch(setSuccess(false));
    setAgendaDatas(agenda);
    setIsDeleteAgendaModal(status);
  };
  const handleAddMinuteModal = (status, agenda) => {
    dispatch(setSuccess(false));
    setAgendaDatas(agenda);
    setIsAddMinuteModal(status);
  };
  const isMeetingOrganiser =
    meetingData?.meetingDetails?.createdById?.toString() ===
    employeeData?.userData?._id?.toString()
      ? true
      : false;
  console.log(isMeetingOrganiser);
  const handleAddAgendaModal = (status) => {
    setIsAddAgendaModal(status);
  };
  const onAddAgenda = (data) => {
    //

    dispatch(
      addAgendaDetails(
        {
          agendaData: [
            {
              topic: data.topic,
              timeLine: data.timeLine,
              title: data.title,
              organizationId: meetingData?.meetingDetails?.organizationId,
              meetingId: meetingData?.meetingDetails?._id,
            },
          ],
        },
        meetingData?.meetingDetails?._id,
        accessToken
      )
    );
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  console.log(meetingData?.agendaDetails);
  const popoverHoverFocus = (title) => {
    return (
      <Popover id="popover-basic" className="custom-popover">
        {/* <Popover.Header as="h3" className="popover-header">
            Meeting Title.
          </Popover.Header> */}
        <Popover.Body className="popover-body">{title}</Popover.Body>
      </Popover>
    );
  };
  let onlyActionAgendas = null;
  onlyActionAgendas = meetingData?.agendaDetails?.filter((agenda) =>
    agenda?.minutesDetail?.find(
      (item) => item.isActive === true && item.isAction === true
    )
  );
  console.log(onlyActionAgendas);
  return (
    <>
      {/* <div className="meeting-header-text">
        <h4>Write Minutes</h4>
      </div>  */}
      <div className="mt-2 details-form details-form-right">
        <div className="form-group">
          <div className="agendas-header d-flex align-items-center justify-content-between">
            <div>
              <label className="mt-3 mb-3">
                <h4 className="mb-0">Agenda(s)</h4>
              </label>
            </div>

            {/* <button type="button" className="create-meeting-button Mom-btn ">
                           <p>Generate MOM</p>
                         </button> */}

          <div className="right-div d-flex align-items-center">
          {onlyActionAgendas?.length !== 0 ? (
              <button
                type="button"
                className="create-meeting-button Mom-btn "
                onClick={() => {
                  handleActionsForNotifyModal(true);
                }}
              >
                <p>Notify Actions</p>
              </button>
            ) : null}

            {isMeetingOrganiser === true ||
            employeeData?.userData?.isAdmin ||
            meetingData?.meetingDetails?.canWriteMOM === true ? (
              <div
                // onClick={() => {
                //   setModalStatus(!isAgendaShow);
                // }}

                onClick={() => {
                  handleAddAgendaModal(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  fill="#0564f3"
                  className="bi bi-plus-circle pointer"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                </svg>
              </div>
            ) : null}
          </div>


          </div>

          {/* {isAgendaShow || props.openAgendaForm ? (
            <AddMinuteAgenda meetingData={meetingData} />
          ) : null} */}

          <>
            <Accordion defaultActiveKey="0" className="accordianagenda">
              {meetingData?.agendaDetails?.length > 0 &&
                meetingData?.agendaDetails?.map((agenda, index) => {
                  return (
                    <Accordion defaultActiveKey="0">
                      <div className="mt-2 agenda-box-border">
                        <Accordion.Item
                          eventKey={index.toString()}
                          className="agenda-accordion"
                        >
                          <Accordion.Header>
                            <span>Agenda {index + 1}:&nbsp;</span>
                            {agenda.title?.length > 50 ? (
                              <OverlayTrigger
                                trigger={["hover", "focus"]}
                                placement="bottom"
                                overlay={popoverHoverFocus(agenda.title)}
                              >
                                <span className="meeting-title-hover">
                                  {`${agenda.title.slice(0, 50)}....`}
                                </span>
                              </OverlayTrigger>
                            ) : (
                              <span className="meeting-title-hover">
                                {agenda.title}
                              </span>
                            )}
                          </Accordion.Header>
                          <Accordion.Body className="d-flex justify-content-between">
                          <div className="edit-meeting-left-accordions">
                              <div className=" form-group">
                                <div className="row">
                                  <div className="col-md-4">
                                    <label className="mt-1 mb-1">
                                      Agenda Title
                                    </label>
                                  </div>
                                  <div className="col-md-8">
                                    <p> {agenda.title}</p>
                                  </div>
                                </div>
                              </div>
                              {checkEmptyString(agenda?.topic) === null ||
                              checkEmptyString(agenda?.topic) === " " ||
                              checkEmptyString(agenda?.topic) ===
                                undefined ? null : (
                                <div className="  form-group">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <label className="mt-2 topic">
                                        Topic to Discuss
                                      </label>
                                    </div>
                                    <div className="col-md-8">
                                      <div className="mb-2 text-editor">
                                        {agenda?.topic
                                          ? parse(agenda?.topic)
                                          : null}
                                      </div>

                                      {/* <div className="mb-2 text-editor">
                                        {getStringLengthAfterHTMLParse(
                                          agenda.topic
                                        ) < 20 ? (
                                          <span>{parse(agenda.topic)}</span>
                                        ) : (
                                          <div>
                                            {`${sliceHtmlEncodedString(
                                              agenda.topic,
                                              20
                                            )}....`}
                                            <button
                                              type="button"
                                              className="badge bg-opacity-10 scheduled-color"
                                              onClick={() =>
                                                setViewAgendaModalStatus(
                                                  true,
                                                  agenda
                                                )
                                              }
                                            >
                                              {" "}
                                              View More
                                            </button>
                                          </div>
                                        )}
                                       
                                      </div> */}
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className=" form-group">
                                <div className="row">
                                  <div className="col-md-4">
                                    <label className="mt-1 mb-1">
                                      Timeline
                                    </label>
                                  </div>
                                  <div className="col-md-8">
                                    <p>
                                      {" "}
                                      {agenda.timeLine === ""
                                        ? 0
                                        : agenda.timeLine}{" "}
                                      Mins
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div className=" form-group m-0">
                                <div className="row">
                                  <div className="col-md-4">
                                    <label className="mt-1 mb-1">
                                      Total Minutes
                                    </label>
                                  </div>
                                  <div className="col-md-8">
                                    <p>
                                      {" "}
                                      {
                                        agenda?.minutesDetail?.filter(
                                          (minute) => minute.isActive
                                        ).length
                                      }
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <button
                                type="button"
                                className="btn rounded-pill add-btn Mom-btn d-flex align-items-center justify-content-center mt-4"
                                onClick={() => {
                                  handleAddMinuteModal(true, agenda);
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="#fff"
                                  className="bi bi-plus-circle pointer me-2"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                                </svg>
                                <p> Add Minute </p>
                              </button>

                              {/* {isMeetingOrganiser === true ||
                              employeeData?.userData?.isAdmin === true ||
                              meetingData?.meetingDetails?.canWriteMOM ===
                                true ? (
                                <AddMinute
                                  agenda={agenda}
                                  currentIndex={currentIndex}
                                  index={index}
                                />
                              ) : null} */}
                            </div>

                            {((agenda?.minutesDetail?.length !== 0 &&
                              agenda?.isMOMGenerated === false) ||
                              (agenda?.minutesDetail?.length === 0 &&
                                agenda?.isMOMGenerated === false)) &&
                            (isMeetingOrganiser === true ||
                              employeeData?.userData?.isAdmin === true ||
                              meetingData?.meetingDetails?.canWriteMOM ===
                                true) ? (
                              <div className="accordianagenda-edit-delete float-end">
                                <div className="btn-outerbox">
                                  <div className="d-inline-block menu-dropdown custom-dropdown">
                                    <ActionDropDown
                                      agendaId={agenda._id}
                                      isShowDelete={
                                        meetingData?.agendaDetails?.length > 1
                                          ? true
                                          : false
                                      }
                                      handleRequestForEditModal={() => {
                                        setEditAgendaModalStatus(true, agenda);
                                      }}
                                      handleRequestForDeleteModal={() => {
                                        setDeleteAgendaModalStatus(
                                          true,
                                          agenda
                                        );
                                      }}
                                      totalAgendas={
                                        meetingData?.agendaDetails?.length
                                      }
                                      meetingData={meetingData?.meetingDetails}
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : null}

                            
                          </Accordion.Body>
                        </Accordion.Item>
                      </div>
                    </Accordion>
                  );
                })}
            </Accordion>
          </>
        </div>
        <EditAgendaModal
          IsEditAgendaModal={isEditAgendaModal}
          agendaData={agendaDatas}
          setIsEditAgendaModal={setIsEditAgendaModal}
          meetingData={meetingData}
        />
        <DeleteAgendaModal
          setIsDeleteAgendaModal={setIsDeleteAgendaModal}
          isDeleteAgendaModal={isDeleteAgendaModal}
          agendaData={agendaDatas}
        />

        <AddMinuteModal
          setIsAddMinuteModal={setIsAddMinuteModal}
          isAddMinuteModal={isAddMinuteModal}
          agenda={agendaDatas}
          agendaDetails={meetingData?.agendaDetails}
        />
        <ViewAgendaModal
          agendaData={agendaDatas}
          IsViewAgendaModal={isViewAgendaModal}
          setIsViewAgendaModal={setIsViewAgendaModal}
        />
        <AddAgendaModal
          IsAddAgendaModal={isAddAgendaModal}
          setIsAddAgendaModal={setIsAddAgendaModal}
          addAgenda={onAddAgenda}
          fromMinute={true}
          totalAgenda={meetingData?.agendaDetails?.length}
        />

        <ActionNotifyModal
          IsActionsForNotifyModal={isActionsForNotifyModal}
          setIsActionsForNotifyModal={setIsActionsForNotifyModal}
          agendaData={onlyActionAgendas}
          meetingData={meetingData}
        />
      </div>
    </>
  );
};

export default CreateMinutes;
