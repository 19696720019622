import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import { Button, Popover, OverlayTrigger, Tooltip } from "react-bootstrap";
import parse from "html-react-parser";
// @ts-ignore
import Header from "../Common/Header/Header";
import Sidebar from "../Common/Sidebar/Sidebar";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { logOut } from "../../redux/actions/authActions/authAction";
import {
  cancelActionInList,
  fetchActionList,
  processReassign,
  processRequestForReassign,
} from "../../redux/actions/actionAction/ActionAction";
import Alert from "../Common/Alert";
import NoDataFound from "../Common/NoDataFound";
import Loader from "../Common/Loader";
import {
  customName,
  formatDateTimeFormat,
  getStringLengthAfterHTMLParse,
  sliceTextFromHTML,
} from "../../helpers/commonHelpers";
import ActionDropDown from "./ActionDropDown";
import ActionFilterComponent from "./ActionFilterComponent";
import RequestForReassignModal from "./RequestForReassignModal";
import ReassignModal from "./ReassignModal";
import { viewMeetingStatistics } from "../../redux/actions/meetingActions/MeetingAction";
import CommonModal from "../Common/CommonModal";
import ActionPieChart from "./ActionPieChart";

const ActionList = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const accessToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);
  const meetingData = useSelector((state) => state.meeting);

  const actionData = useSelector((state) => state.action);
  const employeeData = useSelector((state) => state.user);
  const [isCloseModalOpen, setIsCloseModalOpen] = useState(false);
  const [filter, setfilter] = useState(false);
  const [isReassignModalOpen, setIsReassignModalOpen] = useState(false);
  if (authData.isInValidUser && authData.isInvalidUserChecked) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }
  const [isRequestForReassignModalOpen, setIsRequestForReassignModalOpen] =
    useState(false);
  const [searchData, setSearchData] = useState({
    searchKey: "",
    page: 1,
    limit: 5,
    order: -1,
    filterData: {},
  });
  console.log(searchData.filterData);
  const filterData = (data) => {
    setSearchData({
      ...searchData,
      filterData: data,
      page: 1,
    });
  };
  useEffect(() => {
    document.title = "Action List: MinutesVault";
    if (!authData.isInValidUser) {
    dispatch(viewMeetingStatistics(userData?.organizationId, accessToken));
    }
    if (!accessToken) {
      navigate("/login");
    }
    if (!userData) {
      navigate("/login");
    } else {
      const payload = {
        page: searchData.page,
        order: searchData.order,
        limit: searchData.limit,
        organizationId: userData.organizationId,
        actionStatus:
          searchData.filterData?.actionStatus !== ""
            ? searchData.filterData?.actionStatus
            : undefined,
        delayStatus:
          searchData.filterData?.delayStatus !== ""
            ? searchData.filterData?.delayStatus
            : undefined,
        toDate: searchData.filterData?.toDate,
        fromDate: searchData.filterData?.fromDate,
        createdById:
          searchData.filterData?.ownerId !== ""
            ? searchData.filterData?.ownerId
            : undefined,
        meetingId:
          searchData.filterData?.meetingId !== ""
            ? searchData.filterData?.meetingId
            : undefined,
        assignedUserId:
          searchData.filterData?.assignedUserId !== ""
            ? searchData.filterData?.assignedUserId
            : undefined,
        accessToken,
      };
      if (searchData.searchKey !== "") {
        payload["searchKey"] = searchData.searchKey;
        setSearchData({
          ...searchData,
          page: 1,
        });
        payload.page = 1;
      }
      console.log(payload);
      if (!authData.isInValidUser) {
        dispatch(fetchActionList(payload));
      }
    }
    if (actionData.isSuccess) {
      setIsRequestForReassignModalOpen(false);
      setIsReassignModalOpen(false);
      setActionDatas(null);
      setIsCloseModalOpen(false);
    }
  }, [
    searchData.searchKey,
    searchData.order,
    searchData.page,
    searchData.limit,
    searchData.filterData,
    actionData.isSuccess,
    actionData.isFetchedActionList,
    authData.isInValidUser
  ]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearchData({
      ...searchData,
      [name]: value,
    });
  };

  const totalOption =
    actionData?.totalCount && actionData?.totalCount % 5 === 0
      ? Math.round(actionData.totalCount / 5)
      : actionData?.totalCount && actionData?.totalCount % 5 !== 0
        ? Math.round(actionData.totalCount / 5 + 0.5)
        : 0;

  const checkTotalPage =
    actionData.totalCount % searchData.limit === 0
      ? Math.round(actionData.totalCount / searchData.limit)
      : Math.round(actionData.totalCount / searchData.limit + 0.5);

  const totalPage = actionData.totalCount ? checkTotalPage : 0;

  const totalPageArray = Array(totalPage).fill();

  const fromDataCount =
    actionData?.actionList?.length === 0
      ? 0
      : (searchData.page - 1) * searchData.limit + 1;
  const toDataCount =
    (searchData.page - 1) * searchData.limit + actionData?.actionList?.length;
  //searchData.limit+meetingData.meetingList.length
  const totalCount = actionData.totalCount;

  const [filterAction, setFilterAction] = useState(false);

  const [minuteDetails, setMinuteDetails] = useState(null);

  const [actionId, setActionId] = useState(null);
  const [actionDatas, setActionDatas] = useState(null);

  const handleRequestForReassign = (reason) => {
    dispatch(
      processRequestForReassign(
        actionId,
        { requestDetails: reason },
        accessToken
      )
    );
    setActionId(null);
  };
  const handleCancelAction = (status, minute) => {
    setMinuteDetails(minute);
    setIsCloseModalOpen(status);
  };
  const submitCancelAction = () => {
    const payload = {
      assignedUserDetails: minuteDetails?.assignedUserDetails,
    };
    dispatch(
      cancelActionInList(
        minuteDetails?.meetingId,
        minuteDetails?._id,
        accessToken,
        payload
      )
    );
  };
  const handleReassign = (data) => {
    dispatch(
      processReassign(
        actionId,
        {
          organizationId: userData.organizationId,
          reAssignedId: data.attendeeId !== "" ? data.attendeeId : undefined,
          reAssignReason: data.reason,
          isNewUser: data.name !== "" && data.email !== "" ? true : false,
          name: data.name,
          email: data.email,
          reassignedUserName: data.reassignedUserName,
          dueDate: data.date ? data.date : undefined,
          priority: data.priority,
          designation: data.designation ? data.designation : undefined,
          companyName: data.companyName ? data.companyName : undefined,
        },
        accessToken
      )
    );
    setActionId(null);
  };
  const handleRequestForReassignModal = (actionId) => {
    setActionId(actionId);
    setIsRequestForReassignModalOpen(true);
  };

  const handleReassignModal = (action) => {
    setActionId(action._id);
    setActionDatas(action);
    setIsReassignModalOpen(true);
  };
  const handlePageChange = (event, value) => {
    setSearchData({
      ...searchData,
      page: value,
    });
  };
  // const popoverHoverFocus = (
  //   <Popover id="popover-basic" className="custom-popover">
  //     {/* <Popover.Header as="h3" className="popover-header">
  //     Action Description
  //     </Popover.Header> */}
  //     <Popover.Body className="popover-body">
  //       This is additional information about the meeting. You can add detailed
  //       descriptions or extra content here!
  //     </Popover.Body>
  //   </Popover>
  // );
  const popoverHoverFocus = (title) => {
    return (
      <Popover id="popover-basic" className="custom-popover">
        <Popover.Body className="popover-body">{title}</Popover.Body>
      </Popover>
    );
  };

  // const meetingDetailsPopover = (
  //   <Popover id="popover-basic" className="custom-popover">
  //     {/* <Popover.Header as="h3" className="popover-header">
  //     Action Description
  //     </Popover.Header> */}
  //     <Popover.Body className="popover-body">
  //       This is additional information about the meeting. You can add detailed
  //       descriptions or extra content here!
  //     </Popover.Body>
  //   </Popover>
  // );

  const meetingDetailsPopover = (title) => {
    return (
      <Popover id="popover-basic" className="custom-popover">
        {/* <Popover.Header as="h3" className="popover-header">
          Meeting Title
        </Popover.Header> */}
        <Popover.Body className="popover-body">{title}</Popover.Body>
      </Popover>
    );
  };

  const tooltip = (attendee) => {
    return (
      <Tooltip id="tooltip">
        {attendee.name} / {attendee?.email}
      </Tooltip>
    );
  };

  return (
    <>
    
   
      <div className="main-content">
        <div className="meeting-page ">
          <div className="meeting-header-text">
            <div>
              <h4>Actions</h4>
            </div>
          </div>

          <div className="color-box-resp">
            <div className="row mt-3">
              <div className="col-xl-3 col-md-6 col-sm-12">
                <div className="panel-db panel to-cyan2-400 pending-color d-flex justify-content-between">
                  <div>
                  <div className="flex justify-between">
                    <div className="ltr:mr-1 rtl:ml-1 text-md font-semibold">
                      Total Due
                    </div>
                  </div>
                  <div className="flex items-center mt-3">
                    <div className="text-3xl font-bold">
                      {meetingData?.meetingStatisticsData &&
                        !meetingData.loading
                        ? meetingData?.meetingStatisticsData?.totalDueAction
                        : 0}
                    </div>
                  </div>
                  </div>
                  <div className="flex items-center font-semibold mt-3">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="shrink-0 mr-2"
                    >
                      <path
                        opacity="0.5"
                        d="M3.27489 15.2957C2.42496 14.1915 2 13.6394 2 12C2 10.3606 2.42496 9.80853 3.27489 8.70433C4.97196 6.49956 7.81811 4 12 4C16.1819 4 19.028 6.49956 20.7251 8.70433C21.575 9.80853 22 10.3606 22 12C22 13.6394 21.575 14.1915 20.7251 15.2957C19.028 17.5004 16.1819 20 12 20C7.81811 20 4.97196 17.5004 3.27489 15.2957Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                      ></path>
                      <path
                        d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                      ></path>
                    </svg>
                    All (
                    {meetingData?.meetingStatisticsData && !meetingData.loading
                      ? meetingData?.meetingStatisticsData?.totalAction
                      : 0}
                    )
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-sm-12">
                <div className="panel-db panel to-fuchsia-400 d-flex justify-content-between">
                  <div>
                  <div className="flex justify-between">
                    <div className="ltr:mr-1 rtl:ml-1 text-md font-semibold">
                      Total High Priority Due
                    </div>
                  </div>
                  <div className="flex items-center mt-3">
                    <div className="text-3xl font-bold">
                      {" "}
                      {meetingData?.meetingStatisticsData &&
                        !meetingData.loading
                        ? meetingData?.meetingStatisticsData
                          ?.totalHighPriorityDueAction
                        : 0}
                    </div>
                  </div>
                  </div>
                  <div className="flex align-items-center font-semibold mt-3">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="shrink-0 mr-2"
                    >
                      <path
                        opacity="0.5"
                        d="M3.27489 15.2957C2.42496 14.1915 2 13.6394 2 12C2 10.3606 2.42496 9.80853 3.27489 8.70433C4.97196 6.49956 7.81811 4 12 4C16.1819 4 19.028 6.49956 20.7251 8.70433C21.575 9.80853 22 10.3606 22 12C22 13.6394 21.575 14.1915 20.7251 15.2957C19.028 17.5004 16.1819 20 12 20C7.81811 20 4.97196 17.5004 3.27489 15.2957Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                      ></path>
                      <path
                        d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                      ></path>
                    </svg>
                    All High Priority (
                    {meetingData?.meetingStatisticsData && !meetingData.loading
                      ? meetingData?.meetingStatisticsData
                        ?.totalHighPriorityAction
                      : 0}
                    )
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-sm-12">
                <div className="panel-db panel to-blue-400 d-flex justify-content-between">
                  <div>
                  <div className="flex justify-between">
                    <div className="ltr:mr-1 rtl:ml-1 text-md font-semibold">
                      Total Normal Priority Due
                    </div>
                  </div>
                  <div className="flex items-center mt-3">
                    <div className="text-3xl font-bold">
                      {meetingData?.meetingStatisticsData &&
                        !meetingData.loading
                        ? meetingData?.meetingStatisticsData
                          ?.totalMediumPriorityDueAction
                        : 0}{" "}
                    </div>
                  </div>
                  </div>
                  <div className="flex align-items-center font-semibold mt-3">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="shrink-0 mr-2"
                    >
                      <path
                        opacity="0.5"
                        d="M3.27489 15.2957C2.42496 14.1915 2 13.6394 2 12C2 10.3606 2.42496 9.80853 3.27489 8.70433C4.97196 6.49956 7.81811 4 12 4C16.1819 4 19.028 6.49956 20.7251 8.70433C21.575 9.80853 22 10.3606 22 12C22 13.6394 21.575 14.1915 20.7251 15.2957C19.028 17.5004 16.1819 20 12 20C7.81811 20 4.97196 17.5004 3.27489 15.2957Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                      ></path>
                      <path
                        d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                      ></path>
                    </svg>
                    All Normal Priority (
                    {meetingData?.meetingStatisticsData && !meetingData.loading
                      ? meetingData?.meetingStatisticsData
                        ?.totalNormalPrioprityAction
                      : 0}
                    )
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-sm-12">
                <div className="panel-db panel to-green-400 d-flex justify-content-between">
                  <div>
                    <div className="flex justify-between">
                      <div className="ltr:mr-1 rtl:ml-1 text-md font-semibold">
                        Total Low Priority Due
                      </div>
                    </div>
                    <div className="flex items-center mt-3">
                      <div className="text-3xl font-bold">
                        {meetingData?.meetingStatisticsData &&
                          !meetingData.loading
                          ? meetingData?.meetingStatisticsData
                            ?.totalLowPriorityDueAction
                          : 0}{" "}
                      </div>
                    </div>
                  </div>
                  <div className="flex align-items-center font-semibold mt-3">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-2 shrink-0"
                    >
                      <path
                        opacity="0.5"
                        d="M3.27489 15.2957C2.42496 14.1915 2 13.6394 2 12C2 10.3606 2.42496 9.80853 3.27489 8.70433C4.97196 6.49956 7.81811 4 12 4C16.1819 4 19.028 6.49956 20.7251 8.70433C21.575 9.80853 22 10.3606 22 12C22 13.6394 21.575 14.1915 20.7251 15.2957C19.028 17.5004 16.1819 20 12 20C7.81811 20 4.97196 17.5004 3.27489 15.2957Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                      ></path>
                      <path
                        d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                      ></path>
                    </svg>
                    All Low Priority (
                    {meetingData?.meetingStatisticsData && !meetingData.loading
                      ? meetingData?.meetingStatisticsData
                        ?.totalLowPriorityAction
                      : 0}
                    )
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ActionFilterComponent
            setfilter={setfilter}
            filterData={filterData}
            initData={searchData.filterData}
            filter={filter}
          />
          <div>
            <div className="left-tbl-text">
              <ActionPieChart organizationId={userData?.organizationId} />
            </div>
          </div>
          <div className="mt-2 table-box responsive-table">
            <div className="d-flex align-items-center justify-content-between resp-flex-column">
              <div className="left-tbl-text">
                {totalCount > 0 ? (
                  <p>
                    Showing {fromDataCount} to {toDataCount} of {totalCount}{" "}
                    actions
                  </p>
                ) : null}
              </div>
              <div className="meeting-f-search">
                <div className="search-box">
                  <input
                    type="search"
                    placeholder="Search By Action Title..."
                    onChange={handleChange}
                    name="searchKey"
                    value={searchData.searchKey}
                    autoComplete="off"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#4F2CC8"
                    className="bi bi-search"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                  </svg>
                </div>

                <button
                  type="button"
                  className="btn rounded-pill add-btn Mom-btn d-flex align-items-center justify-content-center"
                  id="open-form-btn-meetinglist"
                  onClick={(e) => setfilter(true)}
                >
                  <p>Filter</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-funnel filter-svg"
                    viewBox="0 0 16 16"
                  >
                    <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2z" />
                  </svg>
                </button>
              </div>
            </div>

            {actionData.loading == false &&
              actionData.actionList?.length !== 0 &&
              actionData.isSuccess ? (
              <table className="mt-4 table table-bordered table-striped">
                <thead>
                  <tr>
                    <th scope="col">Sl.No</th>
                    <th scope="col">Due Date</th>
                    {/* <th scope="col">Completed On</th> */}
                   

                    <th scope="col">Action Title</th>

                    {Object.keys(searchData?.filterData).length === 0 ||
                      searchData?.filterData?.meetingId === "" ? (
                      <th scope="col">Meeting Title & ID</th>
                    ) : null}

                    {/* {employeeData?.userData?.isMeetingOrganiser ||
                    employeeData?.userData?.isAdmin ? (
                      <th scope="col" className="text-align-center">
                        Assigned to
                      </th>
                    ) : null} */}
                    <th scope="col">Priority</th>
                    <th scope="col" className="text-align-center">
                      Assigned to
                    </th>

                    <th scope="col" className="text-align-center">
                      Status
                    </th>
                    <th scope="col">Delay Status</th>
                    <th className="action-col" scope="col">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {actionData.actionList?.map((action, index) => {
                    return (
                      <tr>
                        <td data-label="Sl.No" className="meeting-td-width-9">
                          {searchData.limit * (searchData?.page - 1) +
                            index +
                            1}
                        </td>
                        <td
                          data-label="Due Date"
                          className="meeting-td-width-6"
                        >
                          {action?.mainDueDate}
                        </td>
                        {/* <td
                          data-label="Due Date"
                          className="meeting-td-width-6"
                        >
                          {action?.isComplete
                            ? formatDateTimeFormat(
                                action?.isCompleteDetails[
                                  action?.isCompleteDetails?.length - 1
                                ].dateTime
                              ).formattedDate
                            : "NA"}
                        </td> */}

                        <td
                          data-label="Action Title"
                          className="meeting-td-width word-break"
                        >
                          <Link
                            to={`/view-action-detail/${action?._id}`}
                            style={{
                              textDecoration: "none",
                              color: "black",
                            }}
                          >
                            {" "}
                            <div>
                              {" "}
                              {/* {action?.meetingDetails?.length !== 0
                                ? action?.meetingDetails?.title
                                : "Not Available"}{" "} */}
                              {action?.title?.length > 35 ? (
                                <OverlayTrigger
                                  trigger={["hover", "focus"]}
                                  placement="bottom"
                                  overlay={popoverHoverFocus(action?.title)}
                                >
                                  <span className="meeting-title-hover">
                                    {`${sliceTextFromHTML(
                                      action?.title,
                                      0,
                                      35
                                    )}....`}
                                  </span>
                                </OverlayTrigger>
                              ) : (
                                action?.title
                              )}
                            </div>{" "}
                            {/* {action?.description?.charAt(0).toUpperCase() +
                            action?.description?.slice(1).toLowerCase()} */}
                          </Link>
                        </td>
                        {Object.keys(searchData?.filterData).length === 0 ||
                          searchData?.filterData?.meetingId === "" ? (
                          <td
                            data-label="Meeting Title & ID"
                            className="meeting-td-width word-break"
                          >
                            {" "}
                            <p>
                              {" "}
                              {/* {action?.meetingDetails?.length !== 0
                                ? action?.meetingDetails?.title
                                : "Not Available"}{" "} */}
                              {action?.meetingDetails?.title?.length > 35 ? (
                                <OverlayTrigger
                                  trigger={["hover", "focus"]}
                                  placement="bottom"
                                  overlay={meetingDetailsPopover(
                                    action?.meetingDetails?.title
                                  )}
                                >
                                  <span className="meeting-title-hover">
                                    {`${action?.meetingDetails?.title.slice(
                                      0,
                                      35
                                    )}....`}
                                  </span>
                                </OverlayTrigger>
                              ) : (
                                action?.meetingDetails?.title
                              )}
                            </p>
                            <p className="detail-date-time">
                              Meeting ID - {action?.meetingDetails?.meetingId}
                            </p>
                          </td>
                        ) : null}

                        {/* {employeeData?.userData?._id?.toString() ===
                          action?.meetingDetails?.createdById?.toString() ||
                        employeeData?.userData?.isAdmin ? (
                          <td
                            data-label="Assigned to"
                            className="meeting-td-width-6  attendees-center"
                          >
                            {action?.assignedUserDetails ? (
                              <div
                                className="attendees"
                                title={`${action?.assignedUserDetails?.name} / ${action?.assignedUserDetails?.email}`}
                              >
                                <div className="attendee-list">
                                  {customName(
                                    action?.assignedUserDetails?.name
                                  )}
                                </div>
                              </div>
                            ) : (
                              "Not Available"
                            )}
                          </td>
                        ) : null} */}

<td
                          data-label="Priority"
                          className="meeting-td-width-7"
                        >
                          {" "}
                          {action?.priority?.charAt(0).toUpperCase() +
                            action?.priority?.slice(1).toLowerCase()}
                        </td>

                        <td
                          data-label="Assigned to"
                          className="meeting-td-width-6  attendees-center"
                        >
                          {action?.assignedUserDetails ? (
                            <div
                              className="attendees"
                              title={`${action?.assignedUserDetails?.name} / ${action?.assignedUserDetails?.email}`}
                            >
                              <OverlayTrigger
                                placement="top"
                                overlay={tooltip(action?.assignedUserDetails)}
                                trigger={["hover", "focus"]}
                              >
                                <div className="attendee-list">
                                  {customName(action?.assignedUserDetails?.name)}
                                </div>
                              </OverlayTrigger>

                            </div>
                          ) : (
                            "Not Available"
                          )}
                        </td>

                        <td
                          data-label="Status"
                          className="meeting-td-width-0  text-align-center"
                        >
                          {action?.actionStatus === "COMPLETED" ? (
                            <span className="badge completed-color bg-opacity-10 ">
                              Completed
                            </span>
                          ) : null}
                          {action?.actionStatus === "REOPENED" ? (
                            <span className="badge reopened-color bg-opacity-10 ">
                              Reopened
                            </span>
                          ) : null}
                          {action?.actionStatus === "APPROVED" ? (
                            <span className="badge approved-color bg-opacity-10 ">
                              Approved
                            </span>
                          ) : null}

                          {employeeData?.userData?.isAdmin === false &&
                            employeeData?.userData?._id?.toString() !==
                            action?.meetingDetails?.createdById?.toString() &&
                            action?.actionStatus === "REQUESTFORREASSIGN" &&
                            action?.reassigneRequestDetails[
                              action?.reassigneRequestDetails?.length - 1
                            ]?.userId?.toString() ===
                            employeeData?.userData?._id?.toString() ? (
                            <span className="badge forwardrequested-color bg-opacity-10 ">
                              Forward Requested
                            </span>
                          ) : null}
                          {employeeData?.userData?.isAdmin === false &&
                            employeeData?.userData?._id?.toString() !==
                            action?.meetingDetails?.createdById?.toString() &&
                            action?.actionStatus === "PENDING" ? (
                            <span className="badge pending-color bg-opacity-10 ">
                              Pending
                            </span>
                          ) : null}
                          {(employeeData?.userData?.isAdmin ||
                            employeeData?.userData?._id?.toString() ===
                            action?.meetingDetails?.createdById?.toString()) &&
                            action?.actionStatus === "PENDING" ? (
                            <span className="badge pending-color bg-opacity-10 ">
                              Pending
                            </span>
                          ) : null}
                          {(employeeData.userData.isAdmin ||
                            employeeData?.userData?._id?.toString() ===
                            action?.meetingDetails?.createdById?.toString()) &&
                            action?.actionStatus === "REQUESTFORREASSIGN" ? (
                            <span className="badge forwardrequested-color bg-opacity-10 ">
                              Forward Requested
                            </span>
                          ) : null}

                          {(employeeData.userData.isAdmin ||
                            employeeData?.userData?._id?.toString() ===
                            action?.meetingDetails?.createdById?.toString()) &&
                            action?.actionStatus === "REASSIGNED" ? (
                            <span className="badge reassigned-color bg-opacity-10 ">
                              Forwarded
                            </span>
                          ) : null}
                          {!employeeData.userData.isAdmin &&
                            employeeData?.userData?._id?.toString() !==
                            action?.meetingDetails?.createdById?.toString() &&
                            action?.actionStatus === "REASSIGNED" ? (
                            <span className="badge reassigned-color bg-opacity-10 ">
                              Forwarded
                            </span>
                          ) : null}
                          {/* {(employeeData.userData.isAdmin ||
                            employeeData.userData.isMeetingOrganiser) &&
                          action?.actionStatus === "CANCELLED" ? (
                            <span className="badge canceled-color bg-opacity-10 ">
                              Cancelled
                            </span>
                          ) : null} */}

                          {action?.actionStatus === "CANCELLED" ? (
                            <span className="badge canceled-color bg-opacity-10 ">
                              Cancelled
                            </span>
                          ) : null}
                        </td>

                        {action?.actionStatus === "CANCELLED" ? (
                          <td
                            data-label="Action"
                            className="text-align-center meeting-td-width-7"
                          >
                            <span className="badge NA-color bg-opacity-10 ">
                              NA
                            </span>
                          </td>
                        ) : (
                          <td
                            data-label="Action"
                            className="text-align-center meeting-td-width-7"
                          >
                            {action?.isDelayed === true ? (
                              <>
                                <span className="badge delay-color bg-opacity-10 ">
                                  Delayed
                                </span>
                                <p className="detail-date-time">
                                  {action?.completionStatus}
                                </p>
                              </>
                            ) : (
                              <>
                                <span className="badge notDelay-color bg-opacity-10 ">
                                  Not Delayed
                                </span>
                                <p className="detail-date-time">
                                  {action?.completionStatus}
                                </p>
                              </>
                            )}
                          </td>
                        )}

                        <td
                          data-label="Action"
                          className="text-align-center meeting-td-width-7"
                        >
                          <div className="d-inline-block menu-dropdown custom-dropdown">
                            <ActionDropDown
                              action={action}
                              handleRequestForReassignModal={() => {
                                handleRequestForReassignModal(action?._id);
                              }}
                              handleReassignModal={() => {
                                handleReassignModal(action);
                              }}
                              handleCancelActionModal={() => {
                                handleCancelAction(true, action);
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                  <tr></tr>
                </tbody>
              </table>
            ) : // </div>
              !actionData.loading && actionData.actionList?.length === 0 ? (
                <div className="no-data-img">
                  <Alert
                    status={"info"}
                    message={"No data available."}
                    timeoutSeconds={0}
                  />

                  <NoDataFound dataType={"action"} />
                  {  Object.keys(searchData?.filterData).length!==0 || searchData?.searchKey !==""?(
                  <Button
                    className="mt-2"
                    variant="primary"
                    onClick={(e) => {
                      setSearchData({
                        ...searchData,
                        searchKey: "",
                        filterData: {},
                        page: 1,
                      });
                    }}
                  >
                    Clear
                  </Button>
                  ):null}
                </div>
              ) : (
                <div
                  className="meeting-page "
                  style={{ textAlign: "center", paddingTop: 20 }}
                >
                  <Loader />
                </div>
              )}

            {totalCount <= 1 ? null : (
              <div className="tbl-bottom">
                <div className="left-tbl-bottom">
                  {searchData.page !== 1 ? (
                    <button
                      className="left-arrow"
                      onClick={(e) =>
                        setSearchData({
                          ...searchData,
                          page: searchData.page - 1,
                        })
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="#fff"
                        className="bi bi-chevron-left"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                        />
                      </svg>
                    </button>
                  ) : null}

                  {/* Pagination Component from MUI without arrows */}
                  <Stack spacing={2}>
                    <Pagination
                      count={totalPage}
                      page={searchData.page}
                      siblingCount={0}
                      boundaryCount={2}
                      onChange={handlePageChange}
                      hidePrevButton
                      hideNextButton
                      sx={{
                        "& .Mui-selected": {
                          backgroundColor: "#388d27 !important",
                          color: "#fff !important",
                        },
                      }}
                    />
                  </Stack>
                  {totalPageArray?.length !== searchData.page ? (
                    <button
                      className="right-arrow"
                      onClick={(e) =>
                        setSearchData({
                          ...searchData,
                          page: searchData.page + 1,
                        })
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="#fff"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                        />
                      </svg>
                    </button>
                  ) : null}
                </div>

                <div className="right-tbl-bottom">
                  <p>Rows Per Page</p>
                  <select
                    className="no-opt-box"
                    name="limit"
                    onChange={(e) =>
                      setSearchData({
                        ...searchData,
                        page: 1,
                        limit: e.target.value,
                      })
                    }
                    value={searchData.limit}
                  >
                    {totalOption &&
                      Array(totalOption)
                        .fill()
                        .map((_, option) => {
                          return (
                            <option value={(option + 1) * 5}>
                              {(option + 1) * 5}
                            </option>
                          );
                        })}
                  </select>
                </div>
              </div>
            )}
          </div>
        </div>
        <ReassignModal
          setIsReassignModalOpen={setIsReassignModalOpen}
          isModalOpen={isReassignModalOpen}
          handleSubmit={handleReassign}
          actionData={actionDatas}
          meetingDate={actionDatas?.meetingDetails?.date}
        />
      </div>
      <RequestForReassignModal
        setIsModalOpen={setIsRequestForReassignModalOpen}
        isModalOpen={isRequestForReassignModalOpen}
        handleSubmit={handleRequestForReassign}
      />

      <CommonModal
        message={"Do you want to cancel the action ?"}
        title={"Cancel Action"}
        setIsModalOpen={setIsCloseModalOpen}
        isModalOpen={isCloseModalOpen}
        handleSubmit={submitCancelAction}
        buttonName={"Cancel"}
        isCalledFromOthers={true}
      />
    </>
  );
};

export default ActionList;
