import React from "react";
import { Link } from "react-router-dom";
import { Plus,Pen,SquarePen } from "lucide-react";
import { useDispatch } from "react-redux";
import { setCreateNewMeetingPage } from "../../redux/actions/meetingActions/MeetingAction";

const EditButton = (props) => {
  const dispatch = useDispatch();

  return (
    <Link
    to="/edit-meeting"
    state={{
      meetingId: props?.meetingData?.singleMeetingDetails?._id,
      isMeetingDataUpdate: true,
      step: props?.meetingData
    }}
    style={{
      textDecoration: "none",
      color: "black",
    }}
    >
      <button
        className="plus-icon"
       
      >
         <SquarePen size={22} />
      </button>
    </Link>
  );
};

export default EditButton;
