import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import MainRoute from "./config/route";
import axios from "axios";
import { fetchIpAddress } from "./helpers/commonHelpers";
console.log("-------------------", window.location.pathname);
axios.defaults.headers = {
  ip:await fetchIpAddress()
};


const App = () => {
  const currentPath = window.location.pathname;
  
  if (currentPath === "/") {
    // Add the class to <body> if the path is "/"
    document.body.classList.add("landing-page");
  } else {
    // Remove the class when not on the "/" path
    document.body.classList.remove("landing-page");
  }

  return (
    <div>
      <MainRoute />
    </div>
  );
};

export default App;
