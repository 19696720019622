import React, { useState, useEffect } from "react";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import { useDispatch, useSelector } from "react-redux";
import { getMeetingListForChart } from "../../redux/actions/meetingActions/MeetingAction";
import { ResponsiveChartContainer } from "@mui/x-charts/ResponsiveChartContainer";
import Paper from "@mui/material/Paper";
//import Paper from "@material-ui/core/Paper";
import MeetinWithActionBarChart from "./MeetinWithActionBarChart";
import MeetingBar from "./MeetingBar";
import MeetingPieChart from "./MeetingPieChart";
const MeetingDetailsPieChart = ({ data, totalCount, organizationId }) => {
  const meetingData = useSelector((state) => state.meeting);
  const [updatedDatas, setUpdatedDatas] = useState([]);
  const [finalMeetingDatas, setFinalMeetingDatas] = useState([]);
  const accessToken = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  console.log(meetingData?.meetingChartDetails)
  useEffect(() => {
    // alert("innnn1")
    if (accessToken) {
      dispatch(getMeetingListForChart(organizationId, accessToken));
    }
  }, [meetingData.isFetchedMeetingList]);
  // useEffect(() => {
  //   //  document.title = "Meeting List: MinutesVault";
  //   dispatch(viewMeetingStatistics(userData?.organizationId, accessToken));
  //   getMeetingListForChart(userData?.organizationId, accessToken);
  // }, [meetingData.isFetchedMeetingList]);
  useEffect(() => {
    console.log(meetingData);
    console.log( meetingData?.meetingChartDetails)
    console.log(meetingData?.meetingList);
    const updatedData = [
      {
        id: 0,
        value:
          meetingData?.meetingChartDetails?.totalCount === 0
            ? 0
            : normalize(
                meetingData?.meetingChartDetails?.scheduled,
                meetingData?.meetingChartDetails?.totalCount
              ),
        count: meetingData?.meetingChartDetails?.scheduled,
        label: "Scheduled",

        name: `Scheduled (${
          meetingData?.meetingChartDetails?.scheduled
            ? meetingData?.meetingChartDetails?.scheduled
            : 0
        })`,
        color: "#2796f3",
      },
      {
        id: 1,

        name: `Rescheduled (${
          meetingData?.meetingChartDetails?.rescheduled
            ? meetingData?.meetingChartDetails?.rescheduled
            : 0
        })`,
        value:
        meetingData?.meetingChartDetails?.totalCount === 0
            ? 0
            : normalize(
                meetingData?.meetingChartDetails?.rescheduled,
                meetingData?.meetingChartDetails?.totalCount
              ),
        count: meetingData?.meetingChartDetails?.rescheduled,
        label: "Rescheduled",
        color: "#ab47bc",
      },
      {
        id: 2,

        name: `Closed (${
          meetingData?.meetingChartDetails?.closed
            ? meetingData?.meetingChartDetails?.closed
            : 0
        })`,
        value:
        meetingData?.meetingChartDetails?.totalCount === 0
            ? 0
            : normalize(
                meetingData?.meetingChartDetails?.closed,
                meetingData?.meetingChartDetails?.totalCount
              ),
        count: meetingData?.meetingChartDetails?.closed,
        label: "Closed",
        color: "#4caf50",
      },
      // {
      //   id: 3,
      //   name: `Draft(${meetingData?.meetingChartDetails?.draft?meetingData?.meetingChartDetails?.draft:0})`,
      //   color: "#9E9E9E",
      //   value:
      //   meetingData?.totalCount=== 0
      //   ? 0
      //   : normalize(
      //     meetingData?.meetingChartDetails?.draft,
      //     meetingData?.totalCount
      //   ),
      //   count: meetingData?.meetingChartDetails?.draft,
      //   label: "Draft",
      //   // color: "#c9c9c9",
      // },

      {
        id: 4,
        // value:22%,
        name: `Cancelled (${
          meetingData?.meetingChartDetails?.cancelled
            ? meetingData?.meetingChartDetails?.cancelled
            : 0
        })`,
        count: meetingData?.meetingChartDetails?.cancelled,
        value:
        meetingData?.meetingChartDetails?.totalCount === 0
            ? 0
            : normalize(
                meetingData?.meetingChartDetails?.cancelled,
                meetingData?.meetingChartDetails?.totalCount
              ),
        label: "Cancelled",
        color: "#f83e6a",
      },
    ];

    if (meetingData?.meetingChartDetails===null ||
      (meetingData?.meetingChartDetails?.totalCount === 0 ||
      meetingData?.meetingChartDetails?.totalCount=== undefined)  &&  meetingData?.meetingChartDetails?.draft===0
    ) {
    
      updatedData.push({
        id: 5,
        // value:22%,
        name: "No Meetings",
        value:100,
        count: 0,
        label: "No Meetings",
        color: "#5b7197",
      });
    }

    if (meetingData?.meetingChartDetails?.totalCount=== 0 &&
      meetingData?.totalCount !== 0 &&  meetingData?.meetingChartDetails?.cancelled===0 
      &&  meetingData?.meetingChartDetails?.closed===0
      &&  meetingData?.meetingChartDetails?.rescheduled===0
      &&  meetingData?.meetingChartDetails?.scheduled===0
      &&  meetingData?.meetingChartDetails?.draft!==0
    ) {
      updatedData.push(
        {
        id: 3,
        name: `Draft(${meetingData?.meetingChartDetails?.draft?meetingData?.meetingChartDetails?.draft:0})`,
        color: "#9E9E9E",
        value:
        meetingData?.totalCount=== 0
        ? 0
        : normalize(
          meetingData?.meetingChartDetails?.draft,
          meetingData?.totalCount
        ),
        count: meetingData?.meetingChartDetails?.draft,
        label: "Draft",
        // color: "#c9c9c9",
      },
      );
    }

    console.log(updatedData);
    setFinalMeetingDatas(updatedData);
  }, [
    meetingData?.meetingChartDetails,
    meetingData?.totalCount,
    meetingData?.meetingList,
  ]);

  console.log(meetingData?.meetingChartDetails);
  useEffect(() => {
    const updatedData = [
      {
        id: 0,
        value:
          data?.length === 0
            ? 0
            : normalize(
                data?.filter(
                  (meeting) => meeting?.meetingStatus?.status === "scheduled"
                ).length,
                data?.length
              ),
        count: data?.filter(
          (meeting) => meeting?.meetingStatus?.status === "scheduled"
        ).length,
        label: "Scheduled",
        name: "Scheduled",
        color: "#2196F3",
      },
      {
        id: 1,
        value:
          data?.length === 0
            ? 0
            : normalize(
                data?.filter(
                  (meeting) => meeting?.meetingStatus?.status === "rescheduled"
                ).length,
                data?.length
              ),
        count: data?.filter(
          (meeting) => meeting?.meetingStatus?.status === "rescheduled"
        ).length,
        label: "Rescheduled",
        color: "#AB47BC",
        name: "Rescheduled",
      },
      {
        id: 2,
        value:
          data?.length === 0
            ? 0
            : normalize(
                data?.filter(
                  (meeting) => meeting?.meetingStatus?.status === "closed"
                ).length,
                data?.length
              ),
        count: data?.filter(
          (meeting) => meeting?.meetingStatus?.status === "closed"
        ).length,
        label: "Closed",
        color: "#4CAF50",
        name: "Closed",
      },
      {
        id: 3,
        value:
          data?.length === 0
            ? 0
            : normalize(
                data?.filter(
                  (meeting) => meeting?.meetingStatus?.status === "draft"
                ).length,
                data?.length
              ),
        count: data?.filter(
          (meeting) => meeting?.meetingStatus?.status === "draft"
        ).length,
        label: "Draft",
        color: "#9E9E9E",
        name: "Draft",
      },
      {
        id: 4,
        value:
          data?.length === 0
            ? 0
            : normalize(
                data?.filter(
                  (meeting) => meeting?.meetingStatus?.status === "cancelled"
                ).length,
                data?.length
              ),
        count: data?.filter(
          (meeting) => meeting?.meetingStatus?.status === "cancelled"
        ).length,
        label: "Cancelled",
        color: "#f83e6a",
        name: "Cancelled",
      },
      {
        id: 5,
        value:
          data?.length === 0
            ? 100
            : normalize(
                data?.length === 0 ? 1 : 0,
                data?.length === 0 ? 1 : data?.length
              ),
        count: 0,
        label: "No Meetings",
        color: "#5b7197",
        name: "No Meetings",
      },
    ];
    setUpdatedDatas(updatedData);
  }, [data]);

  const normalize = (v, v2) => Number.parseFloat(((v / v2) * 100).toFixed(2));
  console.log(updatedDatas);
  const finalDatas = [
    ...updatedDatas.map((v) => ({
      ...v,
      label: v.label,
      value: normalize(v.value, data?.length),
    })),
  ];
  console.log(finalDatas);
  const valueFormatter = (item) => `${item.value}%`;
  const valueFormatterb = (item) => `${item.value}%`;
  const datas = {
    data: updatedDatas,
    valueFormatter,
  };
  console.log(finalMeetingDatas);
  const finalChartDatas = {
    data: finalMeetingDatas,
    valueFormatterb,
  };
  console.log(finalChartDatas);

  console.log(datas);
  return (
    <div className="color-box-resp">
      <div className="row">
        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 ">
          <div className="mt-2 table-box responsive-table chart-min">
            <h6>Total Meetings</h6>

            {/* <PieChart 
              series={[
                {
                  arcLabel: (item) => `${item.value}%`,
                  arcLabelMinAngle: 5,
                  arcLabelRadius: "60%",
                  ...finalChartDatas,
                  cx: 155,
                  cy: 155,
                  highlightScope: { fade: "global", highlight: "item" },
                  faded: {
                    innerRadius: 30,
                    additionalRadius: -30,
                    color: "brown",
                  },
                },
              ]}
              sx={{
                [`& .${pieArcLabelClasses.root}`]: {
                  fontWeight: "bold",
                  fontSize: "14px",
                  fill: "#fff"
                },
              }}
              width={500}
              height={330}
              
            />
           */}

            <MeetingPieChart
              data={finalMeetingDatas}
              totalCount={totalCount}
              organizationId={organizationId}
            />
          </div>
        </div>
        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="table-box responsive-table res-mt-20 due-action-table">
            <MeetingBar showDetailsLink={true} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetingDetailsPieChart;
