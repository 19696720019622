export const MAKE_REQUEST = "MEETING_MAKE_REQUEST";
export const FAIL_REQUEST = "MEETING_FAIL_REQUEST";
export const GET_MEETING_LIST = "GET_MEETING_LIST";
export const GET_ATTENDEES_LIST = "GET_ATTENDEES_LIST";
export const UPDATE_RSVP = "UPDATE_RSVP";
export const MAKE_RSVP_UPDATE_REQUEST = "MAKE_RSVP_UPDATE_REQUEST";
export const CREATE_MEETING_RESPONSE = "CREATE_MEETING_RESPONSE";
export const GET_CREATE_MEETING_STEPS = "GET_CREATE_MEETING_STEPS";
export const UPDATE_ISCREATE_MEETING_PROCESSED =
  "UPDATE_ISCREATE_MEETING_PROCESSED";
export const SET_ATTENDEES = "SET_ATTENDEES";
export const LOAD_PREVIOUS_STEP = "LOAD_PREVIOUS_STEP";
export const UPDATE_MEETING_RESPONSE = "UPDATE_MEETING_RESPONSE";
export const SET_SINGLE_MEETING_DETAILS = "SET_SINGLE_MEETING_DETAILS";
export const SET_MEETING_VIEW_PAGE = "SET_MEETING_VIEW_PAGE";
export const SET_CREATE_NEW_MEETING_PAGE = "SET_CREATE_NEW_MEETING_PAGE";
export const UNSET_SINGLE_MEETING_DETAILS = "UNSET_SINGLE_MEETING_DETAILS";
export const UPDATE_STEP = "UPDATE_STEP";
export const UPDATE_FETCH_MEETING_LIST_STATUS =
  "UPDATE_FETCH_MEETING_LIST_STATUS";
export const SET_AGENDA_AND_MINUTES_DETAILS = "SET_AGENDA_AND_MINUTES_DETAILS";
export const SET_MEETING_ACTIVITIES_DETAILS = "SET_MEETING_ACTIVITIES_DETAILS";
export const SET_USER_MEETING_LIST = "SET_USER_MEETING_LIST";
export const UPDATE_ATTENDANCE_RESPONSE = "UPDATE_ATTENDANCE_RESPONSE";
export const UPDATE_MEETING_ISSUCCESS = "UPDATE_MEETING_ISSUCCESS";
export const SET_DOWNLOAD_MOM_RESPONSE = "SET_DOWNLOAD_MOM_RESPONSE";
export const SET_TIMELINE_RESPONSE = "SET_TIMELINE_RESPONSE";
export const SET_PARENT_AGENDA_AND_MINUTES_DETAILS =
  "SET_PARENT_AGENDA_AND_MINUTES_DETAILS";
export const SET_GENERATE_MOM_RESPONSE = "SET_GENERATE_MOM_RESPONSE";
export const SET_MEETING_STATISTICS_RESPONSE =
  "SET_MEETING_STATISTICS_RESPONSE";
export const MAKE_STATISTIC_REQUEST = "MAKE_STATISTIC_REQUEST";
export const UPDATE_ISSHOW_UPDATE_NOTIFY = "UPDATE_ISSHOW_UPDATE_NOTIFY";
export const SEND_MEETING_DETAILS_RESPONSE = "SEND_MEETING_DETAILS_RESPONSE";
export const GET_CURRENT_ATTENDEES_LIST = "GET_CURRENT_ATTENDEES_LIST";
export const SET_MEETING_DETAILS = "SET_MEETING_DETAILS";
export const SET_TOTAL_MEETING_LIST_FOR_CHART =
  "SET_TOTAL_MEETING_LIST_FOR_CHART";
export const SET_MEETING_TOTAL_DUE_ACTION_PRIORITY_WISE =
  "SET_MEETING_TOTAL_DUE_ACTION_PRIORITY_WISE";
export const SET_SUCCESS = "SET_SUCCESS";
export const SET_DELETE_ZOOM_RECORDING_RESPONSE =
  "SET_DELETE_ZOOM_RECORDING_RESPONSE";
export const SET_DOWNLOAD_ALL_ZOOM_RECORDING_RESPONSE =
  "SET_DOWNLOAD_ALL_ZOOM_RECORDING_RESPONSE";
export const SET_ERROR_MESSAGE="SET_ERROR_MESSAGE";
export const SET_SINGLE_MEETING_DETAILS_NOT_UPDATE_STEP="SET_SINGLE_MEETING_DETAILS_NOT_UPDATE_STEP";
export const MAKE_UPDATE_REQUEST="MAKE_UPDATE_REQUEST"
export const SET_IS_NEW_MEETING_PAGE_PAGE="SET_IS_NEW_MEETING_PAGE_PAGE"