import React, { useState, useEffect } from "react";
import {  listAllUnitForMeetingForEmployeeCheck } from "../../redux/actions/meetingRoomAction/meetingRoomAction";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import {
  fetchAttendeesList,
  fetchCurrentAttendeesList,
  updateMeetingDetails,
  updateStep,
  checkAttendeeAvailability,
  setErrorMessage,
} from "../../redux/actions/meetingActions/MeetingAction";
import * as constantMessages from "../../constants/constatntMessages";
import "../Login/style/Login.css";
import LoaderButton from "../Common/LoaderButton";
import {
  checkDuplicateUser,
  getEmployeeListAsPerUnit,
} from "../../redux/actions/userAction/userAction";
import { customName } from "../../helpers/commonHelpers";
import RemoveAttendeesModal from "./RemoveAttendeesModal";
import { useLocation, useNavigate } from "react-router-dom";
import { logOut } from "../../redux/actions/authActions/authAction";
import { toast } from "react-toastify";
import AttendeeDragDrop from "./AttendeeDragDrop";
import ConfirmModal from "./ConfirmModal";

const AddAttendees = (props) => {
  const regularExpression = /^[0-9a-zA-Z .,:;@!#&$()/\-_]+$/;
  const regularNameExpression = /^[0-9a-zA-Z .,:;()/\-_]+$/;
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("accessToken");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();
  const meetingRoomData = useSelector((state) => state.meetingRoom);
  const meetingData = useSelector((state) => state.meeting);
  const employeeData = useSelector((state) => state.user);
  const authData = useSelector((state) => state.auth);
  const actionData = useSelector((state) => state.action);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState(
    "Do you want to save the changes ?"
  );
  const [confirmTitle, setConfirmTitle] = useState("Update Attendees");
  console.log(employeeData?.userData?.unitDetails);
  if (authData.isInValidUser && authData.isInvalidUserChecked) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }
  const location = useLocation();
  const stateData = location.state;
  const [unitId, setUnitId] = useState(null);
  const [selectedAttendeeOption, setSelectedAttendeeOption] = useState([
    { label: "*Name / Email Address", value: "" },
  ]);
  const [selectedEmployeeOption, setSelectedEmployeeOption] = useState([
    { label: "*Name / Emp Id", value: "" },
  ]);
  const [selectedUnitOption, setSelectedUnitOption] = useState([
    { label: "*Select Unit", value: "" },
  ]);
  const [removeAttendeeData, setRemoveAttendeeData] = useState({});
  const [errors, setErrors] = useState({});
  // PRATISHRUTI ADD CODE FOR MEETILG LIST DUPLICATE NOT SHOW
  const [selectedAttendees, setSelectedAttendees] = useState([]);
  const [selectEmployee, setSelectEmployee] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    mode: "physical",
    location: "manual",
    date: "",
    link: "",
    fromTime: "",
    toTime: "",
    roomId: null,
    locationData: "",
    attendyType: "fromPreviousMeeting",
    attendeeId: null,
    name: "",
    email: "",
    unitId: "",
    designation: "",
    companyName: "",
  });
  const [attendeesData, setAttendeesData] = useState([]);
  console.log(meetingData);
  //alert(meetingData?.singleMeetingDetails?.parentMeetingData)
  //===========================
  // useEffect(() => {
  //   if (meetingData?.isSuccess) {
  //     dispatch(updateStep(2));
  //   }
  // }, [meetingData?.isSuccess]);
  //===========================

  console.log("Error Message---", meetingData?.errorMessage);

  useEffect(() => {
    if (meetingData?.singleMeetingDetails?.attendees?.length !== 0) {
      setAttendeesData(
        meetingData?.singleMeetingDetails?.attendees?.map(
          ({ rsvp, isAttended, canWriteMOM, empId, ...keepAttrs }) => keepAttrs
        )
      );
    }
  }, [meetingData?.singleMeetingDetails?.attendees]);
  //===========================
  useEffect(() => {
    document.title = "Create Meeting: MinutesVault";
    if (props?.meetingData) {
      if (attendeesData.length === 0) {
        setAttendeesData(
          props?.meetingData?.attendees?.map(
            ({ rsvp, isAttended, canWriteMOM, empId, ...keepAttrs }) =>
              keepAttrs
          )
        );
      }
    }

    if (stateData?.isMeetingDataUpdate || meetingData?.isUpdateStep) {
      document.title = "Update Meeting: MinutesVault";
      if (attendeesData.length === 0) {
        const data = meetingData.singleMeetingDetails?.attendees?.map(
          ({ rsvp, isAttended, canWriteMOM, empId, ...keepAttrs }) => keepAttrs
        );
        setAttendeesData([...attendeesData, ...data]);
      }
    }

    if (employeeData.isDuplicateUser === false) {
      const newAttendee = {
        name: formData.name,
        email: formData.email,
        isEmployee: false,
        designation: formData.designation,
        companyName: formData.companyName,
      };
      if (formData.email !== "" || null) {
        const newAttendeeData = [...attendeesData, newAttendee];

        setAttendeesData(newAttendeeData);
        setFormData({
          ...formData,
          name: "",
          email: "",
          designation: "",
          companyName: "",
        });
      }
    }
    return () => {
      dispatch(setErrorMessage(null));
    };
  }, [meetingData.step, employeeData.isDuplicateUser]);

  useEffect(() => {
    if (
      meetingData?.singleMeetingDetails?.parentMeetingDetails?.length !== 0 &&
      meetingData?.singleMeetingDetails?.parentMeetingId
    ) {
      //   alert("uu");
      setFormData({
        ...formData,
        attendyType: "fromCurrentMeeting",
      });
    }
    if (
      formData.attendyType === "fromPreviousMeeting" &&
      accessToken &&
      !meetingData?.singleMeetingDetails?.parentMeetingId
    ) {
      setSelectedAttendeeOption([
        { label: "*Name / Email Address", value: "" },
      ]);
    //  alert(36)
      dispatch(
        fetchAttendeesList(
          userData.organizationId,
          createdById,
          accessToken
        )
      );
    }
    if (
      accessToken &&
      meetingData?.singleMeetingDetails?.parentMeetingDetails?.length !== 0 &&
      meetingData?.singleMeetingDetails?.parentMeetingId
    ) {
      setSelectedAttendeeOption([
        { label: "*Name / Email Address", value: "" },
      ]);
      //  alert("ddd");

      dispatch(
        fetchCurrentAttendeesList(
          userData.organizationId,
          meetingData?.singleMeetingDetails?.parentMeetingId,
          accessToken
        )
      );
    }
  }, [meetingData?.singleMeetingDetails]);
  const submitAttendeeDetails = (e) => {
    e.preventDefault();

    if (attendeesData.length === 0) {
      const newErrors = validateForm(formData);
      setErrors(newErrors);
      if (Object.keys(newErrors).length === 0) {
        const errors = {};
        errors.addAttendee = constantMessages.addAttendee;
        setErrors(errors);
      }
    } 
     
    else {
      if (errors.apiError || errors.duplicateAttendee) {
        setErrors(errors);
        return;
      }
      const meetingId = props?.meetingData?._id
        ? props.meetingData._id
        : meetingData?.singleMeetingDetails?._id;
      const isPropCall = props?.meetingData?._id ? true : false;

      if (attendeesData.length > 1) {
        const payload = {
          isEditMeeting: props?.meetingData ? false : true,
          attendees: attendeesData?.map(
            ({
              rsvp,
              isAttended,
              canWriteMOM,
              empId,
              isActive,
              ...keepAttrs
            }) => keepAttrs
          ),
          organizationId: userData.organizationId,
          sendNotification:
            props?.sendSingleNotification === true ? true : false,
          isUpdate:
            stateData?.isMeetingDataUpdate &&
            meetingData?.singleMeetingDetails.step === 3
              ? true
              : false,
          step: 2,
          meetingStatus:
            meetingData?.singleMeetingDetails?.meetingStatus.status,
          sendSingleNotification:
            props?.sendSingleNotification === true ? true : false,
        };
        const fromUpdate =
          meetingData?.singleMeetingDetails?.step === 3 ? true : false;
        dispatch(
          updateMeetingDetails(
            meetingId,
            payload,
            accessToken,
            "addAttendee",
            stateData?.isMeetingDataUpdate,
            isPropCall,
            fromUpdate
          )
        );
      } else {
        toast.error("Please add at least 2 attendees.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const submitAttendeeDetailsByConfirmModal = () => {
    //  e.preventDefault();

    if (attendeesData.length === 0) {
      const newErrors = validateForm(formData);
      setErrors(newErrors);
      if (Object.keys(newErrors).length === 0) {
        const errors = {};
        errors.addAttendee = constantMessages.addAttendee;
        setErrors(errors);
      }
    } else {
      const meetingId = props?.meetingData?._id
        ? props.meetingData._id
        : meetingData?.singleMeetingDetails?._id;
      const isPropCall = props?.meetingData?._id ? true : false;

      if (attendeesData.length > 1) {
        const payload = {
          isEditMeeting: props?.meetingData ? false : true,
          attendees: attendeesData?.map(
            ({
              rsvp,
              isAttended,
              canWriteMOM,
              empId,
              isActive,
              ...keepAttrs
            }) => keepAttrs
          ),
          organizationId: userData.organizationId,
          sendNotification:
            props?.sendSingleNotification === true ? true : false,
          isUpdate:
            stateData?.isMeetingDataUpdate &&
            meetingData?.singleMeetingDetails.step === 3
              ? true
              : false,
          step: 2,
          meetingStatus:
            meetingData?.singleMeetingDetails?.meetingStatus.status,
          sendSingleNotification:
            props?.sendSingleNotification === true ? true : false,
        };
        // const fromUpdate=meetingData?.singleMeetingDetails?.step===3?true:false;
        dispatch(
          updateMeetingDetails(
            meetingId,
            payload,
            accessToken,
            "addAttendee",
            stateData?.isMeetingDataUpdate,
            isPropCall,
            false
          )
        );
      } else {
        toast.error("Please add at least 2 attendees.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };
  let createdById;

  if (meetingData?.singleMeetingDetails?.createdById) {
    createdById = meetingData.singleMeetingDetails.createdById;
  } else if (props?.meetingData?.createdById) {
    createdById = props.meetingData.createdById;
  } else {
    createdById = undefined;
  }
  console.log(createdById)
  // const addAttendee = async (e) => {
  //   dispatch(setErrorMessage(null));
  //   //alert(formData.email);
  //   let attendeeList = [];
  //   if (employeeData.employeeListAsUnit) {
  //     const newEmpList = employeeData.employeeListAsUnit.map((item) => {
  //       return {
  //         _id: item._id,
  //         name: item.name,
  //         email: item.email,
  //         empId: item.empId,
  //       };
  //     });
  //     attendeeList = [...attendeeList, ...newEmpList];
  //   }
  //   if (meetingData.attendeesList) {
  //     attendeeList = [...attendeeList, ...meetingData.attendeesList];
  //   }

  //   const meetingId = props?.meetingData?._id
  //     ? props.meetingData._id
  //     : meetingData?.singleMeetingDetails?._id;

  //   if (formData.attendyType === "addNewPeople") {
  //     const newErrors = validateForm(formData);
  //     setErrors(newErrors);

  //     if (Object.keys(newErrors).length === 0) {
  //       console.log(attendeesData);
  //       if (attendeesData.length > 0) {
  //         const attendeeFound = attendeesData.find(
  //           (u) => u.email === formData.email
  //         );
  //         console.log(attendeeFound);
  //         //   alert("hhhhhhh44444444444hhh")
  //         if (attendeeFound) {
  //           const errors = {};
  //           errors.duplicateAttendee = constantMessages.duplicateAttendee;
  //           setErrors(errors);
  //           return errors;
  //         } else {
  //         }
  //       }
  //       // Check attendee availability
  //       const errors = await checkAttendeeAvailability(
  //         { email: formData.email },
  //         meetingId,
  //         accessToken
  //       );
  //       if (errors.apiError) {
  //         setErrors(errors);
  //         return;
  //       }
  //       const payload = {
  //         organizationId: userData.organizationId,
  //         email: formData.email,
  //       };

  //       dispatch(checkDuplicateUser(payload, accessToken));
  //     }
  //   } else {
  //     if (formData?.attendeeId) {
  //       if (attendeesData?.length > 0) {
  //         const attendeeFound = attendeesData.find(
  //           (u) => u._id === formData.attendeeId
  //         );

  //         if (attendeeFound) {
  //           const errors = {};
  //           errors.duplicateAttendee = constantMessages.duplicateAttendee;
  //           setErrors(errors);
  //           return errors;
  //         } else {
  //           if (formData.attendeeId) {
  //             const errors = await checkAttendeeAvailability(
  //               { attendeeId: formData.attendeeId },
  //               meetingId,
  //               accessToken
  //             );

  //             if (errors.apiError) {
  //               setErrors(errors);
  //               return;
  //             }
  //           }
  //         }
  //       }

  //       let newAttendee = attendeeList.find(
  //         (u) => u._id === formData.attendeeId
  //       );

  //       newAttendee.isEmployee = true;

  //       const newAttendeeData = [...attendeesData, newAttendee];
  //       setAttendeesData(newAttendeeData);
  //       setFormData({
  //         ...formData,
  //         email: "",
  //         attendeeId: null,
  //       });
  //     } else {
  //       const errors = {};

  //       if (formData.attendyType === "fromEmployeeList" && unitId === null) {
  //         errors.duplicateAttendee = constantMessages?.unitRequired;
  //       } else {
  //         errors.duplicateAttendee = "Please select attendees first.";
  //       }
  //       // if (formData.attendyType === "fromEmployeeList" && unitId===null) {

  //       //   errors.unitRequired =constantMessages?.unitRequired;

  //       // }

  //       setErrors(errors);
  //       return errors;
  //     }
  //   }
  //   setUnitId(null);
  //   setSelectedEmployeeOption([{ label: "*Name / Emp Id", value: "" }]);
  //   setSelectedUnitOption([{ label: "*Select Unit", value: "" }]);
  //   setSelectedAttendeeOption([{ label: "*Name / Email Address", value: "" }]);
  // };
  // PRATISHRUTI ADD CODE FOR MEETILG LIST DUPLICATE NOT SHOW
  const addAttendee = async (e) => {
    dispatch(setErrorMessage(null));

    let attendeeList = [];
    if (employeeData.employeeListAsUnit) {
      const newEmpList = employeeData.employeeListAsUnit.map((item) => ({
        _id: item._id,
        name: item.name,
        email: item.email,
        empId: item.empId,

      }));
      attendeeList = [...attendeeList, ...newEmpList];
    }

    if (meetingData.attendeesList) {
      attendeeList = [...attendeeList, ...meetingData.attendeesList];
    }

    const meetingId = props?.meetingData?._id
      ? props.meetingData._id
      : meetingData?.singleMeetingDetails?._id;

    if (formData.attendyType === "addNewPeople") {
      const newErrors = validateForm(formData);
      setErrors(newErrors);

      if (Object.keys(newErrors).length === 0) {
        if (attendeesData.length > 0) {
          const attendeeFound = attendeesData.find(
            (u) => u.email === formData.email
          );

          if (attendeeFound) {
            setErrors({ duplicateAttendee: constantMessages.duplicateAttendee });
            return;
          }
        }

        // Check attendee availability
        const errors = await checkAttendeeAvailability(
          { email: formData.email },
          meetingId,
          accessToken
        );
        if (errors.apiError) {
          setErrors(errors);
          return;
        }

        const payload = {
          organizationId: userData.organizationId,
          email: formData.email,
        };

        dispatch(checkDuplicateUser(payload, accessToken));

        // Create new attendee object
        const newAttendee = {
          label: formData.name,
          value: formData.email,
          email: formData.email,
        };

        //  Add selected attendee to the list
        setSelectedAttendees((prev) => [...prev, newAttendee.value]);

        // //  Add selected attendee to attendeesData
        // setAttendeesData((prevAttendees) => [
        //   ...prevAttendees,
        //   {
        //     name: newAttendee.label,
        //     email: newAttendee.email,
        //     empId: newAttendee.value,
        //   },
        // ]);

        //  Remove the selected attendee from employeeOptions
        setSelectEmployee((prevOptions) =>
          prevOptions.filter((option) => option.value !== newAttendee.value)
        );

        //  Update selected option in form
        setSelectedAttendeeOption(newAttendee);
      }
    } else {
      if (formData?.attendeeId) {
        if (attendeesData?.length > 0) {
          const attendeeFound = attendeesData.find(
            (u) => u._id === formData.attendeeId
          );

          if (attendeeFound) {
            setErrors({ duplicateAttendee: constantMessages.duplicateAttendee });
            return;
          } else {
            const errors = await checkAttendeeAvailability(
              { attendeeId: formData.attendeeId },
              meetingId,
              accessToken
            );
            if (errors.apiError) {
              setErrors(errors);
              return;
            }
          }
        }

        let newAttendee = attendeeList.find(
          (u) => u._id === formData.attendeeId
        );

        if (!newAttendee) {
          setErrors({ invalidAttendee: "Attendee not found in the list" });
          return;
        }

        newAttendee.isEmployee = true;

        const newAttendeeData = [...attendeesData, newAttendee];
        setAttendeesData(newAttendeeData);

        //  Add selected attendee to the list
        setSelectedAttendees((prev) => [...prev, newAttendee._id]);

        //  Remove the selected attendee from employeeOptions
        setSelectEmployee((prevOptions) => {
          const filteredOptions = prevOptions.filter(
            (option) => option.value !== newAttendee._id
          );
          console.log("Removed Attendee:", newAttendee); //  Log removed attendee
          return filteredOptions;
        });

        // Remove other attendees from the same unit
        setSelectedAttendees((prevAttendees) => {
          const filteredAttendees = prevAttendees.filter(
            (att) =>
              !employeeData.employeeListAsUnit.some(
                (emp) => emp.unitId === newAttendee.unitId && emp._id !== newAttendee._id
              )
          );
          console.log("Removed Attendees from the same unit:", filteredAttendees);
          return filteredAttendees;
        });

        //  Update selected option in form
        setSelectedAttendeeOption({
          label: newAttendee.name,
          value: newAttendee._id,
          email: newAttendee.email,
        });

        setFormData({
          ...formData,
          email: "",
          attendeeId: null,
        });
      } else {
        const errors = {};

        if (formData.attendyType === "fromEmployeeList" && unitId === null) {
          errors.duplicateAttendee = constantMessages?.unitRequired;
        } else {
          errors.duplicateAttendee = "Please select attendees first.";
        }

        setErrors(errors);
        return errors;
      }
    }

    setUnitId(null);
    setSelectedEmployeeOption([{ label: "*Name / Emp Id", value: "" }]);
    setSelectedUnitOption([{ label: "*Select Unit", value: "" }]);
    setSelectedAttendeeOption([{ label: "*Name / Email Address", value: "" }]);
  };
  if (meetingRoomData?.unitList?.length !== 0) {
    var unitOptions = meetingRoomData?.unitList?.map((unit) => {
      const returnData = {
        value: unit._id,
        label: unit.name,
        name: "unitId",
      };

      return returnData;
    });
  }
  console.log(errors);
  const validateForm = (formData) => {
    const errors = {};

    if (formData.attendyType === "addNewPeople") {
      // const regularExpression = /^[A-Za-z0-9\s]+$/;

      if (!formData.name.trim()) {
        errors.name = constantMessages.nameRequired;
      } else if (!regularNameExpression.test(formData.name)) {
        errors.name = constantMessages.invalidName;
      } else if (formData.name.length < 2 || formData.name.length > 30) {
        errors.name = "Name must be between 2 and 30 characters.";
      }

      if (!formData.email.trim()) {
        errors.email = constantMessages.emailRequired;
      } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        errors.email = constantMessages.invalidEmail;
      }

      if (formData?.designation?.trim()) {
        if (!regularExpression.test(formData.designation)) {
          errors.designation = constantMessages.invalidDesignation;
        } else if (
          formData.designation.length < 2 ||
          formData.designation.length > 100
        ) {
          errors.designation = constantMessages.characterSizeDesignation;
        }
      }

      if (formData?.companyName?.trim()) {
        if (!regularExpression.test(formData.companyName)) {
          errors.companyName = constantMessages.invalidCompanyName;
        } else if (
          formData.companyName.length < 2 ||
          formData.companyName.length > 50
        ) {
          errors.companyName =
            "Company name must be between 2 and 50 characters.";
        }
      }
    }

    return errors;
  };
  console.log(meetingData?.errorMessage);
  const handleChange = (e) => {
    setErrors({});
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (value === "fromPreviousMeeting" && accessToken) {
    //  alert(34)
      dispatch(
        fetchAttendeesList(
          userData.organizationId,
          createdById,
          accessToken
        )
      );
      setSelectedEmployeeOption([{ label: "*Name / Emp Id", value: "" }]);
      setSelectedUnitOption([{ label: "*Select Unit", value: "" }]);
      setSelectedAttendeeOption([
        { label: "*Name / Email Address", value: "" },
      ]);
      setUnitId(null);
    }
    if (value === "fromCurrentMeeting" && accessToken) {
      setUnitId(null);
      dispatch(
        fetchCurrentAttendeesList(
          userData.organizationId,
          meetingData?.singleMeetingDetails?.parentMeetingId,
          accessToken
        )
      );
      setSelectedEmployeeOption([{ label: "*Name / Emp Id", value: "" }]);
      setSelectedUnitOption([{ label: "*Select Unit", value: "" }]);
      setSelectedAttendeeOption([
        { label: "*Name / Email Address", value: "" },
      ]);
    }

    if (value === "fromEmployeeList") {
      const payload = {
        organizationId: userData.organizationId,
      };
      dispatch(listAllUnitForMeetingForEmployeeCheck(payload, accessToken));
    }
    if (value === "fromEmployeeList") {
      setUnitId(null);
      setSelectedEmployeeOption([{ label: "*Name / Emp Id", value: "" }]);

      if (employeeData?.userData?.unitDetails) {
        setUnitId(employeeData?.userData?.unitDetails?._id);
        // setFormData({
        //   ...formData,
        //   [name]:employeeData?.userData?.unitDetails?._id,
        // });
        setSelectedUnitOption(e);
        const payload = {
          unitId: employeeData?.userData?.unitDetails?._id,
        };
       // alert(11)
        console.log( meetingData?.singleMeetingDetails?.createdById)

        dispatch(
          getEmployeeListAsPerUnit(
            payload,
            createdById,
            accessToken
          )
        );
        setSelectedUnitOption([
          {
            label: employeeData?.userData?.unitDetails?.name,
            value: employeeData?.userData?.unitDetails?._id,
          },
        ]);
      } else {
        setSelectedUnitOption([{ label: "*Select Unit", value: "" }]);
      }

      setSelectedAttendeeOption([
        { label: "*Name / Email Address", value: "" },
      ]);
    }
  };

  useEffect(() => {
    if (meetingData?.singleMeetingDetails?.parentMeetingId) {
      setFormData({
        ...formData,
        attendyType: "fromCurrentMeeting",
      });
      dispatch(
        fetchCurrentAttendeesList(
          userData.organizationId,
          meetingData?.singleMeetingDetails?.parentMeetingId,
          accessToken
        )
      );
    }
  }, []);


 


  const handleUnitChange = (e) => {
    setErrors({});

    const { name, value } = e;
    setUnitId(value);
    setFormData({
      ...formData,
      [name]: value,
    });
    setSelectedUnitOption(e);
    const payload = {
      unitId: value,
    };
//alert(1)
    dispatch(
      getEmployeeListAsPerUnit(
        payload,
        meetingData?.singleMeetingDetails?.createdById,
        accessToken
      )
    );

    setFormData({
      ...formData,
      roomId: "",
    });
    setSelectedEmployeeOption([{ label: "*Name / Emp Id", value: "" }]);
  };

  const nameFieldValidationCheck = (e) => {
    const errors = {};
    // alert(21)
    /// const regularExpression = /^[A-Za-z\s]+$/; //returns true if matched, vaidates for a-z and A-Z and white space
    if (!formData?.name?.trim()) {
      errors.name = constantMessages.nameRequired;
    } else if (!regularNameExpression.test(formData.name)) {
      //  alert(2)
      errors.name = constantMessages.invalidName;
    } else if (formData.name.length < 2 || formData.name.length > 30) {
      errors.titleName = "Name must be between 2-30  characters";
    }
    setErrors(errors);
  };

  const emailFieldValidationCheck = (e) => {
    const errors = {};
    if (!formData?.email?.toLowerCase().trim()) {
      errors.email = constantMessages.emailRequired;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = constantMessages.invalidEmail;
    }
    setErrors(errors);
  };

  // const designationFieldValidationCheck = (e) => {
  //   const errors = {};
  //   //  const regularExpression = /^[A-Za-z\s]+$/; //returns true if matched, vaidates for a-z and A-Z and white space
  //   if (formData?.designation?.trim()) {
  //     if (!regularExpression.test(formData.designation)) {
  //       errors.designation = constantMessages.invalidDesignation;
  //     } else if (
  //       formData.designation.length < 2 ||
  //       formData.designation.length > 30
  //     ) {
  //       errors.designation = "Designation must be between 2-30  characters";
  //     }
  //   }
  //   setErrors(errors);
  // };

  // const companyNameFieldValidationCheck = (e) => {
  //   const errors = {};
  //   // const regularExpression = /^[A-Za-z\s]+$/; //returns true if matched, vaidates for a-z and A-Z and white space
  //   if (formData?.companyName?.trim()) {
  //     if (!regularExpression.test(formData.companyName)) {
  //       errors.companyName = constantMessages.invalidCompanyName;
  //     } else if (
  //       formData?.companyName.length < 2 ||
  //       formData?.companyName.length > 50
  //     ) {
  //       errors.companyName = "Company  must be between 2-50  characters";
  //     }
  //   }
  //   setErrors(errors);
  // };

  // const removeAttendee = (e) => {
  //   const filteredAttendees = attendeesData.filter(
  //     (item) => item.email !== removeAttendeeData.email
  //   );

  //   setAttendeesData(filteredAttendees);
  //   setIsModalOpen(false);
  //   setFormData({
  //     ...formData,
  //     attendeeId: null,
  //   });
  // };

  // PRATISHRUTI ADD CODE FOR MEETILG LIST DUPLICATE NOT SHOW
  const removeAttendee = (e) => {
    console.log("Removing Attendee:", removeAttendeeData);

    const removedAttendee = attendeesData.find(
      (item) => item.email === removeAttendeeData.email
    );

    if (!removedAttendee) {
      console.log("Attendee not found in attendeesData.");
      return;
    }

    //  Remove attendee from attendeesData
    const filteredAttendees = attendeesData.filter(
      (item) => item.email !== removeAttendeeData.email
    );
    setAttendeesData(filteredAttendees);

    console.log("Updated Attendees Data:", filteredAttendees);

    //  Re-add the removed attendee back to employeeOptions
    setSelectedAttendees((prevOptions) => {
      const updatedOptions = [

        { value: removedAttendee._id, label: removedAttendee.name, name: "attendeeId" }
      ];

      console.log("Updated Employee Options:", updatedOptions);
      return updatedOptions;
    });
    setSelectEmployee((prevOptions) => {
      const updatedOptions = [

        { value: removedAttendee._id, label: removedAttendee.name, name: "attendeeId" }
      ];

      console.log("Updated Employee Options:", updatedOptions);
      return updatedOptions;
    });

    setIsModalOpen(false);

    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData, attendeeId: null };
      console.log("Updated Form Data:", updatedFormData);
      return updatedFormData;
    });

    console.log("Attendee removed successfully.");
  };


  console.log(meetingData?.attendeesList);
  const [isModalOpen, setIsModalOpen] = useState(false);
  if (meetingData?.attendeesList?.length !== 0) {
    var attendeeOptions = meetingData?.attendeesList?.map((attendee) => {
      const returnData = {
        value: attendee._id,
        label: attendee.name + " / " + attendee.email,
        name: "attendeeId",
      };

      return returnData;
    });
  }
  if (employeeData?.employeeListAsUnit?.length !== 0) {
    var employeeOptions = employeeData?.employeeListAsUnit?.map((attendee) => {
      const returnData = {
        value: attendee._id,
        label: attendee.name + " / " + attendee.empId,
        name: "attendeeId",
      };

      return returnData;
    });
  }
  const handleAttendeeChange = (e) => {
    setErrors({});

    const { name, value } = e;
    setFormData({
      ...formData,
      [name]: value,
    });
    setSelectedAttendeeOption(e);
  };
  // const handleEmployeeChange = (e) => {
  //   setErrors({});
  //   const { name, value } = e;
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  //   setSelectedEmployeeOption(e);
  // };

// PRATISHRUTI ADD CODE FOR MEETILG LIST DUPLICATE NOT SHOW
const existingAttendeeIds = (attendeesData || []).map((att) => att._id);
  
console.log("Filtered Available Attendee Options:", attendeeOptions);
const availableAttendeeOptions = (attendeeOptions || []).filter(
  (option) =>
    !selectedAttendees.includes(option.value) &&
    !existingAttendeeIds.includes(option.value) 
);

console.log("Filtered Available Attendee Options:", availableAttendeeOptions);


const availableEmployeeOptions = (employeeOptions || []).filter(
  (option) =>
    !selectedAttendees.includes(option.value) &&
    !selectEmployee.includes(option.value) &&
    !existingAttendeeIds.includes(option.value) 
);

console.log("Filtered Available Employee Options:", availableEmployeeOptions);




// PRATISHRUTI ADD CODE FOR MEETILG LIST DUPLICATE NOT SHOW
  const handleEmployeeChange = (e) => {
    setErrors({});
    const { name, value } = e;
  
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  
    setSelectedEmployeeOption(e);
  
    setSelectEmployee((prev) => (prev.includes(value) ? prev : [...prev, value]));
  
    console.log("Updated Selected Employees:", selectEmployee);
  };
  

  const updatedAttendeesData = (attendees) => {
    console.log(attendees);
    setAttendeesData(attendees);
  };
  console.log(meetingData?.singleMeetingDetails);
  console.log(attendeesData);
  const updateSteps = () => {
    if (meetingData?.singleMeetingDetails?.attendees?.length > 1) {
      dispatch(updateStep(2, true));
    } else {
      toast.error("Please add at least 2 attendees.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const updateStepWithCheck = () => {
    if (
      meetingData?.singleMeetingDetails?.attendees?.length ===
      attendeesData?.length
    ) {
      if (meetingData?.singleMeetingDetails?.attendees?.length >= 2) {
        dispatch(updateStep(2));
      } else {
        toast.warning("Please add minimum 2 attendees.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } else {
      setIsConfirmModalOpen(true);
    }
  };

  // useEffect(()=>{
  //   alert(22)
  // if(meetingData?.errorMessage===null && meetingData?.isSuccess){
  //   dispatch(updateStep(2));
  // }
  // },[meetingData?.isSuccess])

  const handleConfirmSubmit = (status) => {
    console.log(attendeesData);
    console.log(meetingData?.singleMeetingDetails?.attendees);
    if (status) {
      // submitMeetingDetailsByConfirmModal();

      if (
        // meetingData?.singleMeetingDetails?.attendees?.length >= 2 &&
        attendeesData?.length >= 2
      ) {
        submitAttendeeDetailsByConfirmModal();
        // dispatch(updateStep(2));
        setIsConfirmModalOpen(false);
      } else {
        toast.warning("Please add minimum 2 attendees.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } else {
      if (
        meetingData?.singleMeetingDetails?.attendees?.length >= 2 &&
        attendeesData?.length >= 2
      ) {
        dispatch(updateStep(2));
      }

      setIsConfirmModalOpen(false);
    }
  };
  console.log(meetingData?.errorMessage);

  const checkAttendeeCount = () => {
    //alert(345)
    if (attendeesData.length > 1) {
      dispatch(updateStep(2, true));
      dispatch(setErrorMessage(null));
      // setRoomErrors({});
      setErrors({});
    } else {
      toast.error("Please add at least 2 attendees.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const preventEnterKey = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };
  return (
    <>
      <form className="m-0 p-0 details-form" onSubmit={submitAttendeeDetails}>
        <ConfirmModal
          message={confirmMessage}
          title={confirmTitle}
          buttonName={"Cancel"}
          isCalledFromOthers={true}
          setIsConfirmModalOpen={setIsConfirmModalOpen}
          isConfirmModalOpen={isConfirmModalOpen}
          handleConfirmSubmit={handleConfirmSubmit}
        />
        <RemoveAttendeesModal
          message={constantMessages.deleteAttendeeMessage}
          title={"Remove"}
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          handleSubmit={removeAttendee}
          buttonName={"Remove"}
          attendee={removeAttendeeData}
          allowRemove={props?.meetingData ? false : true}
          meetingData={meetingData?.singleMeetingDetails}
        />
        <div className="inner-detail-form">
          <label className="mb-1 people">Attendee(s)</label>

          {attendeesData?.length > 0 ? (
            <>
              <AttendeeDragDrop
                attendees={attendeesData}
                updatedAttendeesData={updatedAttendeesData}
                setIsModalOpen={setIsModalOpen}
                setRemoveAttendeeData={setRemoveAttendeeData}
                allowRemove={props?.meetingData ? false : true}
              />
              <br></br>
              {/* {attendeesData.map((attendee, index) => {
                  return (
                    <div className="attendee-content" key={index}>
                      <div
                        className="attendee attendee-list sl"
                        // className="people-circle"
                        onClick={() => {
                          setIsModalOpen(true);
                          setRemoveAttendeeData(attendee);
                        }}
                      >
                        {customName(attendee.name)}
                      </div>
                    </div>
                  );
                })} */}
            </>
          ) : null}

          <div className="add-people-box show">
            <div className="pb-3">
              <label className="mb-2">Select People</label>

              <div className="w-100">
                {meetingData?.singleMeetingDetails?.parentMeetingId ? (
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="attendyType"
                      value="fromCurrentMeeting"
                      id="flexRadioDefault1"
                      onChange={handleChange}
                      checked={formData.attendyType === "fromCurrentMeeting"}
                    />

                    <label
                      className="mb-2 form-check-label"
                      for="flexRadioDefault1"
                      id="flexRadioDefault1"
                    >
                      Select From Current Meeting
                    </label>
                  </div>
                ) : (
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="attendyType"
                      value="fromPreviousMeeting"
                      id="flexRadioDefault1"
                      onChange={handleChange}
                      checked={formData.attendyType === "fromPreviousMeeting"}
                    />

                    <label
                      className="mb-2 form-check-label"
                      for="flexRadioDefault1"
                      id="flexRadioDefault1"
                    >
                      Select From Previous Meetings
                    </label>
                  </div>
                )}
                <div className="form-check-inline">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="attendyType"
                      value="fromEmployeeList"
                      id="flexRadioDefault2"
                      onChange={handleChange}
                      checked={formData.attendyType === "fromEmployeeList"}
                    />
                    <label
                      className=" mb-2 form-check-label"
                      for="flexRadioDefault2"
                      id="flexRadioDefault2"
                    >
                      Select From Employees (Unit Wise)
                    </label>
                  </div>
                </div>

                <div className="form-check-inline">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="attendyType"
                      value="addNewPeople"
                      id="flexRadioDefault3"
                      checked={formData.attendyType === "addNewPeople"}
                      onChange={handleChange}
                    />
                    <label
                      className=" mb-2 form-check-label"
                      for="flexRadioDefault3"
                      id="flexRadioDefault3"
                    >
                      Add New Guest
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {formData.attendyType === "fromPreviousMeeting" ? (
              <Select
                className="fltr-opt"
                placeholder="*Name / Email Address"
                name="attendeeId"
                // options={attendeeOptions}
                // PRATISHRUTI ADD CODE FOR MEETILG LIST DUPLICATE NOT SHOW
                options={availableAttendeeOptions}
                onChange={handleAttendeeChange}
                value={selectedAttendeeOption}
                defaultValue={selectedAttendeeOption}
              />
            ) : formData.attendyType === "fromEmployeeList" ? (
              <>
                <div className="mb-1">
                  <Select
                    className="fltr-opt"
                    defaultValue={selectedUnitOption}
                    value={selectedUnitOption}
                    name="unitId"
                    options={unitOptions}
                    onChange={handleUnitChange}
                  />
                </div>
                {unitId ? (
                  <Select
                    className="fltr-opt"
                    placeholder="*Name / Employee ID"
                    name="attendeeId"
                    // options={employeeOptions}
                    // PRATISHRUTI ADD CODE FOR MEETILG LIST DUPLICATE NOT SHOW
                    options={availableEmployeeOptions}
                    value={selectedEmployeeOption}
                    onChange={handleEmployeeChange}
                  />
                ) : null}
              </>
            ) : formData.attendyType === "fromCurrentMeeting" ? (
              <Select
                className="fltr-opt"
                placeholder="*Name / Email Address"
                name="attendeeId"
                options={attendeeOptions}
                onChange={handleAttendeeChange}
                value={selectedAttendeeOption}
              />
            ) : formData.attendyType === "addNewPeople" ? (
              <div>
                <label className="">Add New Guest</label>
                <div className="row">
                  <div className="col-xl-6 col-md-6 mt-2">
                    <input
                      type="text"
                      autoComplete="off"
                      placeholder="*Name"
                      name="name"
                      onKeyDown={preventEnterKey}
                      value={formData.name}
                      onChange={handleChange}
                      onBlur={nameFieldValidationCheck}
                    />
                    {errors.name && (
                      <span className="error-message">{errors.name}</span>
                    )}
                  </div>
                  <div className="col-xl-6 col-md-6 mt-2">
                    <input
                      type="text"
                      placeholder="*Email"
                      autoComplete="off"
                      name="email"
                      value={formData.email}
                      onKeyDown={preventEnterKey}
                      onBlur={emailFieldValidationCheck}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <span className="error-message">{errors.email}</span>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6 col-md-6 mt-2">
                    <input
                      type="text"
                      autoComplete="off"
                      placeholder="Designation"
                      name="designation"
                      onKeyDown={preventEnterKey}
                      value={formData.designation}
                      onChange={handleChange}
                      // onBlur={designationFieldValidationCheck}
                    />
                    {errors.designation && (
                      <span className="error-message">
                        {errors.designation}
                      </span>
                    )}
                  </div>

                  <div className="col-xl-6 col-md-6 mt-2">
                    <input
                      type="text"
                      autoComplete="off"
                      placeholder="Company Name"
                      name="companyName"
                      onKeyDown={preventEnterKey}
                      value={formData.companyName}
                      onChange={handleChange}
                      // onBlur={companyNameFieldValidationCheck}
                    />
                    {errors.companyName && (
                      <span className="error-message">
                        {errors.companyName}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ) : null}
            {errors.duplicateAttendee && (
              <span className="error-message">{errors.duplicateAttendee}</span>
            )}
            {errors.addAttendee && (
              <span className="error-message">{errors.addAttendee}</span>
            )}
            {errors.apiError && (
              <div className="error-message">{errors.apiError}</div>
            )}
            <div className="form-group d-flex atd-button">
              {!employeeData.loading ? (
                <button
                  type="button"
                  className="btn rounded-pill add-btn Mom-btn d-flex align-items-center justify-content-center "
                  onClick={addAttendee}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="#fff"
                    className="bi bi-plus-circle pointer me-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                  </svg>
                  <p> Add </p>
                </button>
              ) : (
                <LoaderButton />
              )}

              {!meetingData?.updateLoading && stateData?.isMeetingDataUpdate ? (
                <>
                  <button
                    className="create-meeting-button Mom-btn ms-2"
                    type="submit"
                  >
                    <p>Update</p>
                  </button>
                </>
              ) : meetingData?.updateLoading &&
                stateData?.isMeetingDataUpdate ? (
                <LoaderButton />
              ) : null}
            </div>
          </div>

          {meetingData?.errorMessage && (
            <div className="attendee-array-error-messages">
              <ul className="error-list error-message">
                {(Array.isArray(meetingData.errorMessage)
                  ? meetingData.errorMessage
                  : [meetingData.errorMessage]
                ).map((error, index) => (
                  <li key={index} className="error-item">
                    {error}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {props.meetingData ? (
            <Modal.Footer>
              {meetingData.updateLoading ? (
                <LoaderButton />
              ) : (
                <button
                  type="submit"
                  className="create-meeting-button Mom-btn "
                >
                  <p>Submit</p>
                </button>
              )}
            </Modal.Footer>
          ) : (
            <div className="button-outer">
              <div className="d-flex people">
                <button
                  className="add-btn Mom-btn"
                  onClick={(e) => dispatch(updateStep(0, true))}
                >
                  <p>Back</p>
                </button>
              </div>

              {stateData.isMeetingDataUpdate ? (
                <button
                  type="button"
                  className="add-btn Mom-btn"
                  // onClick={() => updateSteps()}
                  // onClick={(e) => dispatch(updateStep(2, true))}
                  // onClick={(e) => updateStepWithCheck() dispatch(setErrorMessage(null));}
                  onClick={(e) => {
                    updateStepWithCheck();
                    dispatch(setErrorMessage(null));
                  }}
                >
                  <p>Next</p>
                </button>
              ) : !meetingData.updateLoading &&
                !stateData.isMeetingDataUpdate ? (
                <>
                  {meetingData?.singleMeetingDetails?.attendees?.length === 0 ||
                  meetingData?.singleMeetingDetails?.attendees?.length !==
                    attendeesData?.length ? (
                    <button
                      className="create-meeting-button Mom-btn"
                      type="submit"
                    >
                      <p>Save & Proceed</p>
                    </button>
                  ) : (
                    <button
                      className="create-meeting-button Mom-btn"
                      type="button"
                      onClick={(e) => {
                        checkAttendeeCount();
                      }}
                    >
                      <p>Next</p>
                    </button>
                  )}
                </>
              ) : meetingData.updateLoading &&
                !stateData.isMeetingDataUpdate ? (
                <LoaderButton />
              ) : null}

              {/* 
              {!meetingData.loading && stateData.isMeetingDataUpdate ? (
                <button
                  className="add-btn Mom-btn"
                  onClick={(e) => dispatch(updateStep(2, true))}
                >
                  <p>Next</p>
                </button>
              ) : !meetingData.loading && !stateData.isMeetingDataUpdate ? (
                <button className="create-meeting-button Mom-btn" type="submit">
                  <p>Save & Proceed</p>
                </button>
              ) : (
                <LoaderButton />
              )} */}
            </div>
          )}
        </div>
      </form>
    </>
  );
};

export default AddAttendees;
