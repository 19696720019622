import React from "react";
import NoDataFound from "../Common/NoDataFound";
import Badge from "react-bootstrap/Badge";
import {
  convertFirstLetterOfFullNameToCapital,
  customName,
} from "../../helpers/commonHelpers";
import { useSelector } from "react-redux";
import Loader from "../Common/Loader";
import Alert from "../Common/Alert";
import { convertTimetoIST } from "../../helpers/commonHelpers";
const MeetingAttendance = () => {
  const meetingData = useSelector((state) => state.meeting);
  return (
    <div className="form-group agenda">
      <label className="mt-2 mb-3 add-agenda">
        <h4>Meeting Attendance</h4>
      </label>
      <div className="mt-2 agenda-box-border attendees-popup-list">
        {meetingData.meetingDetails?.attendanceDetails?.length > 0 ? (
          <form className=" details-form details-form-right responsive-table tbl-2-responsive ">
            <table className="table table-striped attendance-tbl">
              <thead>
                <tr>
                  <th scope="col">Sl. No</th>
                  <th scope="col">Attendees</th>
                  <th scope="col">Status</th>
                  <th scope="col">Type</th>
                </tr>
              </thead>
              <tbody>
                {meetingData.meetingDetails?.attendanceDetails.length !== 0 ? (
                  <>
                    {meetingData.meetingDetails?.attendanceDetails.map(
                      (attendee, index) => {
                        return (
                          <tr key={index}>
                            <td
                              data-label="Sl. No."
                              className="blue-bold-text"
                              scope="row"
                            >
                              {index + 1}
                            </td>
                            <td data-label="Attendees">
                              <div className="attendees responsive-right">
                                <div className="attendee-list">
                                  {customName(attendee.name)}
                                </div>

                                <div className="action-cmnt-text">
                                  <p className="detail-name word-break">
                                    {convertFirstLetterOfFullNameToCapital(
                                      attendee.name
                                    )}
                                  </p>

                                  <p className="name-undertext detail-date-time comment-text">
                                    {attendee.email}
                                  </p>

                                  {meetingData?.meetingDetails 
                                    ?.isAttendanceAdded &&
                                  attendee.isAttended === true ? (
                                    <p>
                                      <Badge bg="" className="badge-light-blue">
                                        {" "}
                                        {convertTimetoIST(attendee?.fromTime)}
                                      </Badge>
                                      -
                                      <Badge bg="" className="badge-light-blue">
                                        {" "}
                                        {convertTimetoIST(attendee?.toTime)}
                                      </Badge>
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                            </td>
                            <td data-label="Status">
                              {meetingData?.meetingDetails?.isAttendanceAdded &&
                              attendee.isAttended ? (
                                <span className="badge Present-color">
                                  Present
                                </span>
                              ) : meetingData?.meetingDetails
                                  ?.isAttendanceAdded &&
                                attendee.isAttended === false ? (
                                <span className="badge Absent-color">
                                 Absent
                                </span>
                              ) : (
                                <span className="badge NA-color">
                                 NA
                                </span>
                              )}
                            </td>

                            <td data-label="Type">
                              {attendee?.attendanceType === "PARTIAL" &&
                              attendee.isAttended === true ? (
                                <span className="badge Partial-color">
                                 Partial
                                </span>
                              ) : attendee?.attendanceType === "PARTIAL" &&
                                attendee.isAttended === false ? (
                                  <span className="badge NA-color">
                                 NA
                                  </span>
                              ) : attendee?.attendanceType === "FULL" &&
                                attendee.isAttended === false ? (
                                  <span className="badge NA-color">
                                  NA
                                  </span>
                              ) : (
                                <span className="badge Full-color">
                                 Full
                                </span>
                              )}
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </>
                ) : null}
              </tbody>
            </table>
          </form>
        ) : meetingData.meetingDetails?.attendanceDetails?.length === 0 &&
          !meetingData.loading ? (
          <div className="no-data-img">
            <Alert
              status={"info"}
              message={"No data available."}
              timeoutSeconds={0}
            />
            <NoDataFound dataType={"meetingActivities"} />
          </div>
        ) : (
          <div
            className="meeting-page "
            style={{ textAlign: "center", paddingTop: 20 }}
          >
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

export default MeetingAttendance;
