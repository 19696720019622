import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link, useParams } from "react-router-dom";
import Header from "../Common/Header/Header";
import Sidebar from "../Common/Sidebar/Sidebar";

const FullActionStatisticsDetails = () => {
  let { organizationId } = useParams();
  return (
    <div>
    <Header />
    <div>FullActionStatisticsDetails :{organizationId}</div>
    <Sidebar />
    </div>
  )
  
 
};

export default FullActionStatisticsDetails;
