import Modal from "react-bootstrap/Modal";
import { customName } from "../../helpers/commonHelpers";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as constantMessages from "../../constants/constatntMessages";
import { sendMeetingDetails } from "../../redux/actions/meetingActions/MeetingAction";
import LoaderButton from "../Common/LoaderButton";

const ResendMeetingDetailsModal = (props) => {
  const dispatch = useDispatch();
  const meetingDetails = useSelector((state) => state.meeting);
  const accessToken = localStorage.getItem("accessToken");
  const meetingData = props.meetingDetail;
  const attendeesArray = meetingData?.attendees;
  const employeeData = useSelector((state) => state.user);
  const [attendeesFinalArray, setAttendeesFinalArray] = useState([]);
  const [isDisabled, setIsDisabled] = useState([]);
  const closeModal = () => {
    props.setIsResendMeetingDetailsModalOpen(false);
  };

  useEffect(() => {
    if (
      meetingDetails?.isSuccess === true &&
      meetingDetails?.loading === false
    ) {
      props.setIsResendMeetingDetailsModalOpen(false);
      setAttendeesFinalArray([]);
    }
    return () => {
      // Anything in here is fired on component unmount.
    };
  }, [meetingDetails?.isSuccess, meetingDetails?.loading]);

  const handleSubmit = () => {
    if (attendeesFinalArray.length === 0) {
      toast.warning(constantMessages.selectAttendee, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      const payload = {
        meetingId: meetingData._id,
        attendees: attendeesFinalArray,
      };
      dispatch(sendMeetingDetails(payload, accessToken));
    }
  };

  const checkHandler = (e, attendeeId) => {
    if (attendeeId === "all") {
      if (attendeesArray.length === attendeesFinalArray.length) {
        setAttendeesFinalArray([]);
        setIsDisabled(false);
      } else {
        const modifiedAttendees = attendeesArray.map((obj) => {
          return obj._id;
        });
        setAttendeesFinalArray([...modifiedAttendees]);
        setIsDisabled(true);
      }
    } else {
      if (attendeeId) {
        if (attendeesFinalArray.includes(attendeeId)) {
          const modifiedAttendees = attendeesFinalArray.filter(
            (item) => item !== attendeeId
          );
          setAttendeesFinalArray([...modifiedAttendees]);
        } else {
          setAttendeesFinalArray([...attendeesFinalArray, attendeeId]);
        }
      }
    }
  };

  return (
    <Modal
      show={props.isResendMeetingDetailsModalOpen}
      onHide={closeModal}
      backdrop="static"
    >
      <div className="modal-header">
        <h4 className="modal-title">Resend Meeting Details</h4>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={closeModal}
        ></button>
      </div>
      <div className="modal-body">
        <div className="mb-0">
          <div className="row">
            <div className="attendees-popup send-meeting-details">
              <div className="check-attendee">
                <input
                  type="checkbox"
                  name="selectedAttendee"
                  id=""
                  className="form-check-input"
                  onChange={(e) => checkHandler(e, "all")}
                  checked={
                    attendeesFinalArray?.length === attendeesArray?.length
                      ? true
                      : false
                  }
                />
                <div className="check-attendee">
                  <div className="attendee1 attendee-list sl "> All</div>
                </div>
                <p> Select All</p>
              </div>

              <div className="minutes-border"></div>
              {attendeesArray &&
                attendeesArray.map((attendee, index) => {
                  return (
                    <div className="attendee-content" key={index}>
                      <div className="check-attendee">
                        <input
                          type="checkbox"
                          name="selectedAttendee"
                          id=""
                          className="form-check-input"
                          onChange={(e) => checkHandler(e, attendee._id)}
                          checked={
                            attendeesFinalArray.includes(
                              attendee?._id?.toString()
                            )
                              ? true
                              : false
                          }
                        />
                        <div className="attendee1 attendee-list sl">
                          {" "}
                          {customName(attendee.name)}
                        </div>
                      </div>

                      <div className="action-cmnt-text">
                        <p className="detail-name word-break">
                          {" "}
                          {attendee?._id?.toString() ===
                          props?.meetingData?.createdById?.toString()
                            ? attendee.name + " (Organiser)"
                            : attendee?._id?.toString() ===
                                employeeData.userData._id.toString() &&
                              employeeData.userData.isAdmin === true
                            ? attendee.name + " (Admin)"
                            : attendee.name}
                        </p>
                        {attendee?._id?.toString() ===
                          props?.meetingData?.createdById?.toString() &&
                        employeeData.userData.isAdmin === true ? (
                          <p className="name-undertext detail-date-time  comment-text">
                            {attendee.email}
                          </p>
                        ) : (
                          <p className="name-undertext detail-date-time  comment-text">
                            {attendee.email}{" "}
                            {attendee.empId
                              ? " (" + attendee.empId + ") "
                              : " (Guest)"}
                          </p>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>

      <div className="modal-footer">
        {meetingDetails?.updateLoading ? (
          <LoaderButton />
        ) : (
          <button
            type="button"
            onClick={handleSubmit}
            className="Mom-btn btn btn-secondary bg-primary border-primary"
          >
            <p>Send</p>
          </button>
        )}
      </div>
    </Modal>
  );
};

export default ResendMeetingDetailsModal;
