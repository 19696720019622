import React, { useState, useEffect } from "react";
import { customName } from "../../helpers/commonHelpers";
import { RiDragMove2Line } from "react-icons/ri";
  import {
    Button,
    Popover,
    OverlayTrigger,
    Accordion,
    Tooltip,
  } from "react-bootstrap";
const AttendeeDragDrop = ({
  attendees,
  updatedAttendeesData,
  setIsModalOpen,
  setRemoveAttendeeData,
  allowRemove,
}) => {
  const [draggingItem, setDraggingItem] = useState(null);
  const [items, setItems] = useState(attendees);

  useEffect(() => {
    setItems(attendees);
  }, [attendees]);

  const handleDragStart = (e, item) => {
    setDraggingItem(item);
    e.dataTransfer.setData("text/plain", "");
  };

  const handleDragEnd = () => {
    setDraggingItem(null);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, targetItem) => {
    if (!draggingItem) return;

    const currentIndex = items.indexOf(draggingItem);
    const targetIndex = items.indexOf(targetItem);

    if (currentIndex !== -1 && targetIndex !== -1) {
      items.splice(currentIndex, 1);
      items.splice(targetIndex, 0, draggingItem);
      setItems(items);
    }
    updatedAttendeesData(items);
  };
  console.log(items);

  const tooltip = (attendee) => {
    return (
      <Tooltip id="tooltip">
        {attendee.name} / {attendee?.email}
      </Tooltip>
    );
  };

  return (
    <div className="d-flex people att-people icon-container">
       
      {items.map((attendee, index) => {
        return (
          <div
            draggable="true"
            onDragStart={(e) => handleDragStart(e, attendee)}
            onDragEnd={handleDragEnd}
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, attendee)}
            className="attendee-content drag-attendees"
            key={index}
           // title={`${attendee?.name} / ${attendee?.email}`}
          >
            {allowRemove ? (
              <OverlayTrigger
                placement="top"
                overlay={tooltip(attendee)}
                trigger={["hover", "focus"]}
                delay={{ show: 350, hide: 50 }}
              >
                <div
                  className="attendee attendee-list sl"
                  // className="people-circle"
                  onClick={() => {
                    setIsModalOpen(true);
                    setRemoveAttendeeData(attendee);
                  }}
                >
                  {customName(attendee.name)}
                  
                </div>
              </OverlayTrigger>
            ) : (
              <OverlayTrigger
                placement="top"
                overlay={tooltip(attendee)}
                trigger={["hover", "focus"]}
              >
                <div
                  className="attendee attendee-list sl"
                  // className="people-circle"
                >
                  {customName(attendee.name)}
                </div>
              </OverlayTrigger>
            )}

            {/* Use the React icon component */}
            {/* <RiDragMove2Line className="drag-opt" /> */}
          </div>
        );
      })}
    </div>
  );
};

export default AttendeeDragDrop;
