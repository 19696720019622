import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Modal from "react-bootstrap/Modal";
import parse from "html-react-parser";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import { useSelector, useDispatch } from "react-redux";
import * as constantMessages from "../../constants/constatntMessages";
import { updateMinute } from "../../redux/actions/minuteActions/MinuteAction";
import { fetchCurrentAttendeesList } from "../../redux/actions/meetingActions/MeetingAction";
import { getEmployeeList } from "../../redux/actions/userAction/userAction";
import Select from "react-select";
import { textBoxStrLength } from "../../config/config";
import {
  checkCharacterLeft,
  convertFirstLetterOfFullNameToCapital,
  convertFirstLetterToCapital,
  getStringLengthAfterHTMLParse,
} from "../../helpers/commonHelpers";
import ReactQuill from "../../../node_modules/react-quill/lib/index";

const ViewAgendaModal = (props) => {
  console.log(props);
  const { agendaData } = props;
  return (
    <>
      <Modal
        size="lg"
        show={props.IsViewAgendaModal}
        onHide={(e) => props.setIsViewAgendaModal(false)}
        backdrop="static"
      >
        <div className="modal-header attendees-modal">
          <div>
            <h4 className="modal-title">View Agenda</h4>
          </div>
          <div>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={(e) => props.setIsViewAgendaModal(false)}
            ></button>
          </div>
        </div>

        <Modal.Body className="attendees-popup modal-margin">
          <div className="open-div">
            <form className="addminutesboxfrom">
              <div
              // className={
              //   props.isMinuteShow && props.index === props.currentIndex
              //     ? "minutes-box no-border show"
              //     : !props.isMinuteShow && props.index === props.currentIndex
              //     ? "minutes-box no-border"
              //     : "minutes-box no-border"
              // }
              >
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-12">
                      <label className="mb-1">Title</label>
                    </div>
                    <div className="col-md-12">
                      <p>{agendaData?.title}</p>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-md-12">
                      <label className="mb-1">Topic</label>
                    </div>
                    <div className="col-md-82">
                      {agendaData?.topic ? parse(agendaData?.topic) : null}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-12">
                      <label className="mb-1">Timeline</label>
                    </div>
                    <div className="col-md-82">
                      <p> {agendaData?.timeLine} Min</p>
                    </div>
                  </div>
                </div>

                <div className=" form-group">
                  <div className="row">
                    <div className="col-md-4">
                      <label className="mt-1 mb-1">Total Minutes</label>
                    </div>
                    <div className="col-md-8">
                      <p> {agendaData?.minutesDetail?.length}</p>
                    </div>
                  </div>
                </div>
                <div className=" form-group m-0">
                  <div className="row">
                    <div className="col-md-4">
                      <label className="mt-1 mb-1">Status</label>
                    </div>
                    <div className="col-md-8">
                      <p>
                        {" "}
                        {agendaData?.minutesDetail?.length !== 0 ? (
                          <Badge bg="success"> Discussed</Badge>
                        ) : (
                          <span className="badge pending-color bg-opacity-10 ">
                            Pending
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewAgendaModal;
