import {
  FETCH_SINGLE_USER,
  FAIL_REQUEST,
  MAKE_REQUEST,
  SET_EMPLOYEE_LIST,
  SET_DUPLICATE_USER_STATUS,
  SET_EMPLOYEE_AS_UNIT_LIST,
} from "./actionTypes";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setInValidUser } from "../authActions/authAction";
import * as constantMessages from "../../../constants/constatntMessages";

export const makeRequest = () => {
  return {
    type: MAKE_REQUEST,
  };
};
export const failRequest = (err) => {
  return {
    type: FAIL_REQUEST,
    payload: err,
  };
};
export const fetchSingleUser = (data) => {
  return {
    type: FETCH_SINGLE_USER,
    payload: data,
  };
};

export const viewSingleUser = (userId, accessToken) => {
  return (dispatch) => {
    dispatch(makeRequest());

    const url = `${process.env.REACT_APP_API_URL}/api/V1/employee/viewSingleEmployee/${userId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };
    axios
      .get(url, headerObject)
      .then((res) => {
        const resData = res.data;

        let data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        else{
          dispatch(setInValidUser(false));
        }
        if (resData.success) {
          dispatch(fetchSingleUser(resData));
        } else {
          dispatch(fetchSingleUser(resData));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",

          });
        }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",

        });


        
      });
  };
};

export const getEmployeeListAsPerUnit = (payload,meetingCreatedById, accessToken) => {
  return (dispatch) => {
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/employee/getEmployeeListAsPerUnit/${payload.unitId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };
const bodyPayload={
  meetingCreatedById:meetingCreatedById?meetingCreatedById:undefined
}
    axios
      .post(webApiUrl, bodyPayload,headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",

        
          });
        }
        dispatch(setEmployeeListAsPerUnit(resData));
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const setEmployeeListAsPerUnit = (data) => {
  return {
    type: SET_EMPLOYEE_AS_UNIT_LIST,
    payload: data,
  };
};

export const getEmployeeList = (payload, accessToken) => {
  return (dispatch) => {
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/employee/listOnlyEmployee/${payload.organizationId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .get(webApiUrl, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        dispatch(setEmployeeList(resData));
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const setEmployeeList = (data) => {
  return {
    type: SET_EMPLOYEE_LIST,
    payload: data,
  };
};

export const checkDuplicateUser = (payload, accessToken) => {
  return (dispatch) => {
    dispatch(makeRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/employee/checkDuplicateUser`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .post(webApiUrl, payload, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        if (resData.success && resData?.data?.isDuplicateUser === true) {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }

        dispatch(setDuplicateUserStatus(resData));
      })
      .catch((err) => {
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const setDuplicateUserStatus = (data) => {
  return {
    type: SET_DUPLICATE_USER_STATUS,
    payload: data,
  };
};
