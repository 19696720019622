import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { logOut } from "../../redux/actions/authActions/authAction";
import NoDataFound from "../Common/NoDataFound";
import Loader from "../Common/Loader";
import Alert from "../Common/Alert";
import { getMeetingActivitiesDetails } from "../../redux/actions/meetingActions/MeetingAction";
import parse from 'html-react-parser';

import {
  convertFirstLetterOfFullNameToCapital,
  convertFirstLetterToCapital,
  customName,
} from "../../helpers/commonHelpers";

const MeetingActivities = (props) => {
  let { meetingId } = useParams();
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  if (authData.isInValidUser && authData.isInvalidUserChecked) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }

  const accessToken = localStorage.getItem("accessToken");
  const meetingData = useSelector((state) => state.meeting);

  useEffect(() => {
    if (!props.meetingId && accessToken) {
      dispatch(getMeetingActivitiesDetails(meetingId, accessToken));
    } else {
      if (accessToken) {
        dispatch(getMeetingActivitiesDetails(props.meetingId, accessToken));
      }
    }

    return () => {};
  }, [meetingData?.meetingActivitiesDetails?.length]);

  return (
    <div className="form-group agenda mom-activities">
      <label className="mt-3 mb-3 add-agenda">
        <h4>MOM Activities</h4>
      </label>

      <div className="mt-2 agenda-box-border mom-activities-list attendees-popup-list">
        {meetingData?.meetingActivitiesDetails?.length > 0 &&
        !meetingData.loading ? (
          <>
            {meetingData?.meetingActivitiesDetails.map((activity) => {
              return (
                <div className=" form-group m-0">
                  <div className="inner-notification req-by">
                    <div>
                      <div className="attendee-list pk me-3">
                        {customName(activity.employeeDetails?.name)}
                      </div>
                    </div>
                    <div className="text">
                      <div className="heading">
                        {parse(convertFirstLetterToCapital(activity?.activityDetails))}{" "}
                        {activity?.activityTitle?.toLowerCase()} by{" "}
                        {convertFirstLetterOfFullNameToCapital(
                          activity.employeeDetails?.name
                        )}
                      </div>

                      <div className="date">{activity.date}</div>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : meetingData?.meetingActivitiesDetails?.length === 0 &&
          !meetingData.loading &&
          !meetingData.isSuccess ? (
          <div className="no-data-img">
            <Alert
              status={"info"}
              message={"No data available."}
              timeoutSeconds={0}
            />
            <NoDataFound dataType={"meetingActivities"} />
          </div>
        ) : meetingData?.meetingActivitiesDetails?.length === 0 &&
          !meetingData.loading &&
          meetingData.isSuccess ? (
          <div className="no-data-img">
            <Alert
              status={"info"}
              message={"No data available."}
              timeoutSeconds={0}
            />
            <NoDataFound dataType={"meetingActivities"} />
          </div>
        ) : (
          <div
            className="meeting-page "
            style={{ textAlign: "center", paddingTop: 20 }}
          >
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

export default MeetingActivities;
