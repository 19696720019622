
import React, { useState, useEffect } from "react";
import {

  Cell,

  Tooltip,
  Legend,
  PieChart,
  Pie,
  ResponsiveContainer,
} from "recharts";
const MeetingPieChart = ({ data, totalCount, organizationId }) => {

console.log(data)
  
  const CustomTooltip = ({ active, payload, label }) => {
    console.log(payload, active, label);
    if (active) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#ffff",
            padding: "2px",
            border: "1px solid #cccc",
          }}
        >
          <p>{`${payload[0].name} : ${payload[0].value}%`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={408}>
      <PieChart width={730} height={300}>
        <Pie
          data={data}
          color="#000000"
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={150}
          fill="#8884d8"

            
                  
        >
          {data.map((entry, index) => (
            <Cell style={{outline: 'none'}} key={`cell-${entry?.name}`} fill={entry?.color} />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip />} />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default MeetingPieChart;
