import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { customName } from "../../helpers/commonHelpers";

const RemoveAttendeesModal = (props) => {
  const { attendee, meetingData } = props;

  const handleSubmit = () => {
    props.handleSubmit();
  };
  const isMeetingOrganiser = meetingData?.createdById?.toString() === attendee?._id?.toString() ? true : false
  return (
    <>
      <Modal
        show={props.isModalOpen}
        onHide={(e) => props.setIsModalOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Attendee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="attendees-popup">
            <div className="attendee-content">
              <div className="attendee1 attendee-list sl">
                {" "}
                {attendee.name ? customName(attendee.name) : null}
              </div>

              <div className="action-cmnt-text">
                <p className="detail-name word-break">{attendee.name}</p>
                <p className="name-undertext detail-date-time comment-text">
                  {attendee.email} {attendee.isEmployee ? "" : "(Guest)"}
                </p>
              </div>
            </div>
            {props.allowRemove && isMeetingOrganiser === false ? (
              <div className="action-cmnt-text">
                <p className="detail-name word-break"> {props.message}</p>
              </div>
            ) : null}
          </div>
        </Modal.Body>
        {props.allowRemove && isMeetingOrganiser === false ? (
          <Modal.Footer>
            <Button
              variant=""
              onClick={(e) => props.setIsModalOpen(false)}
              className="add-btn Mom-btn"
            >
              <p>No</p>
            </Button>
            <Button
              variant=""
              onClick={handleSubmit}
              className="create-meeting-button Mom-btn"
            >
              <p>Yes</p>
            </Button>
          </Modal.Footer>
        ) : null}
      </Modal>
    </>
  );
};

export default RemoveAttendeesModal;
