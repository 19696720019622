import React, { useEffect } from "react";
import NoDataFound from "../Common/NoDataFound";
import Alert from "../Common/Alert";
import Badge from "react-bootstrap/Badge";
import {
  convertFirstLetterOfFullNameToCapital,
  customName,
} from "../../helpers/commonHelpers";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../Common/Loader";
import { getMomAcceptDetails } from "../../redux/actions/minuteActions/MinuteAction";

const MOMAcceptanceDetails = (props) => {
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("accessToken");
  const minuteData = useSelector((state) => state.minute);

  useEffect(() => {
    dispatch(getMomAcceptDetails(props?.meetingId, accessToken));
  }, []);

  return (
    <div className="form-group agenda">
      <label className="mt-2 mb-3 add-agenda">
        <h4>MOM Acceptance Details</h4>
      </label>
      <div className="mt-2 agenda-box-border attendees-popup-list">
        {minuteData?.momAcceptDetails &&
        minuteData?.momAcceptDetails?.length !== 0 ? (
          <form className="details-form details-form-right responsive-table tbl-2-responsive ">
            <table className="table table-striped attendance-tbl">
              <thead>
                <tr>
                  <th scope="col">Sl. No.</th>
                  <th scope="col">Attendees</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>

              <tbody>
                {minuteData?.momAcceptDetails?.length !== 0 ? (
                  <>
                    {minuteData?.momAcceptDetails?.map((attendee, index) => {
                      return (
                        <tr key={index}>
                          <td data-label="Sl. No.">{index + 1}</td>
                          <td data-label="Attendees">
                            <div className="attendees">
                              <div className="attendee-list">
                                {customName(attendee?.userDetails?.name)}
                              </div>

                              <div className="action-cmnt-text">
                                <p className="detail-name word-break">
                                  {convertFirstLetterOfFullNameToCapital(
                                    attendee?.userDetails?.name
                                  )}
                                </p>

                                <p className="name-undertext detail-date-time  comment-text">
                                  {attendee?.userDetails?.email}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td data-label="ATTENDANCE">
                            {!attendee?.isAutoAccepted ? (
                              <p>
                                <span className="badge accepted-color">
                                  Accepted
                                </span>
                              </p>
                            ) : (
                              <p>
                                <span className="badge accepted-auto-color">
                                  {" "}
                                  Accepted (Auto)
                                </span>
                              </p>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </>
                ) : null}
              </tbody>
            </table>
          </form>
        ) : minuteData?.momAcceptDetails?.length === 0 &&
          !minuteData?.loading ? (
          <div className="no-data-img">
            <Alert
              status={"info"}
              message={"No data available."}
              timeoutSeconds={0}
            />
            <NoDataFound dataType={"meetingActivities"} />
          </div>
        ) : (
          <div
            className="meeting-page "
            style={{ textAlign: "center", paddingTop: 20 }}
          >
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

export default MOMAcceptanceDetails;
