import React from "react";
// @ts-ignore
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import { customName } from "../../helpers/commonHelpers";

const GuestAttendeesModal = (props) => {
  const { attendees, IsGuestModalOpen, setIsGuestModalOpen } = props;
  const tooltip = (attendee) => {
    return (
      <Tooltip id="tooltip">
        {attendee.name} / {attendee?.email}
      </Tooltip>
    );
  };
  return (
    <Modal
      show={IsGuestModalOpen}
      onHide={() => setIsGuestModalOpen(false)}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Guest(s)</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="attendees-popup">
          {attendees &&
            attendees
              .filter((item) => !item.empId)
              .map((attendee, index) => {
                return (
                  <div
                    className="attendee-content align-items-start"
                    key={index}
                  >
                    <div key={index}>
                      <div className="attendee1 attendee-list sl">
                        <OverlayTrigger
                          placement="top"
                          overlay={tooltip(attendee)}
                          trigger={["hover", "focus"]}
                        >
                          <span>{customName(attendee.name)}</span>
                          {/* Ensure a single element as child */}
                        </OverlayTrigger>
                      </div>
                    </div>

                    <div className="action-cmnt-text">
                      <p className="detail-name word-break">{attendee.name}</p>

                      <p className="name-undertext detail-date-time comment-text word-break">
                        {attendee.email}
                      </p>
                      {attendee?.designation ? (
                        <div className="detail-date-time mt-1 des-comp">
                          <span> Designation -</span> {attendee.designation}
                        </div>
                      ) : null}
                      {attendee?.companyName ? (
                        <div className="detail-date-time des-comp">
                          <span> Company Name -</span> {attendee.companyName}
                        </div>
                      ) : null}
                    </div>
                  </div>
                );
              })}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default GuestAttendeesModal;
