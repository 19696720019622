import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useSelector, useDispatch } from "react-redux";
import { updateStep } from "../../redux/actions/meetingActions/MeetingAction";

const steps = ["Create Meeting", "Add Attendees(s)", "Add Agenda(s)"];

const CommonStepper = (props) => {
    const dispatch = useDispatch();
  const handleStepUpdate=(page)=>{
   
     // alert(page);
      dispatch(updateStep(page-1));
    
  }
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={props.step} alternativeLabel>
        {steps.map((label,index) => (
          <Step key={label}>
            {
              props?.fromEditMeeting?(
                <StepLabel onClick={() => handleStepUpdate(index + 1)}>{label}</StepLabel>
              ):(
                <StepLabel>{label}</StepLabel>
              )
            }
        
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default CommonStepper;
