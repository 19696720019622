import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

function LoaderButton() {
  return (
    <>
      <Button className="add-btn Mom-btn" disabled>
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />{" "}
        Processing...
      </Button>
    </>
  );
}

export default LoaderButton;
