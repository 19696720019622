

import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  ResponsiveContainer,
} from "recharts";

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getActionListForChart } from "../../redux/actions/actionAction/ActionAction";
import { Box, useTheme } from "@mui/material";
import MeetinWithActionBarChart from "../Meeting/MeetinWithActionBarChart";
import MeetingBar from "../Meeting/MeetingBar";

const ActionPieChart = ({ organizationId }) => {
  const actionData = useSelector((state) => state.action);
  const [updatedDatas, setUpdatedDatas] = useState([]);
  const [finalActionDatas, setFinalActionDatas] = useState([]);
  const accessToken = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  useEffect(() => {
    if (actionData?.isSuccess) {
      dispatch(getActionListForChart(organizationId, accessToken));
    }
  }, [actionData?.isSuccess]);
  useEffect(() => {
    const updatedData = [
      {
        id: 0,
        value:
          actionData?.totalActionList?.length === 0
            ? 0
            : normalize(
                actionData?.totalActionList?.pending,
                actionData?.totalActionList?.totalCount
              ),
        count: actionData?.totalActionList?.pending,
        label: "Pending",
        color: "#6252c1",
        name: `Pending (${actionData?.totalActionList?.pending?actionData?.totalActionList?.pending:0})`,
      },
      // {
      //   id: 1,

      //   value:
      //     actionData?.totalActionList?.length === 0
      //       ? 0
      //       : normalize(
      //           actionData?.totalActionList?.requestedForReassigned,
      //           actionData?.totalActionList?.totalCount
      //         ),
      //   count: actionData?.totalActionList?.requestedForReassigned,
      //   label: "Request For Forward",
      //   color: "#02c7f5",
      //   name: "Request For Forward",
      // },
      // {
      //   id: 2,
      //   value:
      //     actionData?.totalActionList?.length === 0
      //       ? 0
      //       : normalize(
      //           actionData?.totalActionList?.reassigned,
      //           actionData?.totalActionList?.totalCount
      //         ),
      //   count: actionData?.totalActionList?.reassigned,
      //   label: "Forwarded",
      //   color: "#4bb9c7",
      //   name: "Forwarded",
      // },
      {
        id: 3,
        value:
          actionData?.totalActionList?.length === 0
            ? 0
            : normalize(
                actionData?.totalActionList?.completed,
                actionData?.totalActionList?.totalCount
              ),
        count: actionData?.totalActionList?.completed,
        label: "Completed",
        color: "#4ba550",
        name: `Completed (${actionData?.totalActionList?.completed?actionData?.totalActionList?.completed:0})`,
      },
      // {
      //   id: 4,
      //   count: actionData?.totalActionList?.reopened,
      //   value:
      //     actionData?.totalActionList?.length === 0
      //       ? 0
      //       : normalize(
      //           actionData?.totalActionList?.reopened,
      //           actionData?.totalActionList?.totalCount
      //         ),
      //   label: "Reopened",
      //   color: "#ef7e2d",
      //   name: "Reopened",
      // },
      // {
      //   id: 5,
      //   count: actionData?.totalActionList?.approved,
      //   value:
      //     actionData?.totalActionList?.length === 0
      //       ? 0
      //       : normalize(
      //           actionData?.totalActionList?.approved,
      //           actionData?.totalActionList?.totalCount
      //         ),
      //   label: "Approved",
      //   color: "#78bb2c",
      //   name: "Approved",
      // },
      {
        id: 6,
        // value:22%,
        count: actionData?.totalActionList?.cancelled,
        value:
          actionData?.totalActionList?.length === 0
            ? 0
            : normalize(
                actionData?.totalActionList?.cancelled,
                actionData?.totalActionList?.totalCount
              ),
        label: "Cancelled",
        color: "#f1416c",
        name: `Cancelled (${actionData?.totalActionList?.cancelled?actionData?.totalActionList?.cancelled:0})`,
      },
     
    ];

if(actionData?.totalActionList?.length === 0){
  updatedData.push(
  {
    id: 7,
    // value:22%,
    value:
      actionData?.totalActionList?.length === 0
        ? 100
        : normalize(
            actionData?.totalActionList?.totalCount === 0 ? 1 : 0,
            actionData?.totalActionList?.totalCount === 0
              ? 1
              : actionData?.totalActionList?.totalCount
          ),
    count: 0,
    label: "No Actions",
    color: "#5b7197",
    name: "No Actions (0)",
  },
)
}

    console.log(updatedData);
    console.log(
      actionData?.totalActionList?.cancelled,
      actionData?.totalActionList?.totalCount
    );
    console.log(updatedData);
    setFinalActionDatas(updatedData);
  }, [actionData?.totalActionList]);

  const CustomTooltip = ({ active, payload, label }) => {
    console.log(payload, active, label);
    if (active) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#ffff",
            padding: "2px",
            border: "2px solid #cccc",
          }}
        >
          <p>{`${payload[0].name} : ${payload[0].value}%`}</p>
        </div>
      );
    }
    return null;
  };
  const normalize = (v, v2) => Number.parseFloat(((v / v2) * 100).toFixed(2));



  return (
    <div className="color-box-resp">
      <div className="row">
        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 ">
          <div className="mt-2 table-box responsive-table chart-min" 
        
          >
        
            <h6 className="chart-heading">Total Actions</h6>
            <ResponsiveContainer width="100%" height={400}>
              <PieChart width={730} height={300}>
              <Pie
          data={finalActionDatas}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={150}
          fill="#8884d8"
         
        
          
        
        >
                  {finalActionDatas.map((entry, index) => (
                    <Cell style={{outline: 'none'}}  key={`cell-${entry?.name}`} fill={entry?.color} />
                  ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="mt-2 table-box responsive-table chart-min">
            <MeetingBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionPieChart;
