import { setInValidUser } from "../authActions/authAction";
import {
  FAIL_REQUEST,
  MAKE_REQUEST,
  GET_ACTION_LIST,
  SET_REQUEST_FOR_REASSIGN,
  UPDATE_FETCH_ACTION_LIST_STATUS,
  SET_SINGLE_ACTION_DETAILS,
  SET_UPDATE_IS_COMPLETE_RESPONSE,
  SET_ACTION_ACTIVITIES_DETAILS,
  UPDATE_ISSUCCESS,
  SET_CANCEL_ACTION_RESPONSE,
  SET_ACTION_LIST_FOR_CHART,
  SET_USER_TOTAL_DUE_ACTION_PRIORITY_WISE,
  MAKE_UPDATE_REQUEST,SET_SUCCESS
} from "./actionTypes";
import axios from "axios";
import { toast } from "react-toastify";
import * as constantMessages from "../../../constants/constatntMessages";
import { viewParentMeeting } from "../meetingActions/MeetingAction";

export const makeRequest = (data) => {
  return {
    type: MAKE_REQUEST,
    payload: data,
  };
};
export const failRequest = (err) => {
  return {
    type: FAIL_REQUEST,
    payload: err,
  };
};

export const fetchActionList = (payload, accessToken) => {
  return (dispatch) => {
    dispatch(makeRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/action/viewUserAllActions`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: payload.accessToken,
        "Cache-Control": "no-cache",
        Pragma: "no-cache", // For compatibility with older HTTP/1.0 servers
        Expires: "0", // Force response to expire immediately
      },
      params: {
        limit: payload.limit,
        page: payload.page,
        order: payload.order,
      },
    };
    console.log(payload);
    const bodyPayload = {
      actionStatus: payload.actionStatus ? payload.actionStatus : undefined,
      delayStatus: payload.delayStatus ? payload.delayStatus : undefined,
      createdById: payload.createdById ? payload.createdById : undefined,
      organizationId: payload.organizationId,
      meetingId: payload.meetingId,
      searchKey: payload.searchKey ? payload.searchKey : undefined,
      toDate: payload.toDate ? payload.toDate : undefined,
      fromDate: payload.fromDate ? payload.fromDate : undefined,
      assignedUserId:
        payload?.assignedUserId !== "" ? payload?.assignedUserId : undefined,
    };
    axios
      .post(webApiUrl, bodyPayload, headerObject)
      .then((result) => {
        const resData = result.data;

        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        // if (
        //   (!resData.success && resData.data?.totalCount !== 0) ||
        //   resData.data?.isInValidUser
        // ) {
        //   toast.error(resData.message, {
        //     position: "top-right",
        //     autoClose: 4000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     theme: "colored",
        //   });
        // }

        dispatch(getActionList(resData));
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const getActionList = (data) => {
  return {
    type: GET_ACTION_LIST,
    payload: data,
  };
};

export const processRequestForReassign = (actionId, payload, accessToken) => {
  return (dispatch) => {
    dispatch(makeRequest(null));
    dispatch(setIsSuccess(false));
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/action/actionReAssignRequest/${actionId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .put(webApiUrl, payload, headerObject)
      .then((res) => {
        const resData = res.data;

        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        if (resData.success) {
          dispatch(updateActionListStatus(true));
          dispatch(fetchSingleAction(actionId, accessToken));
          toast.success(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          dispatch(updateActionListStatus(false));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        dispatch(updateActionListStatus(false));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const setRequestForReassign = (data) => {
  return {
    type: SET_REQUEST_FOR_REASSIGN,
    payload: data,
  };
};

export const updateActionListStatus = (data) => {
  return {
    type: UPDATE_FETCH_ACTION_LIST_STATUS,
    payload: data,
  };
};

export const processReassign = (actionId, payload, accessToken) => {
  return (dispatch) => {
    dispatch(makeUpdateRequest(true));
    //dispatch(setIsSuccess(false));
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/action/reAssignAction/${actionId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    const bodyPayload = {
      organizationId: payload.organizationId,
      reAssignedId: payload.reAssignedId,
      reAssignReason: payload.reAssignReason,
      isNewUser: payload.isNewUser,
      name: payload.isNewUser ? payload.name : undefined,
      email: payload.isNewUser ? payload.email : undefined,
      // reassignedUserName: payload.reassignedUserName
      //   ? payload.reassignedUserName
      //   : undefined,
      dueDate: payload?.dueDate ? payload?.dueDate : undefined,
      priority: payload?.priority,
      designation: payload.designation ? payload.designation : undefined,
      companyName: payload.companyName ? payload.companyName : undefined,
      lastActionActivityId: payload.lastActionActivityId ? payload.lastActionActivityId : undefined,
    };

    axios
      .put(webApiUrl, bodyPayload, headerObject)
      .then((res) => {
        const resData = res.data;

        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        if (resData.success) {
          dispatch(updateActionListStatus(true));
          dispatch(fetchSingleAction(actionId, accessToken));
          toast.success(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(setIsSuccess(true));
        } else {
          dispatch(updateActionListStatus(false));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        dispatch(makeUpdateRequest(false));
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        dispatch(makeUpdateRequest(false));
        dispatch(updateActionListStatus(false));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const fetchSingleAction = (actionId, accessToken) => {
  return (dispatch) => {
    dispatch(makeRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/action/viewSingleAction/${actionId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .get(webApiUrl, headerObject)
      .then((result) => {
        const resData = result.data;

        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        if (!resData.success && !resData.data?.isInValidUser) {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        dispatch(setSingleActionDetails({ data: resData.data, actionId }));
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const setSingleActionDetails = (data) => {
  return {
    type: SET_SINGLE_ACTION_DETAILS,
    payload: data,
  };
};

export const setIsSuccess = (data) => {
  return {
    type: UPDATE_ISSUCCESS,
    payload: data,
  };
};

export const makeUpdateRequest = (value) => {
  return {
    type: MAKE_UPDATE_REQUEST,
    payload: value,
  };
};

export const updateIsComplete = (actionId, payload, accessToken) => {
  return (dispatch) => {
    dispatch(makeRequest(null));
    dispatch(setIsSuccess(false));
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/action/updateAction/${actionId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .put(webApiUrl, payload, headerObject)
      .then((res) => {
        const resData = res.data;

        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        if (resData.success) {
          dispatch(fetchSingleAction(actionId, accessToken));

          dispatch(setUpdateIsCompleteResponse(resData));
          toast.success(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));

        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const setUpdateIsCompleteResponse = (data) => {
  return {
    type: SET_UPDATE_IS_COMPLETE_RESPONSE,
    payload: data,
  };
};

export const getactionActivitiesDetails = (actionId, accessToken) => {
  return (dispatch) => {
    dispatch(makeRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/action/viewActionActivities/${actionId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .get(webApiUrl, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        if (resData.success) {
          dispatch(setactionActivitiesDetails(resData));
        } else {
          dispatch(setactionActivitiesDetails(resData));
        }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const setactionActivitiesDetails = (data) => {
  return {
    type: SET_ACTION_ACTIVITIES_DETAILS,
    payload: data,
  };
};

export const reopenAction = (meetingId, actionId, payload, accessToken) => {
  return (dispatch) => {
    dispatch(makeRequest(null));
    dispatch(setIsSuccess(false));
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/action/reopenAction/${actionId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .put(webApiUrl, payload, headerObject)
      .then((res) => {
        const resData = res.data;

        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        if (resData.success) {
          dispatch(setIsSuccess(true));

          dispatch(viewParentMeeting(meetingId, accessToken));

          toast.success(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));

        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const approveAction = (meetingId, actionId, payload, accessToken) => {
  return (dispatch) => {
    dispatch(makeRequest(null));
    dispatch(setIsSuccess(false));
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/action/approveAction/${actionId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .put(webApiUrl, payload, headerObject)
      .then((res) => {
        const resData = res.data;

        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        if (resData.success) {
          dispatch(setIsSuccess(true));
          dispatch(viewParentMeeting(meetingId, accessToken));

          toast.success(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));

        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const rejectReassignRequest = (actionId, payload, accessToken) => {
  return (dispatch) => {
    dispatch(makeRequest(null));
    dispatch(setIsSuccess(false));
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/action/rejectReasignRequest/${actionId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .put(webApiUrl, payload, headerObject)
      .then((res) => {
        const resData = res.data;

        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        if (resData.success) {
          dispatch(fetchSingleAction(actionId, accessToken));

          toast.success(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(setIsSuccess(true));
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));

        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const cancelAction = (meetingId, actionId, accessToken, payload) => {
  return (dispatch) => {

    // dispatch(setIsSuccess(false));
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/action/cancelAction/${actionId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };
    dispatch(makeUpdateRequest(true));
    axios
      .put(webApiUrl, payload, headerObject)
      .then((res) => {
        const resData = res.data;

        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        if (resData.success) {
          dispatch(setCancelActionResponse(resData));

          dispatch(viewParentMeeting(meetingId, accessToken));

          toast.success(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          dispatch(setCancelActionResponse(resData));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        dispatch(makeUpdateRequest(false));
      })
      .catch((err) => {
        dispatch(failRequest(err.message));

        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const cancelActionInList = (
  meetingId,
  actionId,
  accessToken,
  payload
) => {
  return (dispatch) => {
  
   dispatch(setIsSuccess(false));
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/action/cancelAction/${actionId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };
    dispatch(makeUpdateRequest(true));
    axios
      .put(webApiUrl, payload, headerObject)
      .then((res) => {
        const resData = res.data;

        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        if (resData.success) {
          dispatch(setCancelActionResponse(resData));
          dispatch(setIsSuccess(true));
          toast.success(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          dispatch(setCancelActionResponse(resData));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        dispatch(makeUpdateRequest(false));
      })
      .catch((err) => {
        dispatch(failRequest(err.message));

        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const setCancelActionResponse = (data) => {
  return {
    type: SET_CANCEL_ACTION_RESPONSE,
    payload: data,
  };
};

export const getActionListForChart = (organizationId, accessToken) => {
  return (dispatch) => {
    dispatch(makeRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/action/totalActionList/${organizationId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .get(webApiUrl, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }

        dispatch(setActionListForChart(resData));
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const setActionListForChart = (data) => {
  return {
    type: SET_ACTION_LIST_FOR_CHART,
    payload: data,
  };
};


export const getUserActionPriotityDetails = ( payload,accessToken) => {
  return (dispatch) => {
   // dispatch(makeRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/action/getUserActionPriotityDetails`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
      params: {
        limit: payload.limit,
        page: payload.page,
        order: payload.order,
      },
    };

    const bodyPayload = {
      organizationId: payload.organizationId,
      searchKey:payload?.searchKey?payload?.searchKey:undefined
    };
    axios
      .post(webApiUrl, bodyPayload, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        dispatch(setUserTotalDueActionPriorityWise(resData));
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const setUserTotalDueActionPriorityWise = (data) => {
  return {
    type: SET_USER_TOTAL_DUE_ACTION_PRIORITY_WISE,
    payload: data,
  };
};

export const sendActionNotification = (payload, accessToken) => {
  return (dispatch) => {
    dispatch(makeUpdateRequest(true));
   dispatch(setSuccess(false));
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/action/sendActionNotification`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .post(webApiUrl, payload, headerObject)
      .then((res) => {
        const resData = res.data;

        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
          // toast.error(resData.message, {
          //   position: "top-right",
          //   autoClose: 4000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "colored",
          // });
        }
        if (resData.success) {
          dispatch(setSuccess(true));
          toast.success(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        dispatch(makeUpdateRequest(false));
      })
      .catch((err) => {
        dispatch(makeUpdateRequest(false));
        dispatch(failRequest(err.message));
        console.log(err)
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const setSuccess = (data) => {
  return {
    type: SET_SUCCESS,
    payload: data,
  };
};
