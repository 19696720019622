import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../Common/Loader";
import "../Login/style/Login.css";
import Alert from "../Common/Alert";
import NoDataFound from "../Common/NoDataFound";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import axios from "axios";
import * as constantMessages from "../../constants/constatntMessages";
import {
  customName,
  decryptWithAES,
  getStringLengthAfterHTMLParse,
  sliceHtmlEncodedString,
} from "../../helpers/commonHelpers";

import { useLocation, Link, useNavigate } from "react-router-dom";
import {
  logOut,
  setInValidUser,
} from "../../redux/actions/authActions/authAction";
import AttendeesModal from "./AttendeesModal";
import GuestAttendeesModal from "./GuestAttendeesModal";
import OnlyAttendeeModal from "./OnlyAttendeeModal";
import parse from "html-react-parser";
import {
  Accordion,
  Tooltip,
  OverlayTrigger,
  Popover,
  Button,
} from "react-bootstrap";
import ViewAgendaModal from "../Minutes/ViewAgendaModal";
import ViewZoomRecordings from "./ViewZoomRecordings";
import {
  getSingleMeetingDetailsForUpdate,
  setSuccess,
} from "../../redux/actions/meetingActions/MeetingAction";
import EditAgendaModal from "../Minutes/EditAgendaModal";

const ViewMeeting = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  if (authData.isInValidUser && authData.isInvalidUserChecked) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }
  const [isEditAgendaModal, setIsEditAgendaModal] = useState(false);
  const accessToken = localStorage.getItem("accessToken");
  const meetingData = useSelector((state) => state.meeting);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [attendeesData, setAttendeesData] = useState([]);
  const [isDeleteAgendaModal, setIsDeleteAgendaModal] = useState(false);
  const employeeData = useSelector((state) => state.user);
  const [isGuestModalOpen, setIsGuestModalOpen] = useState(false);
  const [isOnlyAttendeeModalOpen, setIsOnlyAttendeeModalOpen] = useState(false);
  const [isViewAgendaModal, setIsViewAgendaModal] = useState(false);
  const [agendaDatas2, setAgendaDatas2] = useState([]);
  const [agendaDatas, setAgendaDatas] = useState(false);
  useEffect(() => {
    return () => {};
  }, [meetingData.meetingId, meetingData.step]);

  const setGuestModalStatus = (value, attendeesData) => {
    if (attendeesData.length !== 0) {
      setIsGuestModalOpen(value);
      setAttendeesData([...attendeesData]);
    }
  };

  const setOnlyAttendeeModalStatus = (value, attendeesData) => {
    if (attendeesData.length !== 0) {
      setIsOnlyAttendeeModalOpen(value);
      setAttendeesData([...attendeesData]);
    }
  };
  const setViewAgendaModalStatus = (value, agenda) => {
    setIsViewAgendaModal(value);
    setAgendaDatas(agenda);
  };

  const setEditAgendaModalStatus = (status, agenda) => {
    dispatch(setSuccess(false));
    setAgendaDatas(agenda);
    setIsEditAgendaModal(status);
  };
  const tooltip = (attendee) => {
    return (
      <Tooltip id="tooltip">
        {attendee.name} / {attendee?.email}
      </Tooltip>
    );
  };
  const popoverHoverFocus = (title) => {
    return (
      <Popover id="popover-basic" className="custom-popover">
        {/* <Popover.Header as="h3" className="popover-header">
          Meeting Title.
        </Popover.Header> */}
        <Popover.Body className="popover-body">{title}</Popover.Body>
      </Popover>
    );
  };
  console.log(meetingData?.singleMeetingDetails);
  const setDeleteAgendaModalStatus = (status, agenda) => {
    dispatch(setSuccess(false));
    setAgendaDatas2(agenda);
    setIsDeleteAgendaModal(status);
  };

  const deleteAgenda = (agendaId) => {
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/agenda/deleteAgenda/${agendaId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };
    const payload = {
      meetingId: meetingData?.singleMeetingDetails?._id,
    };
    //  minutes: meetingData.finalAgendaMinutesData,

    axios
      .put(webApiUrl, payload, headerObject)
      .then((res) => {
        const resData = res.data;

        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        if (resData.success) {
          // dispatch(getSingleMeetingDetails(meetingDatas?.singleMeetingDetails?._id, accessToken));
          // props.setIsAddAgendaModal(true);
          toast.success(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          dispatch(
            getSingleMeetingDetailsForUpdate(
              meetingData?.singleMeetingDetails?._id,
              accessToken
            )
          );
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  return (
    <>
      {(meetingData.step + 1 === 1 && !meetingData.singleMeetingDetails) ||
      (meetingData.isNewMeetingPage === true &&
        !meetingData.singleMeetingDetails) ? (
        <form className="mt-2 details-form details-form-right">
          <Alert
            status={"info"}
            message={
              "Meeting preview will be available once you enter meeting details."
            }
            timeoutSeconds={0}
          />
          <div className="form-group mb-2">
            <div className="row">
              <NoDataFound dataType={"meeting"} />
            </div>
          </div>
        </form>
      ) : !meetingData.loading && meetingData.singleMeetingDetails ? (
        <form className="mt-2 details-form details-form-right">
          {meetingData?.singleMeetingDetails?.parentMeetingDetails?.length !==
          0 ? (
            <>
              <div className="form-group mb-2">
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                    <label className="mb-1">Parent Meeting ID</label>
                  </div>
                  <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                    <p>
                      {
                        meetingData?.singleMeetingDetails
                          ?.parentMeetingDetails[0]?.meetingId
                      }
                    </p>
                  </div>
                </div>
              </div>
              <div className="form-group mb-2">
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                    <label className="mb-1">Parent Meeting Title</label>
                  </div>
                  <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                    <p>
                      {
                        meetingData?.singleMeetingDetails
                          ?.parentMeetingDetails[0]?.title
                      }
                    </p>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                    <label className="mb-1">Date & Time</label>
                  </div>
                  <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                    <p>
                      {
                        meetingData?.singleMeetingDetails
                          ?.parentMeetingDetails[0]?.date
                      }
                      ,
                      {
                        meetingData?.singleMeetingDetails
                          ?.parentMeetingDetails[0]?.fromTime
                      }{" "}
                      to{" "}
                      {
                        meetingData?.singleMeetingDetails
                          ?.parentMeetingDetails[0]?.toTime
                      }{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                    <label className="mb-1">Followon Serial Number</label>
                  </div>
                  <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                    <p>{meetingData?.singleMeetingDetails?.followOnSerialNo}</p>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          <div className="form-group mb-2">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <label className="mb-1">Meeting Id</label>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                <p>{meetingData.singleMeetingDetails.meetingId}</p>
              </div>
            </div>
          </div>
          <div className="form-group mb-2">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <label className="mb-1">Title</label>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                {/* <p>{ decryptWithAES(meetingData.singleMeetingDetails.title)}</p> */}
                <p>{meetingData.singleMeetingDetails.title}</p>
              </div>
            </div>
          </div>

          <div className="form-group mb-2">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <label className="mb-1">Meeting Mode</label>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                <p>{meetingData.singleMeetingDetails.mode}</p>
              </div>
            </div>
          </div>

          <div className="form-group mb-2">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <label className="mb-1">Location</label>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                <p>
                  {meetingData?.singleMeetingDetails?.locationDetails
                    .isMeetingRoom === true
                    ? meetingData.singleMeetingDetails?.roomDetail[0]?.title +
                      ", " +
                      meetingData?.singleMeetingDetails?.roomDetail[0]
                        ?.location +
                      ", " +
                      meetingData?.singleMeetingDetails?.unitDetail?.name
                    : meetingData?.singleMeetingDetails?.locationDetails
                        ?.location}
                </p>
              </div>
            </div>
          </div>
          {/* 
          <div className="form-group mb-2">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <label className="mb-1">Meeting Link</label>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                <p>
                  {meetingData.singleMeetingDetails.link
                    ? meetingData.singleMeetingDetails.link
                    : (meetingData.singleMeetingDetails.linkType==="ZOOM" && !meetingData.singleMeetingDetails.link)?
                    "Zoom link will be added once schedule the meeting":"NA"}
                </p>
              </div>
            </div>
          </div> */}

          <div className="form-group">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <label className="mb-1">Meeting Link </label>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                {meetingData?.singleMeetingDetails?.link &&
                meetingData?.singleMeetingDetails?.hostDetails?.hostType ===
                  "MANUAL" ? (
                  <Link
                    target={"_blank"}
                    to={meetingData?.singleMeetingDetails?.link}
                  >
                    Click to Join Meeting
                  </Link>
                ) : meetingData?.singleMeetingDetails?.link ? (
                  <Link
                    target={"_blank"}
                    to={
                      meetingData?.singleMeetingDetails?.createdById?.toString() ===
                      employeeData?.userData?._id?.toString()
                        ? meetingData?.singleMeetingDetails?.hostDetails
                            ?.hostLink
                        : meetingData?.singleMeetingDetails?.link
                    }
                  >
                    Click to Join Meeting
                  </Link>
                ) : meetingData?.singleMeetingDetails?.hostDetails?.hostType ===
                    "ZOOM" && !meetingData?.singleMeetingDetails?.link ? (
                  <p>Zoom link will be added once schedule the meeting</p>
                ) : (
                  <p> NA</p>
                )}
              </div>
            </div>
          </div>

          <div className="form-group mb-2">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <label className="mb-1">Date & Time</label>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                <p>
                  {meetingData.singleMeetingDetails.date},
                  {meetingData.singleMeetingDetails.fromTime} to{" "}
                  {meetingData.singleMeetingDetails.toTime}{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="form-group">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                      <label className="mb-1">Status</label>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                      <span
                        className={`badge bg-opacity-10 ${meetingData.singleMeetingDetails?.meetingStatus
                          ?.status === "scheduled"
                          ? "scheduled-color"
                          : meetingData.singleMeetingDetails?.meetingStatus
                            ?.status === "rescheduled"
                            ? "rescheduled-color"
                            : meetingData.singleMeetingDetails?.meetingStatus
                              ?.status === "closed"
                              ? "closed-color"
                              : meetingData.singleMeetingDetails?.meetingStatus
                                ?.status === "draft"
                                ? "draft-color"
                                : "canceled-color"
                          }`}
                      >
                        {meetingData.singleMeetingDetails?.meetingStatus?.status
                          .charAt(0)
                          .toUpperCase() +
                          meetingData.singleMeetingDetails?.meetingStatus?.status.slice(
                            1
                          )}
                      </span>
                    </div>
                  </div>
                </div>

                {meetingData.singleMeetingDetails?.meetingStatus?.status === "rescheduled" || meetingData.singleMeetingDetails?.meetingStatus?.status === "cancelled" ? (<div className="form-group">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                      <label className="mb-1">Reason</label>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                      <p>{meetingData.singleMeetingDetails?.meetingStatus?.remarks}</p>
                    </div>
                  </div>
                </div>) : null}
          {meetingData.singleMeetingDetails.attendees.length > 0 ? (
            <div className="form-group mb-2">
              {meetingData?.singleMeetingDetails?.attendees.filter(
                (attendee) => attendee?.isEmployee === true
              ).length > 0 && (
                <div className="row align-items-center mt-2">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                    <label className="pb-1"> Attendee(s) </label>
                  </div>

                  <div
                    className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                    onClick={() => {
                      setOnlyAttendeeModalStatus(
                        true,
                        meetingData?.singleMeetingDetails?.attendees
                      );
                    }}
                  >
                    <div className="attendees">
                      {meetingData.singleMeetingDetails.attendees
                        .filter((attendee) => attendee.isEmployee === true)
                        .filter((item, index) => index < 5)
                        .map((attendee) => (
                          <OverlayTrigger
                            placement="top"
                            overlay={tooltip(attendee)}
                            trigger={["hover", "focus"]}
                          >
                            <div
                              className="attendee-list"
                              key={attendee._id}
                              //   title={`${attendee?.name} / ${attendee?.email}`}
                            >
                              {customName(attendee.name)}
                            </div>
                          </OverlayTrigger>
                        ))}

                      {meetingData.singleMeetingDetails.attendees.filter(
                        (attendee) => attendee.isEmployee === true
                      ).length > 5 && (
                        <p className="m-0">
                          +
                          {meetingData.singleMeetingDetails.attendees.filter(
                            (attendee) => attendee.isEmployee === true
                          ).length - 5}{" "}
                          More
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {meetingData?.singleMeetingDetails?.attendees.filter(
                (attendee) => attendee?.isEmployee === false
              ).length > 0 && (
                <div className="row align-items-center mt-2">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                    <label className="pb-1"> Guest(s) </label>
                  </div>
                  <div
                    className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                    onClick={() => {
                      setGuestModalStatus(
                        true,
                        meetingData?.singleMeetingDetails?.attendees
                      );
                    }}
                  >
                    <div className="attendees">
                      {meetingData.singleMeetingDetails.attendees
                        .filter((attendee) => attendee.isEmployee === false)
                        .filter((item, index) => index < 5)
                        .map((attendee) => (
                          <OverlayTrigger
                            placement="top"
                            overlay={tooltip(attendee)}
                            trigger={["hover", "focus"]}
                          >
                            <div
                              className="attendee-list"
                              key={attendee._id}
                              //   title={`${attendee?.name} / ${attendee?.email}`}
                            >
                              {customName(attendee.name)}
                            </div>
                          </OverlayTrigger>
                        ))}

                      {meetingData.singleMeetingDetails.attendees.filter(
                        (attendee) => attendee.isEmployee === false
                      ).length > 5 && (
                        <p className="m-0">
                          +
                          {meetingData.singleMeetingDetails.attendees.filter(
                            (attendee) => attendee.isEmployee === false
                          ).length - 5}{" "}
                          More
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <form className="mt-3 details-form-right">
              <Alert
                status={"info"}
                message={"No Attendee Added"}
                timeoutSeconds={0}
              />
              <div className="form-group mb-0 no-image">
                <NoDataFound dataType={"attendee"} />
              </div>
            </form>
          )}
          {meetingData.singleMeetingDetails.agendasDetail.length !== 0 ? (
            <div className="form-group agenda mt-4">
              <label className="mt-3 mb-3">
                <h4>Agenda(s)</h4>
              </label>
              {meetingData.singleMeetingDetails.agendasDetail.length !== 0 &&
                meetingData.singleMeetingDetails.agendasDetail.map(
                  (agenda, index) => {
                    return (
                      <div className="agenda-box-border">
                        <Accordion defaultActiveKey="0">
                          <Accordion.Item
                            eventKey={index.toString()}
                            key={index}
                            className="agenda-accordion"
                          >
                            <Accordion.Header>
                             <span>Agenda {index + 1}:&nbsp;</span> 
                              {agenda.title?.length > 50 ? (
                                <OverlayTrigger
                                  trigger={["hover", "focus"]}
                                  placement="bottom"
                                  overlay={popoverHoverFocus(agenda.title)}
                                >
                                  <span className="meeting-title-hover">
                                    {`${agenda.title.slice(0, 50)}....`}
                                  </span>
                                </OverlayTrigger>
                              ) : (
                                <span className="meeting-title-hover">
                                  {agenda.title}
                                </span>
                              )}
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="main-accordian-body d-flex justify-content-between">


                                <div className="edit-meeting-left-accordions">
                                  <div className="form-group">
                                    <div className="row">
                                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                        <label className="mt-1 mb-1">
                                          Agenda Title
                                        </label>
                                      </div>
                                      <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                        <p> {agenda.title}</p>
                                      </div>
                                    </div>
                                  </div>
                                  {agenda.topic !== "" ? (
                                    <div className="form-group">
                                      <div className="row">
                                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                          <label className="mt-2 topic">
                                            Topic to Discuss
                                          </label>
                                        </div>
                                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                          <div className="mb-2 text-editor">
                                            {agenda.topic
                                              ? parse(agenda.topic)
                                              : null}
                                          </div>
                                          {/* <div className="mb-2 text-editor">
                                          {getStringLengthAfterHTMLParse(
                                            agenda.topic
                                          ) < 20 ? (
                                            <span>{parse(agenda.topic)}</span>
                                          ) : (
                                            <div>
                                              {`${sliceHtmlEncodedString(
                                                agenda.topic,
                                                20
                                              )}....`}
                                              <button
                                                type="button"
                                                className="badge bg-opacity-10 scheduled-color"
                                                onClick={() =>
                                                  setViewAgendaModalStatus(
                                                    true,
                                                    agenda
                                                  )
                                                }
                                              >
                                                {" "}
                                                View More
                                              </button>
                                            </div>
                                          )}
                                        </div> */}
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                  <div className=" form-group">
                                    <div className="row">
                                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                        <label className="mt-1 mb-1">
                                          Timeline
                                        </label>
                                      </div>
                                      <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                        <p> {agenda.timeLine} Mins</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="accordianagenda-edit-delete float-end">
                                  <div className="btn-outerbox">
                                    <div className="d-inline-block menu-dropdown custom-dropdown">
                                      <Dropdown className="fff">
                                        <Dropdown.Toggle>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="#000"
                                            className="bi bi-three-dots-vertical"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                          </svg>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            href="#"
                                            onClick={() =>
                                              setEditAgendaModalStatus(
                                                true,
                                                agenda
                                              )
                                            }
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="20"
                                              height="20"
                                              fill="currentColor"
                                              className="bi bi-pencil-square me-1"
                                              viewBox="0 0 19 16"
                                            >
                                              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                              <path
                                                fill-rule="evenodd"
                                                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                                              />
                                            </svg>
                                            Edit
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            href="#"
                                            onClick={() =>
                                              setDeleteAgendaModalStatus(
                                                true,
                                                agenda
                                              )
                                            }
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="18"
                                              height="18"
                                              fill="currentColor"
                                              className="me-2 bi bi-trash3"
                                              viewBox="0 0 16 16"
                                            >
                                              <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                                            </svg>
                                            Delete
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    );
                  }
                )}
            </div>
          ) : meetingData.singleMeetingDetails.attendees.length > 0 ? (
            <form className="mt-2 details-form-right">
              <Alert
                status={"info"}
                message={"No Agenda Added"}
                timeoutSeconds={0}
              />
              <div className="form-group mb-0 no-image">
                <NoDataFound dataType={"agenda"} />
              </div>
            </form>
          ) : null}
        </form>
      ) : meetingData.isLoading ? (
        <form className="mt-2 details-form details-form-right">
          <div className="form-group mb-2 loader-icon">
            <div className="row">
              <Loader />
            </div>
          </div>
        </form>
      ) : null}
      <AttendeesModal
        IsModalOpen={isModalOpen}
        attendees={attendeesData}
        setIsModalOpen={setIsModalOpen}
        loginUserData={employeeData}
        isShow={false}
      />
      <GuestAttendeesModal
        IsGuestModalOpen={isGuestModalOpen}
        attendees={attendeesData}
        setIsGuestModalOpen={setIsGuestModalOpen}
      />
      <OnlyAttendeeModal
        IsOnlyAttendeeModalOpen={isOnlyAttendeeModalOpen}
        attendees={attendeesData}
        setIsOnlyAttendeeModalOpen={setIsOnlyAttendeeModalOpen}
        meetingData={meetingData}
      />
      <ViewAgendaModal
        agendaData={agendaDatas}
        IsViewAgendaModal={isViewAgendaModal}
        setIsViewAgendaModal={setIsViewAgendaModal}
      />
      <Modal
        show={isDeleteAgendaModal}
        onClick={(e) => setIsDeleteAgendaModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Agenda</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you want to delete {agendaDatas2?.title} ?</Modal.Body>
        <Modal.Footer>
          <Button
            variant=""
            onClick={(e) => setIsDeleteAgendaModal(false)}
            className="add-btn Mom-btn"
          >
            <p>No</p>
          </Button>
          <Button
            variant=""
            className="create-meeting-button Mom-btn"
            onClick={() => deleteAgenda(agendaDatas2?._id)}
          >
            <p>Yes</p>
          </Button>
        </Modal.Footer>
      </Modal>
      <EditAgendaModal
        IsEditAgendaModal={isEditAgendaModal}
        agendaData={agendaDatas}
        setIsEditAgendaModal={setIsEditAgendaModal}
        meetingData={meetingData}
        fromViewMeeting={true}
      />
    </>
  );
};

export default ViewMeeting;
