import React, { useEffect, useState } from "react";
import Header from "../../Common/Header/Header";
import Sidebar from "../../Common/Sidebar/Sidebar";
import LoaderButton from "../../Common/LoaderButton";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as constantMessages from "../../../constants/constatntMessages";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import WarningModal from "../../Common/WarningModal";
import Loader from "../../Common/Loader";
import { logOut, setInValidUser } from "../../../redux/actions/authActions/authAction";
import CustomizedSwitches from "./switch";
import { encryptWithAES, decryptWithAES } from "../../../helpers/commonHelpers";
const Addhostdetails = () => {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("accessToken");
  const organizationData = useSelector((state) => state.organization);
  const organizationId = organizationData?.organizationData?._id;
  console.log(organizationId);
  const [hostName, setHostName] = useState("ZOOM");
  const [isCloseModalOpen, setIsCloseModalOpen] = useState(false);
  const [showTokenIcon, setShowTokenIcon] = useState(true);
  const [showHostKeyIcon, setShowHostKeyIcon] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [zoomData, setZoomData] = useState({
    accountId: "",
    clientId: "",
    secretToken: "",
    isActive: false,
    hostKey: "",
  });

  const userData = JSON.parse(localStorage.getItem("userData"));
  const authData = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  if (authData.isInValidUser && authData.isInvalidUserChecked) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }
  const employeeData = useSelector((state) => state.user);
  useEffect(() => {
    console.log(employeeData);
    if (employeeData?.userData) {
      //  alert("innnn");
      if (employeeData.userData.isAdmin === false) {
        // alert("innnn2222");
        navigate("/page-not-found");
      }
    }
  }, [employeeData?.userData]);
  useEffect(() => {
    document.title = "Host: MinutesVault";
    if (!accessToken) {
      navigate("/login");
    }
    if (organizationId) {
      getHostDetails();
    }
  }, [organizationId]);

  const handleZoomChange = (e) => {
    const { name, value } = e.target;
    if (name === "hostName") {
      setHostName(value);
    } else {
      if (name === "hostKey") {
        if (value.length < 7) {
          setZoomData({
            ...zoomData,
            [name]: value,
          });
        }
      } else {
        setZoomData({
          ...zoomData,
          [name]: value,
        });
      }
    }
  };

  const setNewErrors = (newErrors) => {
    setErrors(newErrors);
  };

  const handleZoomSubmit = (e) => {
    // e.preventDefault();
    const newErrors = validateForm();

    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      //  alert("done");

      //updateHostDetails
      setIsLoading(true);
      const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/hosting/updateHostDetails/${organizationId}`;
      const headerObject = {
        headers: {
          "Content-Type": "application/json",
          Authorization: accessToken,
        },
      };
      let bodyPayload = {
        hostType: hostName,
      };
      if (hostName === "ZOOM") {
        const encryptedData = {
          accountId: encryptWithAES(zoomData?.accountId),
          clientId: encryptWithAES(zoomData?.clientId),
          secretToken: encryptWithAES(zoomData?.secretToken),
          hostKey: encryptWithAES(zoomData?.hostKey),
        };
        bodyPayload = { ...bodyPayload, ...encryptedData };
      }

      axios
        .put(webApiUrl, bodyPayload, headerObject)
        .then((result) => {
          console.log("result------------------------->>>>>>>", result);
          setIsLoading(false);
          const resData = result.data;
 if (resData?.isInValidUser) {
          dispatch(setInValidUser(true));
        } else {
          dispatch(setInValidUser(false));
        }
          if (resData.success) {
            setIsCloseModalOpen(false);
            toast.success(resData.message, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else {
            toast.error(resData.message, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("err------------------------->>>>>>>", err);
          toast.error(constantMessages.serverErrorMessage, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    }
  };

  const getHostDetails = (e) => {
    console.log(organizationId);
    setIsLoading(true);
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/hosting/getHostDetails/${organizationId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .get(webApiUrl, headerObject)
      .then((result) => {
        console.log("result------------------------->>>>>>>", result);
        setIsLoading(false);
        const resData = result?.data;
        console.log(resData?.data);

 if (resData?.isInValidUser) {
          dispatch(setInValidUser(true));
        } else {
          dispatch(setInValidUser(false));
        }

        if (resData?.success) {
          console.log(resData?.data)
          if (resData?.data?.hostType === "ZOOM") {
            //  setHostName("ZOOM");
            setZoomData({
              ...zoomData,
              accountId: decryptWithAES(
                resData?.data?.zoomCredentials?.accountId
              ),
              clientId: decryptWithAES(
                resData?.data?.zoomCredentials?.clientId
              ),
              secretToken: decryptWithAES(
                resData?.data?.zoomCredentials?.secretToken
              ),
              hostKey: decryptWithAES(resData?.data?.zoomCredentials?.hostKey),
              isActive: resData?.data?.zoomCredentials?.isActive,
            });
          }
          //   toast.success(resData.message, {
          //     position: "top-right",
          //     autoClose: 4000,
          //     hideProgressBar: false,
          //     closeOnClick: true,
          //     pauseOnHover: true,
          //     draggable: true,
          //     progress: undefined,
          //     theme: "colored",
          //   });
        } else {
          toast.error(resData?.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
       
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err------------------------->>>>>>>", err);
        toast.error(constantMessages?.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const zoomAccountIdValidationCheck = (e) => {
    const errors = {};
    if (!zoomData?.accountId?.trim()) {
      errors.accountId = constantMessages.accountIdRequired;
    }
    setErrors(errors);
  };
  const zoomSecretTokenValidationCheck = (e) => {
    const errors = {};
    if (!zoomData?.secretToken?.trim()) {
      errors.secretToken = constantMessages.secretTokenRequired;
    }
    setErrors(errors);
  };
  const zoomHostKeyValidationCheck = (e) => {
    const errors = {};
    if (!zoomData?.hostKey) {
      errors.hostKey = constantMessages.hostKeyRequired;
    }
    setErrors(errors);
  };
  const zoomClientIdValidationCheck = (e) => {
    const errors = {};
    if (!zoomData?.clientId?.trim()) {
      errors.clientId = constantMessages.clientIdRequired;
    }
    setErrors(errors);
  };

  const validateForm = () => {
    const errors = {};

    if (!zoomData?.accountId?.trim()) {
      errors.accountId = constantMessages.accountIdRequired;
    }
    if (!zoomData?.secretToken?.trim()) {
      errors.secretToken = constantMessages.secretTokenRequired;
    }
    if (!zoomData?.clientId?.trim()) {
      errors.clientId = constantMessages.clientIdRequired;
    }
    setErrors(errors);
    return errors;
  };
  const handleWarningModal = () => {
    const newErrors = validateForm();

    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setIsCloseModalOpen(true);
    }
  };

  const showToken = (id) => {
    setShowTokenIcon(!showTokenIcon);
    var x = document.getElementById(id);
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  const showHostKey = (id) => {
    setShowHostKeyIcon(!showHostKeyIcon);
    var x = document.getElementById(id);
    if (x.type === "password") {
      x.type = "string";
    } else {
      x.type = "password";
    }
  };

  const handleToggle = (newStatus) => {
    console.log(newStatus);
    setZoomData({
      ...zoomData,
      isActive: newStatus,
    });
  };
  console.log(zoomData);
  return (
    <div>
      {/* <Header />
      <Sidebar /> */}
      <div className="main-content">
        <div className="Action-list-page">
          <div className="meeting-header-text">
            <h4>Add Hosting Details</h4>
          </div>
          <div className="mt-3 table-box responsive-table">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div className="form-group">
                  <label className="mb-1">
                    Host Name<span className="star-mark"> *</span>
                  </label>

                  <select
                    className="fltr-opt"
                    aria-placeholder="Select Host"
                    onChange={handleZoomChange}
                    name="hostName"
                    value={hostName}
                  >
                    {/* <option value="">Select Host</option> */}
                    <option value={"ZOOM"}>Zoom</option>
                    {/* <option value={"GMEET"}>Google Meet</option>
                 <option value={"MSTEAM"}>Microsoft Teams</option> */}
                  </select>
                </div>
              </div>
            </div>
            {!isLoading ? (
              <>
                {hostName === "ZOOM" ? (
                  <div className="meeting-form">
                    <div className="row">
                      <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                        <div className="form-group">
                          <div>
                            <label className="mb-1">
                              Account Id<span className="star-mark"> *</span>
                            </label>
                            <input
                              type="text"
                              name="accountId"
                              autoComplete="off"
                              placeholder="Enter account id"
                              onChange={handleZoomChange}
                              value={zoomData.accountId}
                              onBlur={zoomAccountIdValidationCheck}
                            />
                            {errors.accountId && (
                              <span className="error-message">
                                {errors.accountId}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="form-group">
                          <div>
                            <label className="mb-1">
                              Client Id<span className="star-mark"> *</span>
                            </label>
                            <input
                              type="text"
                              name="clientId"
                              autoComplete="off"
                              placeholder="Enter client id"
                              onChange={handleZoomChange}
                              value={zoomData.clientId}
                              onBlur={zoomClientIdValidationCheck}
                            />

                            {errors.clientId && (
                              <span className="error-message">
                                {errors.clientId}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="pwd-group">
                            <label className="mb-1">
                              Secret Token <span className="imp-field">*</span>
                            </label>

                            <div className="inner-group input">
                              {!showTokenIcon ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="currentColor"
                                  className="bi bi-eye"
                                  viewBox="0 0 16 16"
                                  onClick={() => showToken("secretToken")}
                                >
                                  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="currentColor"
                                  className="bi bi-eye-slash"
                                  viewBox="0 0 16 16"
                                  onClick={() => showToken("secretToken")}
                                >
                                  <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                                  <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                                  <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                                </svg>
                              )}

                              <input
                                type="password"
                                name="secretToken"
                                autoComplete="off"
                                placeholder="Enter secret token"
                                onChange={handleZoomChange}
                                value={zoomData.secretToken}
                                onBlur={zoomSecretTokenValidationCheck}
                                id="secretToken"
                                className="show-token"
                              />
                            </div>
                            {errors.secretToken && (
                              <span className="error-message">
                                {errors.secretToken}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="pwd-group">
                            <label className="mb-1">
                              Host Key <span className="imp-field">*</span>
                            </label>

                            <div className="inner-group input">
                              {!showHostKeyIcon ? (
                                // <svg
                                //   xmlns="http://www.w3.org/2000/svg"
                                //   width="20"
                                //   height="20"
                                //   fill="currentColor"
                                //   className="bi bi-eye"
                                //   viewBox="0 0 16 16"
                                //   onClick={() => showHostKey("hostKey")}
                                // >
                                //   <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                //   <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                // </svg>

                                // <svg
                                //   xmlns="http://www.w3.org/2000/svg"
                                //   width="20"
                                //   height="20"
                                //   fill="currentColor"
                                //   className="bi bi-eye"
                                //   onClick={() => showHostKey("hostKey")}
                                // >
                                //   <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                //   <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                // </svg>


                                ///
                                
                                <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                className="bi bi-eye"
                                viewBox="0 0 16 16"
                                onClick={() => showHostKey("hostKey")}
                              >
                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                              </svg>

                              ) : (
                                // <svg
                                //   xmlns="http://www.w3.org/2000/svg"
                                //   width="20"
                                //   height="20"
                                //   fill="currentColor"
                                //   className="bi bi-eye"
                                //   onClick={() => showHostKey("hostKey")}
                                // >
                                //   <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                //   <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                // </svg>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="currentColor"
                                  className="bi bi-eye-slash"
                                  viewBox="0 0 16 16"
                                  onClick={() => showHostKey("hostKey")}
                                >
                                  <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                                  <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                                  <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                                </svg>
                              )}

                              <input
                                type="password"
                                name="hostKey"
                                autoComplete="off"
                                placeholder="Enter Host Key"
                                onChange={handleZoomChange}
                                value={zoomData.hostKey}
                                onBlur={zoomHostKeyValidationCheck}
                                id="hostKey"
                                className="show-token"
                              />
                            </div>
                            {errors.hostKey && (
                              <span className="error-message">
                                {errors.hostKey}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {isLoading ? (
                      <LoaderButton />
                    ) : (
                      <button
                        className="create-meeting-button Mom-btn"
                        disabled={isLoading}
                        onClick={handleWarningModal}
                      >
                        <p>Submit</p>
                      </button>
                    )}
                  </div>
                ) : hostName === "GMEET" ? (
                  <div className="mt-2 table-box responsive-table">
                    <form
                      className="meeting-form"
                    //  onSubmit={handleSubmit}
                    >
                      <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                          <div className="form-group">
                            <label className="mb-1">GOOGLE MEET</label>
                            <br></br>
                            <label className="mb-1">
                              ACCOUNT ID<span className="star-mark"> *</span>
                            </label>
                            <input
                              type="text"
                              name="name"
                              autoComplete="off"
                              placeholder="Enter account id"
                            // onChange={handleChange}
                            // value={formValues.name}
                            // onBlur={dpnameValidationCheck}
                            />
                            {/* {errors.name && (
         <span className="error-message">{errors.name}</span>
       )} */}
                          </div>
                        </div>
                      </div>
                      {isLoading ? (
                        <LoaderButton />
                      ) : (
                        <button
                          className="create-meeting-button Mom-btn"
                          disabled={isLoading}
                        >
                          <p>Submit</p>
                        </button>
                      )}
                    </form>
                  </div>
                ) : hostName === "MSTEAM" ? (
                  <div className="mt-2 table-box responsive-table">
                    <form
                      className="meeting-form"
                    //  onSubmit={handleSubmit}
                    >
                      <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                          <div className="form-group">
                            <label className="mb-1">MICROSOFT TEAMS</label>
                            <br></br>
                            <label className="mb-1">
                              {" "}
                              ACCOUNT ID<span className="star-mark"> *</span>
                            </label>
                            <input
                              type="text"
                              name="name"
                              autoComplete="off"
                              placeholder="Enter account id"
                            // onChange={handleChange}
                            // value={formValues.name}
                            // onBlur={dpnameValidationCheck}
                            />
                            {/* {errors.name && (
         <span className="error-message">{errors.name}</span>
       )} */}
                          </div>
                        </div>
                      </div>
                      {isLoading ? (
                        <LoaderButton />
                      ) : (
                        <button
                          className="create-meeting-button Mom-btn"
                          disabled={isLoading}
                        >
                          <p>Submit</p>
                        </button>
                      )}
                    </form>
                  </div>
                ) : null}
              </>
            ) : (
              <div
                className="meeting-page "
                style={{ textAlign: "center", paddingTop: 20 }}
              >
                <Loader />
              </div>
            )}
            {/* {isLoading ? (
           <LoaderButton />
         ) : (
           <button
             className="create-meeting-button Mom-btn"
            // disabled={isLoading}
           >
             <p>Submit</p>
           </button>
         )} */}
          </div>
        </div>
      </div>
      <WarningModal
        message={"Do you want to save changes ?"}
        title={"Update Hosting Details"}
        setIsModalOpen={setIsCloseModalOpen}
        isModalOpen={isCloseModalOpen}
        handleSubmit={handleZoomSubmit}
        buttonName={"Cancel"}
        isCalledFromOthers={true}
        isLoading={isLoading}
      />
    </div>
  );
};

export default Addhostdetails;
