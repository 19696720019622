import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { useSelector } from "react-redux";
import LoaderButton from "../Common/LoaderButton";
const ConfirmModal = (props) => {
  console.log(props);
  const meetingData = useSelector((state) => state.meeting);

  const handleSubmit = () => {
    // alert("in 1");
    props.handleDownloadSubmit();
  };
  const handleDeleteSubmit = () => {
    // alert("in 2");
    props.handleDeleteSubmit();
  };
  const handleConfirmSubmit = (status) => {
    // alert("in 2");
    props.handleConfirmSubmit(status);
  };
  

  return (
    <>
      <Modal
        show={props.isDownloadModalOpen}
        onHide={(e) => props.handleDownloadCloseConfirmModal(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.message}</Modal.Body>
        <Modal.Footer>
          {meetingData?.loading ? (
            <LoaderButton />
          ) : (
            <Button
              variant=""
              className="create-meeting-button Mom-btn btn"
              onClick={handleSubmit}
            >
              <p>Yes</p>
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal
        show={props.isDeleteModalOpen}
        onHide={(e) => props.handleDeleteCloseConfirmModal(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.message}</Modal.Body>
        <Modal.Footer>
          {meetingData?.loading ? (
            <LoaderButton />
          ) : (
            <Button
              variant=""
              className="create-meeting-button Mom-btn btn"
              onClick={handleDeleteSubmit}
            >
              <p>Yes</p>
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal
        show={props.isConfirmModalOpen}
        onHide={(e) => props.setIsConfirmModalOpen(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.message}</Modal.Body>
        <Modal.Footer>
        <Button
              variant=""
              className="add-minutes Mom-btn"
              onClick={()=>handleConfirmSubmit(false)}
            >
              <p>No</p>
            </Button>
          {meetingData?.loading ? (
            <LoaderButton />
          ) : (
            <Button
              variant=""
              className="create-meeting-button Mom-btn btn"
              onClick={()=>handleConfirmSubmit(true)}
            >
              <p>Yes</p>
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmModal;
