import {
  CREATE_MEETING_RESPONSE,
  FAIL_REQUEST,
  GET_ATTENDEES_LIST,
  GET_CREATE_MEETING_STEPS,
  GET_MEETING_LIST,
  MAKE_REQUEST,
  MAKE_RSVP_UPDATE_REQUEST,
  UPDATE_ISCREATE_MEETING_PROCESSED,
  UPDATE_RSVP,
  SET_ATTENDEES,
  UPDATE_MEETING_RESPONSE,
  LOAD_PREVIOUS_STEP,
  SET_SINGLE_MEETING_DETAILS,
  SET_MEETING_VIEW_PAGE,
  SET_CREATE_NEW_MEETING_PAGE,
  UNSET_SINGLE_MEETING_DETAILS,
  UPDATE_STEP,
  UPDATE_FETCH_MEETING_LIST_STATUS,
  SET_AGENDA_AND_MINUTES_DETAILS,
  SET_MEETING_ACTIVITIES_DETAILS,
  SET_USER_MEETING_LIST,
  UPDATE_ATTENDANCE_RESPONSE,
  UPDATE_MEETING_ISSUCCESS,
  SET_DOWNLOAD_MOM_RESPONSE,
  SET_TIMELINE_RESPONSE,
  SET_PARENT_AGENDA_AND_MINUTES_DETAILS,
  SET_GENERATE_MOM_RESPONSE,
  SET_MEETING_STATISTICS_RESPONSE,
  MAKE_STATISTIC_REQUEST,
  UPDATE_ISSHOW_UPDATE_NOTIFY,
  SEND_MEETING_DETAILS_RESPONSE,
  GET_CURRENT_ATTENDEES_LIST,
  SET_MEETING_DETAILS,
  SET_TOTAL_MEETING_LIST_FOR_CHART,
  SET_MEETING_TOTAL_DUE_ACTION_PRIORITY_WISE,
  SET_SUCCESS,
  SET_DELETE_ZOOM_RECORDING_RESPONSE,
  SET_DOWNLOAD_ALL_ZOOM_RECORDING_RESPONSE,
  SET_ERROR_MESSAGE,
  SET_SINGLE_MEETING_DETAILS_NOT_UPDATE_STEP,MAKE_UPDATE_REQUEST,SET_IS_NEW_MEETING_PAGE_PAGE
  
} from "../actions/meetingActions/actionTypes";

const initialObject = {
  loading: false,
  updateLoading:false,
  statisticloading: false,
  ownerList: [],
  assigneeList: [],
  meetingList: [],
  userMeetingList: [],
  message: "",
  totalCount: 0,
  isSuccess: false,
  statusData: ["closed", "scheduled", "rescheduled", "cancelled", "draft"],
  attendeesList: [],
  currentAttendeesList: [],
  isRsvpUpdated: false,
  singleMeetingDetails: null,
  step: 0,
  isCreateMeetingProcessed: false,
  apiProcessed: false,
  checkStep: null,
  meetingId: null,
  isViewMeetingPage: false,
  isNewMeetingPage: false,
  isUpdateStep: false,
  isFetchedMeetingList: false,
  agendaDetails: [],
  meetingDetails: null,
  meetingActivitiesDetails: [],
  isAttendanceStatus: false,
  timeLineDatas: [],
  parentAgendaDetails: [],
  parentMeetingDetails: null,
  meetingStatisticsData: null,
  isShowUpdateNotify: false,
  totalMeetingList: [],
  meetingDueActionDetailsPriorityWise: [],
  errorMessage:null,
  meetingChartDetails:null
};

export const meetingReducer = (state = initialObject, action) => {
console.log(action,state)
  switch (action.type) {
    case MAKE_REQUEST:
      return {
        ...state,
        loading: true,
      };
      case MAKE_UPDATE_REQUEST:
        return {
          ...state,
          updateLoading: action.payload,
        };
  

    case MAKE_STATISTIC_REQUEST:
      return {
        ...state,
        statisticloading: true,
      };

    case FAIL_REQUEST:
      return {
        ...state,
        loading: false,
        statisticloading: false,
        message: action.payload.message,
        isCreateMeetingProcessed: true,
        isSuccess: action.payload.success,
      };

    case GET_MEETING_LIST:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        meetingList: action.payload.data?.meetingData,
        totalCount: action.payload.data?.totalCount,
        isSuccess: action.payload.success,
        isFetchedMeetingList: false,
      };
    case GET_ATTENDEES_LIST:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        attendeesList: action.payload.data,
        isSuccess: action.payload.success,
      };

    case GET_CURRENT_ATTENDEES_LIST:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        attendeesList: action.payload.data,
        isSuccess: action.payload.success,
        currentAttendeesList: action.payload.data,
      };

    case MAKE_RSVP_UPDATE_REQUEST:
      return {
        ...state,
        isRsvpUpdated: false,
      };

    case UPDATE_RSVP:
      return {
        ...state,

        message: action.payload.message,
        isRsvpUpdated: action.payload.success,
      };

    case CREATE_MEETING_RESPONSE:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        isSuccess: action.payload.success,
        isCreateMeetingProcessed: true,
        step: action.payload.success ? 1 : 0,
        isNewMeetingPage: false,
      };

    case GET_CREATE_MEETING_STEPS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        isSuccess: action.payload.success,
        singleMeetingDetails: action.payload.data,
        step: action.payload.data ? action.payload.data.step : 0,
        meetingId: action.payload.data._id,
      };

    case UPDATE_ISCREATE_MEETING_PROCESSED:
      return {
        ...state,

        isCreateMeetingProcessed: false,
      };

    case SET_ATTENDEES:
      return {
        ...state,

        attendeesList: action.payload.data,
        message: action.payload.message,
        isSuccess: action.payload.success,
      };

    case UPDATE_MEETING_RESPONSE:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        isSuccess: action.payload.success,
        step: action.payload.fromUpdate===false &&
          action.payload.success && !state.isUpdateStep
            ? state.step + 1
            : state.step,
        isCreateMeetingProcessed: true,
        checkStep: false,
        isNewMeetingPage: false,
      //  isUpdateStep: state.step === 3 ? false : true,
        isShowUpdateNotify: true,
        singleMeetingDetails: action.payload.data,
      };
    case LOAD_PREVIOUS_STEP:
      return {
        ...state,
        step: action.payload,
        checkStep: false,
      };
    case SET_SINGLE_MEETING_DETAILS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        isSuccess: action.payload.success,
        singleMeetingDetails: action.payload.data,
        meetingId: action.payload.meetingId,
        step: state.isUpdateStep ? state.step : action.payload.data.step,
      };


      case SET_SINGLE_MEETING_DETAILS_NOT_UPDATE_STEP:
        return {
          ...state,
          loading: false,
          message: action.payload.message,
          isSuccess: action.payload.success,
          singleMeetingDetails: action.payload.data,
          meetingId: action.payload.meetingId,
         // step: state.isUpdateStep ? state.step : action.payload.data.step,
        };

    case SET_MEETING_VIEW_PAGE:
      return {
        ...state,
        meetingId: action.payload.meetingId,
        isViewMeetingPage: true,
      };
    case SET_CREATE_NEW_MEETING_PAGE:
      return {
        ...state,

        isNewMeetingPage: action.payload,
        singleMeetingDetails: null,
        isCreateMeetingProcessed: false,
        isUpdateStep: false,
      };
      case SET_IS_NEW_MEETING_PAGE_PAGE:
        return {
          ...state,
         
          isNewMeetingPage: false,
        };
    case UNSET_SINGLE_MEETING_DETAILS:
      return {
        ...state,
        loading: false,

        singleMeetingDetails: null,
        meetingId: null,
      };
    case UPDATE_STEP:
      return {
        ...state,
        step: action.payload.step,
        isUpdateStep: action.payload.isUpdateStep,
      };

    case UPDATE_FETCH_MEETING_LIST_STATUS:
      return {
        ...state,
        isFetchedMeetingList: action.payload,
      };
    case SET_AGENDA_AND_MINUTES_DETAILS:
      return {
        ...state,
        loading: false,
        agendaDetails: action.payload.agendaDetails,
        meetingDetails: action.payload.meetingDetail,
        message: action.payload.message,
        isSuccess: action.payload.success,
        isAttendanceStatus: false,
      };

    case SET_PARENT_AGENDA_AND_MINUTES_DETAILS:
      return {
        ...state,
        loading: false,
        // parentAgendaDetails: action.payload?.agendaDetails
        //   ? action.payload?.agendaDetails
        //   : [],
        parentAgendaDetails: action.payload,
        message: action.payload?.message,
        isSuccess: action.payload?.success,
        isAttendanceStatus: false,
      };

    case SET_MEETING_ACTIVITIES_DETAILS:
      return {
        ...state,
        loading: false,
        meetingActivitiesDetails: action.payload.data,
        message: action.payload.message,
        isSuccess: action.payload.success,
      };
    case SET_USER_MEETING_LIST:
      return {
        ...state,
        loading: false,
        userMeetingList: action.payload?.data?.meetingDetail,
        ownerList: action.payload?.data?.ownerDetails,
        assigneeList: action.payload?.data?.assigneeDetails,
        message: action.payload.message,
        isSuccess: action.payload.success,
      };
    case UPDATE_ATTENDANCE_RESPONSE:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        isSuccess: action.payload.success,
        isAttendanceStatus: action.payload.success ? true : false,
      };

    case UPDATE_MEETING_ISSUCCESS:
      return {
        ...state,
        isSuccess: action.payload,
      };

    case SET_DOWNLOAD_MOM_RESPONSE:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        isSuccess: action.payload.success,
      };

    case SET_TIMELINE_RESPONSE:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        isSuccess: action.payload.success,
        timeLineDatas: action.payload?.data,
      };

    case SET_GENERATE_MOM_RESPONSE:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        isSuccess: action.payload.success,
      };
    case UPDATE_ISSHOW_UPDATE_NOTIFY:
      return {
        ...state,
        isShowUpdateNotify: action.payload,
      };

    case SET_MEETING_STATISTICS_RESPONSE:
      return {
        ...state,
        statisticloading: false,
        message: action.payload.message,
        isSuccess: action.payload.success,
        meetingStatisticsData: action.payload?.data,
      };

    case SEND_MEETING_DETAILS_RESPONSE:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        isSuccess: action.payload.success,
        meetingStatisticsData: action.payload?.data,
      };

    case SET_MEETING_DETAILS:
      return {
        ...state,

        meetingDetails: action.payload,
      };
    case SET_TOTAL_MEETING_LIST_FOR_CHART:
      return {
        ...state,
         loading: false,
        message: action.payload.message,
       // isSuccess: action.payload.success,
        isSuccess: true,
      //  totalMeetingList: action.payload.data,
        meetingChartDetails:action.payload.data,
      };
    case SET_MEETING_TOTAL_DUE_ACTION_PRIORITY_WISE:
      return {
        ...state,
         loading: false,
        //// message: action.payload.message,
         isSuccess: true,
        meetingDueActionDetailsPriorityWise: action.payload.data,
      };

    case SET_SUCCESS:
      return {
        ...state,
        isSuccess: action.payload,
      };

    case SET_DELETE_ZOOM_RECORDING_RESPONSE:
      return {
        ...state,
        loading: false,
        // message: action.payload.message,
        isSuccess: action.payload.success,
      };
      
      case SET_DOWNLOAD_ALL_ZOOM_RECORDING_RESPONSE:
        return {
          ...state,
          loading: false,
          // message: action.payload.message,
          isSuccess: action.payload.success,
        };
        console.log(action.payload)
        case SET_ERROR_MESSAGE:
          return {
            ...state,
            loading: false,
            // message: action.payload.message,
            errorMessage: action.payload,
          };
    default:
      return state;
  }
};
