import React, { useState, useEffect } from "react";
import Collapse from "react-bootstrap/Collapse";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal, Button, Table, Dropdown, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import ReactQuill from "../../../node_modules/react-quill/lib/index";
import {
  updateIShowUpdateNotify,
  updateMeetingDetails,
  updateStep,getSingleMeetingDetails
} from "../../redux/actions/meetingActions/MeetingAction";
import * as constantMessages from "../../constants/constatntMessages";
import "../Login/style/Login.css";
import LoaderButton from "../Common/LoaderButton";
import { logOut } from "../../redux/actions/authActions/authAction";
import { checkCharacterLeft } from "../../helpers/commonHelpers";
import { textBoxStrLength } from "../../config/config";
import AddAgendaModal from "./AddAgendaModal";
import { Accordion } from "react-bootstrap";

const regularExpressionForHtml = new RegExp(/<(\"[^\"]*\"|'[^']*'|[^'\">])*>/);
const AddAgendas = ({ showRemoveButton }) => {
  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],
    ["link", "image", "video", "formula"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"], // remove formatting button
  ];

  const toolBar = {
    toolbar: toolbarOptions,
  };
  const regularExpression = /^[0-9a-zA-Z .,:;%@&()/_'"-]+$/;
  const accessToken = localStorage.getItem("accessToken");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();
  const meetingData = useSelector((state) => state.meeting);
  console.log(meetingData);
  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  if (authData.isInValidUser && authData.isInvalidUserChecked) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }
  const [numAgenda, setNumAgenda] = useState(1);
  const location = useLocation();
  const stateData = location.state;
  const [errors, setErrors] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAgendaId, setSelectedAgendaId] = useState(null);
  const [topic, setTopic] = useState("");
  const [formData, setFormData] = useState({
    title: " ",
    // topic: "",
    timeLine: "0",
    index: 0,
  });
  const [agendaData, setAgendaData] = useState([]);
  const [isAddAgendaModal, setIsAddAgendaModal] = useState(false);
  const [agendaError, setAgendaError] = useState([]);
  const [agendaTimeLineError, setAgendaTimeLineError] = useState([]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
    // Block the 'e', 'E', '+', and '-' keys
    if (["e", "E", "+", "-"].includes(event.key)) {
      event.preventDefault();
    }
  };
  useEffect(() => {
    document.title = "Create Meeting: MinutesVault";
    if (stateData.isMeetingDataUpdate || meetingData.isUpdateStep) {
      document.title = "Update Meeting: MinutesVault";

      if (agendaData?.length === 0) {
        const meetingAgenda =
          meetingData?.singleMeetingDetails?.agendasDetail?.map((item) => {
            item.uid = Math.floor(100000 + Math.random() * 900000);
            return item;
          });

        setAgendaData(meetingAgenda);
      }
    }
  }, [meetingData]);
const goToTop=()=>{
 //(22)
   window.scrollTo(0, 0)
}


const submitAgendasDetails = async (e) => {
  e.preventDefault();
  console.log(agendaData);
  //alert("7777777777")
  
      if (meetingData?.singleMeetingDetails?.attendees?.length >= 2) {
      
       
        const meetingId = meetingData?.singleMeetingDetails?._id;
        // const payload = {
        //   isEditMeeting: stateData.isMeetingDataUpdate
        //     ? stateData.isMeetingDataUpdate
        //     : false,
        //   sendNotification: true,
        //   agendas: newAgendaData,
        //   organizationId: userData.organizationId,
        //   step: 3,
        //   //  meetingStatus: "scheduled",
        //   meetingStatus:
        //     meetingData?.singleMeetingDetails?.meetingStatus?.status ===
        //     "draft"
        //       ? "scheduled"
        //       : meetingData?.singleMeetingDetails?.meetingStatus?.status,
        //   isUpdate:
        //     stateData.isMeetingDataUpdate &&
        //     meetingData.singleMeetingDetails.step === 3
        //       ? true
        //       : false,
        // };
        const payload = {
          sendNotification: true,
          organizationId: userData.organizationId,
          step: 3,
          //  meetingStatus: "scheduled",
          meetingStatus:
            meetingData?.singleMeetingDetails?.meetingStatus?.status ===
            "draft"
              ? "scheduled"
              : meetingData?.singleMeetingDetails?.meetingStatus?.status,

                  isUpdate:
            stateData.isMeetingDataUpdate &&
            meetingData.singleMeetingDetails.step === 3
              ? true
              : false,
        };
        const fromUpdate=meetingData?.singleMeetingDetails?.step===3?true:false;
        console.log(fromUpdate)
        dispatch(
          updateMeetingDetails(
            meetingId,
            payload,
            accessToken,
            "addAgenda",
            stateData.isMeetingDataUpdate,
            false,
            fromUpdate
          )
        );

       
      } else {
        // alert("22222222222")
        toast.warning("Please add minimum 2 attendees.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  



  // const submitAgendasDetails = async (e) => {
  //   e.preventDefault();
  //   console.log(agendaData);
  //   //alert("7777777777")
  //   if (agendaData?.length === 0) {
  //     // alert("00000000000000")
  //     const newErrors = validateForm(formData);
  //     setErrors(newErrors);
  //     if (Object.keys(newErrors).length === 0) {
  //       toast.warning("Please add agenda.", {
  //         position: "top-right",
  //         autoClose: 2000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //     }
  //   } else {
  //     if (agendaError?.length === 0 && agendaTimeLineError?.length === 0) {
  //       if (meetingData?.singleMeetingDetails?.attendees?.length >= 2) {
        
  //         const newAgendaData = agendaData.map((item) => {
  //           return {
  //             topic: item.topic,
  //             title: item.title,
  //             timeLine: item.timeLine.toString(),
  //           };
  //         });
  //         const meetingId = meetingData?.singleMeetingDetails?._id;
  //         const payload = {
  //           isEditMeeting: stateData.isMeetingDataUpdate
  //             ? stateData.isMeetingDataUpdate
  //             : false,
  //           sendNotification: true,
  //           agendas: newAgendaData,
  //           organizationId: userData.organizationId,
  //           step: 3,
  //           //  meetingStatus: "scheduled",
  //           meetingStatus:
  //             meetingData?.singleMeetingDetails?.meetingStatus?.status ===
  //             "draft"
  //               ? "scheduled"
  //               : meetingData?.singleMeetingDetails?.meetingStatus?.status,
  //           isUpdate:
  //             stateData.isMeetingDataUpdate &&
  //             meetingData.singleMeetingDetails.step === 3
  //               ? true
  //               : false,
  //         };

  //         dispatch(
  //           updateMeetingDetails(
  //             meetingId,
  //             payload,
  //             accessToken,
  //             "addAgenda",
  //             stateData.isMeetingDataUpdate,
  //             false
  //           )
  //         );
  //       } else {
  //         // alert("22222222222")
  //         toast.warning("Please add minimum 2 attendees.", {
  //           position: "top-right",
  //           autoClose: 2000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "colored",
  //         });
  //       }
  //     }
  //   }
  // };

  // const onAddAgenda = () => {
  //   const newErrors = validateForm(formData);
  //   setErrors(newErrors);

  //   if (Object.keys(newErrors).length === 0) {
  //     const uid = Math.floor(100000 + Math.random() * 900000);
  //     const checkDuplicateTitle = agendaData?.find(
  //       (agenda) => agenda?.title.trim() === formData?.title.trim()
  //     );

  //     if (!checkDuplicateTitle) {
  //       setAgendaData([
  //         ...agendaData,
  //         {
  //           topic: topic,
  //           timeLine: formData.timeLine,
  //           title: formData.title,
  //           uid,
  //         },
  //       ]);
  //       console.log(formData);
  //       setFormData({
  //         title: " ",

  //         timeLine: "0",
  //         index: 0,
  //       });
  //       setTopic("");
  //       setNumAgenda(numAgenda + 1);
  //       toast.info("One agenda added.", {
  //         position: "top-right",
  //         autoClose: 1000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //       dispatch(updateIShowUpdateNotify(true));
  //     } else {
  //       toast.error("Duplicate title found.", {
  //         position: "top-right",
  //         autoClose: 1000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //     }
  //   } else {
  //   }
  // };
  const onAddAgenda = (data) => {
     const uid = Math.floor(100000 + Math.random() * 900000);
    // const checkDuplicateTitle = agendaData?.find(
    //   (agenda) => agenda?.title.trim() === data?.title.trim()
    // );

    // if (!checkDuplicateTitle) {
      setAgendaData([
        ...agendaData,
        {
          topic: data.topic,
          timeLine: data.timeLine,
          title: data.title,
          uid,
        },
      ]);
      // console.log(data);
      // setFormData({
      //   title: " ",

      //   timeLine: "0",
      //   index: 0,
      // });
     // setTopic("");
      setNumAgenda(numAgenda + 1);
      // toast.info("One agenda added.", {
      //   position: "top-right",
      //   autoClose: 1000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "colored",
      // });
     // dispatch(updateIShowUpdateNotify(true));
    // } else {
    //   toast.error("Duplicate title found.", {
    //     position: "top-right",
    //     autoClose: 1000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "colored",
    //   });
    // }

  };
  const validateForm = () => {
    const errors = {};

    if (!formData?.title.trim()) {
      errors.title = constantMessages.titleRequired;
    } else if (!regularExpression.test(formData.title)) {
      errors.title = constantMessages.titleRegexError;
    } else if (
      formData.title.trim().length < 5 ||
      formData.title.trim().length > 100
    ) {
      errors.title = constantMessages.stringLengthError;
    }
    if (formData.timeLine > 365 || formData.timeLine < 0) {
      errors.timeLine = constantMessages.invalidTime;
    }
    // if (regularExpressionForHtml.test(formData.topic)) {
    //   errors.topic = constantMessages.htmlTagsNotAllowed;
    // }

    return errors;
  };

  const onRemoveAgenda = (uid) => {
    const filteredAgenda = agendaData.filter((item) => item.uid !== uid);
    setAgendaData(filteredAgenda);
  };
  const handleDeleteConfirm = async () => {
    try {
      setIsLoading(true);
      if (selectedAgendaId) {
        onRemoveAgenda(selectedAgendaId);
      }
      setShowDeleteModal(false);
      setSelectedAgendaId(null);
    } catch (error) {
      console.error("Error while removing Agenda:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTopicChange = (uid, value) => {
    const modifiedAgendas = agendaData.map((obj) => {
      if (obj.uid === uid) {
        return { ...obj, topic: value };
      }
      return obj;
    });
    console.log(modifiedAgendas);
    setAgendaData(modifiedAgendas);
    dispatch(updateIShowUpdateNotify(true));
  };

  const handleChange = (e, uid) => {
    let agendaErrors = agendaError;
    let agendaTimeLineErrors = agendaTimeLineError;
    // alert(1)
    setErrors({});
    const error = {};
    dispatch(updateIShowUpdateNotify(true));
    const { name, value } = e.target;
    console.log(uid);

    if (uid) {
      if (name === "title") {
        // alert(value);
        // alert(uid);
        console.log(name, value);

        const errors = {
          uid,
        };
        let isValid = true;
        console.log(value?.trim());
        if (!value?.trim()) {
          // alert(45);
          errors.title = constantMessages.titleRequired;
          isValid = false;
        } else if (!regularExpression.test(value)) {
          // alert(46);
          errors.title = constantMessages.titleRegexError;
          isValid = false;
        } else if (value.trim().length < 5 || value.trim().length > 100) {
          //  alert(47);
          errors.title = constantMessages.stringLengthError;
          isValid = false;
        } else {
          // alert(48);
          const newAgendaErrors = agendaErrors.filter((obj) => obj.uid !== uid);
          isValid = true;
          console.log(newAgendaErrors);
          setAgendaError(newAgendaErrors);
        }
        if (isValid === false) {
          // alert(23);
          if (agendaErrors?.length !== 0) {
            // alert(56);
            if (agendaErrors.find((item) => item.uid === uid)) {
              agendaErrors?.map((item) => {
                if (item?.uid === uid) {
                  //  alert(59);
                  item.errorMessage = errors.title;
                }
              });
            } else {
              //  alert(50);
              agendaErrors.push({
                uid,
                errorMessage: errors.title,
              });
            }
          } else {
            // alert(556);
            agendaErrors.push({
              uid,
              errorMessage: errors.title,
            });
          }
          console.log(agendaErrors);
          setAgendaError(agendaErrors);
        }
      }

      if (name === "timeLine") {
        // alert(value);
        // alert(uid);
        console.log(name, value);

        const errors = {
          uid,
        };
        let isValid = true;
        console.log(value?.trim());

        if (value.trim() > 365 || value.trim() < 0) {
          errors.timeLine = constantMessages.invalidTime;
          isValid = false;
        } else {
          // alert(48);
          const newAgendaErrors = agendaTimeLineErrors.filter(
            (obj) => obj.uid !== uid
          );
          isValid = true;
          console.log(newAgendaErrors);
          setAgendaTimeLineError(newAgendaErrors);
        }
        if (isValid === false) {
          // alert(23);
          if (agendaTimeLineErrors?.length !== 0) {
            // alert(56);
            if (agendaTimeLineErrors.find((item) => item.uid === uid)) {
              agendaTimeLineErrors?.map((item) => {
                if (item?.uid === uid) {
                  //  alert(59);
                  item.timeLineErrorMessage = errors.timeLine;
                }
              });
            } else {
              //  alert(50);
              agendaTimeLineErrors.push({
                uid,
                timeLineErrorMessage: errors.timeLine,
              });
            }
          } else {
            // alert(556);
            agendaTimeLineErrors.push({
              uid,
              timeLineErrorMessage: errors.timeLine,
            });
          }
          console.log(agendaErrors);
          setAgendaTimeLineError(agendaTimeLineErrors);
        }
      }

      const modifiedAgendas = agendaData.map((obj) => {
        if (obj.uid === uid) {
          return { ...obj, [name]: value };
        }
        return obj;
      });
      console.log(modifiedAgendas);
      setAgendaData(modifiedAgendas);
    } else {
      // if (name === "topic") {
      //   if (value.length <= textBoxStrLength) {
      //     setFormData({
      //       ...formData,
      //       [name]: value,
      //     });
      //   }
      // } else {
      setFormData({
        ...formData,
        [name]: value,
      });
      // }
    }
  };
  console.log(agendaError);
  console.log(agendaData);
  console.log(meetingData?.loading);
  const [open, setOpen] = useState(true);
  const validateAgendaTitle = () => {
    const errors = {};
    if (!formData?.title.trim()) {
      errors.title = constantMessages.titleRequired;
    } else if (!regularExpression.test(formData.title)) {
      errors.title = constantMessages.titleRegexError;
    } else if (
      formData.title.trim().length < 5 ||
      formData.title.trim().length > 100
    ) {
      errors.title = constantMessages.stringLengthError;
    }

    setErrors(errors);
  };

  const validateEditAgendaTitle = (e, uid) => {
    //  alert(234);
    e.stopPropagation(); // Prevent multiple calls due to event bubbling
    console.log(formData);
    const { name, value } = e.target;
    console.log(uid);
    console.log(name, value);
    // alert(value)
    // const errors = {};
    // console.log(value?.trim())
    // if (!value?.trim()) {
    //   errors.title = constantMessages.titleRequired;
    // } else if (!regularExpression.test(value)) {
    //   errors.title = constantMessages.titleRegexError;
    // } else if (
    //   value.trim().length < 5 ||
    //   value.trim().length > 100
    // ) {
    //   errors.title = constantMessages.stringLengthError;
    // }

    // setErrors(errors);
  };
  console.log(errors);
  const validateAgendaTime = () => {
    const errors = {};

    if (formData.timeLine > 365 || formData.timeLine < 0) {
      errors.timeLine = constantMessages.invalidTime;
    }
    setErrors(errors);
  };
  const validateAgendaTopic = () => {
    const errors = {};

    // if (regularExpressionForHtml.test(formData.topic)) {
    //   errors.topic = constantMessages.htmlTagsNotAllowed;
    // }
    setErrors(errors);
  };
  const handleValue = (value) => {
    //  alert("test2");
    console.log(value);
    // setFormData({
    //   ...formData,
    //   topic: value,
    // });
    setTopic(value);
  };
  const handleAddAgendaModal = (status) => {
    setIsAddAgendaModal(status);
  };

  return (
    <>
      <form className="mt-2 no-padding-2" onSubmit={submitAgendasDetails}>
        {agendaData?.length === 0 ? (
          <div className="col-12">
            <div className="alert alert-warning">
              Note: Please add atleast 1 agenda to schedule the meeting.
            </div>
          </div>
        ) : null}
          {meetingData?.singleMeetingDetails?.isSendNotification === false && meetingData?.singleMeetingDetails?.agendaIds?.length!==0 && meetingData?.singleMeetingDetails?.step===2? (
              
                
                       
                    
                      
              <div className="col-12">
            <div className="alert alert-warning">
              Note: Please save to schedule the meeting.
            </div>
          </div>
           
       
  ) : null}

{meetingData?.singleMeetingDetails?.isSendNotification === false && meetingData?.singleMeetingDetails?.step===3? (
   <div className="col-12">
   <div className="alert alert-warning">
     Note: Please send notification to inform the updated meeting data.
   </div>
 </div>
):null}

        <div className="inner-detail-form">
          <button
            type="button"
            className="btn rounded-pill add-btn Mom-btn d-flex align-items-center justify-content-center "
            onClick={() => {
              handleAddAgendaModal(true);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="#fff"
              className="bi bi-plus-circle pointer me-2"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
            </svg>
            <p> Add Agenda </p>
          </button>
          {/* <div className="form-group agenda">
          <label className="mb-1">Agenda Item</label>
        </div> */}
          <div>
        
            {errors.addAgenda ? (
              <span className="error-message">{errors.addAgenda}</span>
            ) : null}
            <div className="button-outer" style={{ marginTop: 20 }}>
              <button
                className="add-btn Mom-btn"
                onClick={(e) => dispatch(updateStep(1, true))}
              >
                <p>Back</p>
              </button>

              {meetingData?.singleMeetingDetails?.isSendNotification === false && meetingData?.singleMeetingDetails?.agendaIds?.length!==0 && meetingData?.singleMeetingDetails?.step===2? (
                <>
                  {!meetingData.updateLoading ? (
                    
                 
                        <button
                          className="create-meeting-button Mom-btn"
                          type="submit"
                          // disabled={
                          //   meetingData?.isShowUpdateNotify === false
                          //     ? true
                          //     : false
                          // }
                        >
                          <p>Save & Notify </p>
                        </button>
                    
                       
                  
                  ) : (
                    <LoaderButton />
                  )}
                </>
              ) : null}
              
              {meetingData?.singleMeetingDetails?.isSendNotification === false && meetingData?.singleMeetingDetails?.step===3? (
                <>
                  {!meetingData.updateLoading ? (
                    
                    
                        <button
                          className="create-meeting-button Mom-btn"
                          type="submit"
                          // disabled={
                          //   meetingData?.isShowUpdateNotify === false
                          //     ? true
                          //     : false
                          // }
                        >
                          <p>Send Notification</p>
                        </button>
                      
                    
                  ) : (
                    <LoaderButton />
                  )}
                </>
              ) : null}
            </div>
          
            <div></div>
          </div>
        </div>
        <AddAgendaModal
          IsAddAgendaModal={isAddAgendaModal}
          setIsAddAgendaModal={setIsAddAgendaModal}
          addAgenda={onAddAgenda}
          totalAgenda={agendaData?.length}
          agendaData={agendaData}
          fromEditMeeting={true}
          goToTop={goToTop}
          
        />

        <Modal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this Agenda ?</Modal.Body>
          <Modal.Footer>
            {/* <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button> */}
            {isLoading ? (
              <LoaderButton />
            ) : (
              <Button
                className="add-minutes Mom-btn"
                onClick={handleDeleteConfirm}
              >
                <p>Delete</p>
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </form>
      <div className="attendee-array-error-messages mt-4">
        {meetingData?.errorMessage && (
          <div className="attendee-array-error-messages">
            <ul className="error-list error-message">
              {(Array.isArray(meetingData.errorMessage)
                ? meetingData.errorMessage
                : [meetingData.errorMessage]
              ).map((error, index) => (
                <li key={index} className="error-item">
                  {error}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default AddAgendas;
