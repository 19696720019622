import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { fetchAttendeesList } from "../../redux/actions/meetingActions/MeetingAction";
import * as constantMessages from "../../constants/constatntMessages";
// FILTER COMPONENT
const FilterComponent = (props) => {
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("accessToken");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const meetingData = useSelector((state) => state.meeting);
  const [errors, setErrors] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedOption, setSelectedOption] = useState([
    { label: "Select Attendee(s)", value: "" },
  ]);
  const [selectedStatusOption, setSelectedStatusOption] = useState([
    { label: "Select Status", value: "" },
  ]);
  const [searchData, setSearchData] = useState({
    toDate: "",
    fromDate: "",
    attendeeId: "",
    meetingStatus: "",
    attendeeName: "",
  });

  useEffect(() => {
    setSearchData({
      ...props.initData,
    });
    if (props?.initData?.attendeeName) {
      setSelectedOption([
        {
          label: props?.initData?.attendeeName,
          value: props?.initData?.attendeeId,
        },
      ]);
    }
    if (accessToken) {
      dispatch(fetchAttendeesList(userData?.organizationId,undefined, accessToken));
    }

    if (searchData.meetingStatus) {
      setSelectedStatusOption([
        {
          label: searchData?.meetingStatus?.charAt(0).toUpperCase() +  searchData?.meetingStatus.slice(1),
          value:  searchData?.meetingStatus,
        },
      ]);
    }

  }, []);

  useEffect(() => {
    if (
      searchData.fromDate ||
      searchData.toDate ||
      searchData.attendeeId ||
      searchData.attendeeName ||
      searchData.meetingStatus
    ) {
      setIsDisabled(false);
    }
  }, [
    searchData.fromDate,
    searchData.toDate,
    searchData.attendeeId,
    searchData.meetingStatus,
  ]);

  const fromDateFieldValidationCheck = (e) => {
    const errors = {};
    if (
      new Date(searchData.fromDate).getTime() >
      new Date(searchData.toDate).getTime()
    ) {
      errors.fromDate = constantMessages.invalidFromDate;
    }

    setErrors(errors);
  };
  let attendeesOptions=null;
  if(props.filter){
if(meetingData?.attendeesList?.length!==0){
  attendeesOptions =
    meetingData?.attendeesList?.map((attendee) => {
      const returnData = {
        value: attendee._id,
        label: attendee.name,
        name: "attendeeId",
      };
      return returnData;
    });
  }
}

let statusOptions=null;
if(props.filter){
if(meetingData?.statusData?.length!==0){
  statusOptions =
  meetingData?.statusData?.map((status) => {
    const returnData = {
      value: status,
      label:  status?.charAt(0).toUpperCase() + status.slice(1),
      name: "meetingStatus",
    };
    return returnData;
  });
}
}

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearchData({
      ...searchData,
      [name]: value,
    });
  };

  const handleAttendeesChange = (e) => {
    const { name, value, label } = e;
    setSearchData({
      ...searchData,
      attendeeId: value,
      attendeeName: label,
    });
    setSelectedOption(e);
  };

  const handleStatusChange = (e) => {
    const { name, value, label } = e;
    console.log(value)
    setSearchData({
      ...searchData,
      meetingStatus: value,
    });
    setSelectedStatusOption(e);
  };

  const handleSubmit = (e) => {
    e.preventDefault(e);
    const errors = {};

    if (searchData.fromDate && searchData.toDate) {
      if (
        new Date(searchData.fromDate).getTime() >
        new Date(searchData.toDate).getTime()
      ) {
        errors.fromDate = constantMessages.invalidFromDate;

        setErrors(errors);
      } else {
        props.setfilter(false);

        props.filterData(searchData);
        setSearchData({
          ...searchData,
        });
      }
    } else {
      props.setfilter(false);

      props.filterData(searchData);
      setSearchData({
        ...searchData,
      });
    }
  };
console.log(selectedStatusOption)
  const handleReset = (e) => {
    e.preventDefault(e);

    setSearchData({
      toDate: "",
      fromDate: "",
      attendeeId: "",
      meetingStatus: "",
      attendeeName: "",
    });
    setSelectedOption([{ label: "Select Attendee(s)", value: "" }]);
   setSelectedStatusOption([
      { label: "Select Status", value: "" },
    ]);
    props.filterData({
      toDate: "",
      fromDate: "",
      attendeeId: "",
      meetingStatus: "",
      attendeeName: "",
      page: 1,
    });
    setIsDisabled(true);
    setErrors({});
  };

  return (
    <div
      className={props.filter ? "filter show" : "filter"}
      id="form-container"
    >
      <div className="filter-container">
        <h4 className="filterheading mb-0">Filter Meetings</h4>
        <button
          type="button"
          onClick={(e) => props.setfilter(false)}
          style={{ border: "none", padding: 0, margin: 0 }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            fill="currentColor"
            className="bi bi-x"
            viewBox="0 0 16 16"
          >
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
          </svg>
        </button>
      </div>

      <form id="myForm" onSubmit={handleSubmit} className="filter-form">
        <label htmlFor="from">From Date</label>
        <div className="from-to">
          {/* <input
            className="filter-date"
            type="date"
            id="from"
            onChange={handleChange}
            name="fromDate"
            onBlur={fromDateFieldValidationCheck}
            value={searchData.fromDate}
          /> */}
          <div
            className="relative w-100"
            onClick={() => document.getElementById("from").showPicker()}
           >
                <input
                  className="filter-date"
                  type="date"
                  id="from"
                  onChange={handleChange}
                  name="fromDate"
                  onBlur={fromDateFieldValidationCheck}
                  value={searchData.fromDate}
                />
            </div>
        </div>

        {errors.fromDate && (
          <span className="error-message">{errors.fromDate}</span>
        )}
        
        <label htmlFor="to">To Date</label>
        <div className="from-to">
          {/* <input
            className="filter-date"
            type="date"
            id=""
            onChange={handleChange}
            onBlur={fromDateFieldValidationCheck}
            name="toDate"
            value={searchData.toDate}
          /> */}
          <div
            className="relative w-100"
            onClick={() => document.getElementById("todate").showPicker()}
           >
                <input
                  className="filter-date"
                  type="date"
                  id="todate"
                  onChange={handleChange}
                  onBlur={fromDateFieldValidationCheck}
                  name="toDate"
                  value={searchData.toDate}
                />
            </div>
        </div>
        
        <label>Attendee(s)</label>

        <Select
          className="fltr-opt"
          placeholder="Select Attendees"
          name="attendeeId"
          options={attendeesOptions?attendeesOptions:null}
          onChange={handleAttendeesChange}
          value={selectedOption}
        />
        
        <label>Status</label>


        <Select
          className="fltr-opt"
          placeholder="Select Status"
          name="meetingStatus"
          options={statusOptions? statusOptions:null}
          onChange={handleStatusChange}
          value={selectedStatusOption}
        />

        {/* <select
          className="fltr-opt"
          aria-placeholder="Select Status"
          onChange={handleChange}
          name="meetingStatus"
          value={searchData.meetingStatus}
        >
          <option value="">Select Status</option>
          {meetingData.statusData &&
            meetingData.statusData.map((status) => {
              return (
                <option value={status}>
                  {status.charAt(0).toUpperCase() + status.slice(1)}
                </option>
              );
            })}
        </select> */}

        <div className="mt-2 form-btm-btn">
          <button className="reset" type="reset" onClick={handleReset}>
            <p>Reset</p>
          </button>
          <button className="add-btn Mom-btn" disabled={isDisabled}>
            <p>Filter</p>
          </button>
        </div>
      </form>
    </div>
  );
};

export default FilterComponent;
