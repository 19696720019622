import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Popover, OverlayTrigger, Accordion } from "react-bootstrap";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { getMeetingActionPriotityDetails } from "../../redux/actions/meetingActions/MeetingAction";
import { getUserActionPriotityDetails } from "../../redux/actions/actionAction/ActionAction";
import { Link } from "react-router-dom";
import NoDataFound from "../Common/NoDataFound";
import Alert from "../Common/Alert";

const MeetingBar = ({ showDetailsLink }) => {
  const accessToken = localStorage.getItem("accessToken");
  const employeeData = useSelector((state) => state.user);
  const meetingData = useSelector((state) => state.meeting);
  const actionData = useSelector((state) => state.action);
    const authData = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [showUserData, setShowUserData] = useState(false);
  const [userData, setUserData] = useState([]);
  const [userPage, setUserPage] = useState(1);

///////
  const [searchKey, setSearchKey] = useState("");
  useEffect(() => {
    if (employeeData?.userData?.organizationId) {
      const payload = {
        page,
        order: -1,
        limit,
        organizationId: employeeData?.userData?.organizationId,
      };
      if (!authData.isInValidUser) {
      dispatch(getMeetingActionPriotityDetails(payload, accessToken));
      }
    }
  }, [employeeData?.userData?.organizationId]);

  useEffect(() => {
    if (employeeData?.userData?.organizationId) {
      const payload = {
        page,
        order: -1,
        limit,
        organizationId: employeeData?.userData?.organizationId,
      };
      dispatch(getUserActionPriotityDetails(payload, accessToken));
    }
  }, [showUserData]);

  useEffect(() => {
    if (meetingData?.meetingDueActionDetailsPriorityWise?.length !== 0) {
      console.log(
        meetingData?.meetingDueActionDetailsPriorityWise?.meetingsData
      );
      const requiredFormat =
        meetingData?.meetingDueActionDetailsPriorityWise?.meetingsData?.map(
          (meeting) => {
            return {
              name: meeting?.meetingId?.slice(0, 5) + "...",
              HighPriority: meeting?.totalHighPriorityDueAction,
              NormalPriority: meeting?.totalMediumPriorityDueAction,
              LowPriority: meeting?.totalLowPriorityDueAction,
              value: meeting,
            };
          }
        );
      console.log(requiredFormat);
      if (Array.isArray(requiredFormat)) {
        setData([...requiredFormat]);
      }
      
     
    }
  }, [meetingData?.meetingDueActionDetailsPriorityWise]);

  useEffect(() => {
    if (actionData?.userDueActionDetailsPriorityWise?.length !== 0) {
      const requiredFormat =
        actionData?.userDueActionDetailsPriorityWise?.userDatas?.map(
          (meeting) => {
            return {
              name: meeting?.name?.slice(0, 5) + "...",
              HighPriority: meeting?.totalHighPriorityDueAction,
              NormalPriority: meeting?.totalMediumPriorityDueAction,
              LowPriority: meeting?.totalLowPriorityDueAction,
              value: meeting,
            };
          }
        );
    
      if (Array.isArray(requiredFormat)) {
        setUserData([...requiredFormat]);
      }
    }
  }, [actionData?.userDueActionDetailsPriorityWise]);

  const fetchNextData = () => {
    if (showUserData) {
      if (page > 0) {
        setPage(page + 1);
        const payload = {
          page: page + 1,
          order: -1,
          limit: 5,
          organizationId: employeeData?.userData?.organizationId,
          searchKey,
        };
        dispatch(getUserActionPriotityDetails(payload, accessToken));
      }
    } else {
      if (page > 0) {
        setPage(page + 1);
        const payload = {
          page: page + 1,
          order: -1,
          limit: 5,
          organizationId: employeeData?.userData?.organizationId,
          searchKey,
        };
        if (!authData.isInValidUser) {
        dispatch(getMeetingActionPriotityDetails(payload, accessToken));
        }
      }
    }
  };

  const fetchPreviousData = () => {
    if (showUserData) {
      if (page > 1) {
        setPage(page - 1);
        const payload = {
          page: page - 1,
          order: -1,
          limit: 5,
          organizationId: employeeData?.userData?.organizationId,
          searchKey,
        };
        dispatch(getUserActionPriotityDetails(payload, accessToken));
      }
    } else {
      if (page > 1) {
        setPage(page - 1);
        const payload = {
          page: page - 1,
          order: -1,
          limit: 5,
          organizationId: employeeData?.userData?.organizationId,
          searchKey,
        };
        if (!authData.isInValidUser) {
        dispatch(getMeetingActionPriotityDetails(payload, accessToken));
        }
      }
    }
  };

  const fetchNextUserData = () => {
    if (userPage > 0) {
      setUserPage(userPage + 1);
      const payload = {
        page: userPage + 1,
        order: -1,
        limit: 5,
        organizationId: employeeData?.userData?.organizationId,
        searchKey,
      };
      dispatch(getUserActionPriotityDetails(payload, accessToken));
    }
  };

  const fetchPreviousUserData = () => {
    if (userPage > 1) {
      setUserPage(userPage - 1);
      const payload = {
        page: userPage - 1,
        order: -1,
        limit: 5,
        organizationId: employeeData?.userData?.organizationId,
        searchKey,
      };
      dispatch(getUserActionPriotityDetails(payload, accessToken));
    }
  };

  console.log(data);
  const totalUserCount =
    actionData?.userDueActionDetailsPriorityWise?.totalCount;
  const checkUserTotalPage =
    totalUserCount % limit === 0
      ? Math.round(totalUserCount / limit)
      : Math.round(totalUserCount / limit + 0.5);
  const totalUserPage = totalUserCount ? checkUserTotalPage : 0;
  const totalUserPageArray = Array(totalUserPage).fill();

  console.log(data);
  const totalCount =
    meetingData?.meetingDueActionDetailsPriorityWise?.totalCount;
  const checkTotalPage =
    totalCount % limit === 0
      ? Math.round(totalCount / limit)
      : Math.round(totalCount / limit + 0.5);
  const totalPage = totalCount ? checkTotalPage : 0;
  const totalPageArray = Array(totalPage).fill();
  const CustomTooltip = ({ active, payload, label }) => {
    console.log("payload=================>>>>", payload[0]?.payload.value);

    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip bar-graph-tooltip">
          <p className="intro">{payload[0]?.payload?.value?.meetingId}</p>
          <p className="intro">{`Total : ${payload[0]?.payload?.value?.totalDueAction}`}</p>
          <p className="label high-priority">{`High Priority : ${payload[0]?.payload?.value?.totalHighPriorityDueAction}`}</p>
          <p className="label Normal-priority">{`Normal Priority : ${payload[0]?.payload?.value?.totalMediumPriorityDueAction}`}</p>
          <p className="label low-priority">{`Low Priority : ${payload[0]?.payload?.value?.totalLowPriorityDueAction}`}</p>
        </div>
      );
    }
    return null;
  };

  const CustomUserTooltip = ({ active, payload, label }) => {
    console.log("payload=================>>>>", payload[0]?.payload.value);

    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip bar-graph-tooltip">
          <p className="intro">{payload[0]?.payload?.value?.name}</p>
          <p className="intro">({payload[0]?.payload?.value?.email})</p>
          <p className="intro">{`Total : ${payload[0]?.payload?.value?.pendingActions}`}</p>
          <p className="label high-priority">{`High Priority : ${payload[0]?.payload?.value?.totalHighPriorityDueAction}`}</p>
          <p className="label Normal-priority">{`Normal Priority : ${payload[0]?.payload?.value?.totalMediumPriorityDueAction}`}</p>
          <p className="label low-priority">{`Low Priority : ${payload[0]?.payload?.value?.totalLowPriorityDueAction}`}</p>
        </div>
      );
    }
    return null;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    setSearchKey(value);

    if (showUserData) {
      const payload = {
        page: page,
        order: -1,
        limit: 5,
        organizationId: employeeData?.userData?.organizationId,
        searchKey: value,
      };

      dispatch(getUserActionPriotityDetails(payload, accessToken));
    } else {
      const payload = {
        page: page,
        order: -1,
        limit: 5,
        organizationId: employeeData?.userData?.organizationId,
        searchKey: value,
      };
      if (!authData.isInValidUser) {
      dispatch(getMeetingActionPriotityDetails(payload, accessToken));
      }
    }
  };

  const setAll = (e) => {
    setShowUserData(false);
    setPage(1);
  };

  const setUser = (e) => {
    setShowUserData(true);
    setPage(1);
  };

  const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value } = props;
    const radius = 10;

    return (
      <g>
        <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" />
        <text
          x={x + width / 2}
          y={y - radius}
          fill="#fff"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {value.split(" ")[1]}
        </text>
      </g>
    );
  };
//console.log(actionData?.userDueActionDetailsPriorityWise?.totalCount)
  return (
    <div>
      <div className="title-next-prev d-flex align-items-center justify-content-between ">
        <h6 className="mb-0">Due Actions</h6>

        <div className="graph-btn d-flex align-items-center">
          <button
            onClick={() => setAll()}
            className={
              showUserData
                ? "badge bg-opacity-10 disable-btn me-2 all-btn"
                : "badge bg-opacity-10 scheduled-color me-2 all-btn"
            }
          >
            All
          </button>
          <button
            onClick={() => setUser()}
            className={
              !showUserData
                ? "badge bg-opacity-10 disable-btn me-2 all-btn"
                : "badge bg-opacity-10 scheduled-color me-2 all-btn"
            }
          >
            Attendees
          </button>
        </div>

        {/* <div className="next-prev-button next-prev-button-new">
          {page !== 1 ? (
            <button className="left-arrow" onClick={() => fetchPreviousData()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#fff"
                className="bi bi-chevron-left"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                />
              </svg>
            </button>
          ) : null}

          {!showUserData && totalPageArray?.length !== page ? (
            <button className="right-arrow" onClick={() => fetchNextData()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#fff"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                />
              </svg>
            </button>
          ) : null}
           {showUserData && totalUserPageArray?.length !== page ? (
            <button className="right-arrow" onClick={() => fetchNextData()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#fff"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                />
              </svg>
            </button>
          ) : null}
        </div> */}
      </div>

      <div className="search-div">
        <div className="search-box search-id mb-2">
          <input
            type="search"
            placeholder="Search By Meeting Title / ID"
            onChange={handleChange}
            name="searchKey"
            value={searchKey}
            autoComplete="off"
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#4F2CC8"
            className="bi bi-search"
            viewBox="0 0 16 16"
          >
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
          </svg>
        </div>
      </div>
{  meetingData?.meetingDueActionDetailsPriorityWise?.totalCount===0?( 
     <div className="no-data-img">
     <Alert
       status={"info"}
       message={"No data available."}
       timeoutSeconds={0}
     />

     <NoDataFound dataType={"meeting"} width={350}/>
    
   </div>
):(
  <>
  {showUserData ? (
    <>
   
      <ResponsiveContainer width="100%" height={355}>
        <BarChart
          width={650}
          height={330}
          data={userData}
          margin={{
            top: 15,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis yAxisId="left" orientation="left" stroke="black" />
          <Tooltip content={<CustomUserTooltip />} />
          <Legend />
          <Bar yAxisId="left" dataKey="HighPriority" fill="#f1416c" />
          <Bar yAxisId="left" dataKey="NormalPriority" fill="#186fef" />
          <Bar yAxisId="left" dataKey="LowPriority" fill="#35c654" />
        </BarChart>
      </ResponsiveContainer>
    </>
  ) : (
    <>
      {/* <div className="search-box">
        <input
          type="search"
          placeholder="Search By Meeting Title / ID all"
          onChange={handleChange}
          name="searchKey"
          value={searchKey}
          autoComplete="off"
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="#4F2CC8"
          className="bi bi-search"
          viewBox="0 0 16 16"
        >
          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
        </svg>
      </div> */}

      <ResponsiveContainer width="100%" height={355}>
        <BarChart
          width={650}
          height={330}
          data={data}
          margin={{
            top: 15,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          {/* <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
          <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
          <Tooltip content={<CustomTooltip />} />
          <Legend />

          <Bar yAxisId="left" dataKey="HighPriority" fill="#8884d8" />
          <Bar yAxisId="right" dataKey="NormalPriority" fill="#82ca9d" />
          <Bar yAxisId="right" dataKey="LowPriority" fill="#0d6fdf" />

*/}

          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis yAxisId="left" orientation="left" stroke="black" />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar yAxisId="left" dataKey="HighPriority" fill="#f1416c" />
          <Bar yAxisId="left" dataKey="NormalPriority" fill="#186fef" />
          <Bar yAxisId="left" dataKey="LowPriority" fill="#35c654" />
        </BarChart>
      </ResponsiveContainer>
    </>
  )}
  </>
)}
    

      <div className="statistics-data next-prev-details">
        {/* <Link
          className="badge bg-opacity-10 scheduled-color view-details-btn"
          to={`/statistics-details/${employeeData?.userData?.organizationId}`}
          style={{
            textDecoration: "none",
            color: "black",
          }}
        > */}
        {showDetailsLink && data?.length !==0 && (
          <Link
            to="/action-list"
            className="badge bg-opacity-10 scheduled-color view-details-btn"
            style={{
              textDecoration: "none",
              color: "black",
            }}
          >
            View Details
          </Link>
        )}
{actionData?.userDueActionDetailsPriorityWise?.totalCount!==0?(
  <div className="next-prev-button next-prev-button-new ">
  {page !== 1 ? (
    <button className="left-arrow" onClick={() => fetchPreviousData()}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="#fff"
        className="bi bi-chevron-left"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
        />
      </svg>
    </button>
  ) : null}

  {!showUserData && totalPageArray?.length !== page && data?.length !==0 ? (
    <button className="right-arrow" onClick={() => fetchNextData()}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="#fff"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
        />
      </svg>
    </button>
  ) : null}
  {showUserData && totalUserPageArray?.length !== page && data?.length !==0  ? (
    <button className="right-arrow" onClick={() => fetchNextData()}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="#fff"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
        />
      </svg>
    </button>
  ) : null}
</div>
):null}
      
      </div>
    </div>
  );
};

export default MeetingBar;
