import React, { useState, useEffect } from "react";
import * as constantMessages from "../../constants/constatntMessages";
import { useSelector, useDispatch } from "react-redux";
import { fetchCurrentAttendeesList } from "../../redux/actions/meetingActions/MeetingAction";
import Select from "react-select";
import { getEmployeeList } from "../../redux/actions/userAction/userAction";
import LoaderButton from "../Common/LoaderButton";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  createMinutes,
  setFinalMinuteData,
} from "../../redux/actions/minuteActions/MinuteAction";
import {
  checkCharacterLeft,
  checkEmptyString,
  getStringLengthAfterHTMLParse,
} from "../../helpers/commonHelpers";
import { textBoxStrLength } from "../../config/config";
import TextEditor from "../Common/TextEditor";
import ReactQuill from "../../../node_modules/react-quill/lib/index";
import Loader from "../Common/Loader";

const AddMinuteModal = (props) => {
  console.log(props);
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };

  const formats = ["header", "bold", "italic", "underline", "list", "bullet"];
  const regularExpression = /^[0-9a-zA-Z .(),/-]+$/;
  const regularExpressionForHtml = new RegExp(
    /<(\"[^\"]*\"|'[^']*'|[^'\">])*>/
  );
  const regularExpressionName = /^[a-zA-Z .(),/-]+$/;
  const meetingData = useSelector((state) => state.meeting);
  const minuteDatas = useSelector((state) => state.minute);
  const employeeData = useSelector((state) => state.user);
  const minutesDetails = useSelector((state) => state.minute);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const accessToken = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [selectedAttendeeOption, setSelectedAttendeeOption] = useState([
    { label: "Name / Email Address", value: "" },
  ]);
  const [selectedEmployeeOption, setSelectedEmployeeOption] = useState([
    { label: "Name / Emp Id", value: "" },
  ]);
  const [value, setValue] = useState("");
  const [description, setDescription] = useState("");
  
  const [formData, setFormData] = useState({
    title: "",
   // description: "",
    date: "",
    priority: "",
    attendyType: "fromCurrentMeeting",
    responsiblePersonId: "",
    isAction: false,
    name: "",
    email: "",
    designation: "",
    companyName: "",
  });
  const [minuteData, setMinuteData] = useState([]);

  useEffect(() => {
    if (
      minutesDetails?.finalMinutesData?.length !== 0 &&
      minuteData.length === 0
    ) {
      setMinuteData(minutesDetails.finalMinutesData);
    }
    setSelectedEmployeeOption([{ label: "*Name / Emp Id", value: "" }]);
    setSelectedAttendeeOption([{ label: "*Name / Email Address", value: "" }]);
    // dispatch(
    //   fetchCurrentAttendeesList(
    //     userData.organizationId,
    //     meetingData?.meetingDetails?._id,
    //     accessToken
    //   )
    // );
  }, []);

  useEffect(() => {
    if (formData?.isAction) {
      //  alert("2");
      dispatch(
        fetchCurrentAttendeesList(
          userData.organizationId,
          meetingData?.meetingDetails?._id,
          accessToken
        )
      );
    }
  }, [formData?.isAction]);

  useEffect(() => {
    if (minutesDetails.isSuccess) {
      setSelectedEmployeeOption([{ label: "*Name / Emp Id", value: "" }]);
      setSelectedAttendeeOption([
        { label: "*Name / Email Address", value: "" },
      ]);
    //  alert("inside2")
      setFormData({
        ...formData,
        title: "",
        description: "",
        date: "",
        priority: "",
        attendyType: "fromCurrentMeeting",
        responsiblePersonId: "",
        isAction: false,
        name: "",
        email: "",
        companyName: "",
        designation: "",
      });
    }
  }, [minutesDetails.isSuccess]);
  console.log(formData);
  let attendeesData = meetingData.meetingDetails.attendees.map((attendee) => {
    return {
      id: attendee._id,
      status: "PENDING",
    };
  });

  const checkHandler = (e, uid) => {
    //  alert("ff");
    const { checked } = e.target;

    if (uid) {
      const modifiedMinutes = minutesDetails.finalMinutesData.map((obj) => {
        if (obj.uid === uid) {
          return { ...obj, isAction: checked };
        }
        return obj;
      });
      dispatch(setFinalMinuteData(modifiedMinutes));
    } else {
      //alert("inside3")
      setFormData({
        ...formData,
        isAction: !formData.isAction,
        date: "",
        priority: "",
        attendyType: "fromCurrentMeeting",
        responsiblePersonId: "",
        // isAction: false,
        name: "",
        email: "",
      });
    }
    setSelectedEmployeeOption([{ label: "*Name / Emp Id", value: "" }]);
    setSelectedAttendeeOption([{ label: "*Name / Email Address", value: "" }]);
  };

  const handleChange = (e, uid) => {
    setErrors({});
    const { name, value } = e.target;
    // alert(value);
    if (uid) {
      const modifiedMinutes = minutesDetails.finalMinutesData.map((obj) => {
        if (obj.uid === uid) {
          return { ...obj, [name]: value };
        }
        return obj;
      });
      dispatch(setFinalMinuteData(modifiedMinutes));
    } else {
     // alert("inside4")
      // if (name === "description") {
      //   if (value.length <= textBoxStrLength) {
      setFormData({
        ...formData,
        [name]: value,
      });
      //   }
      // } else {
      //   setFormData({
      //     ...formData,
      //     [name]: value,
      //   });
      // }
    }

    if (value === "fromEmployeeList") {
      // alert("kk")
      const payload = {
        organizationId: userData.organizationId,
      };
      dispatch(getEmployeeList(payload, accessToken));
      setSelectedAttendeeOption([{ label: "Name / Email Address", value: "" }]);
      //  setSelectedEmployeeOption(e);
    }
    // alert(value)

    if (
      formData.attendyType === "fromCurrentMeeting" &&
      accessToken &&
      value === "fromCurrentMeeting"
    ) {
      //  alert("hhhhhhh");
      dispatch(
        fetchCurrentAttendeesList(
          userData.organizationId,
          meetingData?.meetingDetails?._id,
          accessToken
        )
      );
      //setSelectedAttendeeOption(e);
    }
    if (value === "fromCurrentMeeting") {
      // alert("cc")
      setSelectedEmployeeOption([{ label: "*Name / Emp Id", value: "" }]);
    }
    if (value === "addNewPeople") {
      // alert("cc")
      setSelectedEmployeeOption([{ label: "*Name / Emp Id", value: "" }]);
      setSelectedAttendeeOption([
        { label: "*Name / Email Address", value: "" },
      ]);
    }
  };

  const validateMinuteDescription = () => {
    const errors = {};
    // if (!formData?.description.trim()) {
    //   errors.description = constantMessages.descriptionRequired;
    // }
    if (!checkEmptyString(description?.trim())) {
      errors.description = constantMessages.descriptionRequired;
    }
    // else if (regularExpressionForHtml.test(formData.description)) {
    //   errors.description = constantMessages.htmlTagsNotAllowed;
    // }
    // else if (
    //   formData.description.replace(/\s{2,}/g, " ").trim().length < 3 ||
    //   formData.description.replace(/\s{2,}/g, " ").trim().length > 200
    // ) {
    //   errors.description = constantMessages.largeStringLengthError;
    // }
    setErrors(errors);
  };

  const validateMinuteTitle = () => {
    const errors = {};
    if (!formData?.title?.trim()) {
      errors.title = constantMessages.titleRequired;
    } else if (formData.title.length < 5 || formData.title.length > 200) {
      errors.title = "Title must be between 5-200  characters";
    }
    // else if (regularExpressionForHtml.test(formData.description)) {
    //   errors.description = constantMessages.htmlTagsNotAllowed;
    // }
    // else if (
    //   formData.description.replace(/\s{2,}/g, " ").trim().length < 3 ||
    //   formData.description.replace(/\s{2,}/g, " ").trim().length > 200
    // ) {
    //   errors.description = constantMessages.largeStringLengthError;
    // }
    setErrors(errors);
  };

  const dateFieldValidationCheck = (e) => {
    const errors = {};

    if (formData?.date?.trim() && formData.isAction) {
      const currentDate = new Date(meetingData?.meetingDetails?.date);
      const inputDate = new Date(formData.date);
      let differenceInTime = inputDate.getTime() - currentDate.getTime();
      let differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
      if (differenceInDays < 0) {
        errors.date = constantMessages.dateMoreThanMeetingDate;
      }
    } else if (!formData?.date?.trim()) {
      errors.date = constantMessages.dateRequired;
    }
    setErrors(errors);
  };

  const nameFieldValidationCheck = (e) => {
    const errors = {};
    // alert("innn", formData.name);
    if (!formData.name.trim()) {
      errors.name = constantMessages.nameRequired;
    } else if (!regularExpressionName.test(formData.name)) {
      errors.name = constantMessages.invalidName;
    } else if (
      formData.name.trim().length < 3 ||
      formData.name.trim().length > 100
    ) {
      errors.name = constantMessages.stringLengthError;
    }
    console.log(errors.name);
    setErrors(errors);
  };

  const emailFieldValidationCheck = (e) => {
    const errors = {};

    if (!formData.email.trim()) {
      errors.email = constantMessages.emailRequired;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = constantMessages.invalidEmail;
    }
    setErrors(errors);
  };

  const validateForm = (data) => {
    console.log(description);
    const abc = description.replace(/  +/g, " ");
    console.log(abc);
    const errors = {};
    if (formData?.attendyType === "addNewPeople") {
      if (!formData?.name?.trim()) {
        errors.name = constantMessages.nameRequired;
      } else if (!regularExpressionName.test(formData.name)) {
        errors.name = constantMessages.invalidName;
      } else if (
        formData?.name?.trim()?.length < 3 ||
        formData?.name?.trim()?.length > 50
      ) {
        errors.title = constantMessages.stringLengthError;
      }

      if (!formData?.email?.trim()) {
        errors.email = constantMessages.emailRequired;
      } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        errors.email = constantMessages.invalidEmail;
      }
    } else {
      if (
        !formData?.responsiblePersonId?.trim() &&
        formData.isAction &&
        formData.name === "" &&
        formData.email === ""
      ) {
        errors.responsiblePersonId = constantMessages.responsiblePersonRequired;
      }
    }

    if (!formData?.title?.trim()) {
      errors.title = constantMessages.titleRequired;
    } else if (formData.title.length < 5 || formData.title.length > 200) {
      errors.title = "Title must be between 5-200  characters";
    }

    if (!checkEmptyString(description?.trim())) {
      errors.description = constantMessages.descriptionRequired;
    }
    // else if (regularExpressionForHtml.test(formData.description)) {
    //   errors.description = constantMessages.htmlTagsNotAllowed;
    // }
    // else if (
    //   formData.description.trim().replace(/\s{2,}/g, " ").length < 3 ||
    //   formData.description.trim().replace(/\s{2,}/g, " ").length > 200
    // ) {
    //   errors.description = constantMessages.largeStringLengthError;
    // }

    if (formData?.date?.trim() && formData.isAction) {
      const currentDate = new Date(meetingData?.meetingDetails?.date);
      const inputDate = new Date(formData.date);
      let differenceInTime = inputDate.getTime() - currentDate.getTime();
      let differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
      if (differenceInDays < 0) {
        errors.date = constantMessages.dateMoreThanMeetingDate;
      }
    } else if (!formData?.date?.trim() && formData.isAction) {
      errors.date = constantMessages.dateRequired;
    }
    if (!formData?.priority?.trim() && formData.isAction) {
      errors.priority = constantMessages.priorityRequired;
    }
    if (formData?.designation?.trim()) {
      if (!regularExpression.test(formData.designation)) {
        errors.designation = constantMessages.invalidDesignation;
      } else if (
        formData.designation.length < 2 ||
        formData.designation.length > 30
      ) {
        errors.designation = "Designation must be between 2 and 30 characters.";
      }
    }

    if (formData?.companyName?.trim()) {
      if (!regularExpression.test(formData.companyName)) {
        errors.companyName = constantMessages.invalidCompanyName;
      } else if (
        formData.companyName.length < 2 ||
        formData.companyName.length > 50
      ) {
        errors.companyName =
          "Company name must be between 2 and 50 characters.";
      }
    }
    return errors;
  };

  // const validateMinuteDescription = () => {
  //   const errors = {};
  //   if (!formData?.description.trim()) {
  //     errors.description = constantMessages.descriptionRequired;
  //   }
  //   // else if (regularExpressionForHtml.test(formData.description)) {
  //   //   errors.description = constantMessages.htmlTagsNotAllowed;
  //   // }
  //   // else if (
  //   //   formData.description.replace(/\s{2,}/g, " ").trim().length < 3 ||
  //   //   formData.description.replace(/\s{2,}/g, " ").trim().length > 200
  //   // ) {
  //   //   errors.description = constantMessages.largeStringLengthError;
  //   // }
  //   setErrors(errors);
  // };

  const onAddMinute = () => {
    const newErrors = validateForm(formData);
    setErrors(newErrors);
    // alert("ffggg");
    if (Object.keys(newErrors).length === 0) {
      const responsiblePersonId =
        formData.responsiblePersonId === "" &&
          formData.name === "" &&
          formData.email === ""
          ? userData._id
          : formData.responsiblePersonId;

      const isNewUser =
        formData.name !== "" &&
          formData.email !== "" &&
          formData.isAction === true
          ? true
          : formData.name === "" &&
            formData.email === "" &&
            formData.isAction === true
            ? false
            : undefined;
      const newMinuteData = {
        organizationId: userData?.organizationId,
        agendaId: props?.agenda?._id,
        meetingId: meetingData?.meetingDetails?._id?.toString(),
        attendees: attendeesData,
        description: description,
        title: formData?.title,
        dueDate:
          formData.isAction === true && formData.date !== ""
            ? formData.date
            : undefined,
        priority:
          formData.isAction === true && formData.priority !== ""
            ? formData.priority
            : undefined,
        isNewUser,

        assignedUserId:
          formData.isAction === true && isNewUser === false
            ? responsiblePersonId
            : undefined,
        isAction: formData.isAction,

        name:
          formData.isAction === true && isNewUser === true
            ? formData.name
            : undefined,
        email:
          formData.isAction === true && isNewUser === true
            ? formData.email
            : undefined,
        designation:
          formData.designation !== "" && isNewUser === true
            ? formData.designation
            : undefined,
        companyName:
          formData.companyName !== "" && isNewUser === true
            ? formData.companyName
            : undefined,
      };

      dispatch(
        createMinutes(
          {
            meetingId: meetingData?.meetingDetails?._id,
            minutes: [newMinuteData],
          },
          accessToken
        )
      );
    //  alert("inside5")
      setFormData({
        ...formData,
       // description: " ",
        date: "",
        priority: "",
        attendyType: "fromCurrentMeeting",
        responsiblePersonId: "",
        isAction: false,
        name: "",
        email: "",
        companyName: "",
        designation: "",
      });
      setDescription("")
      setSelectedEmployeeOption([{ label: "*Name / Emp Id", value: "" }]);
      setSelectedAttendeeOption([
        { label: "*Name / Email Address", value: "" },
      ]);
    } else {
    }
  };

  if (meetingData?.currentAttendeesList?.length !== 0) {
    var attendeeOptions = meetingData?.currentAttendeesList && meetingData?.currentAttendeesList?.map((attendee) => {
      const returnData = {
        value: attendee._id,
        label: attendee.name + " / " + attendee.email,
        name: "responsiblePersonId",
      };

      return returnData;
    });
  }

  const handleAttendeeChange = (e) => {
    setErrors({});
    const { name, value } = e;
   // alert("inside7")
    setFormData({
      ...formData,
      [name]: value,
    });
    setSelectedAttendeeOption(e);
  };
  const handleEmployeeChange = (e) => {
    setErrors({});
   // alert("inside9")
    const { name, value } = e;
    setFormData({
      ...formData,
      [name]: value,
    });
    setSelectedEmployeeOption(e);
  };
  if (employeeData?.employeeList?.length !== 0) {
    var employeeOptions = employeeData?.employeeList?.map((attendee) => {
      const returnData = {
        value: attendee._id,
        label: attendee.name + " / " + attendee.empId,
        name: "responsiblePersonId",
      };
      return returnData;
    });
  }
  console.log(selectedAttendeeOption);

  // const companyNameFieldValidationCheck = (e) => {
  //   const errors = {};
  //   // const regularExpression = /^[A-Za-z\s]+$/;
  //   if (formData?.companyName?.trim()) {
  //     if (!regularExpression.test(formData.companyName)) {
  //       errors.companyName = constantMessages.invalidCompanyName;
  //     } else if (
  //       formData?.companyName.length < 2 ||
  //       formData?.companyName.length > 50
  //     ) {
  //       errors.companyName = "Company  must be between 2-50  characters";
  //     }
  //   }
  //   setErrors(errors);
  // };
  // const designationFieldValidationCheck = (e) => {
  //   const errors = {};
  //   //const regularExpression = /^[A-Za-z\s]+$/;
  //   if (formData?.designation?.trim()) {
  //     if (!regularExpression.test(formData.designation)) {
  //       errors.designation = constantMessages.invalidDesignation;
  //     } else if (
  //       formData.designation.length < 2 ||
  //       formData.designation.length > 30
  //     ) {
  //       errors.designation = "Designation must be between 2-30  characters";
  //     }
  //   }
  //   setErrors(errors);
  // };

  const handleValue = (value) => {
    setErrors({});
    console.log(value);
  //  alert("inside10")
    // setFormData({
    //  // ...formData,
    //   description: value,
    // });

setDescription(value)

  };
  console.log(formData);
  const resetModal = () => {
    setSelectedEmployeeOption([{ label: "*Name / Emp Id", value: "" }]);
    setSelectedAttendeeOption([
      { label: "*Name / Email Address", value: "" },
    ]);
   // alert("inside13")
    setFormData({
      title: "",
     // description: "",
      date: "",
      priority: "",
      attendyType: "fromCurrentMeeting",
      responsiblePersonId: "",
      isAction: false,
      name: "",
      email: "",
      companyName: "",
      designation: "",
    });
    setDescription("")
    setErrors({})
    
  };
  // console.log(formData)
  // console.log(props?.agenda)
  // console.log(props?.agendaDetails?.filter((agenda)=>agenda?._id===props?.agenda?._id)[0])
  return (
    <>
      <Modal
        size="lg"
        show={props.isAddMinuteModal}
        onHide={(e) => props.setIsAddMinuteModal(false)}
        backdrop="static"
      >
        <div className="modal-header attendees-modal">
          <div>
            <h4 className="modal-title">Add Minute {props?.agendaDetails?.filter((agenda)=>agenda?._id===props?.agenda?._id)[0]?.minutesDetail?.length+1}</h4>
          </div>
          <div className="d-flex gap-4 action-right-option">
          <div className="form-group">
                  <div className=" mt-1 mb-1 d-flex justify-content-between align-items-center ">
                    {/* <label></label> */}

                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexCheckDefault"
                        name="isAction"
                        onChange={checkHandler}
                        checked={formData.isAction}
                      />
                      <label
                        className="form-check-label "
                        id="flexCheckDefault"
                        for="flexCheckDefault"
                      >
                       Add Action
                      </label>
                    </div>
                  </div>
                </div>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={(e) => props.setIsAddMinuteModal(false)}
            ></button>
          </div>
        </div>

        <Modal.Body className="attendees-popup modal-margin">
          <div className="open-div">
            <form className="addminutesboxfrom">
              <div
                className={
                  props.isMinuteShow && props.index === props.currentIndex
                    ? "minutes-box no-border show"
                    : !props.isMinuteShow && props.index === props.currentIndex
                      ? "minutes-box no-border"
                      : "minutes-box no-border"
                }
              >
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-12">
                      <label className="mb-1">Minutes Title  <span className="star-mark">*</span></label>
                     
                    </div>
                    <div className="col-md-12">
                      <input
                        type="text"
                        placeholder="Enter minutes title"
                        name="title"
                        // value={agenda.title}
                        value={formData.title}
                        // readonly={true}
                        autoComplete="off"
                        onBlur={validateMinuteTitle}
                        onChange={handleChange}
                      />
                      {errors.title ? (
                        <span className="error-message">{errors.title}</span>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-md-12">
                      <label className="mb-1">Description  <span className="star-mark">*</span></label>
                     
                    </div>
                    <div className="col-md-82">
                      <ReactQuill
                      className="description-box"
                        theme="snow"
                        placeholder="Please add description"
                        onChange={handleValue}
                        value={description}
                        modules={modules}
                        formats={formats}
                        style={{ background: "white" }} // Adjust height here
                        //onChange={handleChange}
                        onBlur={validateMinuteDescription}
                           className="custom-quill-editor"
                      />
                      {description ? (
                        <p className="success-message">
                          {getStringLengthAfterHTMLParse(description)}{" "}
                          Characters
                        </p>
                      ) : null}

                      {errors.description ? (
                        <span className="error-message">
                          {errors.description}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>

 

                <div className="add-action">
                  {formData.isAction ? (
                    <>
                      <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                          <div className="form-group">
                            <div className="position-relative">
                              <label className="pb-2 input-date">
                                {" "}
                                Due Date
                              </label>
                              {/* <input
                                type="date"
                                name="date"
                                value={formData.date}
                                onChange={handleChange}
                                onBlur={dateFieldValidationCheck}
                              /> */}
                              <div
                    className="relative"
                    onClick={() => document.getElementById("dateInput").showPicker()}
                  >
                    <input
                      type="date"
                      name="date"
                      id = "dateInput"
                      value={formData.date}
                      onChange={handleChange}
                      onBlur={dateFieldValidationCheck}
                    />
                  </div>
                            </div>
                            {errors.date && (
                              <span className="error-message">
                                {errors.date}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                          <div className="form-group">
                            <div className="position-relative">
                              <label className="pb-2 input-date">
                                Priority
                              </label>
                              <div>
                                <select
                                  name="priority"
                                  id="priority"
                                  onChange={handleChange}
                                >
                                  {minuteData.priority &&
                                    minuteData?.isAction === true ? (
                                    <option value={minuteData.priority}>
                                      {minuteData.priority
                                        .charAt(0)
                                        .toUpperCase() +
                                        minuteData.priority
                                          .slice(1)
                                          .toLowerCase()}
                                    </option>
                                  ) : (
                                    <option value="">Select Priority</option>
                                  )}

                                  <option value="HIGH">High</option>
                                  <option value="NORMAL">Normal</option>
                                  <option value="LOW">Low</option>
                                </select>
                              </div>
                            </div>
                            {errors.priority ? (
                              <span className="error-message">
                                {errors.priority}
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="form-group pb-3 border-bottom">
                        <label className="pb-2">
                          Select Responsible Person
                        </label>

                        <div className="w-100">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="attendyType"
                              value="fromCurrentMeeting"
                              id="flexRadioDefault1"
                              onChange={handleChange}
                              checked={
                                formData.attendyType === "fromCurrentMeeting"
                              }
                            />
                            <label
                              className="mb-2 form-check-label"
                              for="flexRadioDefault1"
                              id="flexRadioDefault1"
                            >
                              Select From Current Meetings
                            </label>
                          </div>

                          <div className="form-check-inline">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="attendyType"
                                value="fromEmployeeList"
                                id="flexRadioDefault2"
                                onChange={handleChange}
                                checked={
                                  formData.attendyType === "fromEmployeeList"
                                }
                              />
                              <label
                                className=" mb-2 form-check-label"
                                for="flexRadioDefault2"
                                id="flexRadioDefault2"
                              >
                                Select From Employees
                              </label>
                            </div>
                          </div>

                          <div className="form-check-inline">
                            <div className="form-check">
                              <input
                                className="form-check-input  z-index-high"
                                type="radio"
                                name="attendyType"
                                value="addNewPeople"
                                id="flexRadioDefault3"
                                checked={
                                  formData.attendyType === "addNewPeople"
                                }
                                onChange={handleChange}
                              />
                              <label
                                className=" mb-2 form-check-label"
                                for="flexRadioDefault3"
                                id="flexRadioDefault3"
                              >
                                Add New Guest
                              </label>
                            </div>
                          </div>
                        </div>

                        {formData.attendyType === "fromCurrentMeeting" ? (
                          <Select
                            className="fltr-opt"
                            placeholder="Name / Email Address"
                            name="responsiblePersonId"
                            options={attendeeOptions}
                            onChange={handleAttendeeChange}
                            value={selectedAttendeeOption}
                          />
                        ) : formData.attendyType === "fromEmployeeList" ? (
                          <Select
                            className="fltr-opt"
                            placeholder="Name / Employee ID"
                            name="responsiblePersonId"
                            options={employeeOptions}
                            value={selectedEmployeeOption}
                            onChange={handleEmployeeChange}
                          />
                        ) : formData.attendyType === "addNewPeople" ? (
                          <div>
                            <label className="">Add New Guest</label>
                            <div className="row">
                              <div className="col-xl-6 col-md-6 mt-2">
                                <input
                                  type="text"
                                  autoComplete="off"
                                  placeholder="*Name"
                                  name="name"
                                  value={formData.name}
                                  onChange={handleChange}
                                  onBlur={nameFieldValidationCheck}
                                />
                                {errors.name && (
                                  <span className="error-message">
                                    {errors.name}
                                  </span>
                                )}
                              </div>
                              <div className="col-xl-6 col-md-6 mt-2">
                                <input
                                  type="text"
                                  placeholder="*Email"
                                  autoComplete="off"
                                  name="email"
                                  value={formData.email}
                                  onBlur={emailFieldValidationCheck}
                                  onChange={handleChange}
                                />
                                {errors.email && (
                                  <span className="error-message">
                                    {errors.email}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xl-6 col-md-6 mt-2">
                                <input
                                  type="text"
                                  autoComplete="off"
                                  placeholder="Designation"
                                  name="designation"
                                  value={formData.designation}
                                  onChange={handleChange}
                                // onBlur={designationFieldValidationCheck}
                                />
                                {errors.designation && (
                                  <span className="error-message">
                                    {errors.designation}
                                  </span>
                                )}
                              </div>

                              <div className="col-xl-6 col-md-6 mt-2">
                                <input
                                  type="text"
                                  autoComplete="off"
                                  placeholder="Company Name"
                                  name="companyName"
                                  value={formData.companyName}
                                  onChange={handleChange}
                                // onBlur={companyNameFieldValidationCheck}
                                />
                                {errors.companyName && (
                                  <span className="error-message">
                                    {errors.companyName}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {errors.responsiblePersonId && (
                          <span className="error-message">
                            {errors.responsiblePersonId}
                          </span>
                        )}
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      
        <Modal.Footer>
          <Button
            variant=""
          //  onClick={(e) => props.setIsAddMinuteModal(false)}
          onClick={resetModal}
            className="add-btn Mom-btn"
            type="button"
          >
            <p> Cancel</p>
          </Button>

          {!minuteDatas.loading ? (
            <Button
              className="create-meeting-button Mom-btn"
              variant=""
              onClick={onAddMinute}
            >
              <p> Submit</p>
            </Button>
          ) : (
            <div className="meeting-page " style={{ textAlign: "center" }}>
              <LoaderButton />
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddMinuteModal;
