import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as constantMessages from "../../constants/constatntMessages";
import Modal from "react-bootstrap/Modal";
import { customName, formatDateTimeFormat } from "../../helpers/commonHelpers";
import {
  getAgendaWithMinutesDetails,
  updateAttendanceAndTimeRanges,
} from "../../redux/actions/meetingActions/MeetingAction";

const AttendanceModal = (props) => {
  const { attendees, meetingData } = props;
  const attendanceDetails = meetingData.attendanceDetails;
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    fromTime: "",
    toTime: "",
  });
  const [attendeesArray, setAttendeesArray] = useState(attendees);
  const dispatch = useDispatch();
  const meetingDetails = useSelector((state) => state.meeting);
  const employeeData = useSelector((state) => state.user);
  const accessToken = localStorage.getItem("accessToken");

  const submitAttendance = (isSendNotification) => {
    const newErrors = validateForm(formData);

    setErrors(newErrors);
    if (
      Object.keys(newErrors).length === 0 &&
      !attendeesArray.find((item) => item.fromTimeError) &&
      !attendeesArray.find((item) => item.toTimeError)
    ) {
      console.log("attendeesArray----------", attendeesArray);
      const payload = {
        isSendNotification,
        fromTime: formData.fromTime,
        toTime: formData.toTime,
        attendanceData: attendeesArray
          .map((item) => {
            const itemToTimeArray = item.toTime.split(":");
            const itemToTimeHour = itemToTimeArray[0];
            const itemToTimeMinute = itemToTimeArray[1];
            const toTimeArray = formData.toTime.split(":");
            const toTimeHour = toTimeArray[0];
            const toTimeMinute = toTimeArray[1];

            if (item.attendanceType === "FULL") {
              item["toTime"] = formData.toTime;
            } else {
              if (toTimeHour < itemToTimeHour) {
                //  alert(value);
                item["toTime"] = formData.toTime;
              } else if (
                toTimeHour === itemToTimeHour &&
                toTimeMinute < itemToTimeMinute
              ) {
                // alert("innn2");
                item["toTime"] = formData.toTime;
              }
            }

            const itemFromTimeArray = item.fromTime.split(":");
            const itemFromTimeHour = itemFromTimeArray[0];
            const itemFromTimeMinute = itemFromTimeArray[1];
            const fromTimeArray = formData.fromTime.split(":");
            const fromTimeHour = fromTimeArray[0];
            const fromTimeMinute = fromTimeArray[1];
            if (item.attendanceType === "FULL") {
              item["fromTime"] = formData.fromTime;
            } else {
              if (itemFromTimeHour < fromTimeHour) {
                //  alert(value);
                item["fromTime"] = formData.fromTime;
              } else if (
                fromTimeHour === itemFromTimeHour &&
                fromTimeMinute > itemFromTimeMinute
              ) {
                // alert("innn2");
                item["fromTime"] = formData.fromTime;
              }
            }
            item.attendanceType =
              item.attendanceType === "FULL"
                ? "FULL"
                : formData.fromTime === item.fromTime &&
                  formData.toTime === item.toTime
                ? "FULL"
                : "PARTIAL";
            return item;
          })
          .map(
            ({ isEmployee, canWriteMOM, rsvp, empId, ...keepAttrs }) =>
              keepAttrs
          ),
        attendees: attendeesArray.map(
          ({
            name,
            email,
            isEmployee,
            fromTime,
            toTime,
            attendanceType,
            empId,
            ...keepAttrs
          }) => keepAttrs
        ),
      };
      console.log(payload?.attendanceData);
      console.log("attendeesArray----------", attendeesArray);
      dispatch(
        updateAttendanceAndTimeRanges(meetingData._id, payload, accessToken)
      );
    }
  };

  const validateForm = (data) => {
    console.error(data);
    const errors = {};

    if (!data.fromTime.trim()) {
      errors.fromTime = constantMessages.timeRequired;
    } else if (formData.toTime.trim()) {
      const fromTimeArray = formData.fromTime.split(":");
      const fromTimeHour = fromTimeArray[0];
      const fromTimeMinute = fromTimeArray[1];
      const toTimeArray = formData.toTime.split(":");
      const toTimeHour = toTimeArray[0];
      const toTimeMinute = toTimeArray[1];

      if (fromTimeHour > toTimeHour) {
        errors.fromTime = constantMessages.invalidFromHour;
      } else if (fromTimeHour === toTimeHour && fromTimeMinute > toTimeMinute) {
        errors.fromTime = constantMessages.invalidFromMinute;
      }
    }
    return errors;
  };

  useEffect(() => {
    setFormData({
      ...formData,
      fromTime: meetingData?.updatedFromTime
        ? meetingData?.updatedFromTime
        : meetingData.actualFromTime,
      toTime: meetingData?.updatedToTime
        ? meetingData?.updatedToTime
        : meetingData.actualToTime,
    });
    if (meetingDetails.isAttendanceStatus && accessToken) {
      dispatch(getAgendaWithMinutesDetails(meetingData._id, accessToken));
      props.SetIsAttendanceModalOpen(false);
    }
  }, [meetingDetails.isAttendanceStatus, meetingDetails.loading]);

  useEffect(() => {
    let finalArray = [];
    props.attendees.map((attendee) => {
      const attendanceData = attendanceDetails.find(
        (item) => item.attendeeId.toString() === attendee._id.toString()
      );
      if (attendanceData) {
        finalArray.push({
          name: attendee.name,
          email: attendee.email,
          fromTime: attendanceData.fromTime,
          toTime: attendanceData.toTime,
          attendanceType: attendanceData.attendanceType,
          _id: attendee._id,
          isAttended: attendee.isAttended,
          canWriteMOM: attendee.canWriteMOM,
          rsvp: attendee.rsvp,
          isEmployee: attendee.isEmployee,
          empId: attendee.empId,
        });
      } else {
        finalArray.push({
          _id: attendee._id,
          name: attendee.name,
          email: attendee.email,
          fromTime: meetingData.actualFromTime,
          toTime: meetingData.actualToTime,
          attendanceType: "FULL",
          isAttended: attendee.isAttended,
          canWriteMOM: attendee.canWriteMOM,
          rsvp: attendee.rsvp,
          isEmployee: attendee.isEmployee,
          empId: attendee.empId,
        });
      }
    });

    setAttendeesArray(finalArray);
  }, [props.attendees]);

  const handleChange = (e) => {
    setErrors({});

    const { name, value } = e.target;
    //  alert(value);
    setFormData({
      ...formData,
      [name]: value,
    });
    const updatedArray = attendeesArray.map((item) => {
      if (item.isAttended === false) {
        item[name] = value;
      }

      return item;
    });
    setAttendeesArray(updatedArray);
  };
  console.log("attendeesArray----------", attendeesArray);
  const fromDateFieldValidationCheck = (e) => {
    const errors = {};
    if (!formData.fromTime.trim()) {
      errors.fromTime = constantMessages.timeRequired;
    } else if (formData.toTime.trim()) {
      const fromTimeArray = formData.fromTime.split(":");
      const fromTimeHour = fromTimeArray[0];
      const fromTimeMinute = fromTimeArray[1];
      const toTimeArray = formData.toTime.split(":");
      const toTimeHour = toTimeArray[0];
      const toTimeMinute = toTimeArray[1];

      if (fromTimeHour > toTimeHour) {
        errors.fromTime = constantMessages.invalidFromHour;
      } else if (
        fromTimeHour === toTimeHour &&
        fromTimeMinute >= toTimeMinute
      ) {
        errors.fromTime = constantMessages.invalidFromMinute;
      }
    }
    setErrors(errors);
  };

  const toDateFieldValidationCheck = (e) => {
    const errors = {};
    if (!formData.toTime.trim()) {
      errors.toTime = constantMessages.timeRequired;
    } else if (formData.fromTime.trim()) {
      const fromTimeArray = formData.fromTime.split(":");
      const fromTimeHour = fromTimeArray[0];
      const fromTimeMinute = fromTimeArray[1];
      const toTimeArray = formData.toTime.split(":");
      const toTimeHour = toTimeArray[0];
      const toTimeMinute = toTimeArray[1];

      if (fromTimeHour > toTimeHour) {
        errors.toTime = constantMessages.invalidToHour;
      } else if (
        fromTimeHour === toTimeHour &&
        fromTimeMinute >= toTimeMinute
      ) {
        errors.toTime = constantMessages.invalidToMinute;
      }
    }
    setErrors(errors);
  };

  const checkHandler = (e, attendeeId, attendee) => {
    const { checked } = e.target;

    if (attendeeId) {
      const modifiedAttendees = attendeesArray.map((obj) => {
        if (obj._id === attendeeId) {
          return { ...obj, isAttended: checked };
        }
        return obj;
      });

      setAttendeesArray(modifiedAttendees);
    }
  };

  const checkHandlerAll = (e) => {
    const { checked } = e.target;

    const modifiedAttendees = attendeesArray.map((obj) => {
      return { ...obj, isAttended: checked };
    });

    setAttendeesArray(modifiedAttendees);
  };

  const setAddendanceStatus = (e, attendeeId) => {
    const { name, value } = e.target;

    let updatedArray = [];
    if (value === "FULL") {
      updatedArray = attendeesArray.map((item) => {
        if (item._id.toString() === attendeeId) {
          item.fromTime = formData.fromTime;
          item.toTime = formData.toTime;
          item.attendanceType = "FULL";
        }
        return item;
      });
    } else {
      updatedArray = attendeesArray.map((item) => {
        if (item._id.toString() === attendeeId) {
          item[name] = value;
        }
        return item;
      });
    }

    setAttendeesArray(updatedArray);
  };

  const handleTimeChange = (e, attendeeId) => {
    const { name, value } = e.target;

    let updatedArray = [];

    if (name === "fromTime") {
      if (
        name === "fromTime" &&
        (parseInt(formData.fromTime.split(":")[0]) >
          parseInt(value.split(":")[0]) ||
          parseInt(formData.toTime.split(":")[0]) <
            parseInt(value.split(":")[0]) ||
          (parseInt(formData.fromTime.split(":")[0]) ===
            parseInt(value.split(":")[0]) &&
            parseInt(formData.fromTime.split(":")[1]) >
              parseInt(value.split(":")[1])) ||
          (parseInt(formData.toTime.split(":")[0]) ===
            parseInt(value.split(":")[0]) &&
            parseInt(formData.toTime.split(":")[1]) <
              parseInt(value.split(":")[1])))
      ) {
        updatedArray = attendeesArray.map((item) => {
          if (item._id.toString() === attendeeId) {
            item[name] = value;
            item["fromTimeError"] =
              "Time should be greater than or equals to meeting from time & less than to time";
          }
          return item;
        });
      } else {
        updatedArray = attendeesArray.map((item) => {
          if (item._id.toString() === attendeeId) {
            item[name] = value;
            if (item.fromTimeError) {
              delete item.fromTimeError;
            }
          }
          return item;
        });
      }
    }

    if (name === "toTime") {
      if (
        (name === "toTime" &&
          (parseInt(formData.toTime.split(":")[0]) <
            parseInt(value.split(":")[0]) ||
            parseInt(formData.fromTime.split(":")[0]) >
              parseInt(value.split(":")[0]) ||
            (parseInt(formData.toTime.split(":")[0]) ===
              parseInt(value.split(":")[0]) &&
              parseInt(formData.toTime.split(":")[1]) <
                parseInt(value.split(":")[1])) ||
            (parseInt(formData.toTime.split(":")[0]) ===
              parseInt(value.split(":")[0]) &&
              parseInt(formData.toTime.split(":")[1]) <
                parseInt(value.split(":")[1])))) ||
        (parseInt(formData.fromTime.split(":")[0]) ===
          parseInt(value.split(":")[0]) &&
          parseInt(formData.fromTime.split(":")[1]) >
            parseInt(value.split(":")[1]))
      ) {
        updatedArray = attendeesArray.map((item) => {
          if (item._id.toString() === attendeeId) {
            item[name] = value;
            if (item.fromTimeError) {
              item["fromTimeError"] = item.fromTimeError;
            }
            item["toTimeError"] =
              "Time should be less than or equals to meeting to time & greater than from time";
          }
          return item;
        });
      } else {
        updatedArray = attendeesArray.map((item) => {
          if (item._id.toString() === attendeeId) {
            item[name] = value;
            if (item.toTimeError) {
              delete item.toTimeError;
            }
          }
          return item;
        });
      }
    }

    setAttendeesArray(updatedArray);
  };

  return (
    <>
      <Modal
        dialogClassName="my-modal"
        show={props.IsAttendanceModalOpen}
        onHide={(e) => props.SetIsAttendanceModalOpen(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Attendance & Time</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-height">
          <>
            <div className="mb-3">
              <div className="row">
                <div className="form-group">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                      <label className="mb-1">Meeting Date & Time</label>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                      <p>
                        {formatDateTimeFormat(meetingData?.date).formattedDate},{" "}
                        {meetingData?.fromTime} to {meetingData?.toTime}{" "}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="position-relative resp-input-margin-bottom">
                    <label className="mb-1"> Actual Meeting From Time</label>
                    <span className="star-mark">*</span>
                    <input
                      type="time"
                      className="input-time"
                      name="fromTime"
                      value={formData.fromTime}
                      onChange={handleChange}
                      onBlur={fromDateFieldValidationCheck}
                    />
                  </div>
                  {errors.fromTime && (
                    <span className="error-message">{errors.fromTime}</span>
                  )}
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="position-relative ">
                    <label className="mb-1">Actual Meeting To Time</label>
                    <span className="star-mark">*</span>
                    <input
                      type="time"
                      className="input-time2"
                      name="toTime"
                      value={formData.toTime}
                      onChange={handleChange}
                      onBlur={toDateFieldValidationCheck}
                    />
                  </div>
                  {errors.toTime && (
                    <span className="error-message">{errors.toTime}</span>
                  )}
                </div>
              </div>
            </div>
          </>
          <div className="notifyaction-modal-checkattendee-left d-flex gap-2 align-items-center">
            <input
              type="checkbox"
              name="selectedAttendee"
              id=""
              className="form-check-input"
              onChange={(e) => checkHandlerAll(e)}
              checked={
                attendeesArray?.filter(
                  (attendee) => attendee?.isAttended === false
                )?.length === 0
                  ? true
                  : false
              }
            />

            <p> Select All</p>
          </div>
          <div className="attendees-popup gap-in-field">
            {attendeesArray &&
              attendeesArray.map((attendee, index) => {
                return (
                  <>
                    {/* --------------------------------------------------- */}
                    <div className="modal-attendance">
                      <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <div
                            className="attendee-content margin-bottom-16"
                            key={index}
                          >
                            <div className="check-attendee">
                              <input
                                type="checkbox"
                                name="attendaceId"
                                id=""
                                className="form-check-input"
                                onChange={(e) =>
                                  checkHandler(e, attendee._id, attendee)
                                }
                                checked={attendee.isAttended}
                              />

                              <div className="attendee1 attendee-list sl">
                                {" "}
                                {customName(attendee.name)}
                              </div>
                            </div>

                            <div className="action-cmnt-text">
                              <p className="detail-name word-break">
                                {" "}
                                {attendee?._id?.toString() ===
                                props?.meetingData?.createdById?.toString()
                                  ? attendee.name + " (Organiser)"
                                  : attendee?._id?.toString() ===
                                      employeeData.userData._id.toString() &&
                                    employeeData.userData.isAdmin === true
                                  ? attendee.name + " (Admin)"
                                  : attendee.name}
                              </p>

                              {attendee?._id?.toString() ===
                                props?.meetingData?.createdById?.toString() &&
                              employeeData.userData.isAdmin === true ? (
                                <p className="name-undertext comment-text detail-date-time  word-break">
                                  {attendee.email}
                                </p>
                              ) : (
                                <p className="name-undertext comment-text detail-date-time  word-break">
                                  {attendee.email}{" "}
                                  {attendee.empId
                                    ? " (" + attendee.empId + ") "
                                    : " (Guest)"}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <label className="mb-1">Attendance Type</label>
                          <select
                            aria-label="Default select example"
                            name="attendanceType"
                            className="form-select"
                            onChange={(e) =>
                              setAddendanceStatus(e, attendee._id)
                            }
                            value={attendee.attendanceType}
                          >
                            <option value="FULL">Full</option>
                            <option value="PARTIAL">Partial</option>
                          </select>
                        </div>
                      </div>

                      <div className="row mt-3">
                        {attendee.attendanceType === "PARTIAL" ? (
                          <>
                            <div className="col-md-6 col-sm-6 col-6">
                              <label className="mb-1">From Time</label>
                              <span className="star-mark">*</span>
                              <input
                                type="time"
                                className="input-time2"
                                name="fromTime"
                                value={attendee.fromTime}
                                onChange={(e) =>
                                  handleTimeChange(e, attendee._id)
                                }
                              />
                              {attendee.fromTimeError && (
                                <span className="error-message">
                                  {attendee.fromTimeError}
                                </span>
                              )}
                            </div>
                            <div className="col-md-6 col-sm-6 col-6">
                              <label className="mb-1">To Time</label>
                              <span className="star-mark">*</span>

                              <input
                                type="time"
                                className="input-time2"
                                name="toTime"
                                value={attendee.toTime}
                                onChange={(e) =>
                                  handleTimeChange(e, attendee._id)
                                }
                              />
                              {attendee.toTimeError && (
                                <span className="error-message">
                                  {attendee.toTimeError}
                                </span>
                              )}
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>

                    {/* --------------------------------------------------- */}
                  </>
                );
              })}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            // className="create-meeting-button Mom-btn "
            className="add-minutes Mom-btn"
            onClick={() => submitAttendance(false)}
          >
            <p>Submit</p>
          </button>

          {/* <button
            className="add-minutes Mom-btn"
            onClick={() => submitAttendance(true)}
          >
            <p>Update & Notify</p>
          </button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AttendanceModal;
