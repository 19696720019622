import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Select from "react-select";
import * as constantMessages from "../../constants/constatntMessages";
import {
  Button,
  Popover,
  OverlayTrigger,
  Accordion,
  Tooltip,
} from "react-bootstrap";
import {
  checkEmptyString,
  convertFirstLetterOfFullNameToCapital,
  convertFirstLetterToCapital,
  formatDateTimeFormat,
} from "../../helpers/commonHelpers";

import LoaderButton from "../Common/LoaderButton";
import { sendActionNotification } from "../../redux/actions/actionAction/ActionAction";

const ActionNotifyModal = (props) => {
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("accessToken");
  console.log(props);
  const { agendaData, meetingData } = props;
  let actionArray = [];
  agendaData?.map((agenda) => {
    const onlyActions = agenda?.minutesDetail?.filter(
      (item) => item.isActive === true && item.isAction === true
    );
    console.log(onlyActions);
    if (onlyActions?.length !== 0) {
      actionArray = [...actionArray, ...onlyActions];
    }
  });
  console.log(actionArray);
  const [selectedOption, setSelectedOption] = useState([
    { label: "Select Attendee(s)", value: "" },
  ]);

  const [actionsFinalArray, setActionsFinalArray] = useState([]);

  const actionData = useSelector((state) => state.action);
  useEffect(() => {
    if (actionData?.isSuccess === true && actionData?.updateLoading === false) {
      props.setIsActionsForNotifyModal(false);
      setActionsFinalArray([]);
    }
    
    return () => {
      // Anything in here is fired on component unmount.
    };
  }, [actionData?.isSuccess]);

  const handleSubmit = () => {
    if (actionsFinalArray.length === 0) {
      toast.warning(constantMessages.selectAction, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      console.log(actionsFinalArray);
      const payload = {
        meetingId: meetingData?.meetingDetails?._id,
        actionIds: actionsFinalArray,
      };
      console.log(payload);
      dispatch(sendActionNotification(payload, accessToken));
    }
  };

  const checkHandler = (e, actionId) => {
    if (actionId === "all") {
      setSelectedOption([{ label: "Select Attendee(s)", value: "" }]);
      if (actionArray.length === actionsFinalArray.length) {
        setActionsFinalArray([]);
      } else {
        const modifiedActions = actionArray.map((obj) => {
          return obj._id;
        });
        setActionsFinalArray([...modifiedActions]);
      }
    } else {
      if (actionId) {
        if (actionsFinalArray.includes(actionId)) {
          const modifiedActions = actionsFinalArray.filter(
            (item) => item !== actionId
          );
          setActionsFinalArray([...modifiedActions]);
        } else {
          setActionsFinalArray([...actionsFinalArray, actionId]);
        }
      }
    }
  };

  const popoverHoverFocus = (title) => {
    return (
      <Popover id="popover-basic" className="custom-popover">
        {/* <Popover.Header as="h3" className="popover-header">
          Meeting Title.
        </Popover.Header> */}
        <Popover.Body className="popover-body">{title}</Popover.Body>
      </Popover>
    );
  };
  let attendeesOptions = null;

  let attendeesArray =
    agendaData?.flatMap((agenda) =>
      agenda?.minutesDetail
        ?.filter((minute) => minute?.isActive && minute?.isAction)
        .map((action) => action?.assignedUserDetails)
    ) || [];
  // Remove duplicates based on a unique property (e.g., user ID)
  attendeesArray = [
    ...new Map(attendeesArray.map((user) => [user._id, user])).values(),
  ];
  console.log(attendeesArray);

  if (attendeesArray?.length !== 0) {
    attendeesOptions = attendeesArray?.map((attendee) => {
      const returnData = {
        value: attendee._id,
        label: attendee.name,
        name: "attendeeId",
      };
      return returnData;
    });
  }

  const handleAttendeesChange = (e) => {
    const { value, label } = e; // Extract relevant values

    // Extract action IDs where assignedUserId matches selected attendee ID
    const assignedUserSelectedActionIds =
      actionArray
        ?.filter(({ assignedUserId }) => assignedUserId === value)
        .map(({ _id }) => _id) ?? [];

    console.log(assignedUserSelectedActionIds); // Debugging output

    // Update state variables
    setActionsFinalArray(assignedUserSelectedActionIds);
    setSelectedOption(e);
  };

  console.log(actionsFinalArray);

  const handleReset = (e) => {
    e.preventDefault(e);
    setSelectedOption([{ label: "Select Attendee(s)", value: "" }]);
    setActionsFinalArray([]);
  };
  return (
    <Modal
      show={props.IsActionsForNotifyModal}
      onHide={(e) => props.setIsActionsForNotifyModal(false)}
      size="lg"
      backdrop="static"
    >
      <Modal.Dialog>
        <div className="modal-header attendees-modal">
          <div>
            <h4 className="modal-title">Notify Actions</h4>
          </div>
          <div>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={(e) =>{
                props.setIsActionsForNotifyModal(false)
                setActionsFinalArray([])
                setSelectedOption([{ label: "Select Attendee(s)", value: "" }]);
              } }
            ></button>
          </div>
        </div>

        <Modal.Body className="attendees-popup modal-margin notify-modal-popup">
          <div className="check-attendee justify-content-between notify-modal">
            <div className="notifyaction-modal-checkattendee-left d-flex gap-2 align-items-center">
              <input
                type="checkbox"
                name="selectedAttendee"
                id=""
                className="form-check-input"
                onChange={(e) => checkHandler(e, "all")}
                checked={
                  actionsFinalArray?.length === actionArray?.length
                    ? true
                    : false
                }
              />

              <p> Select All</p>
            </div>

            <div className="notifyaction-modal-checkattendee-right d-flex gap-2 align-items-center">
              <label>Attendee(s)</label>

              <Select
                className="fltr-opt"
                placeholder="Select Attendees"
                name="attendeeId"
                options={attendeesOptions ? attendeesOptions : null}
                onChange={handleAttendeesChange}
                value={selectedOption}
              />
            </div>
          </div>
          {agendaData?.length !== 0 &&
            agendaData?.map((agenda, index) => {
              return (
                <Accordion>
                  <div className="mt-3 agenda-box-border">
                    <Accordion.Item
                      eventKey={index.toString()}
                      className="agenda-accordion"
                    >
                      <Accordion.Header>
                        Agenda {index + 1}:&nbsp;
                        {agenda.title?.length > 50 ? (
                          <OverlayTrigger
                            trigger={["hover", "focus"]}
                            placement="bottom"
                            overlay={popoverHoverFocus(agenda.title)}
                          >
                            <span className="meeting-title-hover">
                              {`${agenda.title.slice(0, 50)}....`}
                            </span>
                          </OverlayTrigger>
                        ) : (
                          <span className="meeting-title-hover">
                            {agenda.title}
                          </span>
                        )}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                <label className="mt-1 mb-1">
                                  Agenda Title
                                </label>
                              </div>
                              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                <p> {agenda.title}</p>
                              </div>
                            </div>
                          </div>

                          {agenda?.minutesDetail?.filter(
                            (item) => item.isActive === true
                          ).length !== 0 ? (
                            <>
                              <div></div>
                              <div className=" minutes-border"></div>

                              {agenda?.minutesDetail?.filter(
                                (item) => item.isActive === true
                              ).length !== 0 &&
                                agenda?.minutesDetail
                                  ?.filter(
                                    (item) =>
                                      item.isActive === true &&
                                      item.isAction === true
                                  )
                                  .map((minute, index) => {
                                    return (
                                      <>
                                        <div className="notify-action-modal-body d-flex gap-2">
                                          <div className="check-attendee">
                                            <input
                                              type="checkbox"
                                              name="selectedAttendee"
                                              id=""
                                              className="form-check-input"
                                              onChange={(e) =>
                                                checkHandler(e, minute._id)
                                              }
                                              checked={
                                                actionsFinalArray.includes(
                                                  minute?._id?.toString()
                                                )
                                                  ? true
                                                  : false
                                              }
                                            />
                                          </div>
                                          <div className="agenda-minutes">
                                            <div className=" form-group ">
                                              <div className="row">
                                                <div className="col-md-4">
                                                  <label className="mt-1 mb-1">
                                                    Action {index + 1}
                                                  </label>
                                                </div>
                                                <div className="col-md-6">
                                                  <p> {minute?.title}</p>
                                                </div>
                                              </div>
                                            </div>
                                            {/* <div className=" form-group ">
                                                <div className="row">
                                                  <div className="col-md-4">
                                                    <label className="mt-1 mb-1">
                                                      Status
                                                    </label>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <p>
                                                      {minute.status ===
                                                        "DRAFT" ? (
                                                        <span className="badge bg-opacity-10 draft-color">
                                                          {convertFirstLetterToCapital(
                                                            minute.status
                                                          )}
                                                        </span>
                                                      ) : (
                                                        <span className="badge bg-opacity-10 scheduled-color">
                                                          {convertFirstLetterToCapital(
                                                            minute.status
                                                          )}
                                                        </span>
                                                      )}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div> */}

                                            {minute.isAction ? (
                                              <>
                                                {/* <div className=" form-group">
                                                    <div className="row">
                                                      <div className="col-md-4">
                                                        <label className="mt-1 mb-1">
                                                          Action Due Date
                                                        </label>
                                                      </div>
                                                      <div className="col-md-8">
                                                        <p>
                                                          {" "}
                                                          {
                                                            formatDateTimeFormat(
                                                              minute?.mainDueDate
                                                            ).formattedDate
                                                          }
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div> */}

                                                {/* {minute?.parentMinuteId ? (
                                                    <div className="form-group">
                                                      <div className="row">
                                                        <div className="col-md-4">
                                                          <label className="mb-1 mb-1">
                                                            Assigned Due Date
                                                          </label>
                                                        </div>
                                                        <div className="col-md-8">
                                                          <p>
                                                            {" "}
                                                            {
                                                              formatDateTimeFormat(
                                                                minute?.dueDate
                                                              ).formattedDate
                                                            }
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ) : null} */}

                                                {/* <div className=" form-group">
                                                    <div className="row">
                                                      <div className="col-md-4">
                                                        <label className="mt-1 mb-1">
                                                          Priority
                                                        </label>
                                                      </div>
                                                      <div className="col-md-8">
                                                        <p>
                                                          {" "}
                                                          {minute.priority
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                            minute.priority
                                                              .slice(1)
                                                              .toLowerCase()}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div> */}
                                                {minute?.isAction ? (
                                                  <div className=" form-group">
                                                    <div className="row">
                                                      <div className="col-md-4">
                                                        <label className="mt-1 mb-1">
                                                          Responsible Person
                                                        </label>
                                                      </div>
                                                      <div className="col-md-8">
                                                        <p>
                                                          {minute
                                                            ?.assignedUserDetails
                                                            ?.name
                                                            ? convertFirstLetterOfFullNameToCapital(
                                                                minute
                                                                  ?.assignedUserDetails
                                                                  ?.name
                                                              )
                                                            : null}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : null}
                                              </>
                                            ) : null}
                                          </div>
                                        </div>
                                        <div className=" minutes-border"></div>
                                      </>
                                    );
                                  })}
                            </>
                          ) : null}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                </Accordion>
              );
            })}
        </Modal.Body>
      </Modal.Dialog>
      <div className="modal-footer">
        <button className="add-btn Mom-btn" type="reset" onClick={handleReset}>
          <p>Cancel</p>
        </button>

        {actionData?.updateLoading ? (
          <LoaderButton />
        ) : (
          <button
            type="button"
            onClick={handleSubmit}
            className="Mom-btn btn btn-secondary bg-primary border-primary"
          >
            <p>Send</p>
          </button>
        )}
      </div>
    </Modal>
  );
};

export default ActionNotifyModal;
