import React from "react";
import { Link } from "react-router-dom";
import { Plus } from "lucide-react";
import { useDispatch } from "react-redux";
import { setCreateNewMeetingPage } from "../../redux/actions/meetingActions/MeetingAction";

const AddButton = () => {
  const dispatch = useDispatch();

  return (
    <Link
      to="/create-meeting"
      state={{ isNewMeeting: true }}
      style={{ textDecoration: "none" }}
    >
      <button
        className="plus-icon"
        onClick={() => dispatch(setCreateNewMeetingPage(true))}
      >
        <Plus size={32} />
      </button>
    </Link>
  );
};

export default AddButton;

