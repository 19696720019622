import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import LoaderButton from "./LoaderButton";
import { useSelector } from "react-redux";
const CommonModal = (props) => {
  const actionData = useSelector((state) => state.action);
  const handleSubmit = () => {
    props.handleSubmit();
  };
  return (
    <>
      <Modal
        show={props.isModalOpen}
        onHide={(e) => props.setIsModalOpen(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.message}</Modal.Body>
        <Modal.Footer>
          {props.isCalledFromOthers === true ? (
            <>
              {!actionData.updateLoading && props.isCalledFromOthers === true ? (
                <Button
                  variant=""
                  className="add-minutes Mom-btn"
                  onClick={handleSubmit}
                >
                  <p>Yes</p>
                </Button>
              ) : (
                <LoaderButton />
              )}
            </>
          ) : (
            <Button
              variant=""
              className="add-minutes Mom-btn"
              onClick={handleSubmit}
            >
              <p>Yes</p>
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CommonModal;
