import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "aos/dist/aos.css";
import AOS from "aos";
import { Modal, Button, Form } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {
  Link, useNavigate,
  useLocation,
} from "react-router-dom";
import ContactUs from "./ContactUs";
import logo from "../../assets/images/LandingpageImage/MinutesVault-Logo.svg";
import whiteLogo from "../../assets/images/LandingpageImage/NewWiteLogo.png";
import arrowColor from "../../assets/images/LandingpageImage/book-arrow-color.png";
import arrow from "../../assets/images/LandingpageImage/book-arrow.png";
import bannerMeeting from "../../assets/images/LandingpageImage/banner-meeting.png";
import bannerArrow from "../../assets/images/LandingpageImage/book-arrow.png";
import bannerSqrFrame from "../../assets/images/LandingpageImage/banner-sqr-frame.png";
import bgImage from "../../assets/images/LandingpageImage/bg.png";
import bookArrow from "../../assets/images/LandingpageImage/book-arrow.png";
import nalco from "../../assets/images/LandingpageImage/nalco.png";
import balco from "../../assets/images/LandingpageImage/balco-logo.png";
import bankOfBaroda from "../../assets/images/LandingpageImage/Bank_of_Baroda_logo.png";
import hzl from "../../assets/images/LandingpageImage/hzl.png";
import imfa from "../../assets/images/LandingpageImage/imfa.png";
import jindal from "../../assets/images/LandingpageImage/jindal-logo.png";
import konkola from "../../assets/images/LandingpageImage/konkola.png";
import lingarajPipe from "../../assets/images/LandingpageImage/lingaraj-pipe.png";
import nmdc from "../../assets/images/LandingpageImage/nmdc.png";
import ocac from "../../assets/images/LandingpageImage/ocac-logo.png";
import facor from "../../assets/images/LandingpageImage/vedanta_facor-logo.png";
import keyfeaturesBg from "../../assets/images/LandingpageImage/explore-right-bg.png";
import tabImg1 from "../../assets/images/LandingpageImage/Source AI 1.jpg";
import tabImg2 from "../../assets/images/LandingpageImage/Source AI 2.jpg";
import tabImg3 from "../../assets/images/LandingpageImage/Source AI 3.jpg";
import tabImg4 from "../../assets/images/LandingpageImage/Source AI 4.jpg";
import tabImg5 from "../../assets/images/LandingpageImage/Source AI 5.jpg";
import tabImg6 from "../../assets/images/LandingpageImage/Source AI 6.jpg";
import listImg from "../../assets/images/LandingpageImage/icon_checkmark_purple.svg";

import vedanta from "../../assets/images/LandingpageImage/vedanta-logo.png";
import icarda from "../../assets/images/LandingpageImage/icarda.png";
import adityabirla from "../../assets/images/LandingpageImage/adityabirla.png";
import omc from "../../assets/images/LandingpageImage/omc.png";
import trl from "../../assets/images/LandingpageImage/trl.png";
import fbIcon from "../../assets/images/LandingpageImage/fb-icon.svg";
import footerWhite from "../../assets/images/LandingpageImage/footer-white.png";
import efficientlyOrganiseMeeting from "../../assets/images/LandingpageImage/efficiently-organise-meeting.svg";
import meetingbgall from "../../assets/images/LandingpageImage/Meeting-footer.png";

import Greencheck from "../../assets/images/LandingpageImage/check.svg";

import twiter from "../../assets/images/LandingpageImage//twitter-icon.svg";
import linkedIn from "../../assets/images/LandingpageImage/linkedin-icon.svg";
import youtube from "../../assets/images/LandingpageImage/youtube-icon.svg";
import instagram from "../../assets/images/LandingpageImage/instagram-icon2.svg";
import ssIcon1 from "../../assets/images/LandingpageImage/ss-icon1.png";
import createMeetingSs from "../../assets/images/LandingpageImage/create-meeting-ss.png";
import zigzag1 from "../../assets/images/LandingpageImage/zigzag-1.svg";
import improvesMinutes from "../../assets/images/LandingpageImage/improves-minutes.svg";
import enhancesSeamlessCollaboration from "../../assets/images/LandingpageImage/enhances-seamless-collaboration.svg";
import efficientlyOrga from "../../assets/images/LandingpageImage/efficiently-orga.png";
import VectorSmartObject16 from "../../assets/images/LandingpageImage/VectorSmartObject16.svg";
import personKeyfeatures from "../../assets/images/LandingpageImage/person-keyfeatures.png";
import prevIconImg from "../../assets/images/LandingpageImage/prev-icon-img.png";
import nextIconImg from "../../assets/images/LandingpageImage/next-icon-img.png";
import phoneIcon from "../../assets/images/LandingpageImage/phone-icon.svg";
import mailIcon from "../../assets/images/LandingpageImage/mail-icon.svg";
import locationIcon from "../../assets/images/LandingpageImage/location-icon.svg";
import faqBg from "../../assets/images/LandingpageImage/faq-bg.png";
import bg from "../../assets/images/LandingpageImage/bg.png";
import homeShapes from "../../assets/images/LandingpageImage/home-shape.png";
//import minutesVaultVideo from "../../assets/images/LandingpageImage/minutes-vault-video.mp4";
// import "./all.min.css";
import "./animate.min.css";
import "./bootstrap.css";
import "./bootstrap.min.css";
import "./LandingPage.css";
import "./swiper-bundle.min.css";
gsap.registerPlugin(ScrollTrigger);

const LandingPage = () => {
  const [key, setKey] = useState("home");
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  // Footer animation End
  const [sanitizedSvg, setSanitizedSvg] = useState(null);
  const [sanitizedSvgTwo, setSanitizedSvgTwo] = useState(null);
  // Footer animation End
  const navigate = useNavigate();
  const location = useLocation();
  const { currentPath } = location.state || {};

  // Initialize AOS
  AOS.init({
    duration: 1200, // Duration of animations
  });

  const fadeElementsRef = useRef([]);

  const loadSvgFile = async () => {
    try {
      console.log("Insider loading SVG:");
      const response = await fetch(
        "https://demo5.ntsplhosting.com/minutesvault/images/footer-meeting-bg-2.svg"
      );
      if (response.ok) {
        console.log("Success loading SVG:", response);
        const svgText = await response.text();
        setSanitizedSvg(svgText);
      } else {
        console.log("Error loading SVG:", response.status);
      }
    } catch (error) {
      console.log("Error loading SVG:", error);
    }
  };

  const loadSvgFileTwo = async () => {
    try {
      console.log("Insider loading SVG:");
      const response = await fetch(
        "https://demo5.ntsplhosting.com/minutesvault/images/first-features.svg"
      );
      if (response.ok) {
        console.log("Success loading SVG:", response);
        const svgText = await response.text();
        setSanitizedSvgTwo(svgText);
      } else {
        console.log("Error loading SVG:", response.status);
      }
    } catch (error) {
      console.log("Error loading SVG:", error);
    }
  };
  /* Lamp Animation Start */
  useEffect(() => {
    gsap.from(".top-lamp", {
      y: -200,
      opacity: 0,
      duration: 1.5,
      ease: "power2.out",
      delay: 0.5,
    });
  }, []);
  /* Lamp Animation Start */

  /* Mouse Moov Animation End */
  // Refs for the elements we want to move
  const element1Ref = useRef(null);
  const element2Ref = useRef(null);
  const element3Ref = useRef(null);
  const element4Ref = useRef(null);
  const element5Ref = useRef(null);
  const layerRef = useRef(null);

  useEffect(() => {
    const layer = layerRef.current;

    const handleMouseMove = (e) => {
      const ivalueX = (e.pageX * -1) / 30;
      const ivalueY = (e.pageY * -1) / 30;
      const cvalueX = (e.pageX * -1) / 40;
      const cvalueY = (e.pageY * -1) / 60;

      if (element1Ref.current) {
        element1Ref.current.style.transform = `translate3d(${ivalueX}px, ${ivalueY}px, 0)`;
      }
      if (element2Ref.current) {
        element2Ref.current.style.transform = `translate3d(${ivalueX}px, ${ivalueY}px, 0)`;
      }
      if (element3Ref.current) {
        element3Ref.current.style.transform = `translate3d(${cvalueX}px, ${cvalueY}px, 0)`;
      }
      if (element4Ref.current) {
        element4Ref.current.style.transform = `translate3d(${cvalueX}px, ${cvalueY}px, 0)`;
      }
      if (element5Ref.current) {
        element5Ref.current.style.transform = `translate3d(${cvalueX}px, ${cvalueY}px, 0)`;
      }
    };

    if (layer) {
      layer.addEventListener("mousemove", handleMouseMove);
    }

    return () => {
      if (layer) {
        layer.removeEventListener("mousemove", handleMouseMove);
      }
    };
  }, []);

  // -------------------------------

  /* Mouse Moov Animation End */

  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phoneNo: "",
    email: "",
    message: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [otpStatus, setOtpStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otpProcessloading, setOtpProcessloading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [nameError, setNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [otpSendMessage, setOtpSendMessage] = useState(""); // Message for "Get OTP"
  const [otpVerifyMessage, setOtpVerifyMessage] = useState("");
  const [otpVerifiedSuccessMessage, setOtpVerifiedSuccessMessage] =
    useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState(""); // Separate state for OTP
  const [otpVerified, setOtpVerified] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);

  const [isValidEmailEntered, setIsValidEmailEntered] = useState(false);
  const [isOTPVerified, setIsOTPVerified] = useState(false);
  const [otpSuccessMessage, setOTPSuccessMessageVal] = useState("");
  const [otpErrorMessage, setOTPErrorMessageVal] = useState("");
  const [otpError, setOTPError] = useState("");

  const [isVisible, setIsVisible] = useState(false);
  const [verifyOTPSuccessMessage, setVerifyOTPSuccessMessageVal] = useState("");
  const [verifyOTPErrorMessage, setVerifyOTPErrorMessageVal] = useState("");
  const [modalSettings, setModalSettings] = useState({ backdrop: true });

  const topTitleRef = useRef(null);
  const paragraphRef = useRef(null);
  const solutionTextRef = useRef(null);
  const containerRef = useRef(null);

  const [showBookADemoModal, setShowBookADemoModal] = useState(false);
  const [showContactUsModal, setShowContactUsModal] = useState(false);

  const [srollPosition, setScrollPosition] = useState(0);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  useEffect(() => {
    loadSvgFile();
    loadSvgFileTwo();
    if (showModal) {
      setSuccessMessage("");
    }

    if (isSidebarVisible) {
      document.body.classList.add("visible-sidebar");
    } else {
      document.body.classList.remove("visible-sidebar");
    }

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);

      document.body.classList.remove("visible-sidebar");
    };
  }, [showModal]);

  const openBookADemoModal = () => {
    setFormData({ name: "", phoneNo: "", email: "", message: "" });
    setFormErrors({});

    setOtpStatus(false);
    setLoading(false);
    setOtpProcessloading(false);
    setOtpLoading(false);
    setErrors({});
    setNameError("");
    setPhoneError("");
    setEmailError("");
    setMessageError("");

    setOtpSendMessage("");
    setOtpVerifyMessage("");
    setOtpVerifiedSuccessMessage("");
    setOtpSent(false);
    setOtpVerified(false);
    setIsValidEmail(false);

    setIsValidEmailEntered(false);
    setIsOTPVerified(false);
    setOTPSuccessMessageVal("");
    setOTPErrorMessageVal("");

    setIsVisible(false);
    setVerifyOTPSuccessMessageVal("");
    setVerifyOTPErrorMessageVal("");
    setErrorMessage("");
    setSuccessMessage("");

    setShowBookADemoModal(true);
  };

  const closeBookADemoModal = () => {
    setShowBookADemoModal(false);
  };

  const openContactUsModal = () => {
    setShowContactUsModal(true);
  };

  const closeContactUsModal = () => {
    setShowContactUsModal(false);
  };

  const setIsContaUsModalOpen = () => {
    setShowContactUsModal(false);
  };

  const nameValidationCheck = () => {
    const newErrors = {};
    const excludeHtml = /^[0-9a-zA-Z ,&/ -]+$/;

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    } else if (!excludeHtml.test(formData.name)) {
      newErrors.name = "Invalid characters";
    } else if (formData.name.trim().length < 3) {
      newErrors.name = "Minimum 3 characters required";
    } else {
      newErrors.name = "";
    }
    setNameError(newErrors.name);
  };

  const phoneValidationCheck = () => {
    const newErrors = {};
    const phonePattern = /^[0-9]{10}$/;

    if (formData.phoneNo?.trim() === "") {
      newErrors.phoneNo = "Mobile number is required";
    } else if (formData.phoneNo.length > 0 && formData.phoneNo.length < 11) {
      if (!phonePattern.test(formData.phoneNo)) {
        newErrors.phoneNo = "Please enter a valid mobile number";
      } else {
        newErrors.phoneNo = "";
      }
    } else if (formData.phoneNo.length > 10) {
      newErrors.phoneNo = "Maximum 10 digits is allowed";
    } else {
      newErrors.phoneNo = "";
    }

    setPhoneError(newErrors.phoneNo);
  };

  const emailValidationCheck = () => {
    const newErrors = {};

    if (!formData.email.trim()) {
      newErrors.email = "Email is required.";
      setIsValidEmailEntered(false);
    } else if (!emailPattern.test(formData.email)) {
      newErrors.email = "Please enter a valid email address";
      setIsValidEmailEntered(false);
    } else {
      setIsValidEmailEntered(true);
      newErrors.email = "";
    }

    setEmailError(newErrors.email);
  };

  const modalClose = () => {
    setShowModal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "name") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        name: null,
      }));
      const newErrors = {};
      const excludeHtml = /^[0-9a-zA-Z ,&/ -]+$/;

      if (value?.trim() == "") {
        newErrors.name = "Name is required";
      } else if (!excludeHtml.test(value)) {
        newErrors.name = "Invalid characters";
      } else if (value.trim().length < 3) {
        newErrors.name = "Minimum 3 characters required";
      } else {
        newErrors.name = "";
      }

      setNameError(newErrors.name);
    }

    if (name === "phoneNo") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        phoneNo: null,
      }));

      const newErrors = {};
      const phonePattern = /^[0-9]{10}$/;

      if (value?.trim() === "") {
        newErrors.phoneNo = "Mobile number is required";
      } else if (value.length > 0 && value.length < 11) {
        if (!phonePattern.test(value)) {
          newErrors.phoneNo = "Please enter a valid mobile number";
        } else {
          newErrors.phoneNo = "";
        }
      } else if (value.length > 10) {
        newErrors.phoneNo = "Maximum 10 digits is allowed";
      } else {
        newErrors.phoneNo = "";
      }

      setPhoneError(newErrors.phoneNo);
    }

    if (name === "email") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        email: null,
      }));

      const newErrors = {};

      if (value?.trim() === "") {
        newErrors.email = "Email is required";
        setIsValidEmailEntered(false);
      } else if (!emailPattern.test(value)) {
        newErrors.email = "Please enter a valid email address.";
        setIsValidEmailEntered(false);
      } else {
        setIsValidEmailEntered(true);
        newErrors.email = "";
      }

      setEmailError(newErrors.email);
    }
  };

  const handleKeyDown = (e) => {
    const { name, value } = e.target;

    if (name === "phoneNo") {
      if (value.length >= 10 && ![8, 37, 39, 46].includes(e.keyCode)) {
        e.preventDefault();
      }
    }

    if (name === "otp") {
      if (value.length >= 6 && ![8, 37, 39, 46].includes(e.keyCode)) {
        e.preventDefault();
      }
    }
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);

    if (e.target.value.toString().length == 6) {
      setOTPError("");
    }
  };

  const isValidate = () => {
    const errors = {};
    let isValid = false;

    var nameError = false;
    var mobileError = false;
    var emailError = false;

    const excludeHtml = /^[0-9a-zA-Z ,&/ -]+$/;

    if (formData.name?.trim() == "") {
      errors.name = "Name is required";
      nameError = true;
    } else if (!excludeHtml.test(formData.name)) {
      errors.name = "Invalid characters";
      nameError = true;
    } else if (formData.name.trim().length < 3) {
      errors.name = "Minimum 3 characters required";
      nameError = true;
    } else {
      errors.name = "";
      nameError = false;
    }

    const phonePattern = /^[0-9]{10}$/;

    if (formData.phoneNo?.trim() === "") {
      errors.phoneNo = "Mobile number is required";
      mobileError = true;
    } else if (formData.phoneNo.length > 0 && formData.phoneNo.length < 11) {
      if (!phonePattern.test(formData.phoneNo)) {
        errors.phoneNo = "Please enter a valid mobile number";
        mobileError = true;
      } else {
        errors.phoneNo = "";
        mobileError = false;
      }
    } else if (formData.phoneNo.length > 10) {
      errors.phoneNo = "Maximum 10 digits is allowed";
      mobileError = true;
    } else {
      errors.phoneNo = "";
      mobileError = false;
    }

    if (!formData.email.trim()) {
      errors.email = "Email is required";
      emailError = true;
    } else if (!emailPattern.test(formData.email)) {
      errors.email = "Please enter a valid email address";
      emailError = true;
    } else {
      errors.email = "";
      emailError = false;
    }

    setFormErrors(errors);

    if (nameError || mobileError || emailError) {
      isValid = false;
    } else {
      isValid = true;
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");

    if (isValidate()) {
      if (!isOTPVerified) {
        setErrorMessage("Please verify the OTP before submitting");
        return false;
      }

      setLoading(true);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/V1/demo/createDemoClient`,
          formData
        );

        if (response?.data) {
          if (response?.data?.success === true) {
            var message = response.data.message
              ? response.data.message
              : "Thank you for requesting a demo! Our team will reach out to you shortly to schedule the demo at a convenient time.";

            setSuccessMessage(message);
            setTimeout(() => {
              setSuccessMessage("");
              setShowBookADemoModal(false);
            }, 4000);

            setFormData({ name: "", phoneNo: "", email: "", message: "" });
            setFormErrors({});

            setOtpStatus(false);
            setLoading(false);
            setOtpProcessloading(false);
            setOtpLoading(false);
            setErrors({});
            setNameError("");
            setPhoneError("");
            setEmailError("");
            setMessageError("");

            setOtpSendMessage("");
            setOtpVerifyMessage("");
            setOtpVerifiedSuccessMessage("");
            setOtpSent(false);
            setOtpSent(false);
            setOtpVerified(false);
            setIsValidEmail(false);

            setIsValidEmailEntered(false);
            setIsOTPVerified(false);
            setOTPSuccessMessageVal("");
            setOTPErrorMessageVal("");

            setIsVisible(false);
            setVerifyOTPSuccessMessageVal("");
            setVerifyOTPErrorMessageVal("");
          }
          if (response.data?.success === false) {
            var message = response.data.message
              ? response.data.message
              : "Something went wrong. Please try again later.";
            setErrorMessage(message);
            setTimeout(() => {
              setErrorMessage("");
            }, 8000);
          }
        }
      } catch (error) {
        setErrorMessage(error.response?.message || "Submission failed.");
        setTimeout(() => {
          setErrorMessage("");
        }, 8000);
      }
    }
  };

  //Send OTP
  useEffect(() => {
    if (successMessage || errorMessage || otpVerifyMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
        setOtpVerifyMessage("");
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [successMessage, errorMessage, otpVerifyMessage]);

  const sendOtp = async (name, email) => {
    try {
      console.log(process.env.REACT_APP_API_URL);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/V1/demo/demoSendOtp`,
        { name, email, type: "requestdemo" }
      );
      return response.data;
    } catch (error) {
      const message =
        error.response?.data?.message || "An unexpected error occurred.";
      return { error: true, message };
    }
  };

  const verifyOtp = async (email, otp) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/V1/demo/verifyOtp`,
        { email, otp }
      );

      return response.data;
    } catch (error) {
      const message =
        error.response?.data?.message || "An unexpected error occurred.";
      return { success: false, message };
    }
  };

  const handleSendOtp = async () => {
    const newErrors = {};
    const excludeHtml = /^[0-9a-zA-Z ,&/ -]+$/;

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    } else if (!excludeHtml.test(formData.name)) {
      newErrors.name = "Invalid characters";
    } else if (formData.name.trim().length < 3) {
      newErrors.name = "Minimum 3 characters required.";
    } else {
      newErrors.name = "";
    }

    setNameError(newErrors.name);

    if (newErrors.name != "") {
      return;
    }

    setOtpLoading(true);
    setOtpVerifyMessage("");

    setOTPSuccessMessageVal("");
    setOTPErrorMessageVal("");

    const response = await sendOtp(formData.name, formData.email);

    if (response) {
      if (response?.success === true) {
        var message = response.message
          ? response.message
          : "OTP has been sent to the entered email";
        setOtpLoading(false);
        setOtpSent(true);
        setOtp("");
        setOTPSuccessMessageVal(message);
        setTimeout(() => {
          setOTPSuccessMessageVal("");
        }, 8000);
      }
      if (response.success === false) {
        setOtpLoading(false);
        var message = response.message
          ? response.message
          : "Unable to send OTP";
        setOTPErrorMessageVal(message);
        setOtpSent(false);
        setOtp("");
        setTimeout(() => {
          setOTPErrorMessageVal("");
        }, 8000);
      }
    }
  };

  const handleVerifyOtp = async () => {
    var isSafeOtpData = false;
    var isSafeEmailData = false;

    if (!otp) {
      setOTPError("Please enter the OTP.");
      setOtpStatus(false);
      isSafeOtpData = false;
    } else if (otp.toString().length < 6) {
      setOTPError("OTP should be 6 digits.");
      setOtpStatus(false);
      isSafeOtpData = false;
    } else if (otp.toString().length == 6) {
      setOTPError("");
      isSafeOtpData = true;
    }

    const newErrors = {};

    if (formData.email?.trim() === "") {
      newErrors.email = "Email is required.";
      setIsValidEmailEntered(false);
      isSafeEmailData = false;
    } else if (!emailPattern.test(formData.email)) {
      newErrors.email = "Please enter a valid email address.";
      setIsValidEmailEntered(false);
      isSafeEmailData = false;
    } else {
      setIsValidEmailEntered(true);
      newErrors.email = "";
      isSafeEmailData = true;
    }

    setEmailError(newErrors.email);

    if (isSafeOtpData == false || isSafeEmailData == false) {
      return;
    }

    setOtpProcessloading(true);
    setVerifyOTPSuccessMessageVal("");
    setVerifyOTPErrorMessageVal("");
    setVerifyOTPErrorMessageVal("");
    setOTPSuccessMessageVal("");
    setOTPErrorMessageVal("");

    const response = await verifyOtp(formData.email, otp);

    if (response) {
      if (response?.success === true) {
        var message = response.message
          ? response.message
          : "OTP verified successfully";
        setOtpProcessloading(false);
        setIsOTPVerified(true);
        setOtpSent(false);
        setOtp("");
        setOTPError("");
        setVerifyOTPSuccessMessageVal(message);
        setTimeout(() => {
          setVerifyOTPSuccessMessageVal("");
        }, 8000);
      }
      if (response.success === false) {
        setOtpProcessloading(false);
        var message = response.message
          ? response.message
          : "Unable to verify OTP";
        setVerifyOTPErrorMessageVal(message);
        setTimeout(() => {
          setVerifyOTPErrorMessageVal("");
        }, 8000);
      }
    }
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
    if (position > 100) {
      document.body.classList.add("sticky-header");
    } else {
      document.body.classList.remove("sticky-header");
    }
  };

  const toggleSidebar = () => {
    setIsVisible(!isVisible);
  };

  const jumpToReleventDiv = (id) => {
    const releventDiv = document.getElementById(id);
    releventDiv.scrollIntoView({ behavior: "smooth" });
  };

  const jumpToReleventDiv2 = (id) => {
    const releventDiv = document.getElementById(id);
    releventDiv.scrollIntoView({ behavior: "smooth" });

    toggleSidebar();
  };

  const closeNavBar = () => {
    toggleSidebar();
  };

  const handleOpenSidebar = () => {
    setIsSidebarVisible(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebarVisible(false);
  };

  return (
    <div
      className={`page-content landing-page ${isVisible ? "visible-sidebar" : ""
        }`}
    >
      <header className="header">
        <div className="container-fluid">
          <div className="header-container">
            <div className="left-header">
              <div className="mv-logo">
                <img src={logo} alt="Minutes Vault Logo" className="logo" />
              </div>
            </div>
            <div className="right-header">
              <nav className="main-menu menu">
                <ul>
                  
                  <li
                    className="skiper-1"
                    onClick={() => jumpToReleventDiv("skip-to-solution")}
                  >
                    Solutions
                  </li>
                  <li
                    className="skiper-2"
                    onClick={() => jumpToReleventDiv("skip-to-features")}
                  >
                    Key Features
                  </li>
                  <li
                    className="skiper-3"
                    onClick={() => jumpToReleventDiv("skip-to-furtherquestion")}
                  >
                    FAQs
                  </li>
                  {/* <li className="nav-item" id="contact-us" data-bs-toggle="modal" data-bs-target="#exampleModal">
                  <a href="#contact-us">Contact Us</a>
                </li> */}
                </ul>

                <div className="header-btn">
                  <div className="book-demo-btn mt-0 line-btn">
                    <button
                      className="book-btn"
                      id="book-demo"
                      onClick={openBookADemoModal}
                    >
                      Book A Demo
                      <span className="arrow-spacing">
                        <img src={arrow} alt="arrow" />
                      </span>
                    </button>
                  </div>

                  <div className="book-demo-btn mt-0">
                    {/* <button
                      className="book-btn con-btn"
                      id="book-demo"
                      onClick={openContactUsModal}
                    >
                      Sign In
                      <span className="arrow-spacing">
                        <img src={arrow} alt="arrow" />
                      </span>
                    </button> */}


                    <Link to="/login" state={{ formData, currentPath }}>
                      <button
                        // disabled={authData.loading}
                        className="signin-btn2 background-wh"
                      // onClick={() => {
                      //   dispatch(updateIsSuccess(true));
                      // }}
                      >
                        Sign In
                        <span className="arrow-spacing">
                          <img src={arrow} alt="arrow" />
                        </span>
                      </button>
                    </Link>
                  </div>
                </div>
              </nav>

              <div className="btn14 hidden-bar-opener" onClick={toggleSidebar}>
                {/* <div className="btn14 hidden-bar-opener"> */}
                <div className="ham-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* hidden-header */}

      <header className="header hidden-header">
        <div className="container-fluid">
          <div className="header-container">
            <div className="left-header">
              <div className="mv-logo">
                <img src={logo} alt="Minutes Vault Logo" className="logo" />
              </div>
            </div>
            <div className="right-header">
              <nav className="main-menu menu">
                <ul>
                  <li
                    className="skiper-1"
                    onClick={() => jumpToReleventDiv("skip-to-solution")}
                  >
                    Solutions
                  </li>
                  <li
                    className="skiper-2"
                    onClick={() => jumpToReleventDiv("skip-to-features")}
                  >
                    Key Features
                  </li>
                  <li
                    className="skiper-3"
                    onClick={() => jumpToReleventDiv("skip-to-furtherquestion")}
                  >
                    FAQs
                  </li>
                  {/* <li className="nav-item" id="contact-us" data-bs-toggle="modal" data-bs-target="#exampleModal">
                  <a href="#contact-us">Contact Us</a>
                </li> */}
                </ul>

                <div className="header-btn">
                  <div className="book-demo-btn mt-0 line-btn">
                    <button
                      className="book-btn"
                      id="book-demo"
                      onClick={openBookADemoModal}
                    >
                      Book A Demo
                      <span className="arrow-spacing">
                        <img src={arrowColor} alt="arrow" />
                      </span>
                    </button>
                  </div>

                  <div className="book-demo-btn mt-0">
                    {/* <button
                      className="book-btn"
                      id="contact-us"
                      onClick={openContactUsModal}
                    >
                      Contact Us
                      <span className="arrow-spacing">
                        <img src={arrow} alt="arrow" />
                      </span>
                    </button> */}
                    <Link to="/login" state={{ formData, currentPath }}>
                      <button
                        // disabled={authData.loading}
                        className="signin-btn2 background-wh"
                      // onClick={() => {
                      //   dispatch(updateIsSuccess(true));
                      // }}
                      >
                        Sign In
                        <span className="arrow-spacing">
                          <img src={arrow} alt="arrow" />
                        </span>
                      </button>
                    </Link>
                  </div>
                </div>
              </nav>

              <div className="btn14 hidden-bar-opener" onClick={toggleSidebar}>
                {/* <div className="btn14 hidden-bar-opener"> */}
                <div className="ham-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* <!-- Hamburger Menu start--> */}

      <div className="menu-overlay"></div>
      {/* <section
        className={`hidden-bar right-align ${
          isVisible ? "visible-sidebar" : ""
        }`} > */}

      <section className="hidden-bar right-align" onClick={handleOpenSidebar}>
        {/* <section className="hidden-bar right-align"> */}
        <div
          className="d-flex align-items-center justify-content-between close-btn"
          id="menu-close"
          onClick={toggleSidebar}
        >
          <div className="mv-logo">
            <img src={whiteLogo} className="logo"></img>
          </div>

          <button className="itemm">
            <span className="inner">
              <span className="label">Close</span>
            </span>
          </button>
        </div>

        <div className="hidden-bar-wrapper">
          <div className="side-menu">
            <ul className="menu2">
            <li>
                <a href="/login">Sign In </a>
              </li>
              <li onClick={() => jumpToReleventDiv2("skip-to-solution")}>
                Solutions
              </li>
              <li onClick={() => jumpToReleventDiv2("skip-to-features")}>
                Key Features
              </li>
              <li onClick={() => jumpToReleventDiv2("skip-to-furtherquestion")}>
                FAQs
              </li>
              <li
                className="nav-item"
                onClick={() => {
                  closeNavBar();
                  openBookADemoModal();
                }}
              >
                Book A Demo
              </li>
              <li
                className="nav-item"
                onClick={() => {
                  closeNavBar();
                  openContactUsModal();
                }}
              >
                Contact Us
              </li>
            </ul>
          </div>
        </div>

        {/* Uncomment if you want to add an image in the hidden bar */}
        {/* <div className="hidebar-img">
        <img src="images/hiddenbar-meeting.svg" alt="Hidden bar image" />
      </div> */}
      </section>
      {/* <!-- Hamburger Menu End --> */}

      {/* <!-- Banner start--> */}
      <div className="banner-section" ref={layerRef}>
        <div className="container-fluid banner-container">
          <div className="left-banner">
            <div className="top-lamp"></div>
            <div className="left-element1" ref={element1Ref}></div>
            <div className="left-element2" ref={element2Ref}></div>
            {/* Uncomment this if needed */}
            {/* <div className="top-lamp"></div> */}
            <div className="wht-qstn-mark"></div>
            <div className="left-content-wrapper">
              <div className="left-content">
                <h1 className="top-title has_char_anim" data-aos="fade-down">
                  Have you ever
                </h1>
                <p className="solution-text" data-aos="fade-up">
                  walked out of a meeting unsure if anyone took notes, what
                  decisions were made, or who's accountable for tasks
                </p>
                <h1
                  className="solution-txt has_fade_anim"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  We have
                  <br />
                  the solution.
                </h1>
                <div className="book-demo-btn">
                  <button
                    className="book-btn"
                    id="book-demo"
                    onClick={openBookADemoModal}
                  >
                    Book A Demo
                    <span className="arrow-spacing">
                      <img src={bannerArrow} alt="Arrow" />
                    </span>
                    <div className="button__shadow"></div>
                  </button>
                </div>
              </div>

              {/* <div className="resp-meeting">
                <img
                  src={bannerMeeting}
                  className="meeting-img"
                  alt="Meeting"
                />
              </div> */}
            </div>
          </div>

          <div className="right-banner">
            <div className="right-element1" ref={element3Ref}></div>
            <div className="right-element2" ref={element4Ref}></div>
            <div className="right-element3" ref={element5Ref}></div>

            <img
              src={bannerSqrFrame}
              className="right-sqr-frame"
              alt="Square Frame"
            />
            <img src={bannerMeeting} className="meeting" alt="Meeting" />
          </div>
        </div>
      </div>
      {/* <!-- Banner End--> */}

      {/* <!-- contact us modal start--> */}
      <ContactUs
        showContactUsModal={showContactUsModal}
        setIsContaUsModalOpen={setIsContaUsModalOpen}
      />
      {/* <!-- contact us modal End--> */}
      {
        <Modal
          className="book-a-demo-modal"
          show={showBookADemoModal}
          backdrop="static"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={closeBookADemoModal}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="middle-text">👋🏻 Let’s get started!</div>
            <div className="middle-text mb-3">
              Excited to begin this journey with you.
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="contact-form mt-0">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-6">
                        {/* Name Field */}
                        <div className="form-floating mb-3">
                          <div className="form-control-feedback-icon">
                            <i className="ph ph-user"></i>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Your Name"
                            name="name"
                            id="name"
                            maxLength={100}
                            autoComplete="off"
                            value={formData.name}
                            onChange={handleChange}
                            onBlur={() => nameValidationCheck(formData.name)}
                          />
                          <label htmlFor="name">
                            Enter Your Name <span>*</span>
                          </label>
                          {nameError && (
                            <p className="text-danger">{nameError}</p>
                          )}
                          {formErrors.name && (
                            <p className="text-danger">{formErrors.name}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        {/* Phone Number Field */}
                        <div className="form-floating mb-3">
                          <div className="form-control-feedback-icon">
                            <i className="ph ph-phone"></i>
                          </div>
                          <input
                            type="number"
                            className="form-control only_number"
                            placeholder="Enter Mobile Number"
                            name="phoneNo"
                            id="phoneNo"
                            autoComplete="off"
                            value={formData.phoneNo}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            onBlur={() =>
                              phoneValidationCheck(formData.phoneNo)
                            }
                          />
                          <label htmlFor="phoneNo">
                            Enter Mobile Number <span>*</span>
                          </label>
                          {phoneError && (
                            <p className="text-danger">{phoneError}</p>
                          )}
                          {formErrors.phoneNo && (
                            <p className="text-danger">{formErrors.phoneNo}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        {/* Email Field with OTP Button */}
                        <div className="form-floating for-overflow-text">
                          <div className="form-control-feedback-icon">
                            <i className="ph ph-email"></i>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Work Email"
                            name="email"
                            id="email"
                            autoComplete="off"
                            value={formData.email}
                            readOnly={isOTPVerified}
                            onBlur={() => emailValidationCheck(formData.email)}
                            onChange={handleChange}
                            maxLength="96"
                          />
                          <label htmlFor="email">
                            Enter Work Email <span>*</span>
                          </label>
                          {emailError && (
                            <p className="text-danger">{emailError}</p>
                          )}
                          {formErrors.email && (
                            <p className="text-danger">{formErrors.email}</p>
                          )}

                          {emailError == "" &&
                            isValidEmailEntered &&
                            !isOTPVerified ? (
                            <button
                              type="button"
                              className="btn btn-primary verify-email"
                              onClick={handleSendOtp}
                            >
                              {otpLoading ? "Sending..." : "Get OTP"}
                            </button>
                          ) : null}

                          {isOTPVerified ? (
                            <div className="image-tick">
                              <img
                                src={Greencheck}
                                className="green-check"
                              ></img>
                            </div>
                          ) : null}
                        </div>
                        {otpLoading === false ? (
                          <div className="">
                            {otpSuccessMessage && (
                              <p className="success-response">
                                {otpSuccessMessage}
                              </p>
                            )}
                            {otpErrorMessage && (
                              <p className="error-response">
                                {otpErrorMessage}
                              </p>
                            )}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-md-12">
                        {/* OTP Input and Verify Button (Shown after OTP is sent and until verified) */}
                        {otpSent === true && (
                          <div className="form-floating mt-3">
                            <div className="form-control-feedback-icon">
                              <i className="ph ph-otp"></i>
                            </div>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Enter OTP"
                              name="otp"
                              id="otp"
                              autoComplete="off"
                              onKeyDown={handleKeyDown}
                              value={otp}
                              onChange={handleOtpChange}
                            />
                            <label htmlFor="otp">
                              Enter OTP <span>*</span>
                            </label>

                            {otpError && (
                              <p className="text-danger">{otpError}</p>
                            )}

                            <div className="">
                              <button
                                type="button"
                                className="btn btn-primary verify-email"
                                onClick={handleVerifyOtp}
                                disabled={otpProcessloading}
                              >
                                {otpProcessloading ? "Verifying..." : "Verify"}
                              </button>
                            </div>
                            { }
                          </div>
                        )}

                        {verifyOTPSuccessMessage != "" ||
                          verifyOTPErrorMessage != "" ? (
                          <div className="">
                            {verifyOTPSuccessMessage && (
                              <p className="success-response">
                                {verifyOTPSuccessMessage}
                              </p>
                            )}
                            {verifyOTPErrorMessage && (
                              <p className="error-response">
                                {verifyOTPErrorMessage}
                              </p>
                            )}
                          </div>
                        ) : null}
                      </div>

                      {/* OTP Verification Message */}
                      {/* Message Field */}
                      <div className="col-md-12">
                        <div className="form-floating mb-3 mt-3 for-overflow-text">
                          <div className="form-control-feedback-icon">
                            <i className="ph ph-message"></i>
                          </div>
                          <textarea
                            className="form-control"
                            placeholder="Message"
                            style={{ height: "120px" }}
                            name="message"
                            id="message"
                            maxLength={300}
                            autoComplete="off"
                            value={formData.message}
                            onChange={handleChange}
                          ></textarea>
                          <label htmlFor="message">Message</label>
                          {messageError && (
                            <p className="text-danger">{messageError}</p>
                          )}
                          {formErrors.message && (
                            <p className="text-danger">{formErrors.message}</p>
                          )}
                        </div>
                      </div>
                      {/* Submit Button */}
                      <div className="book-demo-btn mt-2">
                        <button type="submit" className="book-btn">
                          {loading ? "Submitting..." : "Book A Demo"}
                        </button>
                      </div>
                      {/* General Form Submission Messages */}
                      {errorMessage && (
                        <p className="f-error-response">{errorMessage}</p>
                      )}
                      {successMessage && (
                        <p className="f-success-response">{successMessage}</p>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      }

      {/* <!-- Discover MinutesVault start --> */}
      <section
        className="section discover-section discover-bg"
        id="skip-to-solution"
      >
        <div className="discover-bg-1">
          <object
            id="triangle-svg"
            type="image/svg+xml"
            data={VectorSmartObject16}
          ></object>
        </div>

        <div className="container">
          <div className="about-area-bg-shape">
            <img src={homeShapes} alt="shape" />
          </div>

          <div className="ml-330">
            <h2
              className="section-heading w-100 process-vdo-caption line-h-55"
              data-aos="fade-up"
            >
              Unlock Seamless Meetings With{" "}
              <span className="blue-text">#MinutesVault</span>
            </h2>

            <div className="margin-p">
              <h5 data-aos="fade-up">
                Unlock seamless meetings with MinutesVault, the ultimate
                solution for effortless organization. Capture, store, and share
                meeting minutes with ease, keeping everyone aligned.
              </h5>

              <p data-aos="fade-up">
                MinutesVault enhances collaboration, saves time, and streamlines
                workflows, making meeting documentation simple. Experience
                productive meetings without the hassle — transform your workflow
                with MinutesVault.
              </p>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-12 col-12">
              <div className="process-vdo" data-aos="zoom-in">
                <video width="100%" height="100%" autoPlay muted>
                  <source src="https://d3uom8aq23ax4d.cloudfront.net/MinutesVault.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
          </div>

          <h2 className="section-heading mt-5" data-aos="fade-up">
            Discover how MinutesVault <span>offers the solution</span>
          </h2>

          <div className="features-section">
            <div className="row">
              <div
                className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12"
                data-aos="zoom-in"
              >
                <div className="features-box">
                  <div className="section-icon has_fade_anim">
                    <img
                      src={efficientlyOrganiseMeeting}
                      alt="Efficiently Organise Meetings"
                    />
                  </div>
                  <h3
                    className="feature-heading"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    Efficiently Organise Meetings
                  </h3>
                  <p
                    className="features-sub-heading"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    It streamlines the scheduling process with clear agendas,
                    attendee management, and automated notifications, ensuring
                    all stakeholders are informed and prepared.
                  </p>
                  <div className="corner top-left"></div>
                  <div className="corner top-right"></div>
                  <div className="corner bottom-left"></div>
                  <div className="corner bottom-right"></div>
                </div>
              </div>

              <div
                className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12"
                data-aos="zoom-in"
              >
                <div className="features-box">
                  <div className="section-icon has_fade_anim">
                    <img
                      src={enhancesSeamlessCollaboration}
                      alt="Enhances Seamless Collaboration"
                    />
                  </div>
                  <h3
                    className="feature-heading"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    Enhances Seamless Collaboration
                  </h3>
                  <p
                    className="features-sub-heading"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    Empower teams to collaborate effectively with shared
                    agendas, assigned actions, and real-time updates, promoting
                    productivity and alignment.
                  </p>
                  <div className="corner top-left"></div>
                  <div className="corner top-right"></div>
                  <div className="corner bottom-left"></div>
                  <div className="corner bottom-right"></div>
                </div>
              </div>

              <div
                className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12"
                data-aos="zoom-in"
              >
                <div className="features-box">
                  <div className="section-icon has_fade_anim">
                    <img
                      src={improvesMinutes}
                      alt="Improves Minutes Action Accountability"
                    />
                  </div>
                  <h3
                    className="feature-heading"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    Improves Minutes Action Accountability
                  </h3>
                  <p
                    className="features-sub-heading"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    Track action items, reassignments, and acceptance statuses,
                    ensuring transparent communication and timely resolution of
                    tasks for successful outcomes.
                  </p>
                  <div className="corner top-left"></div>
                  <div className="corner top-right"></div>
                  <div className="corner bottom-left"></div>
                  <div className="corner bottom-right"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Discover MinutesVault end --> */}

      <section
        className="section lets-explore lets-explore-center bg_light"
        id="skip-to-features"
      >
        {/* <div className="discover-bg-2">
          <object
            id="triangle-svg"
            type="image/svg+xml"
            data={VectorSmartObject16}
          ></object>
        </div> */}

        <img src={keyfeaturesBg} className="key-features-bg"></img>

        <div className="container tab-container">
          <h2 className="section-heading explore-headding  has_text_move_anim mb-5">
            Let’s explore <span>the key features</span>
          </h2>
          <div className="outer-nav-wrapper">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
            >
              <Tab eventKey="home" title="Create Meeting">
                <div className="outer-features-box">
                  <div className="row">
                    <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                      <div className="content">
                        <div className="subtitle has_char_anim">
                          Create Meeting with Agendas and Share to the
                          Attendees.
                        </div>
                        <div className="description">
                          <p className="mb-5">
                            Establish a clear framework for the meeting by
                            outlining key topics and objectives. Share the
                            agenda with all attendees in advance to ensure
                            everyone is informed and prepared for meaningful
                            discussions.
                          </p>

                          <ul className="mt_32">
                            <li className="text_body--md">
                              <img
                                src={listImg}
                                className="lazy entered loaded me-2"
                              ></img>
                              Structured Meeting Plan
                            </li>
                            <li className="text_body--md">
                              <img
                                src={listImg}
                                className="lazy entered loaded me-2"
                              ></img>
                              Agenda Distribution
                            </li>
                            <li className="text_body--md">
                              <img
                                src={listImg}
                                className="lazy entered loaded me-2"
                              ></img>
                              Prepared and Focused Participation
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
                      <div className="demo-vdo">
                        {/* <img
                          src={tabImg1}
                          
                        /> */}
                        <img src={tabImg1} className=""></img>
                        {/* <div>
                          {sanitizedSvgTwo ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: sanitizedSvgTwo,
                              }}
                            />
                          ) : (
                            <p>Loading SVG...</p>
                          )}
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>

              <Tab eventKey="profile" title="RSVP confirmation">
                <div className="outer-features-box">
                  <div className="row">
                    <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                      <div className="content">
                        <div className="subtitle has_char_anim">
                          RSVP Confirmation and Meeting Attendance Reports.
                        </div>
                        <div className="description">
                          <p className="mb-5">
                            Collect RSVP confirmations from participants to
                            assess their availability. This helps in planning
                            the meeting effectively and allows you to make any
                            necessary adjustments to the agenda or participant
                            list.
                          </p>

                          <ul className="mt_32">
                            <li className="text_body--md">
                              <img
                                src={listImg}
                                className="lazy entered loaded me-2"
                              ></img>
                              RSVP Collection
                            </li>
                            <li className="text_body--md">
                              <img
                                src={listImg}
                                className="lazy entered loaded me-2"
                              ></img>
                              Enhanced Meeting Planning
                            </li>
                            <li className="text_body--md">
                              <img
                                src={listImg}
                                className="lazy entered loaded me-2"
                              ></img>
                              Agenda and Attendee Updates
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
                      <div className="demo-vdo">
                        {/* <img
                          src={tabImg1}
                          
                        /> */}
                        <img src={tabImg2} className=""></img>
                        {/* <div>
                          {sanitizedSvgTwo ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: sanitizedSvgTwo,
                              }}
                            />
                          ) : (
                            <p>Loading SVG...</p>
                          )}
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>

              <Tab eventKey="contact" title="Assign & track Agendas">
                <div className="outer-features-box">
                  <div className="row">
                    <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                      <div className="content">
                        <div className="subtitle has_char_anim">
                          Assign and Track Actions of an Agenda.
                        </div>
                        <div className="description">
                          <p className="mb-5">
                            During the meeting, designate specific tasks and
                            responsibilities to attendees. Track the progress of
                            these actions afterward to ensure accountability and
                            timely completion, fostering a culture of
                            follow-through.
                          </p>

                          <ul className="mt_32">
                            <li className="text_body--md">
                              <img
                                src={listImg}
                                className="lazy entered loaded me-2"
                              ></img>
                              Task and Responsibility Assignment
                            </li>
                            <li className="text_body--md">
                              <img
                                src={listImg}
                                className="lazy entered loaded me-2"
                              ></img>
                              Action Tracking
                            </li>
                            <li className="text_body--md">
                              <img
                                src={listImg}
                                className="lazy entered loaded me-2"
                              ></img>
                              Completion Reporting
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
                      <div className="demo-vdo">
                        {/* <img
                          src={tabImg1}
                          
                        /> */}
                        <img src={tabImg3} className=""></img>
                        {/* <div>
                          {sanitizedSvgTwo ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: sanitizedSvgTwo,
                              }}
                            />
                          ) : (
                            <p>Loading SVG...</p>
                          )}
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>

              <Tab eventKey="Write-everyMinutes" title="Write every Minutes">
                <div className="outer-features-box">
                  <div className="row">
                    <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                      <div className="content">
                        <div className="subtitle has_char_anim">
                          Write Every Minutes of Agendas.
                        </div>
                        <div className="description">
                          <p className="mb-5">
                            Write detailed minutes for each agenda item,
                            capturing essential discussions, decisions made, and
                            action items assigned. This documentation serves as
                            a valuable reference for future meetings and
                            follow-ups.
                          </p>

                          <ul className="mt_32">
                            <li className="text_body--md">
                              <img
                                src={listImg}
                                className="lazy entered loaded me-2"
                              ></img>
                              Write Detailed Minutes
                            </li>
                            <li className="text_body--md">
                              <img
                                src={listImg}
                                className="lazy entered loaded me-2"
                              ></img>
                              Comprehensive Documentation
                            </li>
                            <li className="text_body--md">
                              <img
                                src={listImg}
                                className="lazy entered loaded me-2"
                              ></img>
                              Resource for future meetings.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
                      <div className="demo-vdo">
                        {/* <img
                          src={tabImg1}
                          
                        /> */}
                        <img src={tabImg4} className=""></img>
                        {/* <div>
                          {sanitizedSvgTwo ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: sanitizedSvgTwo,
                              }}
                            />
                          ) : (
                            <p>Loading SVG...</p>
                          )}
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>

              <Tab
                eventKey="real-time-notification"
                title="Real time notification"
              >
                <div className="outer-features-box">
                  <div className="row">
                    <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                      <div className="content">
                        <div className="subtitle has_char_anim">
                          Get Real Time Notification of Meeting Attendee Action.
                        </div>
                        <div className="description">
                          <p className="mb-5">
                            Stay informed with instant notifications regarding
                            attendee actions and engagement during the meeting.
                            This feature enhances responsiveness and keeps all
                            participants aligned throughout the discussion.
                          </p>

                          <ul className="mt_32">
                            <li className="text_body--md">
                              <img
                                src={listImg}
                                className="lazy entered loaded me-2"
                              ></img>
                              Instant Notifications
                            </li>
                            <li className="text_body--md">
                              <img
                                src={listImg}
                                className="lazy entered loaded me-2"
                              ></img>
                              Engagement Tracking
                            </li>
                            <li className="text_body--md">
                              <img
                                src={listImg}
                                className="lazy entered loaded me-2"
                              ></img>
                              Get timely updates on assigned tasks
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
                      <div className="demo-vdo">
                        {/* <img
                          src={tabImg1}
                          
                        /> */}
                        <img src={tabImg5} className=""></img>
                        {/* <div>
                          {sanitizedSvgTwo ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: sanitizedSvgTwo,
                              }}
                            />
                          ) : (
                            <p>Loading SVG...</p>
                          )}
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>

              <Tab eventKey="Share-mom" title="Share mom to attendees">
                <div className="outer-features-box">
                  <div className="row">
                    <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                      <div className="content">
                        <div className="subtitle has_char_anim">
                          Share MOM to All the Attendees.
                        </div>
                        <div className="description">
                          <p className="mb-5">
                            Distribute comprehensive minutes of the meeting
                            promptly to all attendees. This ensures that
                            everyone has access to the outcomes and action
                            items, facilitating better follow-up and
                            accountability.
                          </p>

                          <ul className="mt_32">
                            <li className="text_body--md">
                              <img
                                src={listImg}
                                className="lazy entered loaded me-2"
                              ></img>
                              Timely Minutes Distribution
                            </li>
                            <li className="text_body--md">
                              <img
                                src={listImg}
                                className="lazy entered loaded me-2"
                              ></img>
                              Well-organized summary of discussions
                            </li>
                            <li className="text_body--md">
                              <img
                                src={listImg}
                                className="lazy entered loaded me-2"
                              ></img>
                              Effective follow-up
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
                      <div className="demo-vdo">
                        {/* <img
                          src={tabImg1}
                          
                        /> */}
                        <img src={tabImg6} className=""></img>
                        {/* <div>
                          {sanitizedSvgTwo ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: sanitizedSvgTwo,
                              }}
                            />
                          ) : (
                            <p>Loading SVG...</p>
                          )}
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>

              <Tab eventKey="Create-follow-on" title="Follow-on meeting">
                <div className="outer-features-box">
                  <div className="row">
                    <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                      <div className="content">
                        <div className="subtitle has_char_anim">
                          Create a Follow-On Meeting to Review Previous
                          Meetings.
                        </div>
                        <div className="description">
                          <p className="mb-5">
                            Plan subsequent meetings to review previous
                            discussions, assess the status of action items, and
                            maintain ongoing collaboration among participants.
                            This ensures that all points are addressed and
                            progress is monitored effectively.
                          </p>

                          <ul className="mt_32">
                            <li className="text_body--md">
                              <img
                                src={listImg}
                                className="lazy entered loaded me-2"
                              ></img>
                              Regular Follow-Up Meetings
                            </li>
                            <li className="text_body--md">
                              <img
                                src={listImg}
                                className="lazy entered loaded me-2"
                              ></img>
                              Action Item Progress Review
                            </li>
                            <li className="text_body--md">
                              <img
                                src={listImg}
                                className="lazy entered loaded me-2"
                              ></img>
                              Continuous Collaboration
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
                      <div className="demo-vdo">
                        {/* <img
                          src={tabImg1}
                          
                        /> */}
                        <img src={tabImg1} className=""></img>
                        {/* <div>
                          {sanitizedSvgTwo ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: sanitizedSvgTwo,
                              }}
                            />
                          ) : (
                            <p>Loading SVG...</p>
                          )}
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </section>

      {/* <!-- Let's Explore section start --> */}
      {/* <section className="section lets-explore pt-0" id="skip-to-features">
        <div className="container">
          <h2 className="section-heading explore-headding has_fade_anim">
            Let’s explore the key features
          </h2>
          <div className="zigzag">
            <object
              id="zigzag-svg"
              width="170px"
              type="image/svg+xml"
              data={zigzag1}
            ></object>
          </div>
        </div>

        <div className="w-100 p-0">
          <div className="key-features-left">
            <img
              src={personKeyfeatures}
              className="person-keyfeatures"
              alt="Person Key Features"
            />
          </div>
          <div className="container">
            <div className="row p-0 mt-4">
              <div className="col-12">
                <div className="key-features-swiper">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      <div className="key-features-right d-flex">
                        <div className="create-meeting-ss">
                          <img src={createMeetingSs} alt="Create Meeting" />
                        </div>
                        <div className="slide-content position-relative">
                          <div className="section-icon">
                            <img src={ssIcon1} alt="Create Meeting Icon" />
                          </div>
                          <div>
                            <h3 className="feature-heading">Create Meeting</h3>
                            <p className="features-sub-heading">
                              MinutesVault allows you to easily create meetings
                              by adding attendees, setting multiple agendas,
                              choosing the meeting mode, specifying the
                              location, and providing a meeting link. All your
                              meetings are stored in one place, giving you quick
                              access to meeting details and minutes of meeting
                              (MOM) whenever needed.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="key-features-right d-flex">
                          <div className="create-meeting-ss">
                            <img src={createMeetingSs} alt="Convenient Login" />
                          </div>
                          <div className="slide-content position-relative">
                            <div className="section-icon">
                              <img
                                src={efficientlyOrga}
                                alt="Convenient Login Icon"
                              />
                            </div>
                            <div>
                              <h3 className="feature-heading">
                                Convenient and Easy Login
                              </h3>
                              <p className="features-sub-heading">
                                We prioritize both user convenience and security
                                by offering a versatile login experience. Users
                                can choose between two seamless authentication
                                methods: OTP and traditional Password login.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>

                    <div className="swiper-slide">
                      <div className="key-features-right d-flex">
                        <div className="create-meeting-ss">
                          <img
                            src="../../assets/images/LandingpageImage/create-meeting-ss.png"
                            alt="Schedule Meeting"
                          />
                        </div>
                        <div className="slide-content position-relative">
                          <div className="section-icon">
                            <img
                              src={efficientlyOrga}
                              alt="Schedule Meeting Icon"
                            />
                          </div>
                          <div>
                            <h3 className="feature-heading">
                              Schedule Meeting
                            </h3>
                            <p className="features-sub-heading">
                              Efficiently schedule meetings with attendee
                              management enhancing collaboration and
                              productivity.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="key-features-right d-flex">
                        <div className="create-meeting-ss">
                          <img src={createMeetingSs} alt="Add Agenda" />
                        </div>
                        <div className="slide-content position-relative">
                          <div className="section-icon">
                            <img src={efficientlyOrga} alt="Add Agenda Icon" />
                          </div>
                          <div>
                            <h3 className="feature-heading">Add Agenda</h3>
                            <p className="features-sub-heading">
                              The add attendees feature allows users to swiftly
                              include participants by selecting from existing
                              lists, adding new contacts, or importing from
                              employee lists, ensuring comprehensive
                              representation and effective communication within
                              meetings.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="next-prev-main-div position-relative">
                    <div className="next-prev-sub-div d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <div className="keyfeatures-prev">
                          <img src={prevIconImg} alt="Previous" />
                        </div>
                        <div className="text">Previous</div>
                      </div>

                      <div className="pagination-outer-box position-relative w-100">
                        <div className="swiper-pagination"></div>
                      </div>

                      <div className="d-flex align-items-center">
                        <div className="text">Next</div>
                        <div className="keyfeatures-next">
                          <img src={nextIconImg} alt="Next" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!-- Let's Explore section End --> */}

      {/* <!-- Further Question section start --> */}
      <section className="section faq-page" id="skip-to-furtherquestion">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
              <h2
                className="section-heading faq-header mb-3"
                data-aos="fade-up"
              >
                Do you have any <span>further questions?</span>
              </h2>
              <p
                className="explore-txt"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                Explore our FAQs or reach out to the MinutesVault support team
                for a personalized response!
              </p>
              <ul className="contact-details">
                <li className="has_fade_anim" data-aos="fade-up">
                  <span className="icons">
                    <img
                      src={phoneIcon}
                      className="phone-icon"
                      alt="Phone Icon"
                    />
                  </span>
                  <p>
                    <span className="list-heading has_char_anim">
                      Make a Call
                    </span>
                    <a href="tel:+918260003333" className="icon-hover">
                      +91-8260003333
                    </a>
                  </p>
                </li>
                <li className="has_fade_anim" data-aos="fade-up">
                  <span className="icons">
                    <img src={mailIcon} className="mail-icon" alt="Mail Icon" />
                  </span>
                  <p>
                    <span className="list-heading has_char_anim">
                      Write to Us
                    </span>
                    <a href="mailto:info@ntspl.co.in" className="icon-hover">
                      info@ntspl.co.in
                    </a>
                  </p>
                </li>
                <li className="has_fade_anim" data-aos="fade-up">
                  <span className="icons">
                    <img src={locationIcon} className="location-icon" />
                  </span>
                  <p className="font-15 icon-hover">
                    <span className="list-heading has_char_anim">
                      Head Office
                    </span>
                    DLF Two Horizon Centre, 5th Floor, DLF Phase 5, Gurugram,
                    Haryana - 122002, Contact No- +91-8260003333
                  </p>
                  <p className="font-15 icon-hover mt-3">
                    <span className="list-heading has_char_anim">
                      Research & Development Center
                    </span>
                    DCB: #430, 4th Floor, DCB: #502 & 503, 5th Floor DLF Cyber
                    City, Chandaka Industrial Estate, Patia, Bhubaneswar, Odisha
                    751024, Contact No- +91-9337705517, +91-8260003333
                    +91-7682835509
                  </p>
                </li>
              </ul>
            </div>
            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 position-relative">
              <div className="qa-bg">
                <img src={faqBg} alt="FAQ Background" />
              </div>
              <div className="question-answers">
                <div
                  className="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item faq-border" data-aos="fade-up">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                        className="accordion-button faq-heading collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        What is MinutesVault?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        MinutesVault is a simple app designed to help you keep
                        track of everything that happens in meetings. It allows
                        you to create agendas, take notes during the meeting,
                        and assign tasks afterward. This way, everyone knows
                        what decisions were made and what they need to do next.
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item" data-aos="fade-up">
                    <h2 className="accordion-header" id="flush-headingTwo">
                      <button
                        className="accordion-button faq-heading collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwo"
                      >
                        How does MinutesVault improve my meetings?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingTwo"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        MinutesVault allows you to easily create meetings by
                        adding attendees, setting multiple agendas, choosing the
                        meeting mode, specifying the location, and providing a
                        meeting link. All your meetings are stored in one place,
                        giving you quick access to meeting details and minutes.
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item" data-aos="fade-up">
                    <h2 className="accordion-header" id="flush-headingThree">
                      <button
                        className="accordion-button collapsed faq-heading"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree"
                      >
                        Can I use MinutesVault on different devices?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseThree"
                      className="accordion-collapse collapse show"
                      aria-labelledby="flush-headingThree"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        Absolutely! MinutesVault works on both computers and
                        mobile devices, so you can use it wherever you are.
                        Whether you're in the office, at home, or traveling, you
                        can take notes and manage your meetings from your phone
                        or tablet, making it convenient for everyone involved.
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item" data-aos="fade-up">
                    <h2 className="accordion-header" id="flush-headingFour">
                      <button
                        className="accordion-button collapsed faq-heading"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFour"
                        aria-expanded="false"
                        aria-controls="flush-collapseFour"
                      >
                        What are the benefits of using MinutesVault?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingFour"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        MinutesVault allows you to easily create meetings by
                        adding attendees, setting multiple agendas, choosing the
                        meeting mode, specifying the location, and providing a
                        meeting link. All your meetings are stored in one place,
                        giving you quick access to meeting details and minutes.
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item" data-aos="fade-up">
                    <h2 className="accordion-header" id="flush-headingFour">
                      <button
                        className="accordion-button collapsed faq-heading"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFive"
                        aria-expanded="false"
                        aria-controls="flush-collapseFive"
                      >
                        Is my data secure in the MinutesVault application?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingFive"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        MinutesVault prioritizes data security, employing
                        encryption and secure servers to protect your
                        information. Always check the app's privacy policy for
                        specific security measures. Access is strictly
                        controlled, allowing only authorized users within your
                        organization to view or edit your meeting notes.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Further Question section end --> */}

      {/* <!-- Leading Brands start--> */}
      <section className="section leading-brand p-0">
        <div className="container">
          <h2 className="section-heading has_word_anim mb-4" data-aos="fade-up">
            We are trusted by leading brands
          </h2>

          {/* <section className="section leading-brand pb-0 p-0 mb-0 mt-2"> */}
          <div className="container">
            {/* Swiper for the first row of logos */}
            <section className="section leading-brand pb-0 p-0">
              <div className="container">
                {/* Swiper for the first row of logos (left to right) */}
                <Swiper
                  spaceBetween={0}
                  slidesPerView={5}
                  loop={true}
                  speed={4000}
                  autoplay={{
                    delay: 0,
                    disableOnInteraction: false,
                  }}
                  grabCursor={true}
                  direction={"horizontal"}
                  modules={[Navigation, Pagination, Autoplay]}
                >
                  <SwiperSlide>
                    <img className="image-bg" src={nalco} alt="Nalco Logo" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      className="image-bg"
                      src={vedanta}
                      alt="Vedanta Logo"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img className="image-bg" src={icarda} alt="ICARDA Logo" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      className="image-bg"
                      src={adityabirla}
                      alt="Aditya Birla Logo"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img className="image-bg" src={omc} alt="OMC Logo" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img className="image-bg" src={trl} alt="TRL Logo" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img className="image-bg" src={balco} alt="Balco Logo" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      className="image-bg"
                      src={bankOfBaroda}
                      alt="Bank of Baroda Logo"
                    />
                  </SwiperSlide>
                </Swiper>

                {/* Swiper for the second row of logos (right to left) */}
                <Swiper
                  spaceBetween={0}
                  slidesPerView={5}
                  loop={true}
                  speed={4000}
                  autoplay={{
                    delay: 0,
                    disableOnInteraction: false,
                    reverseDirection: true,
                  }}
                  grabCursor={true}
                  direction={"horizontal"}
                  modules={[Navigation, Pagination, Autoplay]}
                >
                  <SwiperSlide>
                    <img className="image-bg" src={hzl} alt="HZL Logo" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img className="image-bg" src={imfa} alt="Imfa Logo" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      className="image-bg"
                      src={konkola}
                      alt="Konkola Logo"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      className="image-bg"
                      src={lingarajPipe}
                      alt="Lingaraj Pipe Logo"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img className="image-bg" src={jindal} alt="jindal Logo" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img className="image-bg" src={nmdc} alt="NMDC Logo" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img className="image-bg" src={ocac} alt="Ocac Logo" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      className="image-bg"
                      src={facor}
                      alt="Vedanta Facor Logo"
                    />
                  </SwiperSlide>
                </Swiper>
              </div>
            </section>
          </div>
          {/* </section> */}
        </div>
      </section>
      {/* <!-- Leading Brands end--> */}

      {/* <!-- Footer start--> */}
      <footer className="footer pb-0">
        <div className="container p-top">
          <div className="row">
            <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
              <h2 className="footer-heading has_word_anim" data-aos="fade-up">
                With <span className="blue-text">MinutesVault</span> keep your
                minutes organised and accessible <br /> from anywhere with ease
              </h2>
              <div className="book-demo-btn" data-aos="fade-up">
                <button className="book-btn" onClick={openContactUsModal}>
                  Enquiry Now
                  <span className="arrow-spacing">
                    <img src={arrow} alt="Arrow" />
                  </span>
                  <div className="button__shadow"></div>
                </button>
              </div>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
              {/* <object id="triangle-svg" type="image/svg+xml" data="../../assets/images/LandingpageImage/footer-meeting-bg.svg"></object> */}
              {/* <img
                src={meetingbgall}
                className="footer-meeting-bg-all"
                alt="Meeting Background"
              /> */}

              {/* <div>
                {sanitizedSvg ? (
                  <div dangerouslySetInnerHTML={{ __html: sanitizedSvg }} />
                ) : (
                  <p>Loading SVG...</p>
                )}
              </div> */}

              <img
                src={meetingbgall}
                className="footer-meeting-bg-all"
                alt="Meeting Background"
              />
              {/* <div>
                <ReactSVG src={meetingbgall} />
              </div> */}
            </div>
          </div>

          <div className="footer-bottom">
            <div className="left-bottom">
              <div className="social-boxes">
                <a
                  href="https://www.facebook.com/people/MinutesVault/61567147532629/"
                  className="s-box"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={fbIcon} alt="Facebook" />
                </a>
                <a
                  href="https://x.com/MinutesVault"
                  className="s-box"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={twiter} alt="Twitter" />
                </a>
                <a
                  href="https://www.linkedin.com/company/minutesvault/"
                  className="s-box"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={linkedIn} alt="LinkedIn" />
                </a>
                <a
                  href="https://www.youtube.com/@MinutesVault"
                  className="s-box"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={youtube} alt="YouTube" />
                </a>
                <a
                  href="https://www.instagram.com/minutesvault/"
                  className="s-box"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={instagram} alt="Instagram" />
                </a>
              </div>
              {/* <p>Powered by MinutesVault</p> */}
            </div>
            <div className="right-bottom">
              {/* <ul>
                <li>
                  <a>Terms & Condition</a>
                </li>
                <li>
                  <a>Privacy Policy</a>
                </li>
              </ul> */}
              <p>Powered by NTSPL</p>
            </div>
          </div>
        </div>

        <div className="left-footer-bg"></div>
        <div className="right-footer-bg"></div>
        <img
          src={footerWhite}
          className="footer-white"
          alt="Footer Background"
        />
      </footer>
      {/* <!-- Footer end--> */}
    </div>
  );
};
export default LandingPage;
