import React, { useState, useEffect } from "react";
import "../../App.css";
import { useSelector, useDispatch } from "react-redux";
import { Link,useLocation } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import parse from "html-react-parser";
import { Accordion, Tooltip, OverlayTrigger, Popover } from "react-bootstrap";

import {
  generateMOM,
  getAgendaWithMinutesDetails,
  unSetSingleMeetingDetails,
  processCancelMeeting, //added for meeting dropdown
  processCloseMeeting, //added for meeting dropdown
} from "../../redux/actions/meetingActions/MeetingAction";
import { toast } from "react-toastify";
import {
  checkEmptyString,
  combineDateAndTime,
  convertFirstLetterOfFullNameToCapital,
  convertFirstLetterToCapital,
  customName,
  decryptWithAES,
  formatDateTimeFormat,
  formatTimeFormat,
  getStringLengthAfterHTMLParse,
  sliceHtmlEncodedString,
} from "../../helpers/commonHelpers";
import {
  acceptMOM,
  processAmendmentRequest,
  setSuccess,
  updateAmendmentRequest,
} from "../../redux/actions/minuteActions/MinuteAction";
import MinutesRsvpStatusModal from "./MinutesRsvpStatusModal";
import AttendeesModal from "../Meeting/AttendeesModal";
import LoaderButton from "../Common/LoaderButton";
import AmendmentRequestModal from "./AmendmentRequestModal";
import AmendmentRequestViewModal from "./AmendmentRequestViewModal";
import MeetingActivities from "../Meeting/MeetingActivities";
import EditMinuteModal from "./EditMinuteModal";
import DeleteMeetingModal from "./DeleteMeetingModal";
import MeetingAttendance from "../Meeting/MeetingAttendance";
import AddAttendeesModal from "../Meeting/AddAttendeesModal";
import AttendanceModal from "./AttendanceModal";
import RescheduleMeetingModal from "../Meeting/RescheduleMeetingModal";
import MinutesActionDropDown from "./MinutesActionDropDown";
import GenerateMOMModal from "./GenerateMOMModal";
import GuestAttendeesModal from "../Meeting/GuestAttendeesModal";
import OnlyAttendeeModal from "../Meeting/OnlyAttendeeModal";
import ViewMinuteModal from "./ViewMinuteModal";
import ViewAgendaModal from "./ViewAgendaModal";

// added for meeting dropdown
import Dropdown from "react-bootstrap/Dropdown";
import MeetingDropDown from "../Meeting/MeetingDropDown";
import CancelMeetingModal from "../Meeting/CancelMeetingModal";
import ResendMeetingDetailsModal from "../Meeting/ResendMeetingDetailsModal";
import CommonModal from "../Common/CommonModal";

const ViewMinutes = (props) => {
      const location = useLocation();
  const accessToken = localStorage.getItem("accessToken");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();
  const employeeData = useSelector((state) => state.user);
  const minuteData = useSelector((state) => state.minute);
  const meetingData = useSelector((state) => state.meeting);
  const [isMinutesRsvpStatusModal, setIsMinutesRsvpStatusModal] =
    useState(false);
  const [isRescheduleModalOpen, setIsRescheduleModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [attendeesData, setAttendeesData] = useState([]);
  const [isCloseModalOpen, setIsCloseModalOpen] = useState(false);
  const [isAttendaceModalOpen, setIsAttendaceModalOpen] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const [isDeleteMinuteModal, setIsDeleteMinuteModal] = useState(false);
  const [isEditMinuteModal, setIsEditMinuteModal] = useState(false);
  const [minuteDetails, setMinuteDetails] = useState(null);
  const [amendmentModal, setAmendmentModal] = useState(false);
  const [createdById, setCreatedById] = useState(null);
  const [details, setDetails] = useState(null);
  const [amendmentViewModal, setAmendmentViewModal] = useState(false);
  const [isAddAttendeeModal, setIsAddAttendeeModal] = useState(false);
  const [isOnlyAttendeeModalOpen, setIsOnlyAttendeeModalOpen] = useState(false);
  const [isGuestModalOpen, setIsGuestModalOpen] = useState(false);
  const [isViewMinuteModal, setIsViewMinuteModal] = useState(false);
  const [isViewAgendaModal, setIsViewAgendaModal] = useState(false);

  const [agendaDatas, setAgendaDatas] = useState(false);
  const [minuteDatas, setMinuteDatas] = useState(false);
  const [status, setStatus] = useState(null);
  const [minuteId, setMinuteId] = useState(null);

  console.log(props.meetingId);

  // added for meeting dropdown
  const [meetingId, setMeetingId] = useState(null);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [meetingDetails, setMeetingDetails] = useState(null);

  const [singleMeetingDetails, setSingleMeetingDetails] = useState(null);
  const [isResendMeetingDetailsModalOpen, setIsResendMeetingDetailsModalOpen] =
    useState(false);

    const isViewMinutesPage = location.pathname.includes("/view-minute-detail");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (meetingData.isFetchedMeetingList) {
      if (meetingData?.meetingDetails?._id && accessToken) {
        dispatch(
          getAgendaWithMinutesDetails(
            meetingData?.meetingDetails?._id,
            accessToken
          )
        );
      }
    }
    if (meetingData?.isSuccess === true) {
      setIsCloseModalOpen(false);
    }
    return () => {
      dispatch(unSetSingleMeetingDetails);
    };
  }, [meetingData.isFetchedMeetingList, meetingData?.isSuccess]);

  useEffect(() => {
    if (meetingData?.isSuccess === true) {
      setIsCloseModalOpen(false);
    }

    return () => {};
  }, [meetingData?.isSuccess]);

  const handleAmendmentRequest = (details) => {
    const payload = {
      details,
      meetingId: meetingData?.meetingDetails?._id,
    };

    dispatch(processAmendmentRequest(payload, minuteId, accessToken));
    setMinuteId(null);
    setAmendmentModal(false);
  };
  const setGuestModalStatus = (value, attendeesData) => {
    if (attendeesData.length !== 0) {
      setIsGuestModalOpen(value);
      setAttendeesData([...attendeesData]);
    }
  };
  const handleAmendmentUpdate = (status) => {
    const payload = {
      createdById,
      status,
      meetingId: meetingData.meetingDetails._id,
    };

    dispatch(updateAmendmentRequest(payload, minuteId, accessToken));
    setMinuteId(null);
    setDetails(null);
    setCreatedById(null);
    setAmendmentViewModal(false);
  };

  const handleAmendmentUpdateByButton = (status, createdById, minuteId) => {
    const payload = {
      createdById,
      status,
      meetingId: meetingData.meetingDetails._id,
    };

    dispatch(updateAmendmentRequest(payload, minuteId, accessToken));
    setMinuteId(null);
    setDetails(null);
    setCreatedById(null);
    setAmendmentViewModal(false);
  };

  const handleAmendmentUpdateModal = (minuteId, details, createdById) => {
    setMinuteId(minuteId);
    setDetails(details);
    setCreatedById(createdById);
    setAmendmentViewModal(true);
  };

  const updateIsMinutesRsvpStatusModal = (status, attendees) => {
    if (attendees.length !== 0) {
      setStatus(status);
      setAttendeesData(attendees);
      setIsMinutesRsvpStatusModal(!isMinutesRsvpStatusModal);
    }
  };

  const setModalStatus = (value, attendeesData) => {
    if (attendeesData.length !== 0) {
      setIsModalOpen(value);
      setAttendeesData([...attendeesData]);
    }
  };

  const setAttendanceModalStatus = (value, attendeesData) => {
    if (attendeesData.length !== 0) {
      setIsAttendaceModalOpen(value);

      setAttendeesData([...attendeesData]);
    }
  };

  const setAddAttendeesModal = (status, attendeesData) => {
    setAttendeesData([...attendeesData]);
    setIsAddAttendeeModal(status);
  };

  const setIsAgendaShowModal = (value) => {
    setIsAgendaShowModal(value);
  };
  const setOnlyAttendeeModalStatus = (value, attendeesData) => {
    if (attendeesData.length !== 0) {
      setIsOnlyAttendeeModalOpen(value);
      setAttendeesData([...attendeesData]);
    }
  };
  const setViewMinuteModalStatus = (value, minute) => {
    setIsViewMinuteModal(value);
    setMinuteDatas(minute);
  };
  const setViewAgendaModalStatus = (value, agenda) => {
    setIsViewAgendaModal(value);
    setAgendaDatas(agenda);
  };

  const handleEditMinuteModal = (status, minute) => {
    dispatch(setSuccess(false));
    setMinuteDetails(minute);
    setIsEditMinuteModal(status);
  };
  const handleDeleteMinuteModal = (status, minute) => {
    dispatch(setSuccess(false));
    setMinuteDetails(minute);
    setIsDeleteMinuteModal(status);
  };
  const momId =
    meetingData?.meetingDetails?.momGenerationDetails?.length !== 0
      ? meetingData?.meetingDetails?.momGenerationDetails[
          meetingData?.meetingDetails?.momGenerationDetails?.length - 1
        ]?.momId
      : null;
  const acceptMOMData = (meetingId) => {
    if (isAccepted) {
      dispatch(acceptMOM(meetingId, { momId }, accessToken));
    } else {
      toast.warning("Please accept MOM condition.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const generateMOMDetails = () => {
    const meetingId = props.meetingId
      ? props.meetingId
      : meetingData.meetingDetails?._id;
    const payload = {
      organizationId: userData.organizationId,
      attendees: meetingData?.meetingDetails?.attendees?.map(
        ({
          rsvp,
          isAttended,
          isEmployee,
          empId,
          designation,
          companyName,
          ...keepAttrs
        }) => keepAttrs
      ),
    };
    dispatch(generateMOM(meetingId, payload, accessToken));
  };
  const isMomAccepted =
    meetingData?.meetingDetails?.momAcceptDetails?.length > 0 &&
    meetingData?.meetingDetails?.momAcceptDetails?.find(
      (user) =>
        user?.userId?.toString() == employeeData?.userData?._id?.toString()
    )
      ? true
      : false;

  const meetingDateTime = combineDateAndTime(
    new Date(meetingData?.meetingDetails?.actualDate),
    meetingData?.meetingDetails?.actualFromTime
  ).getTime();

  const currentDateTime = new Date().getTime();
  const isWriteMinuteAllowed =
    meetingDateTime <= currentDateTime ? true : false;

  const isStatusAllowd =
    meetingData?.meetingDetails?.meetingStatus?.status === "scheduled" ||
    meetingData?.meetingDetails?.meetingStatus?.status === "rescheduled"
      ? true
      : false;

  const handleGenerateMOMModal = () => {
    setIsCloseModalOpen(true);
  };
  console.log(meetingData?.meetingDetails);

  const isMeetingOrganiser =
    meetingData?.meetingDetails?.createdById?.toString() ===
    employeeData?.userData?._id?.toString()
      ? true
      : false;

  const tooltip = (attendee) => {
    return (
      <Tooltip id="tooltip">
        {attendee.name} / {attendee?.email}
      </Tooltip>
    );
  };
  const popoverHoverFocus = (title) => {
    return (
      <Popover id="popover-basic" className="custom-popover">
        {/* <Popover.Header as="h3" className="popover-header">
          Meeting Title.
        </Popover.Header> */}
        <Popover.Body className="popover-body">{title}</Popover.Body>
      </Popover>
    );
  };

  //added for meeting dropdown

  console.log("Minutes Data------------", minuteData);
  console.log("Meeting Data------------", meetingData?.meetingDetails);

  const handleCloseMeeting = () => {
    dispatch(
      processCloseMeeting(meetingId, { meetingStatus: "closed" }, accessToken)
    );
    setMeetingId(null);
    setIsCloseModalOpen(false);
  };

  const handleCancelMeeting = (remarks) => {
    dispatch(processCancelMeeting(meetingId, { remarks }, accessToken));
    setMeetingId(null);
    setIsCancelModalOpen(false);
  };

  const handleCloseModal = (meetingId) => {
    setMeetingId(meetingId);
    setIsCloseModalOpen(true);
  };

  const handleCancelModal = (meetingId) => {
    setMeetingId(meetingId);
    setIsCancelModalOpen(true);
  };
  const handleRescheduleModal = (singleMeetingDetails) => {
    setSingleMeetingDetails(singleMeetingDetails);

    setIsRescheduleModalOpen(true);
  };
  const handleResendMeetingDetailsModal = (singleMeetingDetails) => {
    setSingleMeetingDetails(singleMeetingDetails);
    setIsResendMeetingDetailsModalOpen(true);
  };

  const meeting = meetingData?.meetingDetails;
  // added above code for meeting dropdown

  return (
    <>
      <CancelMeetingModal
        setIsModalOpen={setIsCancelModalOpen}
        isModalOpen={isCancelModalOpen}
        handleSubmit={handleCancelMeeting}
      />
      <CommonModal
        message={"Do you want to close the meeting ?"}
        title={"Close Meeting"}
        setIsModalOpen={setIsCloseModalOpen}
        isModalOpen={isCloseModalOpen}
        handleSubmit={handleCloseMeeting}
        buttonName={"Close"}
      />
      <RescheduleMeetingModal
        setIsRescheduleModalOpen={setIsRescheduleModalOpen}
        isRescheduleModalOpen={isRescheduleModalOpen}
        meetingData={singleMeetingDetails}
        isFetchedMeetingList={meetingData.isFetchedMeetingList}
      />

      <ResendMeetingDetailsModal
        isResendMeetingDetailsModalOpen={isResendMeetingDetailsModalOpen}
        setIsResendMeetingDetailsModalOpen={setIsResendMeetingDetailsModalOpen}
        meetingDetail={singleMeetingDetails}
      />

   
      {meetingData.meetingDetails ? (
        <form className="details-form details-form-right mt-2">
          {/* <Accordion defaultActiveKey="0">
                      <div className="mt-2 agenda-box-border">
                        <Accordion.Item
                          eventKey={index.toString()}
                          className="agenda-accordion"
                        >
           */}
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0" className="agenda-accordion">
              <Accordion.Header>Meeting Details</Accordion.Header>
              <Accordion.Body>
                {meetingData?.meetingDetails?.parentMeetingDetails?.length !==
                0 ? (
                  <>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                          <label className="mb-1">Parent Meeting ID</label>
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                          <p>
                            {
                              meetingData?.meetingDetails
                                ?.parentMeetingDetails[0].meetingId
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                          <label className="mb-1">Parent Meeting Title</label>
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                          <p>
                            {
                              meetingData?.meetingDetails
                                ?.parentMeetingDetails[0].title
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                          <label className="mb-1">Followon Serial Number</label>
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                          <p>{meetingData?.meetingDetails?.followOnSerialNo}</p>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}

                <div className="form-group">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                      <label className="mb-1">Meeting ID</label>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                      <p>{meetingData.meetingDetails?.meetingId}</p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                      <label className="mb-1">Title</label>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                      <p>
                        {convertFirstLetterOfFullNameToCapital(
                          meetingData.meetingDetails?.title
                        )}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                      <label className="mb-1">Meeting Mode</label>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                      <p>
                        {convertFirstLetterOfFullNameToCapital(
                          meetingData.meetingDetails?.mode
                        )}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="form-group ">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                      <label className="mb-1">Location</label>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                      <p>
                        {meetingData?.meetingDetails?.locationDetails
                          .isMeetingRoom === true
                          ? meetingData?.meetingDetails?.roomDetail[0]?.title +
                            ", " +
                            meetingData?.meetingDetails?.roomDetail[0]
                              ?.location +
                            ", " +
                            meetingData?.meetingDetails?.unitDetail?.name
                          : meetingData?.meetingDetails?.locationDetails
                              ?.location}
                      </p>
                    </div>
                  </div>
                </div>

                {/* 
          <div className="form-group">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <label className="mb-1">Meeting Link</label>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                <p>
                  {meetingData.meetingDetails?.link
                    ? meetingData.meetingDetails?.link
                    : "NA"}
                </p>
              </div>
            </div>
          </div> */}

                <div className="form-group">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                      <label className="mb-1">Link </label>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                      <>
                        {meetingData?.meetingDetails?.link &&
                        meetingData?.meetingDetails?.hostDetails?.hostType ===
                          "MANUAL" ? (
                          <Link
                            target={"_blank"}
                            to={meetingData?.meetingDetails?.link}
                          >
                            Click to Join Meeting
                          </Link>
                        ) : meetingData?.meetingDetails?.link ? (
                          <>
                            <Link
                              target={"_blank"}
                              to={
                                meetingData?.meetingDetails?.hostDetails
                                  ?.hostLink
                              }
                              // to={
                              //   meetingData?.singleMeetingDetails?.createdById?.toString() ===
                              //   employeeData?.userData?._id?.toString()
                              //     ? meetingData?.singleMeetingDetails
                              //         ?.hostDetails?.hostLink
                              //     : meetingData?.singleMeetingDetails?.hostDetails?.hostLink?.split("?")[0]
                              // }
                            >
                              Click to Join Meeting
                            </Link>
                            {employeeData?.userData?.isAdmin ||
                            meetingData?.singleMeetingDetails?.createdById?.toString() ===
                              employeeData?.userData?._id?.toString() ? (
                              <p>
                                {" "}
                                ( Host Key :{" "}
                                {decryptWithAES(
                                  meetingData?.meetingDetails
                                    ?.meetingHostDetails?.zoomCredentials
                                    ?.hostKey
                                )}{" "}
                                )
                              </p>
                            ) : null}
                            {/* {!employeeData?.userData?.isAdmin &&
                              meetingData?.singleMeetingDetails?.createdById?.toString() !==
                                employeeData?.userData?._id?.toString() ? (
                                <p>
                                  {" "}
                                  ( Passcode :{" "}
                                  {
                                    meetingData?.singleMeetingDetails
                                      ?.hostDetails?.hostingPassword
                                  }{" "}
                                  )
                                </p>
                              ) : null} */}
                          </>
                        ) : meetingData?.meetingDetails?.link &&
                          meetingData?.meetingDetails?.hostDetails?.hostType ===
                            "ZOOM" ? (
                          <>
                            <Link
                              target={"_blank"}
                              // to={
                              //   meetingData?.singleMeetingDetails?.createdById?.toString() ===
                              //   employeeData?.userData?._id?.toString()
                              //     ? meetingData?.singleMeetingDetails
                              //         ?.hostDetails?.hostLink
                              //     : meetingData?.singleMeetingDetails?.hostDetails?.hostLink?.split(
                              //         "?"
                              //       )[0]
                              // }
                              to={
                                meetingData?.meetingDetails?.hostDetails
                                  ?.hostLink
                              }
                            >
                              Click to Join Meeting
                            </Link>

                            {meetingData?.meetingDetails?.createdById?.toString() ===
                            employeeData?.userData?._id?.toString() ? (
                              <p>
                                {" "}
                                Host Key :
                                {decryptWithAES(
                                  meetingData?.meetingDetails
                                    ?.meetingHostDetails?.zoomCredentials
                                    ?.hostKey
                                )}
                              </p>
                            ) : null}
                          </>
                        ) : meetingData?.meetingDetails?.hostDetails
                            ?.hostType === "ZOOM" &&
                          !meetingData?.meetingDetails?.link ? (
                          <p>
                            Zoom link will be added once schedule the meeting
                          </p>
                        ) : (
                          <p> NA</p>
                        )}
                      </>
                    </div>
                  </div>
                </div>

                {/* <div className="form-group">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                      <label className="mb-1">Recordings </label>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                      {meetingData?.meetingDetails?.hostDetails
                        ?.hostType === "ZOOM" &&
                      meetingData?.meetingDetails?.recordings ? (
                        <>
                          <Link
                            target={"_blank"}
                            to={
                              meetingData?.meetingDetails?.recordings
                                ?.recordingFile?.download_url
                            }
                            // style={{
                            //   textDecoration: "none",
                            //   color: "black",
                            // }}
                          >
                            <span className="recording-btn">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                className="bi bi-cloud-download me-2"
                                viewBox="0 0 16 16"
                              >
                                <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383" />
                                <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708z" />
                              </svg>
                              Download
                            </span>
                          </Link>

                          <Link
                            target={"_blank"}
                            to={
                              meetingData?.singleMeetingDetails?.recordings
                                ?.recordingFile?.play_url
                            }
                            // style={{
                            //   textDecoration: "none",
                            //   color: "black",
                            // }}
                          >
                            <span className="recording-btn">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                className="bi bi-box-arrow-right me-2"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"
                                />
                                <path
                                  fill-rule="evenodd"
                                  d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"
                                />
                              </svg>
                              Watch
                            </span>
                          </Link>
                          <br></br>
                          <p>
                            Passcode: ({" "}
                            {
                              meetingData?.meetingDetails?.recordings
                                ?.password
                            }{" "}
                            )
                          </p>
                        </>
                      ) : (
                        <p>NA</p>
                      )}
                    </div>
                  </div>
                </div> */}

                <div className="form-group">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                      <label className="mb-1">Date & Time</label>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                      <p>
                        {
                          formatDateTimeFormat(meetingData.meetingDetails?.date)
                            .formattedDate
                        }
                        , {meetingData.meetingDetails?.fromTime} to{" "}
                        {meetingData.meetingDetails?.toTime}{" "}
                      </p>
                    </div>
                  </div>
                </div>
                {meetingData.meetingDetails?.updatedFromTime &&
                meetingData.meetingDetails?.updatedToTime ? (
                  <div className="form-group">
                    <div className="row">
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                        <label className="mb-1">Actual Date & Time</label>
                      </div>
                      <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                        <p>
                          {
                            formatDateTimeFormat(
                              meetingData.meetingDetails?.actualDate
                            ).formattedDate
                          }
                          ,{" "}
                          {formatTimeFormat(
                            meetingData.meetingDetails?.updatedFromTime
                          )}{" "}
                          to{" "}
                          {formatTimeFormat(
                            meetingData.meetingDetails?.updatedToTime
                          )}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="form-group">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                      <label className="mb-1">Status</label>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                      <span
                        className={`badge bg-opacity-10 ${
                          meetingData.meetingDetails?.meetingStatus?.status ===
                          "scheduled"
                            ? "scheduled-color"
                            : meetingData.meetingDetails?.meetingStatus
                                ?.status === "rescheduled"
                            ? "rescheduled-color"
                            : meetingData.meetingDetails?.meetingStatus
                                ?.status === "closed"
                            ? "closed-color"
                            : meetingData.meetingDetails?.meetingStatus
                                ?.status === "draft"
                            ? "draft-color"
                            : "canceled-color"
                        }`}
                      >
                        {meetingData.meetingDetails?.meetingStatus?.status
                          .charAt(0)
                          .toUpperCase() +
                          meetingData.meetingDetails?.meetingStatus?.status.slice(
                            1
                          )}
                      </span>
                    </div>
                  </div>
                </div>

                {meetingData.meetingDetails?.meetingStatus?.status ===
                  "rescheduled" ||
                meetingData.meetingDetails?.meetingStatus?.status ===
                  "cancelled" ? (
                  <div className="form-group">
                    <div className="row">
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                        <label className="mb-1">Reason</label>
                      </div>
                      <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                        <p>
                          {meetingData.meetingDetails?.meetingStatus?.remarks}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : null}
                {meetingData.meetingDetails.attendees.length !== 0 ? (
                  <>
                    <div className="row">
                      {meetingData.meetingDetails?.attendees.length > 0 ? (
                        <div className="form-group mb-0">
                          {meetingData?.meetingDetails?.attendees.filter(
                            (attendee) => attendee?.isEmployee === true
                          ).length > 0 && (
                            <div className="row align-items-center mb-2">
                              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                <label className="pb-1"> Attendees</label>
                              </div>

                              <div
                                className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                                onClick={() => {
                                  setOnlyAttendeeModalStatus(
                                    true,
                                    meetingData?.meetingDetails?.attendees
                                  );
                                }}
                              >
                                <div className="attendees">
                                  {meetingData?.meetingDetails?.attendees
                                    .filter(
                                      (attendee) =>
                                        attendee?.isEmployee === true
                                    )
                                    .filter((item, index) => index < 5)
                                    .map((guest) => (
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={tooltip(guest)}
                                        trigger={["hover", "focus"]}
                                      >
                                        <div
                                          // title={` ${
                                          //   meetingData?.meetingDetails?.createdById.toString() ===
                                          //   guest._id.toString()
                                          //     ? "Organiser"
                                          //     : "Employee"
                                          // }`}
                                          //  title={`${guest?.name} / ${guest?.email}`}
                                          className={`attendee-list ${
                                            meetingData?.meetingDetails?.createdById.toString() ===
                                            guest._id.toString()
                                              ? "attendees-border"
                                              : ""
                                          }`}
                                          key={guest._id}
                                        >
                                          {customName(guest.name)}
                                        </div>
                                      </OverlayTrigger>
                                    ))}
                                  <p className="m-0">
                                    {meetingData.meetingDetails?.attendees.filter(
                                      (guest) => guest.isEmployee === true
                                    ).length > 5
                                      ? `+${
                                          meetingData.meetingDetails?.attendees.filter(
                                            (guest) => guest.isEmployee === true
                                          ).length - 5
                                        } More`
                                      : null}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}

                          {meetingData?.meetingDetails?.attendees.filter(
                            (attendee) => attendee?.isEmployee === false
                          ).length > 0 && (
                            <div className="row align-items-center mb-2">
                              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                <label className="pb-1"> Guest(s) </label>
                              </div>
                              <div
                                className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                                onClick={() => {
                                  setGuestModalStatus(
                                    true,
                                    meetingData?.meetingDetails?.attendees
                                  );
                                }}
                              >
                                <div className="attendees" title="Guest">
                                  {meetingData?.meetingDetails?.attendees
                                    .filter(
                                      (attendee) =>
                                        attendee?.isEmployee === false
                                    )
                                    .filter((item, index) => index < 5)
                                    .map((guest) => (
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={tooltip(guest)}
                                        trigger={["hover", "focus"]}
                                      >
                                        <div
                                          className="attendee-list"
                                          key={guest._id}
                                          //  title={`${guest?.name} / ${guest?.email}`}
                                        >
                                          {customName(guest.name)}
                                        </div>
                                      </OverlayTrigger>
                                    ))}
                                  <p className="m-0">
                                    {meetingData.meetingDetails?.attendees.filter(
                                      (guest) => guest.isEmployee === false
                                    ).length > 5
                                      ? `+${
                                          meetingData.meetingDetails?.attendees.filter(
                                            (guest) =>
                                              guest.isEmployee === false
                                          ).length - 5
                                        } More`
                                      : null}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      ) : null}
                    </div>

                    {/* <div className="row align-items-center mb-3">
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                  <label className="pb-1"> Attendees </label>
                </div>
                <div
                  className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                  onClick={(e) => {
                    setModalStatus(true, meetingData.meetingDetails?.attendees);
                  }}
                >
                  <div className="attendees">
                    {meetingData.meetingDetails?.attendees &&
                      meetingData.meetingDetails?.attendees.map((attendee) => {
                        return (
                          <>
                            <div
                              className={`attendee-list${
                                meetingData.meetingDetails?.createdById ===
                                attendee._id.toString()
                                  ? " attendees-border"
                                  : ""
                              }`}
                              key={attendee?._id}
                            >
                              {customName(attendee?.name)}
                            </div>
                          </>
                        );
                      })}

                    <p className="m-0">
                      {meetingData.meetingDetails?.attendees.length > 5
                        ? `+${
                            meetingData.meetingDetails?.attendees.length - 5
                          } More`
                        : null}
                    </p>
                  </div>
                </div>
              </div> */}

                    <div className="form-group">
                      <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12"></div>
                        <div
                          className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                          onClick={(e) => {
                            setModalStatus(
                              true,
                              meetingData.meetingDetails?.attendees
                            );
                          }}
                        >
                          <div className="attendees"></div>
                        </div>
                      </div>
                      {!props.meetingId &&
                      (employeeData?.userData?.isAdmin === true ||
                        isMeetingOrganiser) === true &&
                      isWriteMinuteAllowed === true &&
                      isStatusAllowd === true ? (
                        <>
                          <div className="button-position btn-outerbox mt-3">
                            {meetingData.meetingDetails?.meetingStatus
                              ?.status === "scheduled" || "rescheduled" ? (
                              <button
                                className="add-minutes Mom-btn mt-2 me-2"
                                type="button"
                                onClick={(e) => {
                                  setAddAttendeesModal(
                                    true,
                                    meetingData.meetingDetails?.attendees
                                  );
                                }}
                              >
                                <p>Add New Attendees</p>
                              </button>
                            ) : null}
                            {meetingData.meetingDetails?.meetingStatus
                              ?.status === "scheduled" || "rescheduled" ? (
                              <button
                                className="add-minutes Mom-btn mt-2 me-2"
                                type="button"
                                onClick={(e) => {
                                  setAttendanceModalStatus(
                                    true,
                                    meetingData.meetingDetails?.attendees
                                  );
                                }}
                              >
                                <p>Add Attendance & Time</p>
                              </button>
                            ) : null}
                          </div>
                        </>
                      ) : null}
                    </div>
                  </>
                ) : null}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <div className="minutes-border"></div>
          {meetingData?.agendasDetail?.length !== 0 ? (
            <div className="form-group agenda">
              <label className="mt-3 mb-3 add-agenda">
                <h4>Agenda(s)</h4>
              </label>

              {meetingData?.agendaDetails?.length !== 0 &&
                meetingData?.agendaDetails?.map((agenda, index) => {
                  return (
                    <Accordion>
                      <div className="mt-2 agenda-box-border">
                        <Accordion.Item
                          eventKey={index.toString()}
                          className="agenda-accordion"
                        >
                          <Accordion.Header>
                          <span>Agenda {index + 1}:&nbsp;</span> 
                            {agenda.title?.length > 50 ? (
                              <OverlayTrigger
                                trigger={["hover", "focus"]}
                                placement="bottom"
                                overlay={popoverHoverFocus(agenda.title)}
                              >
                                <span className="meeting-title-hover">
                                  {`${agenda.title.slice(0, 50)}....`}
                                </span>
                              </OverlayTrigger>
                            ) : (
                              <span className="meeting-title-hover">
                                {agenda.title}
                              </span>
                            )}
                          </Accordion.Header>
                          <Accordion.Body>
                            {/* <div className="form-group  m-0">
                        <div className="topic-head p-2 d-flex align-items-center justify-content-between">
                          <div>
                            <label> Agenda {index + 1}</label>
                          </div>
                        </div>
                      </div> */}
                            <div className="">
                              <div className="form-group">
                                <div className="row">
                                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                    <label className="mt-1 mb-1">
                                      Agenda Title
                                    </label>
                                  </div>
                                  <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                    <p> {agenda.title}</p>
                                  </div>
                                </div>
                              </div>
                              {checkEmptyString(agenda?.topic) === null ||
                              checkEmptyString(agenda?.topic) === " " ||
                              checkEmptyString(agenda?.topic) ===
                                undefined ? null : (
                                <>
                                  <div className="form-group">
                                    <div className="row">
                                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                        <label className="mt-2 topic">
                                          Topic to Discuss
                                        </label>
                                      </div>
                                      <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                        <div className="mb-2 text-editor">
                                          {agenda?.topic
                                            ? parse(agenda?.topic)
                                            : null}
                                        </div>

                                        {/* <div className="mb-2 text-editor">
                                          {getStringLengthAfterHTMLParse(
                                            agenda.topic
                                          ) < 20 ? (
                                            <span>{parse(agenda.topic)}</span>
                                          ) : (
                                            <div>
                                              {`${sliceHtmlEncodedString(
                                                agenda.topic,
                                                20
                                              )}....`}
                                              <button
                                                type="button"
                                                className="badge bg-opacity-10 scheduled-color"
                                                onClick={() =>
                                                  setViewAgendaModalStatus(
                                                    true,
                                                    agenda
                                                  )
                                                }
                                              >
                                                {" "}
                                                View More
                                              </button>
                                            </div>
                                          )}
                                        </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                              <div className=" form-group">
                                <div className="row">
                                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                    <label className="mt-1 mb-1">
                                      Timeline
                                    </label>
                                  </div>
                                  <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                    <p> {agenda.timeLine} Min</p>
                                  </div>
                                </div>
                              </div>
                              <div className=" form-group">
                                <div className="row">
                                  <div className="col-md-4">
                                    <label className="mt-1 mb-1">
                                      Total Minutes
                                    </label>
                                  </div>
                                  <div className="col-md-8">
                                    <p> {agenda?.minutesDetail?.length}</p>
                                  </div>
                                </div>
                              </div>

                              <div className=" form-group m-0">
                                <div className="row">
                                  <div className="col-md-4">
                                    <label className="mt-1 mb-1">Status</label>
                                  </div>
                                  <div className="col-md-8">
                                    <p>
                                      {" "}
                                      {agenda?.minutesDetail?.length !== 0 ? (
                                        <Badge bg="success"> Discussed</Badge>
                                      ) : (
                                        <span className="badge pending-color bg-opacity-10 ">
                                          Pending
                                        </span>
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              {agenda?.minutesDetail?.filter(
                                (item) => item.isActive === true
                              ).length !== 0 ? (
                                <>
                                  <div></div>
                                  <div className=" minutes-border"></div>

                                  {agenda?.minutesDetail?.filter(
                                    (item) => item.isActive === true
                                  ).length !== 0 &&
                                    agenda?.minutesDetail
                                      ?.filter((item) => item.isActive === true)
                                      .map((minute, index) => {
                                        return (
                                          <>
                                            <div className="agenda-minutes">
                                              <div className=" form-group ">
                                                <div className="row">
                                                  <div className="col-md-4">
                                                    <label className="mt-1 mb-1">
                                                      Minutes {index + 1}
                                                    </label>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <p> {minute?.title}</p>
                                                  </div>
                                                  {minute.status ===
                                                  "Published" ? null : (
                                                    <div className="col-md-2 d-flex justify-content-end">
                                                      <div className={isViewMinutesPage?"btn-outerbox not-visible-btn":"btn-outerbox"}>
                                                        {!props.meetingId &&
                                                        (isMeetingOrganiser ===
                                                          true ||
                                                          employeeData?.userData
                                                            ?.isAdmin ===
                                                            true ||
                                                          meetingData
                                                            ?.meetingDetails
                                                            ?.canWriteMOM ===
                                                            true) ? (
                                                          <div className="d-inline-block menu-dropdown custom-dropdown">
                                                            <MinutesActionDropDown
                                                              minuteId={
                                                                minute._id
                                                              }
                                                              isShowDelete={
                                                                true
                                                              }
                                                              handleRequestForEditModal={() => {
                                                                handleEditMinuteModal(
                                                                  true,
                                                                  minute
                                                                );
                                                              }}
                                                              handleRequestForDeleteModal={() => {
                                                                handleDeleteMinuteModal(
                                                                  true,
                                                                  minute
                                                                );
                                                              }}
                                                              minute={minute}
                                                            />
                                                          </div>
                                                        ) : null}
                                                      </div>
                                                    </div>
                                                  )}

                                                  {checkEmptyString(
                                                    minute?.description
                                                  ) === null ||
                                                  checkEmptyString(
                                                    minute?.description
                                                  ) === " " ||
                                                  checkEmptyString(
                                                    minute?.description
                                                  ) === undefined ? null : (
                                                    <>
                                                      <div className="col-md-4">
                                                        <label className="mt-1 mb-1">
                                                          Description
                                                        </label>
                                                      </div>
                                                      <div className="col-md-8">
                                                        <div className="text-editor">
                                                          {minute?.description
                                                            ? parse(
                                                                minute?.description
                                                              )
                                                            : null}
                                                        </div>
                                                      </div>
                                                    </>
                                                  )}

                                                  {/* <div className="text-editor">
                                                      {" "}
                                                      {getStringLengthAfterHTMLParse(
                                                        minute.description
                                                      ) < 20 ? (
                                                        <span>
                                                          {parse(
                                                            minute.description
                                                          )}
                                                        </span>
                                                      ) : (
                                                        <div>
                                                          {`${sliceHtmlEncodedString(
                                                            minute.description,
                                                            20
                                                          )}....`}
                                                          <button
                                                            type="button"
                                                            className="badge bg-opacity-10 scheduled-color"
                                                            onClick={() =>
                                                              setViewMinuteModalStatus(
                                                                true,
                                                                minute
                                                              )
                                                            }
                                                          >
                                                            {" "}
                                                            View More
                                                          </button>
                                                        </div>
                                                      )}
                                                     
                                                    </div> */}
                                                </div>
                                              </div>
                                              <div className=" form-group ">
                                                <div className="row">
                                                  <div className="col-md-4">
                                                    <label className="mt-1 mb-1">
                                                      Status
                                                    </label>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <p>
                                                      {minute.status ===
                                                      "DRAFT" ? (
                                                        <span className="badge bg-opacity-10 draft-color">
                                                          {convertFirstLetterToCapital(
                                                            minute.status
                                                          )}
                                                        </span>
                                                      ) : (
                                                        <span className="badge bg-opacity-10 scheduled-color">
                                                          {convertFirstLetterToCapital(
                                                            minute.status
                                                          )}
                                                        </span>
                                                      )}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>

                                              {minute.isAction ? (
                                                <>
                                                  <div className=" form-group">
                                                    <div className="row">
                                                      <div className="col-md-4">
                                                        <label className="mt-1 mb-1">
                                                          Action Due Date
                                                        </label>
                                                      </div>
                                                      <div className="col-md-8">
                                                        <p>
                                                          {" "}
                                                          {
                                                            formatDateTimeFormat(
                                                              minute?.mainDueDate
                                                            ).formattedDate
                                                          }
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>

                                                  {minute?.parentMinuteId ? (
                                                    <div className="form-group">
                                                      <div className="row">
                                                        <div className="col-md-4">
                                                          <label className="mb-1 mb-1">
                                                            Assigned Due Date
                                                          </label>
                                                        </div>
                                                        <div className="col-md-8">
                                                          <p>
                                                            {" "}
                                                            {
                                                              formatDateTimeFormat(
                                                                minute?.dueDate
                                                              ).formattedDate
                                                            }
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ) : null}

                                                  <div className=" form-group">
                                                    <div className="row">
                                                      <div className="col-md-4">
                                                        <label className="mt-1 mb-1">
                                                          Priority
                                                        </label>
                                                      </div>
                                                      <div className="col-md-8">
                                                        <p>
                                                          {" "}
                                                          {minute.priority
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                            minute.priority
                                                              .slice(1)
                                                              .toLowerCase()}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  {minute?.isAction ? (
                                                    <div className=" form-group">
                                                      <div className="row">
                                                        <div className="col-md-4">
                                                          <label className="mt-1 mb-1">
                                                            Responsible Person
                                                          </label>
                                                        </div>
                                                        <div className="col-md-8">
                                                          <p>
                                                            {minute
                                                              ?.assignedUserDetails
                                                              ?.name
                                                              ? convertFirstLetterOfFullNameToCapital(
                                                                  minute
                                                                    ?.assignedUserDetails
                                                                    ?.name
                                                                )
                                                              : null}
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ) : null}
                                                </>
                                              ) : null}

                                              {minute.reassignDetails ? (
                                                <div className=" form-group">
                                                  <div className="row">
                                                    <div className="col-md-4">
                                                      <label className="mt-1 mb-1">
                                                        Reassigned To
                                                      </label>
                                                    </div>
                                                    <div className="col-md-8 detail ">
                                                      <p>Rakesh Baral</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : null}

                                              {minute?.attendees?.length ==
                                              0 ? (
                                                <>
                                                  <div className="form-group mt-4">
                                                    <div className="row">
                                                      <div className="col-md-4">
                                                        <label className="mt-2 topic">
                                                          Accepted By
                                                        </label>
                                                      </div>
                                                      <div
                                                        className="col-md-8"
                                                        onClick={() =>
                                                          updateIsMinutesRsvpStatusModal(
                                                            "Accepted",
                                                            minute?.attendees?.filter(
                                                              (attendee) =>
                                                                attendee.status ===
                                                                "ACCEPTED"
                                                            )
                                                          )
                                                        }
                                                      >
                                                        {minute?.attendees?.filter(
                                                          (attendee) =>
                                                            attendee.status ===
                                                            "ACCEPTED"
                                                        ).length !== 0 &&
                                                          minute?.attendees
                                                            ?.filter(
                                                              (attendee) =>
                                                                attendee.status ===
                                                                "ACCEPTED"
                                                            )
                                                            .map((attendee) => {
                                                              return (
                                                                <>
                                                                  <div className="attendees mb-2">
                                                                    <div className="attendee-list">
                                                                      {customName(
                                                                        attendee.name
                                                                      )}
                                                                    </div>
                                                                    {}

                                                                    <p className="plus-more-text m-0">
                                                                      {minute
                                                                        .attendees
                                                                        .length >
                                                                      5
                                                                        ? `+${
                                                                            minute
                                                                              .attendees
                                                                              .length -
                                                                            5
                                                                          } More`
                                                                        : null}
                                                                    </p>
                                                                  </div>
                                                                </>
                                                              );
                                                            })}

                                                        <p>
                                                          Accepted by{" "}
                                                          {
                                                            minute?.attendees?.filter(
                                                              (attendee) =>
                                                                attendee.status ===
                                                                "ACCEPTED"
                                                            ).length
                                                          }
                                                          /
                                                          {
                                                            minute.attendees
                                                              .length
                                                          }{" "}
                                                          attendants
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>

                                                  <div className="form-group mt-4">
                                                    <div className="row">
                                                      <div className="col-md-4">
                                                        <label className="mt-2 topic">
                                                          Rejected By
                                                        </label>
                                                      </div>
                                                      <div
                                                        className="col-md-8"
                                                        onClick={() =>
                                                          updateIsMinutesRsvpStatusModal(
                                                            "Rejected",
                                                            minute?.attendees?.filter(
                                                              (attendee) =>
                                                                attendee.status ===
                                                                "REJECTED"
                                                            )
                                                          )
                                                        }
                                                      >
                                                        {minute?.attendees?.filter(
                                                          (attendee) =>
                                                            attendee.status ===
                                                            "REJECTED"
                                                        ).length !== 0 &&
                                                          minute?.attendees
                                                            ?.filter(
                                                              (attendee) =>
                                                                attendee.status ===
                                                                "REJECTED"
                                                            )
                                                            .map((attendee) => {
                                                              return (
                                                                <>
                                                                  <div className="attendees mb-2">
                                                                    <div className="attendee-list">
                                                                      {customName(
                                                                        attendee.name
                                                                      )}
                                                                    </div>
                                                                    {}

                                                                    <p className="plus-more-text m-0">
                                                                      {minute
                                                                        .attendees
                                                                        .length >
                                                                      5
                                                                        ? `+${
                                                                            minute
                                                                              .attendees
                                                                              .length -
                                                                            5
                                                                          } More`
                                                                        : null}
                                                                    </p>
                                                                  </div>
                                                                </>
                                                              );
                                                            })}

                                                        <p>
                                                          Rejected by{" "}
                                                          {
                                                            minute?.attendees?.filter(
                                                              (attendee) =>
                                                                attendee.status ===
                                                                "REJECTED"
                                                            ).length
                                                          }
                                                          /
                                                          {
                                                            minute.attendees
                                                              .length
                                                          }{" "}
                                                          attendants
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : null}
                                              {!isMeetingOrganiser &&
                                              props.meetingId ? (
                                                <div>
                                                  <></>
                                                  <div className=" minutes-border"></div>
                                                </div>
                                              ) : null}

                                              {isMeetingOrganiser &&
                                              minute?.amendmentDetails
                                                ?.length !== 0 ? (
                                                <div className="added-by">
                                                  {minute?.amendmentDetails?.map(
                                                    (amendment) => {
                                                      return (
                                                        <>
                                                          <div className="add-agenda">
                                                            <p className="add-text">
                                                              Amendment
                                                              Requested By
                                                            </p>
                                                            <div className="attendee1 attendee-list">
                                                              {customName(
                                                                amendment.name
                                                              )}
                                                            </div>
                                                          </div>
                                                          <div
                                                            className="view"
                                                            onClick={() => {
                                                              handleAmendmentUpdateModal(
                                                                minute._id,
                                                                amendment.details,
                                                                amendment.createdById
                                                              );
                                                            }}
                                                          >
                                                            <p>View Details</p>
                                                          </div>

                                                          <div className="add-agenda">
                                                            <div className="pe-2">
                                                              {minuteData.loading &&
                                                              minuteData.status ===
                                                                "REJECTED" ? (
                                                                <LoaderButton />
                                                              ) : (
                                                                <button
                                                                  className="add-minutes Mom-btn"
                                                                  type="button"
                                                                  onClick={() =>
                                                                    handleAmendmentUpdateByButton(
                                                                      "ACCEPTED",
                                                                      amendment.createdById,
                                                                      minute._id
                                                                    )
                                                                  }
                                                                >
                                                                  <p>Accept</p>
                                                                </button>
                                                              )}
                                                            </div>

                                                            <div>
                                                              {minuteData.loading &&
                                                              minuteData.status ===
                                                                "REJECTED" ? (
                                                                <LoaderButton />
                                                              ) : (
                                                                <button
                                                                  className=" reset"
                                                                  type="button"
                                                                  onClick={() =>
                                                                    handleAmendmentUpdateByButton(
                                                                      "REJECTED",
                                                                      amendment.createdById,
                                                                      minute._id
                                                                    )
                                                                  }
                                                                >
                                                                  <p>Reject</p>
                                                                </button>
                                                              )}
                                                            </div>
                                                          </div>
                                                        </>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              ) : null}
                                            </div>
                                            <div className=" minutes-border"></div>
                                          </>
                                        );
                                      })}
                                </>
                              ) : null}
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </div>
                    </Accordion>
                  );
                })}

              {meetingData?.meetingDetails?.attendees?.length !== 0 &&
              !meetingData?.meetingDetails?.attendees.find(
                (attendee) =>
                  attendee._id.toString() ===
                  employeeData?.userData?._id?.toString()
              ) ? null : (
                // {employeeData?.userData?.isAdmin === true ? null : (
                <>
                  <br></br>
                  {
                    //employeeData?.userData?.isMeetingOrganiser === false &&
                    props.meetingId && isMomAccepted === false ? (
                      <div className="mom-acceptance">
                        <div className="meeting-organiser d-flex align-items-center">
                          <input
                            type="checkbox"
                            name="isAccepted"
                            checked={isAccepted}
                            onChange={() => {
                              setIsAccepted(!isAccepted);
                            }}
                          />
                          <span>
                            By acknowledging this Minutes of Meeting (MOM), you
                            confirm the accuracy and completeness of the
                            discussions and agreements recorded herein.{" "}
                          </span>
                        </div>
                        <button
                          className="add-minutes Mom-btn mt-2"
                          type="button"
                          onClick={(e) => {
                            acceptMOMData(meetingData.meetingDetails._id);
                          }}
                        >
                          <p>I Accept the MOM</p>
                        </button>
                      </div>
                    ) : // employeeData?.userData?.isMeetingOrganiser === false &&
                    props.meetingId && isMomAccepted ? (
                      <div className="mom-acceptance meeting-organiser d-flex align-items-center">
                        <button
                          className="add-minutes Mom-btn mt-2"
                          type="button"
                        >
                          <p>MOM Accepted</p>
                        </button>
                      </div>
                    ) : null
                  }
                </>
              )}
            </div>
          ) : null}
        </form>
      ) : null}
      {props.meetingId ? null : (
        <>
          <form className="mt-4 details-form details-form-right">
            <MeetingAttendance />
          </form>
          <form className="mt-4 details-form details-form-right">
            <MeetingActivities />
          </form>
        </>
      )}

      <GenerateMOMModal
        message={"Do you want to generate the MOM report ?"}
        title={"Generate MOM"}
        setIsModalOpen={setIsCloseModalOpen}
        isModalOpen={isCloseModalOpen}
        handleSubmit={generateMOMDetails}
        buttonName={"Cancel"}
        isCalledFromOthers={true}
      />

      <AmendmentRequestViewModal
        handleSubmit={handleAmendmentUpdate}
        details={details}
        setAmendmentViewModal={setAmendmentViewModal}
        amendmentViewModal={amendmentViewModal}
      />

      <AmendmentRequestModal
        amendmentModal={amendmentModal}
        setAmendmentModal={setAmendmentModal}
        handleSubmit={handleAmendmentRequest}
      />
      <AttendeesModal
        IsModalOpen={isModalOpen}
        attendees={attendeesData}
        setIsModalOpen={setIsModalOpen}
        loginUserData={employeeData}
        isShow={false}
        isAttendace={true}
        meetingData={meetingData?.meetingDetails}
      />
      <AddAttendeesModal
        isAddAttendeeModal={isAddAttendeeModal}
        attendees={attendeesData}
        setIsAddAttendeeModal={setIsAddAttendeeModal}
        meetingData={meetingData?.meetingDetails}
        sendSingleNotification={true}
      />
      <DeleteMeetingModal
        meetingId={minuteDetails?.meetingId}
        minuteId={minuteDetails?._id}
        IsDeleteMinuteModal={isDeleteMinuteModal}
        setIsDeleteMinuteModal={setIsDeleteMinuteModal}
        accessToken={accessToken}
      />
      <EditMinuteModal
        IsEditMinuteModal={isEditMinuteModal}
        minuteData={minuteDetails}
        setIsEditMinuteModal={setIsEditMinuteModal}
      />

      <AttendanceModal
        IsAttendanceModalOpen={isAttendaceModalOpen}
        attendees={attendeesData}
        SetIsAttendanceModalOpen={setIsAttendaceModalOpen}
        loginUserData={employeeData}
        meetingData={meetingData?.meetingDetails}
      />

      <RescheduleMeetingModal
        setIsRescheduleModalOpen={setIsRescheduleModalOpen}
        isRescheduleModalOpen={isRescheduleModalOpen}
        meetingData={meetingData?.meetingDetails}
        isFetchedMeetingList={meetingData.isFetchedMeetingList}
        isNewMeeting={true}
      />

      <MinutesRsvpStatusModal
        IsMinutesRsvpStatusModal={isMinutesRsvpStatusModal}
        attendees={attendeesData}
        setIsMinutesRsvpStatusModal={setIsMinutesRsvpStatusModal}
        status={status}
      />
      <GuestAttendeesModal
        IsGuestModalOpen={isGuestModalOpen}
        attendees={attendeesData}
        setIsGuestModalOpen={setIsGuestModalOpen}
      />
      <OnlyAttendeeModal
        IsOnlyAttendeeModalOpen={isOnlyAttendeeModalOpen}
        attendees={attendeesData}
        setIsOnlyAttendeeModalOpen={setIsOnlyAttendeeModalOpen}
        meetingData={meetingData}
      />
      <ViewMinuteModal
        minuteData={minuteDatas}
        IsViewMinuteModal={isViewMinuteModal}
        setIsViewMinuteModal={setIsViewMinuteModal}
      />

      <ViewAgendaModal
        agendaData={agendaDatas}
        IsViewAgendaModal={isViewAgendaModal}
        setIsViewAgendaModal={setIsViewAgendaModal}
      />
    </>
  );
};

export default ViewMinutes;
