import axios from "axios";
import {
  CREATE_MEETING_RESPONSE,
  FAIL_REQUEST,
  GET_ATTENDEES_LIST,
  GET_MEETING_LIST,
  MAKE_REQUEST,
  MAKE_RSVP_UPDATE_REQUEST,
  UPDATE_RSVP,
  GET_CREATE_MEETING_STEPS,
  UPDATE_ISCREATE_MEETING_PROCESSED,
  UPDATE_MEETING_RESPONSE,
  LOAD_PREVIOUS_STEP,
  SET_SINGLE_MEETING_DETAILS,
  SET_MEETING_VIEW_PAGE,
  SET_CREATE_NEW_MEETING_PAGE,
  UNSET_SINGLE_MEETING_DETAILS,
  UPDATE_STEP,
  UPDATE_FETCH_MEETING_LIST_STATUS,
  SET_AGENDA_AND_MINUTES_DETAILS,
  SET_MEETING_ACTIVITIES_DETAILS,
  SET_USER_MEETING_LIST,
  UPDATE_ATTENDANCE_RESPONSE,
  UPDATE_MEETING_ISSUCCESS,
  SET_DOWNLOAD_MOM_RESPONSE,
  SET_TIMELINE_RESPONSE,
  SET_PARENT_AGENDA_AND_MINUTES_DETAILS,
  SET_GENERATE_MOM_RESPONSE,
  SET_MEETING_STATISTICS_RESPONSE,
  MAKE_STATISTIC_REQUEST,
  UPDATE_ISSHOW_UPDATE_NOTIFY,
  SEND_MEETING_DETAILS_RESPONSE,
  GET_CURRENT_ATTENDEES_LIST,
  SET_TOTAL_MEETING_LIST_FOR_CHART,
  SET_MEETING_TOTAL_DUE_ACTION_PRIORITY_WISE,
  SET_SUCCESS,
  SET_DELETE_ZOOM_RECORDING_RESPONSE,
  SET_DOWNLOAD_ALL_ZOOM_RECORDING_RESPONSE,
  SET_ERROR_MESSAGE,
  SET_SINGLE_MEETING_DETAILS_NOT_UPDATE_STEP,
  MAKE_UPDATE_REQUEST,
  SET_IS_NEW_MEETING_PAGE_PAGE,
} from "./actionTypes";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";
import "react-toastify/dist/ReactToastify.css";
import * as constantMessages from "../../../constants/constatntMessages";
import { setInValidUser } from "../authActions/authAction";
import { convertFirstLetterToCapital } from "../../../helpers/commonHelpers";

const accessToken = localStorage.getItem("accessToken");

// axios.defaults.headers = {
//   "Content-Type": "application/json",
//   Authorization: accessToken,
// };

export const setSuccess = (data) => {
  return {
    type: SET_SUCCESS,
    payload: data,
  };
};

export const makeStastisticRequest = () => {
  return {
    type: MAKE_STATISTIC_REQUEST,
  };
};
export const makeUpdateRequest = (value) => {
  return {
    type: MAKE_UPDATE_REQUEST,
    payload: value,
  };
};

export const makeRequest = () => {
  return {
    type: MAKE_REQUEST,
  };
};

export const failRequest = (err) => {
  return {
    type: FAIL_REQUEST,
    payload: err,
  };
};

export const fetchMeetingList = (payload) => {
  return (dispatch) => {
    dispatch(makeRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/viewAllMeetings`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: payload.accessToken,
      },
      params: {
        limit: payload.limit,
        page: payload.page,
        order: payload.order,
      },
    };

    const bodyPayload = {
      organizationId: payload.organizationId,
      searchKey: payload.searchKey ? payload.searchKey : undefined,
      meetingStatus: payload.meetingStatus ? payload.meetingStatus : undefined,
      toDate: payload.toDate ? payload.toDate : undefined,
      fromDate: payload.fromDate ? payload.fromDate : undefined,
      attendeeId: payload.attendeeId ? payload.attendeeId : undefined,
    };
    axios
      .post(webApiUrl, bodyPayload, headerObject)
      .then((result) => {
        const resData = result.data;

        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        else{
          dispatch(setInValidUser(false));
        }
        if (
          (!resData.success && resData.data?.totalCount !== 0) ||
          resData.data?.isInValidUser
        ) {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }

        dispatch(getMeetingList(resData));
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const getMeetingList = (data) => {
  return {
    type: GET_MEETING_LIST,
    payload: data,
  };
};

export const updateIsNewMeetingPage=(data)=>{
  return{
    type:SET_IS_NEW_MEETING_PAGE_PAGE,
    payload:data
  }
}

export const fetchAttendeesList = (organizationId,meetingCreatedById, token) => {
  return (dispatch) => {
    dispatch(makeRsvpRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/listAttendeesFromPreviousMeeting/${organizationId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
const payload={
  meetingCreatedById:meetingCreatedById?meetingCreatedById:undefined
}
    axios
      .post(webApiUrl,payload, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
          // toast.error(resData.message, {
          //   position: "top-right",
          //   autoClose: 4000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "colored",
          // });
        }
        else{
          dispatch(setInValidUser(false));
        }
        dispatch(getAttendeesList(resData));
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const fetchCurrentAttendeesList = (
  organizationId,
  parentMeetingId,
  token
) => {
  return (dispatch) => {
    dispatch(makeRsvpRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/fetchCurrentAttendeesList`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    axios
      .post(webApiUrl, { organizationId, parentMeetingId }, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        else{
          dispatch(setInValidUser(false));
        }
        dispatch(getCurrentAttendeesList(resData));
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const getAttendeesList = (data) => {
  return {
    type: GET_ATTENDEES_LIST,
    payload: data,
  };
};

export const getCurrentAttendeesList = (data) => {
  return {
    type: GET_CURRENT_ATTENDEES_LIST,
    payload: data,
  };
};
export const makeRsvpRequest = () => {
  return {
    type: MAKE_RSVP_UPDATE_REQUEST,
  };
};
export const updateRsvp = (rsvp, meetingId, accessToken) => {
  return (dispatch) => {
    dispatch(makeRsvpRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/updateRsvp/${meetingId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    const bodyPayload = {
      rsvp,
    };
    axios
      .put(webApiUrl, bodyPayload, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        else{
          dispatch(setInValidUser(false));
        }
        if (resData.success) {
          toast.success(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        dispatch(updateRsvpStatus(resData));
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const updateRsvpStatus = (data) => {
  return {
    type: UPDATE_RSVP,
    payload: data,
  };
};

export const createMeetingDetails = (payload, accessToken) => {
  return (dispatch) => {
    dispatch(makeUpdateRequest(true));
    dispatch(setErrorMessage(null));
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/createMeeting`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .post(webApiUrl, payload, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        else{
          dispatch(setInValidUser(false));
        }
        if (resData.success) {
          dispatch(getSingleMeetingDetails(resData.data._id, accessToken));

          toast.success(constantMessages.meetingCreated, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(createMeetingResponse(resData));
          dispatch(updateStep(1, false));
        } else {


          if (
            Array.isArray(resData?.message) &&
            resData?.message?.length !== 0
          ) {
            dispatch(failRequest(resData.message));
            dispatch(setErrorMessage(resData.message[0]));
            toast.error(resData.message[0], {
              position: "top-right",
              autoClose: 6000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else {

            dispatch(setErrorMessage([resData.message]));
          dispatch(failRequest(resData.message));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        }
        dispatch(makeUpdateRequest(false));
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const createMeetingResponse = (data) => {
  return {
    type: CREATE_MEETING_RESPONSE,
    payload: data,
  };
};

export const getCreateMeetingStep = (organizationId, accessToken) => {
  return (dispatch) => {
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/getCreateMeetingStep/${organizationId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .get(webApiUrl, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        else{
          dispatch(setInValidUser(false));
        }
        dispatch(fetchCreateMeetingStep(resData));
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const fetchCreateMeetingStep = (data) => {
  return {
    type: GET_CREATE_MEETING_STEPS,
    payload: data,
  };
};

export const updateIsCreateMeetingProcessed = (data) => {
  return {
    type: UPDATE_ISCREATE_MEETING_PROCESSED,
    payload: data,
  };
};

export const updateMeetingDetails = (
  meetingId,
  bodyPayload,
  accessToken,
  isFrom,
  isUpdate,
  isPropCall,
  fromUpdate
) => {
  return (dispatch) => {
    dispatch(setErrorMessage(null));
    dispatch(makeUpdateRequest(true));
    dispatch(updateIsSuccess(false));
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/updateMeeting/${meetingId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };
    console.log(bodyPayload);
    axios
      .put(webApiUrl, bodyPayload, headerObject)
      .then((result) => {
        const resData = result.data;

        const message =
          isFrom === "addAttendee" && !isUpdate
            ? "Attendees added successfully. Please add agenda."
            : isFrom === "addAttendee" && isUpdate
            ? "Attendees updated successfully."
            : isFrom === "addAgenda" && !isUpdate
            ? "Agenda added and notification of meeting has been sent to all attendees successfully."
            : isFrom === "addAgenda" && isUpdate
            ? //  ? "Agenda updated successfully."
              "Meeting is updated and notification sent to all the attendees successfully."
            : resData.message;
        console.log(resData);
        if (resData.success) {
          dispatch(updateIsSuccess(true));

          toast.success(message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

         
          if (isFrom === "addAgenda" && isUpdate) {
            dispatch(updateIShowUpdateNotify(false));
          }
          if (isPropCall) {
            dispatch(getAgendaWithMinutesDetails(meetingId, accessToken));
          } else {
            if (fromUpdate) {
              console.log("aaaaaaa");
              getSingleMeetingDetailsForUpdate(meetingId, accessToken);
              resData.fromUpdate = true;
            } else {
              dispatch(updateStep(bodyPayload.step, true));
              console.log("bbbbbbbbb");
              resData.fromUpdate = false;
              dispatch(getSingleMeetingDetails(meetingId, accessToken));
            }

            dispatch(updateMeetingResponse(resData));
          }
          dispatch(setErrorMessage(null));
          if (bodyPayload?.step === 2) {
            // dispatch(updateStep(2))
          }
        } else {
          if (resData.data?.isInValidUser) {
            dispatch(setInValidUser(true));
          }
          else{
            dispatch(setInValidUser(false));
          }
          if (
            Array.isArray(resData?.message) &&
            resData?.message?.length !== 0
          ) {
            dispatch(setErrorMessage(resData.message[0]));
            toast.error(resData.message[0], {
              position: "top-right",
              autoClose: 6000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else {
            dispatch(setErrorMessage(resData.message));
            toast.error(resData.message, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }

          dispatch(failRequest(resData.message));
          if (resData?.message?.length !== 0) {
            dispatch(setErrorMessage(resData.message));
          }
        }
        dispatch(makeUpdateRequest(false));
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const updateMeetingResponse = (data) => {
  return {
    type: UPDATE_MEETING_RESPONSE,
    payload: data,
  };
};
export const updateIsSuccess = (data) => {
  return {
    type: UPDATE_MEETING_ISSUCCESS,
    payload: data,
  };
};
export const updateIShowUpdateNotify = (data) => {
  return {
    type: UPDATE_ISSHOW_UPDATE_NOTIFY,
    payload: data,
  };
};

export const getSingleMeetingDetails = (meetingId, accessToken) => {
  return (dispatch) => {
    dispatch(makeRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/viewMeeting/${meetingId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .get(webApiUrl, headerObject)
      .then((result) => {
        const resData = result.data;

        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        else{
          dispatch(setInValidUser(false));
        }
        if (resData.success === false) {
          dispatch(failRequest(resData.message));
        }
        if (!resData.success && !resData.data?.isInValidUser) {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          dispatch(failRequest(resData.message));
        }
        if (resData.success) {
          dispatch(setSingleMeetingDetails({ data: resData.data, meetingId }));
        }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const getSingleMeetingDetailsForUpdate = (meetingId, accessToken) => {
  return (dispatch) => {
    // dispatch(makeRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/viewMeeting/${meetingId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .get(webApiUrl, headerObject)
      .then((result) => {
        const resData = result.data;

        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        else{
          dispatch(setInValidUser(false));
        }
        if (resData.success === false) {
          dispatch(failRequest(resData.message));
        }
        if (!resData.success && !resData.data?.isInValidUser) {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          dispatch(failRequest(resData.message));
        }
        if (resData.success) {
          dispatch(
            setSingleMeetingDetailsForUpdate({ data: resData.data, meetingId })
          );
        }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const setSingleMeetingDetails = (data) => {
  return {
    type: SET_SINGLE_MEETING_DETAILS,
    payload: data,
  };
};
export const setSingleMeetingDetailsForUpdate = (data) => {
  return {
    type: SET_SINGLE_MEETING_DETAILS_NOT_UPDATE_STEP,
    payload: data,
  };
};

export const unSetSingleMeetingDetails = () => {
  return {
    type: UNSET_SINGLE_MEETING_DETAILS,
  };
};
export const updateStep = (step, isUpdateStep) => {
  return {
    type: UPDATE_STEP,
    payload: { step, isUpdateStep },
  };
};

export const loadCreateMeeting = (data) => {
  return {
    type: LOAD_PREVIOUS_STEP,
    payload: data,
  };
};
export const setMeetingViewPage = (data) => {
  return {
    type: SET_MEETING_VIEW_PAGE,
    payload: data,
  };
};

export const setCreateNewMeetingPage = (data) => {
  return {
    type: SET_CREATE_NEW_MEETING_PAGE,
    payload: data,
  };
};

// export const processCancelMeeting = (meetingId, bodyPayload, accessToken) => {
//   return (dispatch) => {
//     const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/cancelMeeting/${meetingId}`;

//     const headerObject = {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: accessToken,
//       },
//     };
//     dispatch(makeRequest());
//     axios
//       .put(webApiUrl, bodyPayload, headerObject)
//       .then((result) => {
//         const resData = result.data;
//         if (resData.data?.isInValidUser) {
//           dispatch(setInValidUser(true));
//         }
//         if (resData.success) {
//           dispatch(getSingleMeetingDetails(meetingId, accessToken));
//           toast.success(resData.message, {
//             position: "top-right",
//             autoClose: 4000,
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//             theme: "colored",
//           });
//           dispatch(updateFetchMeetingListStatus(true));
//         } else {
//           toast.error(resData.message, {
//             position: "top-right",
//             autoClose: 4000,
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//             theme: "colored",
//           });
//           dispatch(updateFetchMeetingListStatus(false));
//         }
//       })
//       .catch((err) => {
//         dispatch(failRequest(err.message));
//         toast.error(constantMessages.serverErrorMessage, {
//           position: "top-right",
//           autoClose: 4000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "colored",
//         });
//         dispatch(updateFetchMeetingListStatus(false));
//       });
//   };
// };


export const processCancelMeeting = (meetingId, bodyPayload, accessToken) => {
  return (dispatch) => {
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/cancelMeeting/${meetingId}`;

    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .put(webApiUrl, bodyPayload, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        else{
          dispatch(setInValidUser(false));
        }
        if (resData.success) {
          toast.success(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(updateFetchMeetingListStatus(true));
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(updateFetchMeetingListStatus(false));
        }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        dispatch(updateFetchMeetingListStatus(false));
      });
  };
};


export const updateFetchMeetingListStatus = (data) => {
  return {
    type: UPDATE_FETCH_MEETING_LIST_STATUS,
    payload: data,
  };
};

export const processCloseMeeting = (meetingId, bodyPayload, accessToken) => {
  return (dispatch) => {
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/updateMeetingStatus/${meetingId}`;

    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .put(webApiUrl, bodyPayload, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        else{
          dispatch(setInValidUser(false));
        }
        if (resData.success) {
          toast.success(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(updateFetchMeetingListStatus(true));
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(updateFetchMeetingListStatus(false));
        }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        dispatch(updateFetchMeetingListStatus(false));
      });
  };
};

export const getAgendaWithMinutesDetails = (meetingId, accessToken) => {
  return (dispatch) => {
    dispatch(makeRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/viewMeetingAgendaWithMinutes/${meetingId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .get(webApiUrl, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        else{
          dispatch(setInValidUser(false));
        }
        if (resData.success) {
          dispatch(updateFetchMeetingListStatus(false));
          dispatch(setAgendaWithMinutesDetails(resData.data));
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(failRequest(resData.message));
        }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const setAgendaWithMinutesDetails = (data) => {
  return {
    type: SET_AGENDA_AND_MINUTES_DETAILS,
    payload: data,
  };
};

export const viewParentMeeting = (meetingId, accessToken) => {
  return (dispatch) => {
    dispatch(makeRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/viewParentMeeting/${meetingId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .get(webApiUrl, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        else{
          dispatch(setInValidUser(false));
        }
        if (resData.success) {
          dispatch(setParentAgendaWithMinutesDetails(resData.data));
        }
        // else {
        //   toast.error(resData.message, {
        //     position: "top-right",
        //     autoClose: 4000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     theme: "colored",
        //   });
        // }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};
export const setParentAgendaWithMinutesDetails = (data) => {
  return {
    type: SET_PARENT_AGENDA_AND_MINUTES_DETAILS,
    payload: data,
  };
};

export const setMeetingDetails = (data) => {
  return {
    type: SET_PARENT_AGENDA_AND_MINUTES_DETAILS,
    payload: data,
  };
};

export const getMeetingActivitiesDetails = (meetingId, accessToken) => {
  return (dispatch) => {
    dispatch(makeRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/viewMeetingActivities/${meetingId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .get(webApiUrl, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        else{
          dispatch(setInValidUser(false));
        }
        if (resData.success) {
          dispatch(setMeetingActivitiesDetails(resData));
        } else {
          dispatch(failRequest(resData.message));
        }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const setMeetingActivitiesDetails = (data) => {
  return {
    type: SET_MEETING_ACTIVITIES_DETAILS,
    payload: data,
  };
};

export const fetchUserMeetingList = (organizationId, accessToken) => {
  return (dispatch) => {
    dispatch(makeRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/minute/getMeetingListOfAttendees/${organizationId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .get(webApiUrl, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        else{
          dispatch(setInValidUser(false));
        }
        if (resData.success) {
          dispatch(setUserMeetingList(resData));
        }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const setUserMeetingList = (data) => {
  return {
    type: SET_USER_MEETING_LIST,
    payload: data,
  };
};

export const updateAttendanceAndTimeRanges = (
  meetingId,
  bodyPayload,
  accessToken
) => {
  return (dispatch) => {
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/updateMeetingAttendance/${meetingId}`;

    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .put(webApiUrl, bodyPayload, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        else{
          dispatch(setInValidUser(false));
        }
        if (resData.success) {
          toast.success(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(updateAttendanceAndTimeRangesResponse(resData));
          dispatch(getMeetingActivitiesDetails(meetingId, accessToken));
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(updateAttendanceAndTimeRangesResponse(resData));
        }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        dispatch(updateFetchMeetingListStatus(false));
      });
  };
};

export const updateAttendanceAndTimeRangesResponse = (data) => {
  return {
    type: UPDATE_ATTENDANCE_RESPONSE,
    payload: data,
  };
};

export const generateMOM = (meetingId, bodyPayload, accessToken) => {
  return (dispatch) => {
    dispatch(makeRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/generateMOM/${meetingId}`;

    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .put(webApiUrl, bodyPayload, headerObject, {
        responseType: "blob",
      })

      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        else{
          dispatch(setInValidUser(false));
        }

        if (resData.success) {
          toast.success(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          dispatch(setGenerateMOMResponse(resData));
          dispatch(getAgendaWithMinutesDetails(meetingId, accessToken));
          dispatch(getMeetingActivitiesDetails(meetingId, accessToken));
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(setGenerateMOMResponse(resData));
        }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};
export const setGenerateMOMResponse = (data) => {
  return {
    type: SET_GENERATE_MOM_RESPONSE,
    payload: data,
  };
};

export const downloadMOM = (meetingId, token) => {
  return (dispatch) => {
    dispatch(makeRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/downloadMOM/${meetingId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    axios
      .get(webApiUrl, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        else{
          dispatch(setInValidUser(false));
        }

        if (resData.success) {
          toast.success(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          saveAs(resData.data, "mom.pdf");
          dispatch(setDownloadMomResponse(resData));
          dispatch(getMeetingActivitiesDetails(meetingId, token));
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(setDownloadMomResponse(resData));
        }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const setDownloadMomResponse = (data) => {
  return {
    type: SET_DOWNLOAD_MOM_RESPONSE,
    payload: data,
  };
};

// export const rescheduleMeeting = (meetingId, bodyPayload, accessToken) => {
//   return async (dispatch) => {
//     dispatch(setErrorMessage(null));
//     dispatch(makeUpdateRequest(true));
//     dispatch(makeRequest());
//     dispatch(updateFetchMeetingListStatus(false));
//     const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/rescheduleMeeting/${meetingId}`;
//     const headerObject = {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: accessToken,
//       },
//     };

//     axios
//       .put(webApiUrl, bodyPayload, headerObject)
//       .then((result) => {
//         console.log(result);
//         const resData = result.data;
//         if (resData.data?.isInValidUser) {
//           dispatch(setInValidUser(true));
//         }
//         console.log(resData);
//         if (resData.success) {
//           dispatch(updateFetchMeetingListStatus(true));
//           dispatch(getSingleMeetingDetails(meetingId, accessToken));
//           toast.success(resData.message, {
//             position: "top-right",
//             autoClose: 4000,
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//             theme: "colored",
//           });
//         } else {
//           console.log("dddddddddddd", resData.message);
//           if (resData?.message?.length !== 0) {
//             dispatch(setErrorMessage(resData.message));
//           }

//           // toast.error(resData.message, {
//           //   position: "top-right",
//           //   autoClose: 4000,
//           //   hideProgressBar: false,
//           //   closeOnClick: true,
//           //   pauseOnHover: true,
//           //   draggable: true,
//           //   progress: undefined,
//           //   theme: "colored",
//           // });
//         }
//         dispatch(makeUpdateRequest(false));
//       })
//       .catch((err) => {
//         console.log("eeeeeeeeeeeeeee");
//         dispatch(failRequest(err.message));
//         toast.error(constantMessages.serverErrorMessage, {
//           position: "top-right",
//           autoClose: 4000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "colored",
//         });
//       });
//   };
// };



export const rescheduleMeeting = (meetingId, bodyPayload, accessToken) => {
  return async (dispatch) => {
    dispatch(setErrorMessage(null));
    dispatch(makeRequest());
    dispatch(updateFetchMeetingListStatus(false));
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/rescheduleMeeting/${meetingId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .put(webApiUrl, bodyPayload, headerObject)
      .then((result) => {
        console.log(result);
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        else{
          dispatch(setInValidUser(false));
        }
        console.log(resData);
        if (resData.success) {
          dispatch(updateFetchMeetingListStatus(true));

          toast.success(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          console.log("dddddddddddd", resData.message);
          if (resData?.message?.length !== 0) {
            dispatch(setErrorMessage(resData.message));
          }

          // toast.error(resData.message, {
          //   position: "top-right",
          //   autoClose: 4000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "colored",
          // });
        }
      })
      .catch((err) => {
        console.log("eeeeeeeeeeeeeee");
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const giveMomWritePermission = (meetingId, bodyPayload, accessToken) => {
  return (dispatch) => {
    dispatch(makeRequest());
    dispatch(updateFetchMeetingListStatus(false));

    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/giveMomWritePermission/${meetingId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .put(webApiUrl, bodyPayload, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        else{
          dispatch(setInValidUser(false));
        }

        if (resData.success) {
          dispatch(updateFetchMeetingListStatus(true));
          toast.success(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const getTimelineList = (meetingId, token) => {
  return (dispatch) => {
    dispatch(makeRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/getTimelineList/${meetingId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    axios
      .get(webApiUrl, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        else{
          dispatch(setInValidUser(false));
        }

        if (resData.success) {
          toast.success(resData.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          dispatch(setTimelineResponse(resData));
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(setTimelineResponse(resData));
        }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const setTimelineResponse = (data) => {
  return {
    type: SET_TIMELINE_RESPONSE,
    payload: data,
  };
};

export const setErrorMessage = (data) => {
  return {
    type: SET_ERROR_MESSAGE,
    payload: data,
  };
};

export const viewMeetingStatistics = (organizationId, token) => {
  return (dispatch) => {
    dispatch(makeStastisticRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/viewMeetingStatistics/${organizationId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    axios
      .get(webApiUrl, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        else{
          dispatch(setInValidUser(false));
        }

        if (resData.success) {
          dispatch(setMeetingStatisticsResponse(resData));
        } else {
          // toast.error(resData.message, {
          //   position: "top-right",
          //   autoClose: 3000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "colored",
          // });
          dispatch(setMeetingStatisticsResponse(resData));
        }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const setMeetingStatisticsResponse = (data) => {
  return {
    type: SET_MEETING_STATISTICS_RESPONSE,
    payload: data,
  };
};

export const sendMeetingDetails = (payload, accessToken) => {
  return (dispatch) => {
    dispatch(makeUpdateRequest(true));
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/sendMeetingDetails`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .post(webApiUrl, payload, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        else{
          dispatch(setInValidUser(false));
        }
        if (resData.success) {
          toast.success(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(sendMeetingDetailsResponse(resData));
        } else {
          dispatch(failRequest(resData.message));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        dispatch(makeUpdateRequest(false));
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const sendMeetingDetailsResponse = (data) => {
  return {
    type: SEND_MEETING_DETAILS_RESPONSE,
    payload: data,
  };
};

export const newMeetingAsRescheduled = (
  meetingId,
  bodyPayload,
  accessToken
) => {
  return (dispatch) => {
    dispatch(makeRequest());
    // dispatch(updateFetchMeetingListStatus(false));

    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/newMeetingAsRescheduled/${meetingId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .put(webApiUrl, bodyPayload, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        else{
          dispatch(setInValidUser(false));
        }
        if (resData.success) {
          dispatch(updateFetchMeetingListStatus(true));
          toast.success(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const getMeetingListForChart = (organizationId, accessToken) => {
  return (dispatch) => {
    dispatch(makeRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/totalMeetingListForChart/${organizationId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .get(webApiUrl, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        else{
          dispatch(setInValidUser(false));
        }

        dispatch(setMeetingListForChart(resData));
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const setMeetingListForChart = (data) => {
  return {
    type: SET_TOTAL_MEETING_LIST_FOR_CHART,
    payload: data,
  };
};

export const getMeetingActionPriotityDetails = (payload, accessToken) => {
  return (dispatch) => {
    dispatch(makeRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/getMeetingActionPriotityDetails`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
      params: {
        limit: payload.limit,
        page: payload.page,
        order: payload.order,
      },
    };

    const bodyPayload = {
      organizationId: payload.organizationId,
      searchKey: payload?.searchKey ? payload?.searchKey : undefined,
    };
    axios
      .post(webApiUrl, bodyPayload, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        else{
          dispatch(setInValidUser(false));
        }
        dispatch(setMeetingTotalDueActionPriorityWise(resData));
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const setMeetingTotalDueActionPriorityWise = (data) => {
  return {
    type: SET_MEETING_TOTAL_DUE_ACTION_PRIORITY_WISE,
    payload: data,
  };
};

export const setDeleteZoomRecordingResponse = (data) => {
  return {
    type: SET_DELETE_ZOOM_RECORDING_RESPONSE,
    payload: data,
  };
};

export const updateRsvpByEmail = (payload) => {
  return (dispatch) => {
    // dispatch(makeRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/updateRsvpByEmail/${payload?.meetingId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const bodyPayload = {
      userId: payload?.userId,
      rsvp: payload?.rsvp,
    };
    axios
      .put(webApiUrl, bodyPayload, headerObject)
      .then(async (result) => {
        const resData = result.data;
        if (resData.success) {
          toast.success(
            await constantMessages.rsvpConfirmationMessage(
              convertFirstLetterToCapital(payload?.rsvp)
            ),
            {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const deleteZoomRecording = (payload, accessToken) => {
  return (dispatch) => {
    dispatch(makeRequest());
    dispatch(setSuccess(false));
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/deleteZoomRecording`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    const bodyPayload = {
      recordingId: payload?.recordingId,
      meetingId: payload?.meetingId,
      organizationId: payload?.organizationId,
    };
    axios
      .post(webApiUrl, bodyPayload, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.success) {
          dispatch(setSuccess(true));
          toast.success(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(setDeleteZoomRecordingResponse(resData));
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const downloadAllZoomRecordings = (downloadUrls, accessToken) => {
  return (dispatch) => {
    dispatch(makeRequest());
    dispatch(setSuccess(false));
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/downloadZoomRecordingsInZip`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
        responseType: "blob",
      },
    };

    const bodyPayload = {
      downloadUrls,
    };
    axios
      .post(webApiUrl, bodyPayload, headerObject)
      .then((result) => {
        console.log(result);
        const resData = result.data;
        console.log(resData);
        console.log(resData?.data);
        // Decode the Base64 string into a binary Blob
        const byteCharacters = atob(resData.data.file); // Decode Base64 to byte characters
        const byteArrays = [];

        // Convert the byte characters into a Uint8Array
        for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
          const slice = byteCharacters.slice(offset, offset + 1024);
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
        const zipBlob = new Blob(byteArrays, { type: "application/zip" });

        // Generate a URL for the Blob
        const url = window.URL.createObjectURL(zipBlob);

        // Create a temporary link element to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.download = resData.data.fileName; // Download as files.zip

        // Trigger the file download
        document.body.appendChild(link);
        link.click();

        // Cleanup
        link.remove();
        window.URL.revokeObjectURL(url);
        if (resData.success) {
          toast.success(resData?.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(setDownloadAllZoomRecordingResponse(resData));
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const setDownloadAllZoomRecordingResponse = (data) => {
  return {
    type: SET_DOWNLOAD_ALL_ZOOM_RECORDING_RESPONSE,
    payload: data,
  };
};

////////////////////

// attendee availability
export const checkAttendeeAvailability = (payload, meetingId, accessToken) => {
  return new Promise((resolve) => {
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/check-availability/${meetingId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };
    axios
      .post(webApiUrl, payload, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.success === false && resData.data == null) {
          resolve({ apiError: resData.message });
        } else if (resData.data?.isScheduleUser === false) {
          resolve({ apiError: "" });
        } else {
          resolve({ success: true });
        }
      })
      .catch((err) => {
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  });
};
