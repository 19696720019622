import React, { useState, useEffect, useRef } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import "bootstrap/dist/css/bootstrap.css";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import * as constantMessages from "../../constants/constatntMessages";
import {
  editAgenda,
  setSuccess,
} from "../../redux/actions/minuteActions/MinuteAction";
import parse from "html-react-parser";
import { Accordion, Tooltip, OverlayTrigger, Popover } from "react-bootstrap";
import {
  checkCharacterLeft,
  checkEmptyString,
  getStringLengthAfterHTMLParse,
} from "../../helpers/commonHelpers";
import { textBoxStrLength } from "../../config/config";
import ReactQuill from "../../../node_modules/react-quill/lib/index";
import LoaderButton from "../Common/LoaderButton";
import {
  makeRequest,
  setInValidUser,
} from "../../redux/actions/authActions/authAction";
import DeleteAgendaModal from "../Minutes/DeleteAgendaModal";
import {
  getSingleMeetingDetails,
  getSingleMeetingDetailsForUpdate,
  makeUpdateRequest,
} from "../../redux/actions/meetingActions/MeetingAction";
/// Styles
const overlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0,0,0,0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
const AddAgendaModal = (props) => {
  console.log(props);
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };

  const formats = ["header", "bold", "italic", "underline", "list", "bullet"];
  const regularExpression = /^[0-9a-zA-Z .,:;%@&()/_'"-]+$/;
  const regularExpressionForHtml = new RegExp(
    /<(\"[^\"]*\"|'[^']*'|[^'\">])*>/
  );
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("accessToken");
  const minuteData = useSelector((state) => state.minute);
  const { agendaData, meetingData } = props;
  const [errors, setErrors] = useState({});
  const [agendaDatas, setAgendaDatas] = useState([]);
  const [selectedAgendaId, setSelectedAgendaId] = useState(null);
  const [selectedAgenda, setSelectedAgenda] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  
  const [agendaNumber, setAgendaNumber] = useState(0);
  const [addLoading, setAddLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [agendaDatas2, setAgendaDatas2] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    // topic: "",
    timeLine: "0",
  });
  const [topic, setTopic] = useState("");
  const minuteDatas = useSelector((state) => state.minute);
  const meetingDatas = useSelector((state) => state.meeting);
  const [isDeleteAgendaModal, setIsDeleteAgendaModal] = useState(false);
  const handleSubmit = () => {
    const newErrors = validateForm(formData);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      props.addAgenda({
        ...formData,
        topic,
      });
      setTopic(null);
      setFormData({
        title: " ",
        timeLine: "0",
        // topic: "",
      });
    }
  };
  const setDeleteAgendaModalStatus = (status, agenda) => {
    dispatch(setSuccess(false));
    setAgendaDatas2(agenda);
    setIsDeleteAgendaModal(status);
  };
  const handleSubmit2 = () => {
    const newErrors = validateForm(formData);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      props.addAgenda({
        ...formData,
        topic,
      });

      const checkDuplicateTitle = agendaDatas?.find(
        (agenda) => agenda?.title.trim() === formData?.title.trim()
      );
      if (checkDuplicateTitle) {
        toast.error("Duplicate title found.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return;
      }

      if (meetingDatas?.singleMeetingDetails?.organizationId !== undefined) {
        const payload = {
          agendaData: [
            {
              topic,
              title: formData.title,
              timeLine: formData.timeLine.toString(),
              meetingId: meetingDatas?.singleMeetingDetails?._id,
              organizationId:
                meetingDatas?.singleMeetingDetails?.organizationId,
            },
          ],
          //  minutes: meetingData.finalAgendaMinutesData,
        };
        //dispatch(makeUpdateRequest(true));
        setAddLoading(true);
        const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/agenda/createAgendaWithMinutes`;
        const headerObject = {
          headers: {
            "Content-Type": "application/json",
            Authorization: accessToken,
          },
        };
        console.log(payload);
        axios
          .post(webApiUrl, payload, headerObject)
          .then((res) => {
            const resData = res.data;
            console.log(resData);
            if (resData.data?.isInValidUser) {
              dispatch(setInValidUser(true));
              toast.error(resData.message, {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }

            if (resData.success) {
              setTopic(null);
              setFormData({
                title: " ",
                timeLine: "0",
                // topic: "",
              });
              // dispatch(getAgendaWithMinutesDetails(meetingId, accessToken));
              // dispatch(getMeetingActivitiesDetails(meetingId, accessToken));
              toast.success(resData.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              setAgendaDatas([...agendaDatas, resData?.data]);
              // dispatch(
              //   getSingleMeetingDetails(
              //     meetingDatas?.singleMeetingDetails?._id,
              //     accessToken
              //   )
              // );
            } else {
              toast.error(resData.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
            setAddLoading(false);
            // dispatch(createAgendaResponse(resData));
          })
          .catch((err) => {
            // dispatch(failRequest(err.message));
            setAddLoading(false);
            toast.error(constantMessages.serverErrorMessage, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          });
      }
    }
  };

  const handleSubmit3 = () => {
    const newErrors = validateForm(formData);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setTopic(null);
      setFormData({
        title: " ",
        timeLine: "0",
        // topic: "",
      });
      // dispatch(makeRequest());
      const payload = {
        agendaData: [
          {
            topic,
            title: formData.title,
            timeLine: formData.timeLine.toString(),
            meetingId: meetingDatas?.singleMeetingDetails?._id,
            organizationId: meetingDatas?.singleMeetingDetails?.organizationId,
          },
        ],
      };

      const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/agenda/createAgendaWithMinutes`;
      const headerObject = {
        headers: {
          "Content-Type": "application/json",
          Authorization: accessToken,
        },
      };
      console.log(payload);
      setAddLoading(true);
      axios
        .post(webApiUrl, payload, headerObject)
        .then((res) => {
          const resData = res.data;
          console.log(resData);
          if (resData.data?.isInValidUser) {
            dispatch(setInValidUser(true));
            toast.error(resData.message, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
          if (resData.success) {
            // dispatch(getAgendaWithMinutesDetails(meetingId, accessToken));
            // dispatch(getMeetingActivitiesDetails(meetingId, accessToken));
            toast.success(resData.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setAgendaDatas([...agendaDatas, resData?.data]);
            // dispatch(
            //   getSingleMeetingDetails(
            //     meetingDatas?.singleMeetingDetails?._id,
            //     accessToken
            //   )
            // );
          } else {
            toast.error(resData.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
          // dispatch(createAgendaResponse(resData));
          setAddLoading(false);
        })
        .catch((err) => {
          // dispatch(failRequest(err.message));
          toast.error(constantMessages.serverErrorMessage, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    }
  };

  const deleteAgenda = (agendaId) => {
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/agenda/deleteAgenda/${agendaId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };
    const payload = {
      meetingId: meetingDatas?.singleMeetingDetails?._id,
    };
    //  minutes: meetingData.finalAgendaMinutesData,
    setDeleteLoading(true);
    axios
      .put(webApiUrl, payload, headerObject)
      .then((res) => {
        const resData = res.data;

        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        if (resData.success) {
          // dispatch(getSingleMeetingDetails(meetingDatas?.singleMeetingDetails?._id, accessToken));
          // props.setIsAddAgendaModal(true);
          toast.success(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          console.log(agendaDatas);
          const modiFiedData = agendaDatas.filter(
            (agenda) => agenda?._id !== agendaId
          );
          console.log(modiFiedData);
          setAgendaDatas(modiFiedData);
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        setDeleteLoading(false);
        setIsDeleteAgendaModal(false);
      })
      .catch((err) => {
        setDeleteLoading(false);
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const validateAgendaTitle = () => {
    const errors = {};
    if (!formData?.title.trim()) {
      errors.title = constantMessages.titleRequired;
    } else if (
      formData.title.trim().length < 5 ||
      formData.title.trim().length > 100
    ) {
      errors.title = constantMessages.stringLengthError;
    } else if (!regularExpression.test(formData.title)) {
      errors.title = constantMessages.titleRegexError;
    }
    setErrors(errors);
  };
  const validateAgendaTime = () => {
    const errors = {};

    if (formData.timeLine > 365 || formData.timeLine < 0) {
      errors.timeLine = constantMessages.invalidTime;
    }
    setErrors(errors);
  };

  const handleChange = (e, uid) => {
    setErrors({});
    // alert("in3");
    const { name, value } = e.target;

    // if (name === "topic") {
    //   if (value.length <= textBoxStrLength) {
    //     setFormData({
    //       ...formData,

    //       [name]: value,
    //     });
    //   }
    // } else {
    setFormData({
      ...formData,

      [name]: value,
    });
    // }
  };

  const validateForm = () => {
    //
    const errors = {};

    if (!formData?.title.trim()) {
      errors.title = constantMessages.titleRequired;
    } else if (
      formData.title.trim().length < 5 ||
      formData.title.trim().length > 100
    ) {
      errors.title = constantMessages.stringLengthError;
    } else if (!regularExpression.test(formData.title)) {
      errors.title = constantMessages.titleRegexError;
    }

    if (formData.timeLine > 365 || formData.timeLine < 0) {
      errors.timeLine = constantMessages.invalidTime;
    }
    // if (regularExpressionForHtml.test(formData.topic)) {
    //   errors.topic = constantMessages.htmlTagsNotAllowed;
    // }
    return errors;
  };

  const validateAgendaTopic = () => {
    const errors = {};

    // if (regularExpressionForHtml.test(formData.topic)) {
    //   errors.topic = constantMessages.htmlTagsNotAllowed;
    // }
    setErrors(errors);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
    // Block the 'e', 'E', '+', and '-' keys
    if (["e", "E", "+", "-"].includes(event.key)) {
      event.preventDefault();
    }
  };
  const handleValue = (value) => {
    console.log(value);
    //  alert("in2");
    setTopic(value);
    // setFormData({
    //   ...formData,
    //   topic: value,
    // });
  };

  const closeModal = () => {
    if (props?.fromMinute) {
    } else {
      if (agendaDatas?.length !== 0) {
        dispatch(
          getSingleMeetingDetailsForUpdate(
            meetingDatas?.singleMeetingDetails?._id,
            accessToken
          )
        );
      }
    }

    // alert("in");
    setTopic(null);
    setFormData({
      title: "",
      // topic: "",
      timeLine: "0",
    });
    props.setIsAddAgendaModal(false);
    setAgendaDatas([]);
  };
  const resetModal = (agenda) => {
    //alert(55)
    if (selectedAgendaId) {
      //  alert(12)
      setTopic(null);
      setFormData({
        ...formData,
        title: "",
        // topic: "",
        timeLine: "0",
      });
      setSelectedAgendaId(null);
      setErrors({});
    } else {
      //  alert(13)
      // alert("in");
      setTopic(null);
      setFormData({
        title: "",
        // topic: "",
        timeLine: "0",
      });
      setErrors({});
      setAgendaNumber(0)
    }
  };
  console.log(formData);
  const popoverHoverFocus = (title) => {
    return (
      <Popover id="popover-basic" className="custom-popover">
        {/* <Popover.Header as="h3" className="popover-header">
          Meeting Title.
        </Popover.Header> */}
        <Popover.Body className="popover-body">{title}</Popover.Body>
      </Popover>
    );
  };

  const setEdit = (agenda) => {
    setTimeout(() => {
      const topSection = document.getElementById("scroll-top-agenda");
      if (topSection) {
        topSection.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 100);
    //  alert(4444)
    window.scrollTo({ top: 0, behavior: "smooth" });
    setFormData({
      title: agenda?.title,
      // topic: "",
      timeLine: agenda?.timeLine,
    });
    setTopic(agenda?.topic);
    setSelectedAgendaId(agenda?._id);
    setSelectedAgenda(agenda);
    // props.goToTop(true)
  };
  const modalRef = useRef(null);
  const scrollToTop = () => {
    //  alert(34)
    if (modalRef.current) {
      modalRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const handleEditSubmit = async () => {
    const checkDuplicateTitle = agendaDatas?.find(
      (agenda) => agenda?.title.trim() === formData?.title.trim()
    );
    console.log(checkDuplicateTitle);
    if (checkDuplicateTitle && selectedAgendaId !== checkDuplicateTitle?._id) {
      toast.error("Duplicate title found.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    setShowEdit(true);
    const newErrors = validateForm(formData);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      const payload = {
        title: formData?.title,
        timeLine: formData?.timeLine,
        topic,
        organizationId: meetingDatas?.singleMeetingDetails?.organizationId,
        meetingId: meetingDatas?.singleMeetingDetails?._id,
      };

      // dispatch(makeRequest(null));
      // dispatch(setSuccess(false));
      const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/agenda/updateAgenda/${selectedAgendaId}`;
      const headerObject = {
        headers: {
          "Content-Type": "application/json",
          Authorization: accessToken,
        },
      };
      setEditLoading(true);
      axios
        .put(webApiUrl, payload, headerObject)
        .then(async (res) => {
          const resData = res.data;

          if (resData.data?.isInValidUser) {
            dispatch(setInValidUser(true));
            toast.error(resData.message, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
          if (resData.success) {
            // dispatch(getAgendaWithMinutesDetails(payload.meetingId, accessToken));
            // dispatch(getMeetingActivitiesDetails(payload.meetingId, accessToken));
            toast.success(resData.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });

            const modifiedAgenda = await agendaDatas.map((agenda) => {
              if (agenda?._id === selectedAgendaId) {
                agenda.title = payload.title;
                agenda.timeLine = payload?.timeLine;
                agenda.topic = topic;
              }
              return agenda;
            });
            console.log(modifiedAgenda);

            setAgendaDatas(modifiedAgenda);
            // dispatch(
            //   getSingleMeetingDetails(
            //     meetingDatas?.singleMeetingDetails?._id,
            //     accessToken
            //   )
            // );
            setTopic(null);
            setFormData({
              title: "",
              // topic: "",
              timeLine: "0",
            });
            setErrors({});
            setSelectedAgendaId(null);
            setSelectedAgenda(null);
            setAgendaNumber(0)
          } else {
            toast.error(resData.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
          setEditLoading(false);
          //  dispatch(editAgendaResponse(resData));
        })
        .catch((err) => {
          setEditLoading(false);
          //  dispatch(failRequest(err.message));
          toast.error(constantMessages.serverErrorMessage, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    }
  };
  console.log(agendaDatas);
  return (
    <>
      <Modal
        size="lg"
        show={props.IsAddAgendaModal}
        onHide={() => closeModal()}
        backdrop="static"
      >
        <Modal.Dialog>
          <div className="modal-header attendees-modal">
            <div>
             
                {selectedAgendaId === null?(
              

<h4 className="modal-title">
Add Agenda {props?.totalAgenda + 1}
</h4>
                ):(
                  <h4 className="modal-title">
                  Edit Agenda {agendaNumber}
                  </h4>
                )}
               
            
            </div>
            <div>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => closeModal()}
              ></button>
            </div>
          </div>

          <Modal.Body
            className="attendees-popup modal-margin"
            id="scroll-top-agenda"
          >
            <div className="open-div">
              <div className="minutes-box no-border">
                <div className="form-group">
                  <div className="mb-2">
                    <div className="row">
                      <div className="col-md-12">
                        <label className="mb-1">
                          Agenda Title <span className="star-mark">*</span>
                        </label>
                      </div>
                      <div className="col-md-12">
                        <input
                          type="text"
                          placeholder="Enter title"
                          name="title"
                          value={formData.title}
                          onChange={handleChange}
                          onBlur={validateAgendaTitle}
                          autoComplete="off"
                        />

                        {errors.title ? (
                          <span className="error-message">{errors.title}</span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-md-12">
                      <label className="mb-1">
                        What are the topic to discuss ?
                      </label>
                    </div>
                    <div className="col-md-12">
                      {/* <textarea
                        name="topic"
                        value={formData.topic}
                        onChange={handleChange}
                        onBlur={validateAgendaTopic}
                        autoComplete="off"
                        placeholder="Enter issue to discuss..."
                        id=""
                        cols="56"
                        rows="4"
                      ></textarea> */}
                      <ReactQuill
                        theme="snow"
                        placeholder="Please add topic"
                        onChange={handleValue}
                        value={topic}
                        //onChange={handleChange}
                        onBlur={validateAgendaTopic}
                        modules={modules}
                        formats={formats}
                        className="custom-quill-editor"
                        style={{ background: "white" }} // Adjust height here
                      />
                      {/* <p className="success-message">
                        {checkCharacterLeft(
                          formData.topic ? formData.topic : ""
                        )}{" "}
                        Characters left
                      </p> */}
                      <p className="success-message">
                        {getStringLengthAfterHTMLParse(topic)} Characters
                      </p>
                      {errors.topic && (
                        <span className="error-message">{errors.topic}</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-group m-0">
                  <div className="row">
                    <div className="col-md-12">
                      <label className="mb-1">
                        How long will this agenda item take to discuss?
                      </label>
                    </div>
                    <div className="col-md-12">
                      <div className="time-taken bg-white">
                        <input
                          name="timeLine"
                          value={formData.timeLine}
                          onChange={handleChange}
                          onBlur={validateAgendaTime}
                          required="required"
                          onKeyDown={handleKeyDown}
                          type="number"
                          autocomplete="off"
                        />
                        <div className="minute_box">mins</div>
                      </div>
                      {errors.timeLine && (
                        <span className="error-message">{errors.timeLine}</span>
                      )}
                    </div>
                  </div>

                  <div className="mt-3 plus pointer"></div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <div className="md-footer">
            <Modal.Footer>
              <Button
                variant=""
                onClick={() => resetModal()}
                type="button"
                className="add-minutes Mom-btn"
              >
                <p>Cancel</p>
              </Button>
              {/* <Button
              variant=""
              onClick={handleSubmit}
              className="create-meeting-button Mom-btn"
            >
              <p>Submit</p>
            </Button> */}
              {props?.fromMinute && selectedAgendaId === null ? (
                <>
                  {!minuteDatas.loading ? (
                    <Button
                      className="create-meeting-button Mom-btn"
                      variant=""
                      onClick={handleSubmit}
                    >
                      <p>Submit</p>
                    </Button>
                  ) : (
                    <div
                      className="meeting-page"
                      style={{ textAlign: "center" }}
                    >
                      <LoaderButton />
                    </div>
                  )}
                </>
              ) : props?.fromEditMeeting && selectedAgendaId === null ? (
                <>
                  {!addLoading ? (
                    <Button
                      className="create-meeting-button Mom-btn"
                      variant=""
                      onClick={handleSubmit2}
                    >
                      <p>Submit</p>
                    </Button>
                  ) : (
                    <div
                      className="meeting-page"
                      style={{ textAlign: "center" }}
                    >
                      <LoaderButton />
                    </div>
                  )}
                </>
              ) : props?.fromViewMeeting && selectedAgendaId === null ? (
                <>
                  {!addLoading ? (
                    <Button
                      className="create-meeting-button Mom-btn"
                      variant=""
                      onClick={handleSubmit3}
                    >
                      <p>Submit</p>
                    </Button>
                  ) : (
                    <div
                      className="meeting-page"
                      style={{ textAlign: "center" }}
                    >
                      <LoaderButton />
                    </div>
                  )}
                </>
              ) : selectedAgendaId !== null ? (
                <>
                  {!editLoading && selectedAgendaId !== null ? (
                    <Button
                      className="create-meeting-button Mom-btn"
                      variant=""
                      onClick={handleEditSubmit}
                    >
                      <p>Update</p>
                    </Button>
                  ) : (
                    <div
                      className="meeting-page"
                      style={{ textAlign: "center" }}
                    >
                      <LoaderButton />
                    </div>
                  )}
                </>
              ) : null}
            </Modal.Footer>
          </div>
        </Modal.Dialog>

        {agendaDatas?.length !== 0 ? (
          <div className="form-group agenda mt-4 ag-data">
            <label className="mt-3 mb-3">
              <h4>Agenda(s)</h4>
            </label>
            {agendaDatas?.length !== 0 &&
              agendaDatas?.map((agenda, index) => {
                return (
                  <div className="agenda-box-border">
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item
                        eventKey={index.toString()}
                        key={index}
                        className="agenda-accordion"
                      >
                        <Accordion.Header>
                          Agenda{" "}
                          {props?.totalAgenda -
                            (agendaDatas?.length - index) +
                            1}
                          :&nbsp;
                          {agenda.title?.length > 50 ? (
                            <OverlayTrigger
                              trigger={["hover", "focus"]}
                              placement="bottom"
                              overlay={popoverHoverFocus(agenda.title)}
                            >
                              <span className="meeting-title-hover">
                                {`${agenda.title.slice(0, 50)}....`}
                              </span>
                            </OverlayTrigger>
                          ) : (
                            <span className="meeting-title-hover">
                              {agenda.title}
                            </span>
                          )}
                          {/* <button
                            onClick={() =>
                              setDeleteAgendaModalStatus(true, agenda)
                            }
                          >
                            Delete
                          </button> */}
                        </Accordion.Header>

                        <Accordion.Body>
                          <div className="accordion-bbb d-flex justify-content-between">
                          <div className="edit-meeting-left-accordions">
                            <div className="form-group">
                              <div className="row">
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                  <label className="mt-1 mb-1">
                                    Agenda Title
                                  </label>
                                </div>
                                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                  <p> {agenda.title}</p>
                                </div>
                              </div>
                            </div>
                            {checkEmptyString(agenda?.topic) === null ||
                            checkEmptyString(agenda?.topic) === " " ||
                            checkEmptyString(agenda?.topic) === undefined ||
                            agenda?.topic === null ? null : (
                              <div className="form-group">
                                <div className="row">
                                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                    <label className="mt-1 mb-1">
                                      Topic to Discuss
                                    </label>
                                  </div>
                                  <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                    <div className="mb-2 text-editor">
                                      {agenda.topic
                                        ? parse(agenda.topic)
                                        : null}
                                      {/* {agenda.topic ? (
                                                    <>
                                                      {getStringLengthAfterHTMLParse(
                                                        agenda.topic
                                                      ) < 20 ? (
                                                        <span>
                                                          {parse(agenda.topic)}
                                                        </span>
                                                      ) : (
                                                        <div>
                                                          {`${sliceHtmlEncodedString(
                                                            agenda.topic,
                                                            20
                                                          )}....`}
                                                          <button
                                                            type="button"
                                                            className="badge bg-opacity-10 scheduled-color"
                                                            onClick={() =>
                                                              setViewAgendaModalStatus(
                                                                true,
                                                                agenda
                                                              )
                                                            }
                                                          >
                                                            {" "}
                                                            View More
                                                          </button>
                                                        </div>
                                                      )}
                                                    </>
                                                  ) : null} */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className=" form-group">
                              <div className="row">
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                  <label className="mt-1 mb-1">Timeline</label>
                                </div>
                                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                  <p> {agenda.timeLine} Mins</p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="accordianagenda-edit-delete">
                            <div className="btn-outerbox">
                              <div className="d-inline-block menu-dropdown custom-dropdown">
                                <Dropdown className="fff">
                                  <Dropdown.Toggle>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="#000"
                                      className="bi bi-three-dots-vertical"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                    </svg>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      href="#"
                                      onClick={() => {setEdit(agenda)
                                        setAgendaNumber(
                                          props?.totalAgenda -
                                            (agendaDatas?.length - index) +
                                            1
                                        )
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        fill="currentColor"
                                        className="bi bi-pencil-square me-1"
                                        viewBox="0 0 19 16"
                                      >
                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                        <path
                                          fill-rule="evenodd"
                                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                                        />
                                      </svg>
                                      Edit
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      href="#"
                                      onClick={() =>
                                        setDeleteAgendaModalStatus(true, agenda)
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        fill="currentColor"
                                        className="me-2 bi bi-trash3"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                                      </svg>
                                      Delete
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </div>
                          {/* ///////////////////////////////////////////////////////////////// */}

                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                );
              })}
          </div>
        ) : null}
      </Modal>

      <Modal
        show={isDeleteAgendaModal}
        onClick={(e) => setIsDeleteAgendaModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Agenda</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you want to delete {agendaDatas2?.title} ?</Modal.Body>
        <Modal.Footer>
          <Button
            variant=""
            onClick={(e) => setIsDeleteAgendaModal(false)}
            className="add-btn Mom-btn"
          >
            <p>No</p>
          </Button>

          {!deleteLoading ? (
            <Button
              type="button"
              variant=""
              className="create-meeting-button Mom-btn"
              onClick={(e) => {
                // e.preventDefault(); // Prevent unintended form submission
                deleteAgenda(agendaDatas2?._id);
              }}
            >
              <p>Yes</p>
            </Button>
          ) : (
            <div className="meeting-page" style={{ textAlign: "center" }}>
              <LoaderButton />
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddAgendaModal;
