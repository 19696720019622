import React, { useState, useEffect } from "react";
import {
  Navigate,
  Link,
  useParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../Common/Loader";
import {
  isLogInProcessForGmail,
  setInValidUser,
} from "../../redux/actions/authActions/authAction";
const SingleSignIn = () => {
  const [isProcessing, setIsProcessing] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { token } = useParams();
  useEffect(() => {
    document.title = "Log In: MinutesVault";
    if (token) {
      verifyTokenForSingleSignIn();
    }
  }, []);

  const verifyTokenForSingleSignIn = async () => {
    try {
      setIsProcessing(true);
      // const webApiUrl = `https://demo9.ntspl.co.in/api/user/SSOTokenValidate/ValidateToken`;
      const webApiUrl = `${process.env.REACT_APP_INTRANET_API_URL}/api/user/SSOTokenValidate/ValidateToken`;
      // const webApiUrl = `https://intranet.ntspl.co.in/api/user/SSOTokenValidate/ValidateToken`;

      // Create FormData object
      const formData = new FormData();
      formData.append("token", token); // "file" should match the API's expected key
      const response = await axios.post(webApiUrl, formData, {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      console.log(response);
      setIsProcessing(false);
      if (response.data.Status === "Success") {
        singleSignOn(response.data.Data);
      } else {
        toast.error(response.data.Data.Message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        navigate("/login");
      }
    } catch (error) {
      console.log(
        "Error while updating Employee:",
        error?.response?.data?.Data?.Message
      );
      toast.error(error?.response?.data?.Data?.Message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      navigate("/login");
    }
  };

  const singleSignOn = async (data) => {
    console.log(data);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/V1/auth/singleSignOn`,
        {
          email: data?.Email,
          empId: data?.Employee_Code,
        }
      );

      const resData = response.data;
      if (resData.success) {
        dispatch(setInValidUser(false));
        const { token, userData } = resData.data;
        localStorage.setItem("accessToken", token);
        localStorage.setItem("userData", JSON.stringify(userData));
        data = {
          ...resData,
          variant: "success",
          message: resData.message,
          userData,
        };
        toast.success(resData.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        navigate("/meeting-list");
      } else {
        data = {
          ...resData,
          variant: "danger",
          message: resData.message,
        };
        toast.error(resData.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        navigate("/login");
      }
      dispatch(isLogInProcessForGmail(data));
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log("Error while updating Employee:", error);
      navigate("/login");
    }
  };
  return (
    <>
      {isProcessing ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <h2>Verifying....</h2>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              width: "100vw",
            }}
          >
            <Loader />
          </div>
        </>
      ) : null}
    </>
  );
};

export default SingleSignIn;
