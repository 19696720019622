import React, { useEffect, useState } from "react";
import Header from "../Common/Header/Header";
import Sidebar from "../Common/Sidebar/Sidebar";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link, useLocation } from "react-router-dom";
import importantLogo from "../../assets/images/star.png";
import "./style/notification.css";
import { convertFirstLetterOfFullNameToCapital } from "../../helpers/commonHelpers";
import NotificationDropdown from "./NotificationDropDown";
import NotificationFilterDropDown from "./NotificationFilterDropDown";
import Alert from "../Common/Alert";
import NoDataFound from "../Common/NoDataFound";
import Loader from "../Common/Loader";

import { logOut } from "../../redux/actions/authActions/authAction";
const ViewNotification = () => {
  useEffect(() => {
    document.title = "Notification: MinutesVault";
  });

  const location = useLocation();
  const [cameFromNotification, setCameFromNotification] = useState(location.state?.cameFromNotification || false);

  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  if (authData.isInValidUser && authData.isInvalidUserChecked) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }
  const notificationData = useSelector((state) => state.notification);

  console.log(notificationData?.notificationList?.length);

  // useEffect(() => {
  //   return () => {
  //     navigate(location.pathname, {
  //       replace: true,
  //       state: { cameFromNotification: false },
  //     });
  //   };
  // }, [navigate, location.pathname]);

  const handleBack = () => {
    setCameFromNotification(false); 
    navigate(-1);
  };

  return (
    <>
      {/* <Header />
      <Sidebar /> */}

      <div className="main-content">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 detail-col">
            <section className="notification-page">
              <div className="meeting-header-text justify-content-between">
                <div>
                  <h4>
                    Notifications: {notificationData?.notificationList?.length}
                  </h4>
                </div>
                <div className="d-flex gap-2">
                  {notificationData?.notificationList?.length > 0 && (
                    <NotificationFilterDropDown />
                  )}

                  {cameFromNotification && (
                    <button
                      className="create-meeting-button Mom-btn back-btn"
                      onClick={handleBack}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 64 64"
                        width="19"
                        height="19"
                        className="fill-white notification-back-icon me-1"
                      >
                        <path d="M54 30H14.101l15.278-14.552a2 2 0 1 0-2.759-2.897L9.172 29.171A3.978 3.978 0 0 0 8 32c0 1.068.417 2.073 1.207 2.862l17.414 16.586c.387.369.883.552 1.379.552a1.999 1.999 0 0 0 1.38-3.448L14.038 34H54a2 2 0 0 0 0-4z" />
                      </svg>{" "}
                      <p>Back</p>
                    </button>
                  )}
                </div>
                {/* <NotificationFilterDropDown /> */}
              </div>
              <div className="table-box mt-3 p-0 overflow">
                {notificationData?.notificationList?.length !== 0 ? (
                  <>
                    {notificationData?.notificationList?.map((notification) => {
                      return (
                        <div
                          className={
                            notification.isRead
                              ? "notification"
                              : "notification unseen"
                          }
                        >
                          <div className="inner-notification">
                            <div className="text">
                              <div className="heading">
                                {notification?.isImportant ? (
                                  <img src={importantLogo} />
                                ) : null}
                                <Link
                                  to={
                                    notification?.type === "MEETING"
                                      ? `/view-meeting-details/${notification?.typeId?.toString()}`
                                      : notification?.type === "ACTION"
                                      ? `/view-action-details/${notification?.typeId?.toString()}`
                                      : notification?.type === "MINUTE"
                                      ? `/view-minute-details/${notification?.typeId?.toString()}`
                                      : undefined
                                  }
                                >
                                  {convertFirstLetterOfFullNameToCapital(
                                    notification.title
                                  )}
                                </Link>
                              </div>
                              {/* <div className="msg"> */}
                              <div className="text-editor">
                                {notification?.details
                                  ? convertFirstLetterOfFullNameToCapital(
                                      notification?.details
                                    )
                                  : null}
                              </div>
                              {/* <div className="topic">
                               */}
                              <div className="text-editor">
                                Meeting Title :{" "}
                                {notification?.meetingTitle
                                  ? convertFirstLetterOfFullNameToCapital(
                                      notification?.meetingTitle
                                    )
                                  : null}
                              </div>
                              <div className="date">
                                {notification.date} , {notification.time}
                              </div>
                            </div>
                          </div>
                          <div className="d-inline-block menu-dropdown custom-dropdown notification-dropdown">
                            <NotificationDropdown
                              notificationId={notification._id}
                              notification={notification}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : !notificationData?.loading &&
                  notificationData?.notificationList?.length === 0 ? (
                  <div className="no-data-img">
                    <Alert
                      status={"info"}
                      message={"No data available."}
                      timeoutSeconds={0}
                    />

                    <NoDataFound dataType={"meeting"} />
                  </div>
                ) : (
                  <div
                    className="meeting-page "
                    style={{ textAlign: "center", paddingTop: 20 }}
                  >
                    <Loader />
                  </div>
                )}
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewNotification;
