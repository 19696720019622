import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.css";
import JSZip from "jszip";
import parse from "html-react-parser";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { useSelector, useDispatch } from "react-redux";
import * as constantMessages from "../../constants/constatntMessages";
import { updateMinute } from "../../redux/actions/minuteActions/MinuteAction";
import {
  deleteZoomRecording,
  downloadAllZoomRecordings,
  fetchCurrentAttendeesList,
} from "../../redux/actions/meetingActions/MeetingAction";
import { getEmployeeList } from "../../redux/actions/userAction/userAction";
import Select from "react-select";
import { textBoxStrLength } from "../../config/config";
import {
  checkCharacterLeft,
  convertFirstLetterOfFullNameToCapital,
  convertFirstLetterToCapital,
  formatDateTimeFormat,
  getStringLengthAfterHTMLParse,
} from "../../helpers/commonHelpers";
import ReactQuill from "../../../node_modules/react-quill/lib/index";
import ConfirmModal from "./ConfirmModal";
import LoaderButton from "../Common/LoaderButton";


const ViewZoomRecordings = (props) => {
  console.log(props?.singleMeetingDetails?.recordings);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState(null);
  const [confirmTitle, setConfirmTitle] = useState(null);
  const [file, setFile] = useState(null);
  const [recordingId, setRecordingId] = useState(null);
  const [downloadAll, setDownloadAll] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("accessToken");
  const meetingData = useSelector((state) => state.meeting);
  const downloadFiles = (files) => {
    files.forEach((file, index) => {
      setTimeout(() => {
        const link = document.createElement("a");
        link.href = file?.download_url;
        link.download = "Recording"; // Optional file name
        link.style.display = "none"; // Hide the link
        // link.target = "_blank";
        document.body.appendChild(link);

        link.click(); // Simulate the click

        // Clean up by removing the link
        document.body.removeChild(link);
      }, index * 1000); // 500 ms  delay between each download
    });
  };

  const downloadFilesAsZip = async (files) => {
    const zip = new JSZip();

    try {
      // Loop through the files array
      for (let index = 0; index < files.length; index++) {
        const file = files[index];

        try {
          console.log(file.download_url)
          // Fetch the file asynchronously using async/await
          const response = await fetch("https://us06web.zoom.us/rec/download/XQsFQts0Qq9c5gVZo60UgxkzKwyGZb0zncGfIE7hmaAMHuqj16GSpDfND7I8n8m7RDiIztw24-Y7uzxz.oW2SsHOYDR48j_X6", { mode: 'no-cors' });
          console.log(response);
          if (!response.ok) {
            console.log("innn")
            throw new Error(`Failed to fetch file at index ${index}`);
          }

          const blob = await response.blob(); // Get the file content as a blob
          console.log(blob);
          const fileName = file.name || `Recording-${index}`;

          // Add the file to the zip
          zip.file("fileName", blob);
          console.log(`Added ${fileName} to the zip`);
        } catch (error) {
          console.error(`Error downloading file at index ${index}:`, error);
        }
      }

      // After all files are added, generate the zip and trigger the download
      const content = await zip.generateAsync({ type: "blob" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(content);
      link.download = "recordings.zip"; // Name the zip file

      document.body.appendChild(link);

      link.click(); // Simulate the click

      // Clean up by removing the link
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error creating the zip:", error);
    }
  };

  // const downloadFilesAsZip = (files) => {
  //   const zip = new JSZip();
  //   const promises = [];

  //   console.log(files);

  //   files.forEach((file, index) => {
  //     if (file?.download_url) {

  //       const promise = fetch(file.download_url)
  //         .then((response) => {
  //           console.log(response);
  //           if (!response.ok) {
  //             throw new Error(`Failed to fetch file at index ${index}`);
  //           }
  //           return response.blob(); // Fetch the file as a blob
  //         })
  //         .then((blob) => {
  //           const fileName = file.name || `Recording-${index}`;
  //           zip.file(fileName, blob); // Add the file to the zip
  //           console.log(`Added ${fileName} to the zip`);
  //         })
  //         .catch((error) => {
  //           console.error(`Error downloading file at index ${index}:`, error);
  //         });

  //       promises.push(promise);
  //     } else {
  //       console.warn(`File at index ${index} is missing a download URL.`);
  //     }
  //   });
  //   console.log(promises);
  //   // Wait for all files to be fetched and added to the zip before generating it

  //         // Await the zip file creation asynchronously
  // const content = await zip.generateAsync({ type: 'blob' });

  //             const link = document.createElement("a");
  //             link.href = URL.createObjectURL(content);
  //             link.download = "recordings.zip"; // Name the zip file
  //             link.click(); // Trigger the download
  //             console.log("Zip file generated and download triggered.");

  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error waiting for all fetches:", error);
  //     });
  // };

  const downloadSubmit = () => {
    if (downloadAll) {
      downloadFiles(file);
      setDownloadAll(false);
      setFile(null);
      setIsDownloadModalOpen(false);
    } else {
      console.log(file);
      const files = [file];
      files.forEach((download_url, index) => {
        setTimeout(() => {
          const link = document.createElement("a");
          link.href = download_url;
          link.download = "Recording"; // Optional file name
          link.style.display = "none"; // Hide the link
          link.target = "_blank";
          document.body.appendChild(link);

          link.click(); // Simulate the click

          // Clean up by removing the link
          document.body.removeChild(link);
        }, index * 500); // 500 ms  delay between each download
      });
      setIsDownloadModalOpen(false);
      setFile(null);
    }
  };

  const downloadAllSubmit = (recordingDatas) => {
    // downloadFilesAsZip(recordingFiles);
    const recordingFiles = recordingDatas?.map((rec) => rec?.download_url)
    dispatch(downloadAllZoomRecordings(recordingFiles, accessToken))
  };

  const downloadSingleSubmit = (downloadFile) => {
    const files = [downloadFile];
    files.forEach((download_url, index) => {
      setTimeout(() => {
        const link = document.createElement("a");
        link.href = download_url;
        link.download = "Recording"; // Optional file name
        link.style.display = "none"; // Hide the link
        link.target = "_blank";
        document.body.appendChild(link);

        link.click(); // Simulate the click

        // Clean up by removing the link
        document.body.removeChild(link);
      }, index * 500); // 500 ms  delay between each download
    });
  };

  const handleDeleteSubmit = () => {
    const payload = {
      meetingId: props?.singleMeetingDetails?._id,
      recordingId,
      organizationId: props?.singleMeetingDetails?.organizationId,
    };
    dispatch(deleteZoomRecording(payload, accessToken));

    setIsDeleteModalOpen(false);
  };

  const handleDownloadConfirmModal = (value, download_url) => {
    console.log(download_url);
    // props.setIsViewZoomRecordingModal(false)
    setIsDownloadModalOpen(value);
    setIsDeleteModalOpen(false);
    setConfirmTitle("Download Recording");
    setConfirmMessage("Do you want to download the recording ?");
    setFile(download_url);
  };
  const handleDownloadAllConfirmModal = (value, files) => {
    console.log(files);
    setIsDownloadModalOpen(value);
    setConfirmTitle("Download All Recording");
    setConfirmMessage("Do you want to download all recordings ?");
    setDownloadAll(true);
    setFile(files);
  };

  const handleDownloadCloseConfirmModal = () => {
    //props.setIsViewZoomRecordingModal(true)
    setIsDownloadModalOpen(false);
    setFile(null);
  };
  console.log(file);

  // const handleDeleteConfirmModal = (value, singleRecordingId) => {
  //   console.log(singleRecordingId);
  //   // props.setIsViewZoomRecordingModal(false)
  //   setIsDeleteModalOpen(value);
  //   setIsDownloadModalOpen(false);
  //   setConfirmTitle("Delete Recording");
  //   setConfirmMessage("Do you want to delete the recording ?");
  //   setRecordingId(singleRecordingId);
  // };
  const handleDeleteCloseConfirmModal = () => {
    //props.setIsViewZoomRecordingModal(true)
    setIsDeleteModalOpen(false);
    setRecordingId(null);
  };

  const handleDeleteConfirmModal = (value, singleRecordingId) => {
    console.log(singleRecordingId);
    // props.setIsViewZoomRecordingModal(false)
    props.handleOpenDeleteModal(singleRecordingId, value);
    // setIsDownloadModalOpen(false);
    // setConfirmTitle("Delete Recording");
    // setConfirmMessage("Do you want to delete the recording ?");
    // setRecordingId(singleRecordingId);
  };

  console.log(meetingData?.loading);
  return (
    <>
      <Modal
        size="xl"
        show={props.IsViewZoomRecordingModal}
        onHide={(e) => props.setIsViewZoomRecordingModal(false)}
        backdrop="static"
      >
        <div className="modal-header attendees-modal">
          <div className="d-flex justify-content-between w-100 resp-flex-col">
            <h4 className="modal-title">Recordings</h4>

            {!meetingData?.loading ? (
              <button
                className="p-0"
                type="button"
                onClick={() =>
                  downloadAllSubmit(
                    props?.singleMeetingDetails?.recordings?.recordingFile
                  )
                }
              >
                <span className="recording-btn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="#5f07b0"
                    className="bi bi-cloud-arrow-down-fill me-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2m2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708" />
                  </svg>

                  {`Download All (${props?.singleMeetingDetails?.recordings?.recordingFile?.length
                    } ${props?.singleMeetingDetails?.recordings?.recordingFile
                      ?.length > 1
                      ? "Files"
                      : "File"
                    })`}
                </span>
              </button>
            ) : (

              <button>
                <span className="recording-btn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="#5f07b0"
                    className="bi bi-cloud-arrow-down-fill me-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2m2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708" />
                  </svg>

                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                  Processing...
                </span>
              </button>

            )}
          </div>

          <div>



            




            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={(e) => props.setIsViewZoomRecordingModal(false)}
            ></button>
          </div>
        </div>

        <Modal.Body className="attendees-popup modal-margin">
          <div className="open-div">
            <div className="col-12">
              <div className="alert alert-warning">
                Note: Please download recordings with in 30 days. All recordings
                will be deleted permanently after 30 days.
              </div>
            </div>

            <form className="addminutesboxfrom">
              <div className="">
                {/* <div className="row recording-video-box-wrapper"> */}
                <div className="row recording-video-box-wrapper">
                  {props?.recordingData?.length != 0 &&
                    props.recordingData?.map((recording, index) => {
                      return (
                        <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                          <div className="recording-video-box">
                            <div className="video-box">
                              <a
                                id="play-video"
                                className="video-play-button"
                                target="_balnk"
                                href={recording?.play_url}
                              >
                                <span></span>
                              </a>
                            </div>
                            <h2>Session {index + 1}</h2>
                            <span className="video-size">
                              Size: {Math.round(recording?.file_size / 1024)}{" "}
                              kbs ,{" "}
                              {
                                formatDateTimeFormat(recording?.recording_end)
                                  .formattedDate
                              }{" "}
                              {
                                formatDateTimeFormat(recording?.recording_end)
                                  .formattedTime
                              }
                            </span>
                            <button
                              type="button"
                              className="btn btn-sm download-one me-2"
                              // onClick={() =>
                              //   handleDownloadConfirmModal(
                              //     true,
                              //     recording?.download_url
                              //   )
                              // }
                              onClick={() =>
                                downloadSingleSubmit(recording?.download_url)
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="#ffffff"
                                className="bi bi-cloud-arrow-down-fill me-2"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2m2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708" />
                              </svg>
                              Download
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm delete-one"
                              onClick={() =>
                                handleDeleteConfirmModal(true, recording?.id)
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-trash-fill me-2"
                                viewBox="0 0 16 16"
                              >
                                <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0" />
                              </svg>
                              Delete
                            </button>
                          </div>
                        </div>

                        //                           <div className="col-sm-4">
                        //                             <h5>Session {index + 1}</h5>
                        //                             <div>
                        //                               <iframe
                        //                                 src={recording?.play_url}
                        //                                 height={100}
                        //                                 width={200}
                        //                                 style={{ border: "1px solid black" }}
                        //                               />
                        //                             </div>
                        //                             <button
                        //                               type="button"
                        //                               className="p-1"
                        //                               onClick={() =>
                        //                                 handleDownloadConfirmModal(
                        //                                   true,
                        //                                   recording?.download_url
                        //                                 )
                        //                               }
                        //                             >
                        //                               <span className="recording-btn">
                        //                                 <svg
                        //                                   xmlns="http://www.w3.org/2000/svg"
                        //                                   width="20"
                        //                                   height="20"
                        //                                   fill="#5f07b0"
                        //                                   className="bi bi-cloud-arrow-down-fill me-2"
                        //                                   viewBox="0 0 16 16"
                        //                                 >
                        //                                   <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2m2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708" />
                        //                                 </svg>
                        //                                 {/* <a
                        //                                   href={recording?.download_url}
                        //                                   target="_blank"
                        //                                 > */}
                        //                                 Download
                        //                                 {/* </a> */}
                        //                               </span>
                        //                             </button>
                        //                             <button type="button" className="p-0">
                        //                               <a href={recording?.play_url} target="_blank">
                        //                                 Watch
                        //                               </a>
                        //                             </button>
                        //                             <button
                        //                               type="button"
                        //                               className="p-2"
                        //                               onClick={() =>
                        //                                 handleDeleteConfirmModal(true, recording?.id)
                        //                               }
                        //                             >
                        //                               Delete
                        //                             </button>
                        //                             <p>
                        //                               Size: {Math.round(recording?.file_size / 1024)}{" "}
                        //                               kbs
                        //                             </p>

                        //                             <p>
                        //                               {
                        //                                  formatDateTimeFormat(
                        //                                   recording?.recording_end
                        //                                 ).formattedDate
                        //                               }
                        //                               {" "}

                        // {
                        //                                  formatDateTimeFormat(
                        //                                   recording?.recording_end
                        //                                 ).formattedTime
                        //                               }
                        //                             </p>
                        //                             {/* <p>
                        //                               Passcode: ({" "}
                        //                               {
                        //                                 props?.singleMeetingDetails?.recordings
                        //                                   ?.password
                        //                               }{" "}
                        //                               )
                        //                             </p> */}
                        //                           </div>
                      );
                    })}
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>

      <ConfirmModal
        message={confirmMessage}
        title={confirmTitle}
        setIsDownloadModalOpen={setIsDownloadModalOpen}
        isDownloadModalOpen={isDownloadModalOpen}
        handleDownloadSubmit={downloadSubmit}
        handleDownloadCloseConfirmModal={handleDownloadCloseConfirmModal}
        buttonName={"Cancel"}
        isCalledFromOthers={true}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        isDeleteModalOpen={isDeleteModalOpen}
        handleDeleteCloseConfirmModal={handleDeleteCloseConfirmModal}
        handleDeleteSubmit={handleDeleteSubmit}
      />
    </>
  );
};

export default ViewZoomRecordings;
