import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Link , useLocation} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { downloadMOM } from "../../redux/actions/meetingActions/MeetingAction";
import { combineDateAndTime } from "../../helpers/commonHelpers";
import ShowWriteMinuteYetToStartModal from "../Minutes/ShowWriteMinuteYetToStartModal";
const MeetingDropDown = (props) => {
  const employeeData = useSelector((state) => state.user);
  const organizationData = useSelector((state) => state.organization);
  const accessToken = localStorage.getItem("accessToken");
  const dispatch = useDispatch();

    //dropdown menu validation
    const location = useLocation();
    const isViewMeetingPage = location.pathname.includes("/view-meeting-details");
    const isWriteMinutePage = location.pathname.includes("/write-minute");
    const isEditMeetingPage = location.pathname.includes("/edit-meeting");
    const isViewMinutesPage = location.pathname.includes("/view-minute-detail");
  let isWriteMinuteAllowedForClosedMinutes = true;
  const [showWriteMinuteYetToStartModal, setShowWriteMinuteYetToStartModal] =
    useState(false);
  const meetingDateTime = combineDateAndTime(
    new Date(props?.meetingDetails?.actualDate),
    props?.meetingDetails?.actualFromTime
  ).getTime();
  console.log(new Date());
  const currentDateTime = new Date().getTime();
  console.log(props?.meetingDetails?.meetingCloseDetails?.closedAt);

  if (props?.meetingDetails?.meetingCloseDetails?.closedAt) {
    const utcDate = new Date(
      props?.meetingDetails?.meetingCloseDetails?.closedAt
    );
    utcDate.setMinutes(utcDate.getMinutes() - (5 * 60 + 30));
    const meetingCloseTime = utcDate.getTime();
    const closedTime = props?.meetingDetails?.meetingCloseDetails?.closedAt
      ? meetingCloseTime
      : 0;

    const targetHoursInMilliSecond = organizationData?.organizationData
      ?.configData?.writeMinuteMaxTimeInHour
      ? parseInt(
          organizationData?.organizationData?.configData
            ?.writeMinuteMaxTimeInHour
        ) * 3600000
      : 0;
    console.log(organizationData?.organizationData?.configData);
    console.log(
      organizationData?.organizationData?.configData?.writeMinuteMaxTimeInHour
    );
    console.log(currentDateTime);
    console.log(closedTime);
    console.log(targetHoursInMilliSecond);
    console.log(currentDateTime - closedTime);

    if (targetHoursInMilliSecond === 0) {
      isWriteMinuteAllowedForClosedMinutes = false;
    } else {
      isWriteMinuteAllowedForClosedMinutes =
        currentDateTime - closedTime > targetHoursInMilliSecond ? false : true;
    }
    console.log(isWriteMinuteAllowedForClosedMinutes);
  }

  const isWriteMinuteAllowed =
    meetingDateTime <= currentDateTime ? true : false;
  const isMeetingOrganiser =
    employeeData?.userData?._id?.toString() ===
    props?.meetingDetails?.createdById?.toString()
      ? true
      : false;
console.log(props?.meetingDetails)
  // console.log(isWriteMinuteAllowed)
  //   console.log(isMeetingOrganiser);
  //   console.log(props?.meetingDetails?.createdById?.toString());
  //   console.log(employeeData?.userData?._id?.toString());
  //   console.log(props?.meetingDetails);
  return (
    <div>
      <Dropdown.Toggle>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="#000"
          className="bi bi-three-dots-vertical"
          viewBox="0 0 16 16"
        >
          <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
        </svg>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {/* <Link
          to={`/view-meeting-details/${props.meetingId}`}
          state={{ meetingId: props.meetingId, isViewMeetingPage: true }}
          style={{
            textDecoration: "none",
            color: "black",
          }}
        >
          <Dropdown.Item href={`/view-meeting-details/${props.meetingId}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              className="bi bi-eye me-2"
              viewBox="0 0 16 16"
            >
              <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
              <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
            </svg>
            View Meeting
          </Dropdown.Item>
        </Link> */}

{!isViewMeetingPage && (
          <Dropdown.Item
            as={Link}
            to={`/view-meeting-details/${props.meetingId}`}
            state={{ meetingId: props.meetingId, isViewMeetingPage: true }}
            style={{
              textDecoration: "none",
              color: "black",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              className="bi bi-eye me-2"
              viewBox="0 0 16 16"
            >
              <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
              <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
            </svg>
            View Meeting
          </Dropdown.Item>
        )}

        {!isViewMinutesPage && (props.status === "closed" &&
          props?.meetingDetails?.momGenerationDetails?.length !== 0) ||
        (props.status === "rescheduled" &&
          props?.meetingDetails?.momGenerationDetails?.length !== 0) ||
        (props.status === "scheduled" &&
          props?.meetingDetails?.momGenerationDetails?.length !== 0) ? (
          <Link
            to={`/view-minute-detail/${props.meetingId}`}
            state={{ meetingId: props.meetingId, isViewMeetingPage: true }}
            style={{
              textDecoration: "none",
              color: "black",
            }}
          >
            <Dropdown.Item href={`/view-minute-detail/${props.meetingId}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-box-arrow-right me-2"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"
                />
                <path
                  fill-rule="evenodd"
                  d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"
                />
              </svg>
              View Minute
            </Dropdown.Item>
          </Link>
        ) : null}

        {/* {isWriteMinutePage===false 
        && (
        (props.status === "scheduled" &&
          (isMeetingOrganiser === true ||
            employeeData?.userData?.isAdmin ||
            props?.meetingDetails?.canWriteMOM === true))
             ||
        (props.status === "rescheduled" &&
          (isMeetingOrganiser === true ||
            employeeData?.userData?.isAdmin ||
            props?.meetingDetails?.canWriteMOM === true)) ||
        
        (props.status === "closed" &&
          (isMeetingOrganiser === true ||
            employeeData?.userData?.isAdmin ||
            props?.meetingDetails?.canWriteMOM === true) &&
          isWriteMinuteAllowedForClosedMinutes === true) )? (
          <>
            {isWriteMinuteAllowed === true ? (
              <Link
                to={`/write-minute/${props.meetingId}`}
                state={{ meetingId: props.meetingId }}
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
              >
                <Dropdown.Item href={`/write-minute/${props.meetingId}`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="19"
                    fill="currentColor"
                    className="bi bi-pencil me-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                  </svg>
                  Write Minutes
                </Dropdown.Item>
              </Link>
            ) : (
              <Dropdown.Item
                eventKey="4"
                onClick={() => setShowWriteMinuteYetToStartModal(true)}
              >
                <Link
                  style={{
                    textDecoration: "none",
                    color: "black",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    fill="currentColor"
                    className="bi bi-pencil me-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                  </svg>
                  Write Minutes
                </Link>
              </Dropdown.Item>
              
            )}
          </>
        ) : null} */}

{!isWriteMinutePage && (
  (["scheduled", "rescheduled"].includes(props.status) &&
    (isMeetingOrganiser ||
      employeeData?.userData?.isAdmin ||
      props.meetingDetails?.canWriteMOM ||
      props.meetingData?.singleMeetingDetails?.canWriteMOM)) ||
  (props.status === "closed" &&
    (isMeetingOrganiser ||
      employeeData?.userData?.isAdmin ||
      props.meetingDetails?.canWriteMOM ||
      props.meetingData?.singleMeetingDetails?.canWriteMOM) &&
    isWriteMinuteAllowedForClosedMinutes)
) ? (
  <>
    {isWriteMinuteAllowed ? (
      <Link
        to={`/write-minute/${props.meetingId ?? props.meetingData?.singleMeetingDetails?._id}`}
        state={{ meetingId: props.meetingId ?? props.meetingData?.singleMeetingDetails?._id }}
        style={{
          textDecoration: "none",
          color: "black",
        }}
      >
        <Dropdown.Item href={`/write-minute/${props.meetingId ?? props.meetingData?.singleMeetingDetails?._id}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            fill="currentColor"
            className="bi bi-pencil me-2"
            viewBox="0 0 16 16"
          >
            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
          </svg>
          Write Minutes
        </Dropdown.Item>
      </Link>
    ) : (
      <Dropdown.Item
        eventKey="4"
        onClick={(e) => {
          e.preventDefault(); 
          e.stopPropagation();
          setShowWriteMinuteYetToStartModal(true);
        }}
      >
        <span
          style={{
            textDecoration: "none",
            color: "black",
            cursor: "pointer",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            fill="currentColor"
            className="bi bi-pencil me-2"
            viewBox="0 0 16 16"
          >
            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
          </svg>
          Write Minutes
        </span>
      </Dropdown.Item>
    )}
  </>
) : null}




        {!isEditMeetingPage && (props.status === "scheduled" ||
          props.status === "rescheduled" ||
          props.status === "draft") &&
        (isMeetingOrganiser === true ||
          employeeData?.userData?.isAdmin === true) &&
       // props?.meetingDetails?.minutesDetails?.length === 0 ? (
        !(props?.meetingDetails?.actionDetail?.length > 0 ) ? (  //added for meeting dropdown
          //   ||
          // (props.status === "rescheduled" &&
          //   (isMeetingOrganiser === true ||
          //     employeeData?.userData?.isAdmin === true) &&
          //   props?.meetingDetails?.actionDetail?.length === 0) ||
          // (props.status === "draft" &&
          //   (isMeetingOrganiser === true ||
          //     employeeData?.userData?.isAdmin === true) &&
          //   props?.meetingDetails?.actionDetail?.length === 0)
          <Link
            to="/edit-meeting"
            state={{
              meetingId: props.meetingId,
              isMeetingDataUpdate: true,
              step: props.step,
             // isEditMeetingPage:true
            }}
            style={{
              textDecoration: "none",
              color: "black",
            }}
          >
            <Dropdown.Item href="#/action-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-pencil-square me-2"
                viewBox="0 0 16 16"
              >
                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                <path
                  fill-rule="evenodd"
                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                />
              </svg>
              Edit Meeting
            </Dropdown.Item>
          </Link>
        ) : null}

        {/* {(props.status === "scheduled" || props.status === "rescheduled") &&
        (isMeetingOrganiser === true ||
          employeeData?.userData?.isAdmin === true) &&
        props?.meetingDetails?.actionDetail?.length === 0 ? (
          // {(props.status === "scheduled" &&
          //   (employeeData?.userData?.isMeetingOrganiser === true ||
          //     employeeData?.userData?.isAdmin === true)) ||
          //   (props.status === "rescheduled" &&
          //     (employeeData?.userData?.isMeetingOrganiser === true ||
          //       employeeData?.userData?.isAdmin === true) &&
          //     props?.meetingDetails?.actionDetail?.length === 0) ? (
          <Link
            style={{
              textDecoration: "none",
              color: "black",
            }}
          >
            <Dropdown.Item
              eventKey="4"
              onClick={() => {
                props.handleRescheduleModal(props.meetingId);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-stopwatch me-2"
                viewBox="0 0 16 16"
              >
                <path d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5z" />
                <path d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64l.012-.013.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5M8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3" />
              </svg>
              Reschedule Meeting
            </Dropdown.Item>
          </Link>
        ) : null} */}
        
        {["scheduled", "rescheduled"].includes(props.status) &&
          (isMeetingOrganiser || employeeData?.userData?.isAdmin) &&
          (props?.meetingDetails?.actionDetail?.length === 0 || !props?.meetingDetails?.actionDetail ) ? (
          <Link
            style={{
              textDecoration: "none",
              color: "black",
            }}
          >
            <Dropdown.Item
              eventKey="4"
              onClick={() => props.handleRescheduleModal(props.meetingId)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-stopwatch me-2"
                viewBox="0 0 16 16"
              >
                <path d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5z" />
                <path d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64l.012-.013.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5M8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3" />
              </svg>
              Reschedule Meeting
            </Dropdown.Item>
          </Link>
        ) : null}

        {(props.status === "scheduled" || props.status === "rescheduled") &&
        (isMeetingOrganiser === true ||
          employeeData?.userData?.isAdmin === true) &&
        props?.meetingDetails?.actionDetail?.length === 0 ? (
          <Link
            style={{
              textDecoration: "none",
              color: "black",
            }}
          >
            <Dropdown.Item
              eventKey="4"
              onClick={() => {
                props.handleResendMeetingDetailsModal(props.meetingDetails);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-send-plus me-2"
                viewBox="0 0 16 16"
              >
                <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855a.75.75 0 0 0-.124 1.329l4.995 3.178 1.531 2.406a.5.5 0 0 0 .844-.536L6.637 10.07l7.494-7.494-1.895 4.738a.5.5 0 1 0 .928.372zm-2.54 1.183L5.93 9.363 1.591 6.602z" />
                <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0m-3.5-2a.5.5 0 0 0-.5.5v1h-1a.5.5 0 0 0 0 1h1v1a.5.5 0 0 0 1 0v-1h1a.5.5 0 0 0 0-1h-1v-1a.5.5 0 0 0-.5-.5" />
              </svg>
              Resend Meeting Details
            </Dropdown.Item>
          </Link>
        ) : null}

        {(props.status === "scheduled" || props.status === "rescheduled") &&
          (isMeetingOrganiser === true ||
            employeeData?.userData?.isAdmin === true) &&
        props?.meetingDetails?.actionDetail?.length === 0 ? (
          /* {(props.status === "scheduled" &&
          (employeeData?.userData?.isMeetingOrganiser === true ||
            employeeData?.userData?.isAdmin === true)) ||
        (props.status === "rescheduled" &&
          (employeeData?.userData?.isMeetingOrganiser === true ||
            employeeData?.userData?.isAdmin === true)) ? ( */
          <>
            {" "}
            {props.status === "scheduled" || props.status === "rescheduled" ? (
              <Dropdown.Divider />
            ) : null}
            <Link
              style={{
                textDecoration: "none",
                color: "#ef1d06",
              }}
            >
              <Dropdown.Item
                eventKey="4"
                onClick={() => {
                  props.handleCancelModal(props.meetingId);
                }}
                className="colorRed"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-x-circle me-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"></path>
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"></path>
                </svg>
                Cancel Meeting
              </Dropdown.Item>
            </Link>
          </>
        ) : null}

        {!isViewMinutesPage && props.status == "closed" &&
        props?.meetingDetails?.momGenerationDetails?.length !== 0 ? (
          <Link
            style={{
              textDecoration: "none",
              color: "black",
            }}
          >
            <Dropdown.Item
              eventKey="4"
              onClick={() => {
                dispatch(downloadMOM(props.meetingId, accessToken));
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-cloud-download me-2"
                viewBox="0 0 16 16"
              >
                <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383" />
                <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708z" />
              </svg>
              Download Minutes
            </Dropdown.Item>
          </Link>
        ) : null}

        {props.status === "closed" &&
        (isMeetingOrganiser === true ||
          employeeData?.userData?.isAdmin === true) ? (
          // {(props.status === "closed" &&
          //   (employeeData?.userData?.isMeetingOrganiser === true ||
          //     employeeData?.userData?.isAdmin === true)) ||
          // (props.status === "closed" &&
          //   (employeeData?.userData?.isMeetingOrganiser === true ||
          //     employeeData?.userData?.isAdmin === true)) ? (
          <Link
            to={`/create-followon/${props.meetingId}`}
            state={{ meetingId: props.meetingId }}
            style={{
              textDecoration: "none",
              color: "black",
            }}
          >
            <Dropdown.Item href={`/create-followon/${props.meetingId}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-plus-circle me-2"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
              </svg>
              Create Follow On
            </Dropdown.Item>
          </Link>
        ) : null}

        {/* {(props.status === "rescheduled" &&
          (employeeData?.userData?.isMeetingOrganiser === true ||
            employeeData?.userData?.isAdmin === true) &&
          props?.meetingDetails?.momGenerationDetails?.length !== 0) ||
        (props.status === "scheduled" &&
          (employeeData?.userData?.isMeetingOrganiser === true ||
            employeeData?.userData?.isAdmin === true) &&
          props?.meetingDetails?.momGenerationDetails?.length !== 0) ? (
          <Dropdown.Item
            eventKey="4"
            onClick={() => {
              props.handleCloseModal(props.meetingId);
            }}
          >
            <Link
              state={{ meetingId: props.meetingId }}
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-x-circle me-2"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
              </svg>
              Close Meeting
            </Link>
          </Dropdown.Item>
        ) : null} */}
      </Dropdown.Menu>
      <ShowWriteMinuteYetToStartModal
        isModalOpen={showWriteMinuteYetToStartModal}
        setIsModalOpen={setShowWriteMinuteYetToStartModal}
      />
    </div>
  );
};

export default MeetingDropDown;
