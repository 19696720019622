import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useSelector, useDispatch } from "react-redux";
import * as constantMessages from "../../constants/constatntMessages";
import { editAgenda } from "../../redux/actions/minuteActions/MinuteAction";
import { checkCharacterLeft, getStringLengthAfterHTMLParse } from "../../helpers/commonHelpers";
import { textBoxStrLength } from "../../config/config";
import ReactQuill from "../../../node_modules/react-quill/lib/index";
import LoaderButton from "../Common/LoaderButton";

const EditAgendaModal = (props) => {
  const modules = {
    toolbar: [
      [{ header: [1, 2,3,4,5,6, false] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };
  
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
  ];
  const regularExpression = /^[0-9a-zA-Z .,:;%@&()/_'"-]+$/;
  const regularExpressionForHtml = new RegExp(
    /<(\"[^\"]*\"|'[^']*'|[^'\">])*>/
  );
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("accessToken");
  const minuteData = useSelector((state) => state.minute);
  const { agendaData, meetingData } = props;
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    title: "",
    topic: "",
    timeLine: "0",
  });
 const minuteDatas = useSelector((state) => state.minute);
  useEffect(() => {
    setFormData({
      ...formData,
      title: agendaData?.title,
      topic: agendaData?.topic,
      timeLine: agendaData?.timeLine,
    });
    if (minuteData.isSuccess) {
      props.setIsEditAgendaModal(false);
    }
  }, [props.IsEditAgendaModal, minuteData.isSuccess]);

  const handleSubmit = () => {
    const newErrors = validateForm(formData);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      const payload = {
        ...formData,
        organizationId: agendaData.organizationId,
        meetingId: agendaData.meetingId,
      };

      dispatch(editAgenda(payload, agendaData._id, accessToken,props?.fromViewMeeting));
    }
  };

  const validateAgendaTitle = () => {
    const errors = {};
    if (!formData?.title.trim()) {
      errors.title = constantMessages.titleRequired;
    } else if (
      formData.title.trim().length < 5 ||
      formData.title.trim().length > 100
    ) {
      errors.title = constantMessages.stringLengthError;
    } else if (!regularExpression.test(formData.title)) {
      errors.title = constantMessages.titleRegexError;
    }
    setErrors(errors);
  };
  
  const validateAgendaTime = () => {
    const errors = {};

    if (formData.timeLine > 365 || formData.timeLine < 0) {
      errors.timeLine = constantMessages.invalidTime;
    }
    setErrors(errors);
  };

  const handleChange = (e, uid) => {
    setErrors({});

    const { name, value } = e.target;

    // if (name === "topic") {
    //   if (value.length <= textBoxStrLength) {
    //     setFormData({
    //       ...formData,

    //       [name]: value,
    //     });
    //   }
    // } else {
    setFormData({
      ...formData,

      [name]: value,
    });
    // }
  };

  const validateForm = () => {
    //
    const errors = {};

    if (!formData?.title.trim()) {
      errors.title = constantMessages.titleRequired;
    } else if (
      formData.title.trim().length < 5 ||
      formData.title.trim().length > 100
    ) {
      errors.title = constantMessages.stringLengthError;
    } else if (!regularExpression.test(formData.title)) {
      errors.title = constantMessages.titleRegexError;
    }

    if (formData.timeLine > 365 || formData.timeLine < 0) {
      errors.timeLine = constantMessages.invalidTime;
    }
    // if (regularExpressionForHtml.test(formData.topic)) {
    //   errors.topic = constantMessages.htmlTagsNotAllowed;
    // }
    return errors;
  };

  const validateAgendaTopic = () => {
    const errors = {};

    // if (regularExpressionForHtml.test(formData.topic)) {
    //   errors.topic = constantMessages.htmlTagsNotAllowed;
    // }
    setErrors(errors);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
    // Block the 'e', 'E', '+', and '-' keys
    if (["e", "E", "+", "-"].includes(event.key)) {
      event.preventDefault();
    }
  };
  const handleValue = (value) => {
    console.log(value);
    setFormData({
      ...formData,
      topic: value,
    });
  };
  // const resetModal = () => {
  //   // alert("in");
  //   setFormData({
  //     title: "",
  //     // topic: "",
  //     timeLine: "0",
  //   });
  //   setErrors({})
  // };


  const resetModal = () => {
   
   // alert("inside13")
    setFormData({
      ...formData,
      title: agendaData?.title,
      topic: agendaData?.topic,
      timeLine: agendaData?.timeLine,
    });
    setErrors({})
    
  };
  return (
    <>
      <Modal size="lg"
        show={props.IsEditAgendaModal}
        onHide={(e) => props.setIsEditAgendaModal(false)}
          backdrop="static"
      >
        <Modal.Dialog>
          <div className="modal-header attendees-modal">
            <div>
              <h4 className="modal-title">Edit Agenda</h4>
            </div>
            <div>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={(e) => props.setIsEditAgendaModal(false)}
              ></button>
            </div>
          </div>

          <Modal.Body className="attendees-popup modal-margin">
            <div className="open-div">
              <div className="minutes-box no-border">
                <div className="form-group">
                  <div className="mb-2">
                    <div className="row">
                      <div className="col-md-12">
                        <label className="mb-1">
                          Agenda Title <span className="star-mark">*</span>
                        </label>
                      </div>
                      <div className="col-md-12">
                        <input
                          type="text"
                          placeholder="Enter agenda title"
                          name="title"
                          value={formData.title}
                          onChange={handleChange}
                          onBlur={validateAgendaTitle}
                          autoComplete="off"
                        />

                        {errors.title ? (
                          <span className="error-message">{errors.title}</span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-md-12">
                      <label className="mb-1">
                        What are the topic to discuss ?
                      </label>
                    </div>
                    <div className="col-md-12">
                      {/* <textarea
                        name="topic"
                        value={formData.topic}
                        onChange={handleChange}
                        onBlur={validateAgendaTopic}
                        autoComplete="off"
                        placeholder="Enter issue to discuss..."
                        id=""
                        cols="56"
                        rows="4"
                      ></textarea> */}
                      <ReactQuill
                        theme="snow"
                        placeholder="Please add topic"
                        onChange={handleValue}
                        value={formData.topic}
                        //onChange={handleChange}
                        onBlur={validateAgendaTopic} 
                        modules={modules}
                        formats={formats}
                        style={{ background: "white" }}
                           className="custom-quill-editor"
                      />
                      {/* <p className="success-message">
                        {checkCharacterLeft(
                          formData.topic ? formData.topic : ""
                        )}{" "}
                        Characters left
                      </p> */}
                      <p className="success-message">
                        {getStringLengthAfterHTMLParse(formData?.topic)} Characters
                      </p>
                      {errors.topic && (
                        <span className="error-message">{errors.topic}</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-group m-0">
                  <div className="row">
                    <div className="col-md-12">
                      <label className="mb-1">
                        How long will this agenda item take to discuss?
                      </label>
                    </div>
                    <div className="col-md-12">
                      <div className="time-taken bg-white">
                        <input
                          name="timeLine"
                          value={formData.timeLine}
                          onChange={handleChange}
                          onBlur={validateAgendaTime}
                          required="required"
                          onKeyDown={handleKeyDown}
                          type="number"
                          autocomplete="off"
                        />
                        <div className="minute_box">mins</div>
                      </div>
                      {errors.timeLine && (
                        <span className="error-message">{errors.timeLine}</span>
                      )}
                    </div>
                  </div>

                  <div className="mt-3 plus pointer"></div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
             // onClick={(e) => props.setIsEditAgendaModal(false)}
            //  className="add-minutes Mom-btn"
              type="button"
              // onClick={(e) => props.setIsEditAgendaModal(false)}
              onClick={() => resetModal()}
              className="add-minutes Mom-btn"
            >
              <p>Cancel</p>
            </Button>
           



            {!minuteDatas.loading ? (
            <Button
            variant=""
            onClick={handleSubmit}
            className="create-meeting-button Mom-btn"
          >
            <p>Update</p>
            </Button>
          ) : (
            <div
              className="meeting-page "
              style={{ textAlign: "center" }}
            >
              <LoaderButton />
            </div>
          )}

          </Modal.Footer>
        </Modal.Dialog>
      </Modal>
    </>
  );
};

export default EditAgendaModal;
