import React from "react";
// @ts-ignore
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
  Outlet,
  useParams,
} from "react-router-dom";
import OtpVerify from "../components/Login/OtpVerify";
import LoginByOtp from "../components/Login/LogInByOtp";
import LogInByPassword from "../components/Login/LogInByPassword";
import SetPassword from "../components/Login/SetPassword";
import MeetingList from "../components/Meeting/MeetingList";
import ActionList from "../components/Action/ActionList";
import Alert from "../components/Setting/Alert/Alert";
import MeetingRoom from "../components/Setting/MeetingRoom/MeetingRoom";
import Employee from "../components/Setting/Employee/Employee";
import Configuration from "../components/Setting/Configuration/Configuration";
import Organization from "../components/Setting/Organization/Organization";
import Unit from "../components/Setting/Unit/Unit";
import Department from "../components/Setting/Department/Department";
import Designation from "../components/Setting/Designation/Designation";
import MeetingPage from "../components/Meeting/MeetingPage";
import { ToastContainer } from "react-toastify";
import ViewMeetingDetails from "../components/Meeting/ViewMeetingDetails";
import EditMeetingPage from "../components/Meeting/EditMeetingPage";
import MinutesPage from "../components/Minutes/MinutesPage";
import ViewActionPage from "../components/Action/ViewActionPage";
import ViewMinutePage from "../components/Minutes/ViewMinutePage";
import TimelinePage from "../components/Timeline/TimelinePage";
import ViewNotification from "../components/Notification/ViewNotification";
import PageNotFound from "../components/Common/PageNotFound";
import ActivityLogs from "../components/ActivityLogs/ActivityLogs";
import LandingPage from "../components/LandingPage/LandingPage";
import GoogleAuth from "../components/Login/LoginByGoogle";
import Addhostdetails from "../components/Setting/HostingDetails/Addhostdetails";
import OrganizationRegistration from "../components/Setting/Organization/OrganizationRegistration";
import FullActionStatisticsDetails from "../components/Action/FullActionStatisticsDetails";
import SingleSignIn from "../components/Login/SingleSignIn";
import Sidebar from "../components/Common/Sidebar/Sidebar";
import Header from "../components/Common/Header/Header";

const PrivateRoutes = () => {
  const currentPath = window.location.pathname;
  let accessToken = localStorage.getItem("accessToken");
  return accessToken ? (
    <>
      <Header />
      <Sidebar />
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" state={{ currentPath }} />
  );
};

const AuthRoutes = () => {
  let paramDatas = useParams();
  const isRememberMe = localStorage.getItem("rememberMe");
  let accessToken = localStorage.getItem("accessToken");
  console.log(paramDatas);
  if (accessToken) {
    return !accessToken ? (
      <Outlet />
    ) : (
      <Navigate to="/meeting-list" state={{ paramDatas }} />
    );
  }
  return !isRememberMe ? (
    <Outlet />
  ) : (
    <Navigate to="/meeting-list" state={{ paramDatas }} />
  );
};

// const CLIENT_ID = `${process.env.REACT_APP_CLIENT_ID}`
// console.log("C ID", CLIENT_ID)
const MainRoute = () => {
  return (
    <div>
      <Router>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route exact path="/meeting-list" element={<MeetingList />} />
            <Route exact path="/action-list" element={<ActionList />} />
            <Route exact path="/organization" element={<Organization />} />
            <Route exact path="/unit" element={<Unit />} />
            <Route exact path="/department" element={<Department />} />
            <Route exact path="/designation" element={<Designation />} />
            <Route exact path="/employee" element={<Employee />} />
            <Route exact path="/meeting-room" element={<MeetingRoom />} />
            <Route exact path="/alerts" element={<Alert />} />
            <Route exact path="/configuration" element={<Configuration />} />
            <Route exact path="/create-meeting" element={<MeetingPage />} />
            <Route exact path="/edit-meeting" element={<EditMeetingPage />} />
            <Route exact path="/activity-logs" element={<ActivityLogs />} />
            <Route exact path="/hosting-details" element={<Addhostdetails />} />
            <Route
              exact
              path="/statistics-details/:organizationId"
              element={<FullActionStatisticsDetails />}
            />
            <Route
              exact
              path="/write-minute/:meetingId"
              element={<MinutesPage />}
            />
            <Route
              exact
              path="/edit-meeting/:meetingId"
              element={<MinutesPage />}
            />
            <Route
              exact
              path="/view-action-detail/:id"
              element={<ViewActionPage />}
            />
            <Route
              exact
              path="/view-minute-detail/:meetingId"
              element={<ViewMinutePage />}
            />
            <Route exact path="/notification" element={<ViewNotification />} />

            <Route
              exact
              path="/create-followon/:meetingId"
              element={<TimelinePage />}
            />
            <Route
              exact
              path="/view-meeting-details/:id"
              element={<ViewMeetingDetails />}
            />
          </Route>

          <Route element={<AuthRoutes />}>
            <Route exact path="/" element={<LandingPage />} />
            <Route
              exact
              path="/organization-registration"
              element={<OrganizationRegistration />}
            />
            <Route
              exact
              path="/login"
              element={<LoginByOtp />}
              title="LoginByOtp"
            />
            <Route
              exact
              path="/login/:meetingId/:rsvp/:userId"
              element={<LoginByOtp />}
            />
            <Route exact path="/otp-verify" element={<OtpVerify />} />
            <Route
              exact
              path="/log-in-by-otp"
              element={<LoginByOtp />}
              title="LoginByOtp"
            />
            <Route exact path="/redirect/:token" element={<SingleSignIn />} />

            <Route
              exact
              path="/log-in-by-google"
              element={<GoogleAuth />}
              title="GoogleAuth"
            />
            <Route exact path="/otp-verify" element={<OtpVerify />} />
            <Route
              exact
              path="/organization-registration"
              element={<OrganizationRegistration />}
            />
            <Route
              exact
              path="/login-by-password"
              element={<LogInByPassword />}
            />
            <Route exact path="/set-password" element={<SetPassword />} />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
      <ToastContainer />
    </div>
  );
};

export default MainRoute;
