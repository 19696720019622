import Header from "../Common/Header/Header";
import Sidebar from "../Common/Sidebar/Sidebar";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  generateMOM,
  getAgendaWithMinutesDetails,
  processCancelMeeting,
  setMeetingDetails,
} from "../../redux/actions/meetingActions/MeetingAction";
import ViewMinutes from "./ViewMinutes";
import "./minutes.css";
import CreateMinutes from "./CreateMinutes";
import { logOut } from "../../redux/actions/authActions/authAction";
import ViewParentActions from "./ViewParentActions";
import NoDataFound from "../Common/NoDataFound";
import Loader from "../Common/Loader";
import Alert from "../Common/Alert";
import GenerateMOMModal from "./GenerateMOMModal";
import MeetingDropDown from "../Meeting/MeetingDropDown";
import Dropdown from "react-bootstrap/Dropdown";
import RescheduleMeetingModal from "../Meeting/RescheduleMeetingModal";
import ResendMeetingDetailsModal from "../Meeting/ResendMeetingDetailsModal";
import CancelMeetingModal from "../Meeting/CancelMeetingModal";

const MinutesPage = (props) => {
  let { meetingId } = useParams();
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  if (authData.isInValidUser && authData.isInvalidUserChecked) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }
  const meetingData = useSelector((state) => state.meeting);
  const accessToken = localStorage.getItem("accessToken");
  const minuteData = useSelector((state) => state.minute);
  const [meetingIds, setMeetingIds] = useState(null);
  const [openAgendaForm, setOpenAgendaForm] = useState(false);
  const [isCloseModalOpen, setIsCloseModalOpen] = useState(false);
  const [isRescheduleModalOpen, setIsRescheduleModalOpen] = useState(false);
  const [singleMeetingDetails, setSingleMeetingDetails] = useState(null);
  const [isResendMeetingDetailsModalOpen, setIsResendMeetingDetailsModalOpen] =
    useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  useEffect(() => {
    if (accessToken) {
      dispatch(getAgendaWithMinutesDetails(meetingId, accessToken));
    }

    return () => {
      //dispatch(setMeetingDetails(null))
    };
  }, [minuteData.isSuccess]);

  const openAgenda = () => {
    setOpenAgendaForm(!openAgendaForm);
  };

  const closeAgenda = () => {
    setOpenAgendaForm(false);
  };
  console.log(meetingData?.meetingDetails);

  const generateMOMDetails = () => {
    const meetingId = props.meetingId
      ? props.meetingId
      : meetingData.meetingDetails?._id;
    const payload = {
      organizationId: userData.organizationId,
      attendees: meetingData?.meetingDetails?.attendees?.map(
        ({
          rsvp,
          isAttended,
          isEmployee,
          empId,
          designation,
          companyName,
          ...keepAttrs
        }) => keepAttrs
      ),
    };
    dispatch(generateMOM(meetingId, payload, accessToken));
  };
  const handleGenerateMOMModal = () => {
    setIsCloseModalOpen(true);
  };

  const handleCancelModal = (meetingId) => {
    setMeetingIds(meetingId);
    setIsCancelModalOpen(true);
  };

  const handleResendMeetingDetailsModal = (singleMeetingDetails) => {
    setSingleMeetingDetails(singleMeetingDetails);
    setIsResendMeetingDetailsModalOpen(true);
  };

  const handleRescheduleModal = (singleMeetingDetails) => {
    setSingleMeetingDetails(singleMeetingDetails);

    setIsRescheduleModalOpen(true);
  };

  const handleCloseModal = (meetingId) => {
    setMeetingIds(meetingId);
    setIsCloseModalOpen(true);
  };

  console.log(meetingData)
let meetingDetailss=meetingData?.meetingDetails
console.log(meetingDetailss)
if(meetingData?.agendaDetails?.length!==0){
  console.log(meetingData?.agendaDetails)
  const actionDetails= meetingData?.agendaDetails?.find((agenda)=>agenda?.minutesDetail?.length!==0);
  console.log(actionDetails)
  meetingDetailss["actionDetail"]=actionDetails?[actionDetails]:[]
}


  const handleCancelMeeting = (remarks) => {
    if (!authData.isInValidUser) {
    dispatch(processCancelMeeting(meetingId, { remarks }, accessToken));
    }
    setMeetingIds(null);
    setIsCancelModalOpen(false);
  };

console.log(meetingDetailss)
  return (
    <>
      {/* <Header />
      <Sidebar /> */}
      {meetingData?.meetingDetails !== null ? (
        <div className="main-content">
          <div className="page-heading d-flex justify-content-between align-items-center">
            <div className="meeting-header-text">
              <h4>Write Minutes</h4>
            </div>
           <div className="right-agenda-div d-flex">

         

           {meetingData?.meetingDetails?.isMinutesAdded ? (
              <div className="agenda-btn-icon">
                {/* <button type="button" className="create-meeting-button Mom-btn ">
                <p>Generate MOM</p>
              </button> */}

                <button
                  type="button"
                  className="create-meeting-button Mom-btn "
                  onClick={handleGenerateMOMModal}
                >
                  <p>Generate MOM</p>
                </button>
              </div>
            ) : null}
            
          {meetingData?.statusData !== "cancelled" && (
            <div className="d-inline-block menu-dropdown custom-dropdown inner-mt">
              <Dropdown>
                <MeetingDropDown
                  meetingDetails={meetingDetailss}
                  meetingId={meetingDetailss?._id}
                  status={meetingDetailss?.meetingStatus?.status}
                  handleCancelModal={() =>
                    handleCancelModal(meetingData?.meetingDetails?._id)
                  }
                  handleResendMeetingDetailsModal={() =>
                    handleResendMeetingDetailsModal(meetingData?.meetingDetails)
                  }
                  handleRescheduleModal={() =>
                    handleRescheduleModal(meetingData?.meetingDetails)
                  }
                  handleCloseModal={() => handleCloseModal(meetingData?.meetingDetails?._id)}
                />
              </Dropdown>
            </div>
          )}
           </div>
          </div>


          <div className="row">
            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 detail-col">
              <CreateMinutes
                openAgendaForm={openAgendaForm}
                closeAgendaForm={closeAgenda}
              />
              {meetingData?.meetingDetails?.parentMeetingId ? (
                <form className="mt-4">
                  <ViewParentActions
                    parentMeetingId={
                      meetingData?.meetingDetails?.parentMeetingId
                    }
                    childMeetingId={meetingData?.meetingDetails?._id}
                  />
                </form>
              ) : null}
            </div>

            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 detail-col res-mt-20">
              <ViewMinutes openAgendaForm={openAgenda} inWriteMinute={true} />
            </div>
          </div>
        </div>
      ) : meetingData?.meetingDetails === null &&
        !meetingData?.loading &&
        !meetingData.isSuccess ? (
        <div className="main-content">
          <div className="meeting-header-text">
            <h4>Write Minutes</h4>
          </div>

          <form className="mt-2 details-form details-form-right">
            <div className="row">
              <Alert
                status={"info"}
                message={"No data available."}
                timeoutSeconds={0}
              />
              <NoDataFound dataType={"meeting"} />
            </div>
          </form>
        </div>
      ) : (
        <div>
          <div className="meeting-header-text">
            <h4>Write Minutes</h4>
          </div>
          <Loader />
        </div>
      )}
      <GenerateMOMModal
        message={"Do you want to generate the MOM report ?"}
        title={"Generate MOM"}
        setIsModalOpen={setIsCloseModalOpen}
        isModalOpen={isCloseModalOpen}
        handleSubmit={generateMOMDetails}
        buttonName={"Cancel"}
        isCalledFromOthers={true}
      />

      <RescheduleMeetingModal
        setIsRescheduleModalOpen={setIsRescheduleModalOpen}
        isRescheduleModalOpen={isRescheduleModalOpen}
        meetingData={meetingData?.meetingDetails}
        isFetchedMeetingList={meetingData.isFetchedMeetingList}
        isNewMeeting={false}
      />

       <ResendMeetingDetailsModal
                isResendMeetingDetailsModalOpen={isResendMeetingDetailsModalOpen}
                setIsResendMeetingDetailsModalOpen={
                  setIsResendMeetingDetailsModalOpen
                }
                meetingDetail={singleMeetingDetails}
              />

<CancelMeetingModal
          setIsModalOpen={setIsCancelModalOpen}
          isModalOpen={isCancelModalOpen}
          handleSubmit={handleCancelMeeting}
        />
    </>
  );
};

export default MinutesPage;
