import React, { useState, useEffect } from "react";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import axios from "../../../../node_modules/axios/index";
import { toast } from "react-toastify";

const CustomizedSwitches = ({ employee, onToggle, disableCondition }) => {
  const accessToken = localStorage.getItem("accessToken");
  const [isActive, setIsActive] = useState(employee.isActive);
  const [isDisabled, setIsDisabled] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const organizationId = userData?.organizationId;
  useEffect(() => {
    if (employee) {
      setIsActive(employee.isActive);
      setIsDisabled(disableCondition);
    }
  }, [employee, disableCondition]);

  const handleToggle = async (empId, isactive) => {
    const updateEmployee = {
      organizationId: organizationId,
      isActive: isactive,
    };
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/V1/employee/editEmployee/${empId}`,
      updateEmployee,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: accessToken,
        },
      }
    );
    setIsActive(!isActive);
    if (response.data.success) {
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      if (onToggle) {
        onToggle();
      }
    } else {
      toast.error(response.data.message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <FormGroup>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>{isActive ? "Active" : "Deactive"}</Typography>
        <Switch
          checked={isActive}
          onClick={() => {
            handleToggle(employee._id, !isActive);
          }}
          inputProps={{ "aria-label": "ant design" }}
          disabled={isDisabled}
        />
      </Stack>
    </FormGroup>
  );
};

export default CustomizedSwitches;
