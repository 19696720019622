export const MAKE_REQUEST='MINUTE_MAKE_REQUEST'
export const FAIL_REQUEST='MINUTE_FAIL_REQUEST'
export const CREATE_MINUTE_RESPONSE='CREATE_MINUTE_RESPONSE'
export const SET_FINAL_MINUTES_DATA="SET_FINAL_MINUTES_DATA"
export const SET_FINAL_AGENDA_MINUTES_DATA="SET_FINAL_AGENDA_MINUTES_DATA";
export const SET_AGENDA_DATAS="SET_AGENDA_DATAS";
export const CREATE_NEW_AGENDA_RESPONSE="CREATE_NEW_AGENDA_RESPONSE";
export const SET_SUCCESS="SET_SUCCESS";
export const UPDATE_MINUTE_RESPONSE="UPDATE_MINUTE_RESPONSE";
export const PROCESS_AMENDMENT_REQUEST="PROCESS_AMENDMENT_REQUEST";
export const UPDATE_AMENDMENT_REQUEST="UPDATE_AMENDMENT_REQUEST";
export const EDIT_AGENDA_RESPONSE="EDIT_AGENDA_RESPONSE";
export const DELETE_AGENDA_RESPONSE="DELETE_AGENDA_RESPONSE";
export const SET_MOM_ACCEPT_DETAILS="SET_MOM_ACCEPT_DETAILS";
export const MAKE_UPDATE_REQUEST="MAKE_UPDATE_REQUEST";



