
import React from "react";
import {
  GoogleLogin,
  GoogleOAuthProvider,
  useGoogleLogin,
} from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { logInByGmail } from "../../redux/actions/authActions/authAction";
import { toast } from "react-toastify";

const GoogleButton = () => {
  const dispatch = useDispatch();
  const handleLogin = async (googleData) => {
    console.log(googleData);
    dispatch(logInByGmail(googleData));
  };

  const handleError = async (err) => {
    console.log(err);
    toast.error(err.message, {
      // position: "top-right",
      // autoClose: 4000,
      // hideProgressBar: false,
      // closeOnClick: true,
      // pauseOnHover: true,
      // draggable: true,
      // progress: undefined,
      // theme: "colored",

      position: "top-right",
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored"

    });
  };

  const googleLogin = useGoogleLogin({
    // onSuccess: (response) => handleLogin(response),

    onSuccess: async (tokenResponse) => {
      console.log("Google token:", tokenResponse.credential);
      handleLogin(tokenResponse)
    },

    onError: (err) => handleError(err),
  });

  return (
    <div style={{ textAlign: "center" }}>
      <button className="login-with-google-btn" onClick={googleLogin} type="button">
        {/* <img
          src="https://developers.google.com/identity/images/g-logo.png"
          alt="Google Logo"
          className="google-icon"
        /> */}
        Sign in with Google
      </button>
    </div>
  );
};

const GoogleAuth = () => {
  const clientId = process.env.REACT_APP_CLIENT_ID;
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <GoogleButton />
    </GoogleOAuthProvider>
  );
};

export default GoogleAuth;
