import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link, useParams } from "react-router-dom";
import parse from "html-react-parser";
import Spinner from "react-bootstrap/Spinner";
import {
  deleteZoomRecording,
  getSingleMeetingDetails,
  unSetSingleMeetingDetails,
  processCloseMeeting, //added for meeting dropdown
  processCancelMeeting, //added for meeting dropdown
} from "../../redux/actions/meetingActions/MeetingAction";
import GuestAttendeesModal from "./GuestAttendeesModal";
import OnlyAttendeeModal from "./OnlyAttendeeModal";
import Header from "../Common/Header/Header";
import Sidebar from "../Common/Sidebar/Sidebar";
import {
  checkEmptyString,
  combineDateAndTime,
  convertIsoFormat,
  customName,
  decryptWithAES,
  getStringLengthAfterHTMLParse,
  sliceHtmlEncodedString,
} from "../../helpers/commonHelpers";
import AttendeesDetails from "./AttendeesDetails";
import NoDataFound from "../Common/NoDataFound";
import Loader from "../Common/Loader";
import Alert from "../Common/Alert";
import { logOut } from "../../redux/actions/authActions/authAction";
import {
  Button,
  Popover,
  OverlayTrigger,
  Accordion,
  Tooltip,
} from "react-bootstrap";
import ViewAgendaModal from "../Minutes/ViewAgendaModal";
import ViewZoomRecordings from "./ViewZoomRecordings";
import AddAgendaModal from "../Meeting/AddAgendaModal";
import { addAgendaDetails } from "../../redux/actions/minuteActions/MinuteAction";

//added for meeting dropdown
import Dropdown from "react-bootstrap/Dropdown";
import MeetingDropDown from "../Meeting/MeetingDropDown";

import CancelMeetingModal from "./CancelMeetingModal";
import RescheduleMeetingModal from "./RescheduleMeetingModal";
import ResendMeetingDetailsModal from "./ResendMeetingDetailsModal";
import CommonModal from "../Common/CommonModal";
import EditButton from "../Common/EditButton";

const ViewMeetingDetails = () => {
  let { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  if (authData.isInValidUser && authData.isInvalidUserChecked) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }
  const [isAddAgendaModal, setIsAddAgendaModal] = useState(false);
  const accessToken = localStorage.getItem("accessToken");
  const meetingData = useSelector((state) => state.meeting);
  const employeeData = useSelector((state) => state.user);
  const [attendeesData, setAttendeesData] = useState([]);
  const [recordingData, setRecordingData] = useState([]);
  const [isGuestModalOpen, setIsGuestModalOpen] = useState(false);
  const [isOnlyAttendeeModalOpen, setIsOnlyAttendeeModalOpen] = useState(false);
  const [isViewAgendaModal, setIsViewAgendaModal] = useState(false);
  const [agendaDatas, setAgendaDatas] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isViewZoomRecordingModal, setIsViewZoomRecordingModal] =
    useState(false);
  const [recordingId, setRecordingId] = useState(null);

  // added for meeting dropdown
  const [meetingId, setMeetingId] = useState(null);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [meetingDetails, setMeetingDetails] = useState(null);

  const [singleMeetingDetails, setSingleMeetingDetails] = useState(null);
  const [isResendMeetingDetailsModalOpen, setIsResendMeetingDetailsModalOpen] =
    useState(false);

  const [isCloseModalOpen, setIsCloseModalOpen] = useState(false);
  const [isRescheduleModalOpen, setIsRescheduleModalOpen] = useState(false);
  const [isDeletedraftModalOpen, setIsDeletedraftlModalOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = "Meeting Details: MinutesVault";
    if (accessToken) {
      dispatch(getSingleMeetingDetails(id, accessToken));
    }
    return () => {
      dispatch(unSetSingleMeetingDetails);
    };
  }, []);

  const onAddAgenda = (data) => {
    dispatch(
      addAgendaDetails(
        {
          agendaData: [
            {
              topic: data.topic,
              timeLine: data.timeLine,
              title: data.title,
              organizationId: meetingData?.meetingDetails?.organizationId,
              meetingId: meetingData?.meetingDetails?._id,
            },
          ],
        },
        meetingData?.meetingDetails?._id,
        accessToken
      )
    );
  };

  useEffect(() => {
    setRecordingData(
      meetingData?.singleMeetingDetails?.recordings?.recordingFile
    );
  }, [meetingData?.singleMeetingDetails?.recordings?.recordingFile]);

  useEffect(() => {
    // alert("in 1",recordingId);
    if (meetingData?.isSuccess && recordingId !== null) {
      //  alert("in 2");
      var newRecordingData = recordingData?.filter(
        (recording) => recording?.id != recordingId
      );

      setRecordingData(newRecordingData);
      setIsDeleteModalOpen(false);
      if (newRecordingData?.length === 0) {
        setIsViewZoomRecordingModal(false);
      }
    }
  }, [meetingData?.isSuccess]);
  console.log(recordingData);
  const setGuestModalStatus = (value, attendeesData) => {
    if (attendeesData.length !== 0) {
      setIsGuestModalOpen(value);
      setAttendeesData([...attendeesData]);
    }
  };
  const setOnlyAttendeeModalStatus = (value, attendeesData) => {
    if (attendeesData.length !== 0) {
      setIsOnlyAttendeeModalOpen(value);
      setAttendeesData([...attendeesData]);
    }
  };
  const setViewAgendaModalStatus = (value, agenda) => {
    setIsViewAgendaModal(value);
    setAgendaDatas(agenda);
  };
  const setViewZoomRecordingModalStatus = (value) => {
    setIsViewZoomRecordingModal(value);
  };
  const isMeetingOrganiser =
    employeeData?.userData?._id?.toString() ===
    meetingData?.singleMeetingDetails?.createdById.toString()
      ? true
      : false;
  console.log("isMeetingOrganiser1--->", isMeetingOrganiser);
  console.log(
    "Created By Id-->",
    meetingData?.singleMeetingDetails?.createdById.toString()
  );
  const downloadFiles = (files) => {
    files.forEach((file, index) => {
      setTimeout(() => {
        const link = document.createElement("a");
        link.href = file?.download_url;
        link.download = "Recording"; // Optional file name
        link.style.display = "none"; // Hide the link
        link.target = "_blank";
        document.body.appendChild(link);

        link.click(); // Simulate the click

        // Clean up by removing the link
        document.body.removeChild(link);
      }, index * 500); // 500 ms  delay between each download
    });
  };
  console.log(meetingData?.singleMeetingDetails?.actualDate);
  //const meetingDateTime = meetingData?.singleMeetingDetails?.actualDate?combineDateAndTime(meetingData?.singleMeetingDetails?.actualDate, meetingData?.singleMeetingDetails?.toTime).getTime():null;

  // console.log(meetingDateTime)
  // 2025-01-14T11:31:48.615Z
  //2025-01-14T00:40:00.000Z
  // console.log(meetingDateTime)
  // let isShowMeetingLink = false;
  // if (meetingData?.singleMeetingDetails?.actualDate) {
  //   const currentDate = convertIsoFormat(new Date());
  //   const currentDateInMilliSecond = new Date(currentDate).getTime();
  //   console.log(currentDateInMilliSecond);
  //   const meetingDateTime = convertIsoFormat(
  //     combineDateAndTime(
  //       meetingData?.singleMeetingDetails?.actualDate,
  //       meetingData?.singleMeetingDetails?.toTime
  //     )
  //   );
  //   console.log(meetingDateTime);
  //   console.log(currentDate);
  //   const meetingDateInMilliSecond = new Date(meetingDateTime).getTime();
  //   console.log(meetingDateInMilliSecond);
  //   isShowMeetingLink =
  //     currentDateInMilliSecond <= meetingDateInMilliSecond ? true : false;
  // }
  // console.log(isShowMeetingLink);

  const handleDeleteSubmit = () => {
    const payload = {
      meetingId: meetingData?.singleMeetingDetails?._id,
      recordingId,
      organizationId: meetingData?.singleMeetingDetails?.organizationId,
    };
    dispatch(deleteZoomRecording(payload, accessToken));
  };
  const handleOpenDeleteModal = (singleRecordingId, status) => {
    setIsDeleteModalOpen(status);
    setRecordingId(singleRecordingId);
  };
  console.log(meetingData?.singleMeetingDetails?.recordings?.recordingFile);

  const tooltip = (attendee) => {
    return (
      <Tooltip id="tooltip">
        {attendee.name} / {attendee?.email}
      </Tooltip>
    );
  };
  const popoverHoverFocus = (title) => {
    return (
      <Popover id="popover-basic" className="custom-popover">
        {/* <Popover.Header as="h3" className="popover-header">
          Meeting Title.
        </Popover.Header> */}
        <Popover.Body className="popover-body">{title}</Popover.Body>
      </Popover>
    );
  };

  const handleAddAgendaModal = (status) => {
    setIsAddAgendaModal(status);
  };

  //modals for meeting dropdown
  const handleCancelMeeting = (remarks) => {
    dispatch(processCancelMeeting(meetingId, { remarks }, accessToken));
    setMeetingId(null);
    setIsCancelModalOpen(false);
  };

  const handleCloseMeeting = () => {
    dispatch(
      processCloseMeeting(meetingId, { meetingStatus: "closed" }, accessToken)
    );
    setMeetingId(null);
    setIsCloseModalOpen(false);
  };

  const handleCancelModal = (meetingId) => {
    setMeetingId(meetingId);
    setIsCancelModalOpen(true);
  };

  const handleCloseModal = (meetingId) => {
    setMeetingId(meetingId);
    setIsCloseModalOpen(true);
  };

  const handleRescheduleModal = (singleMeetingDetails) => {
    setSingleMeetingDetails(singleMeetingDetails);
    setIsRescheduleModalOpen(true);
  };
  const handleResendMeetingDetailsModal = (singleMeetingDetails) => {
    setSingleMeetingDetails(singleMeetingDetails);
    setIsResendMeetingDetailsModalOpen(true);
  };

  const meeting = meetingData?.singleMeetingDetails;
  console.log("Single meeting Details----", meetingData);


  console.log(meetingData)
let meetingDetailss=meeting
console.log(meetingDetailss)
if(meetingData?.agendaDetails?.length!==0){
  console.log(meetingData?.agendaDetails)
  const actionDetails= meetingData?.agendaDetails?.find((agenda)=>agenda?.minutesDetail?.length!==0);
  console.log(actionDetails)

  if(actionDetails){
    meetingDetailss["actionDetail"]=actionDetails;
  }
  else{
    meetingDetailss["actionDetail"]=[];
  }

  // meetingDetailss["actionDetail"]=actionDetails?[actionDetails]:[]
}
let organizationData = useSelector((state) => state.organization);
let isWriteMinuteAllowedForClosedMinutes = true;
let isWriteMinuteAllowed=true;
console.log(meeting)
if(meeting){
 
 

  const meetingDateTime = combineDateAndTime(
    new Date(meeting?.actualDate),
    meeting?.actualFromTime
  ).getTime();
  console.log(new Date());
  const currentDateTime = new Date().getTime();
  console.log(meeting?.meetingCloseDetails?.closedAt);

  if (meeting?.meetingCloseDetails?.closedAt) {
    const utcDate = new Date(
      meeting?.meetingCloseDetails?.closedAt
    );
    utcDate.setMinutes(utcDate.getMinutes() - (5 * 60 + 30));
    const meetingCloseTime = utcDate.getTime();
    const closedTime = meeting?.meetingCloseDetails?.closedAt
      ? meetingCloseTime
      : 0;

    const targetHoursInMilliSecond = organizationData?.organizationData
      ?.configData?.writeMinuteMaxTimeInHour
      ? parseInt(
          organizationData?.organizationData?.configData
            ?.writeMinuteMaxTimeInHour
        ) * 3600000
      : 0;
    console.log(organizationData?.organizationData?.configData);
    console.log(
      organizationData?.organizationData?.configData?.writeMinuteMaxTimeInHour
    );
    console.log(currentDateTime);
    console.log(closedTime);
    console.log(targetHoursInMilliSecond);
    console.log(currentDateTime - closedTime);

    if (targetHoursInMilliSecond === 0) {
      isWriteMinuteAllowedForClosedMinutes = false;
    } else {
      isWriteMinuteAllowedForClosedMinutes =
        currentDateTime - closedTime > targetHoursInMilliSecond ? false : true;
    }
    console.log(isWriteMinuteAllowedForClosedMinutes);
  }

   isWriteMinuteAllowed = meetingDateTime <= currentDateTime ? true : false;

}


console.log(meeting)
  // console.log(isWriteMinuteAllowed)
  //   console.log(isMeetingOrganiser);
  //   console.log(props?.meetingDetails?.createdById?.toString());
  //   console.log(employeeData?.userData?._id?.toString());
  //   console.log(props?.meetingDetails);


  return (
    <>
      {/* added for dropdown */}
      <CancelMeetingModal
        setIsModalOpen={setIsCancelModalOpen}
        isModalOpen={isCancelModalOpen}
        handleSubmit={handleCancelMeeting}
      />
      <CommonModal
        message={"Do you want to close the meeting ?"}
        title={"Close Meeting"}
        setIsModalOpen={setIsCloseModalOpen}
        isModalOpen={isCloseModalOpen}
        handleSubmit={handleCloseMeeting}
        buttonName={"Close"}
      />
      <RescheduleMeetingModal
        setIsRescheduleModalOpen={setIsRescheduleModalOpen}
        isRescheduleModalOpen={isRescheduleModalOpen}
        meetingData={singleMeetingDetails}
        isFetchedMeetingList={meetingData.isFetchedMeetingList}
      />
      <ResendMeetingDetailsModal
        isResendMeetingDetailsModalOpen={isResendMeetingDetailsModalOpen}
        setIsResendMeetingDetailsModalOpen={setIsResendMeetingDetailsModalOpen}
        meetingDetail={singleMeetingDetails}
      />
      {isDeleteModalOpen ? (
        <div className="alert-wrapper">
          <div className="alert-container">
            <div className="alert-icon alert-warning alert-icon-show">
              <div className="alert-icon-content">!</div>
            </div>
            <h2 className="alert-title">Are you sure? </h2>
            <div className="alert-html-container">
              You want to delete this recording?
              <br />
              <span>You will not be able to recover this file later!</span>
            </div>
            <div className="alert-actions">
              {!meetingData.loading ? (
                <button
                  className="create-meeting-button Mom-btn alert-confirm"
                  type="button"
                  onClick={() => handleDeleteSubmit()}
                >
                  <p>Yes, delete it!</p>
                </button>
              ) : (
                <button
                  type="button"
                  className="create-meeting-button Mom-btn alert-confirm"
                  disabled={true}
                >
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  Processing...
                </button>
              )}

              <button
                type="button"
                className="add-minutes Mom-btn btn alert-cancel"
                onClick={() => setIsDeleteModalOpen(false)}
              >
                <p>Cancel</p>
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {/* <Header />
      <Sidebar /> */}

{(meetingData?.singleMeetingDetails?.meetingStatus?.status === "scheduled" ||
         meetingData?.singleMeetingDetails?.meetingStatus?.status === "rescheduled" ||
         meetingData?.singleMeetingDetails?.meetingStatus?.status  === "draft") &&
        (isMeetingOrganiser === true ||
          employeeData?.userData?.isAdmin === true) &&
       // props?.meetingDetails?.minutesDetails?.length === 0 ? (
        !(meetingData?.singleMeetingDetails?.actionDetail?.length > 0 ) ?(  <EditButton meetingData={meetingData} />):null}

    
      {meetingData.singleMeetingDetails !== null ? (
        <div className="main-content">
          <div className="page-heading d-flex justify-content-between align-items-center">
            <div className="meeting-header-text">
              <h4>Meeting Details</h4>
            </div>
            <div
              className="agenda-btn-icon"
             
            >

{isWriteMinuteAllowed && (
  (["scheduled", "rescheduled"].includes(meeting?.meetingStatus?.status) &&
    (isMeetingOrganiser ||
      employeeData?.userData?.isAdmin ||
      meeting?.canWriteMOM ||
      meeting?.canWriteMOM)) ||
  (meeting?.meetingStatus?.status === "closed" &&
    (isMeetingOrganiser ||
      employeeData?.userData?.isAdmin ||
      meeting?.canWriteMOM ||
      meeting?.canWriteMOM) &&
    isWriteMinuteAllowedForClosedMinutes))?(

      <Button
      className="btn rounded-pill add-btn Mom-btn d-flex align-items-center justify-content-center"
      id="open-form-btn"
      onClick={() => {
        handleAddAgendaModal(true);
      }}
    >
      <p>Add Agenda</p>
    </Button>
    ):null}


             
              <div className="d-inline-block menu-dropdown custom-dropdown">
                <div className="dropdown">
                  {meetingData?.singleMeetingDetails?.meetingStatus?.status !==
                    "cancelled" && (
                    <div className="d-inline-block menu-dropdown custom-dropdown">
                      <Dropdown>
                        <MeetingDropDown
                          meetingDetails={meeting}
                          meetingId={meeting._id}
                          status={meeting.meetingStatus?.status}
                          handleCancelModal={() =>
                            handleCancelModal(meeting._id)
                          }
                          handleResendMeetingDetailsModal={() =>
                            handleResendMeetingDetailsModal(meeting)
                          }
                          handleRescheduleModal={() =>
                            handleRescheduleModal(meeting)
                          }
                          handleCloseModal={() => handleCloseModal(meeting._id)}
                          // handleDeleteDraftlModal={() => handleDeleteDraftlModal(meeting._id)}
                        />
                      </Dropdown>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 detail-col">
              {/* <div className="meeting-header-text">
                <h4>Meeting Details</h4>
              </div> */}

              <form className="mt-2 details-form details-form-right">
                {meetingData?.singleMeetingDetails?.parentMeetingDetails
                  ?.length !== 0 ? (
                  <>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                          <label className="mb-1">Parent Meeting ID</label>
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                          <p>
                            {
                              meetingData?.singleMeetingDetails
                                ?.parentMeetingDetails[0].meetingId
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                          <label className="mb-1">Parent Meeting Title</label>
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                          <p>
                            {
                              meetingData?.singleMeetingDetails
                                ?.parentMeetingDetails[0].title
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}

                <div className="form-group ">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                      <label className="mb-1">Meeting Id</label>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                      <p>{meetingData.singleMeetingDetails?.meetingId}</p>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                      <label className="mb-1">Title</label>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                      <p>{meetingData.singleMeetingDetails?.title}</p>
                    </div>
                  </div>
                </div>

                <div className="form-group ">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                      <label className="mb-1">Meeting Mode</label>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                      <p>{meetingData.singleMeetingDetails?.mode}</p>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                      <label className="mb-1">Location</label>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                      <p>
                        {meetingData.singleMeetingDetails?.locationDetails
                          .isMeetingRoom === true
                          ? meetingData.singleMeetingDetails?.roomDetail[0]
                              ?.title +
                            ", " +
                            meetingData.singleMeetingDetails?.roomDetail[0]
                              .location +
                            ", " +
                            meetingData?.singleMeetingDetails?.unitDetail?.name
                          : meetingData.singleMeetingDetails?.locationDetails
                              .location}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                      <label className="mb-1">Meeting Link </label>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                      {meetingData?.singleMeetingDetails?.link &&
                      meetingData?.singleMeetingDetails?.hostDetails
                        ?.hostType === "MANUAL" ? (
                        <Link
                          target={"_blank"}
                          to={meetingData?.singleMeetingDetails?.link}
                        >
                          Click to Join Meeting
                        </Link>
                      ) : meetingData?.singleMeetingDetails?.link ? (
                        <Link
                          target={"_blank"}
                          to={
                            meetingData?.singleMeetingDetails?.createdById?.toString() ===
                            employeeData?.userData?._id?.toString()
                              ? meetingData?.singleMeetingDetails?.hostDetails
                                  ?.hostLink
                              : meetingData?.singleMeetingDetails?.link
                          }
                        >
                          Click to Join Meeting
                        </Link>
                      ) : meetingData?.singleMeetingDetails?.hostDetails
                          ?.hostType === "ZOOM" &&
                        !meetingData?.singleMeetingDetails?.link ? (
                        <p>Zoom link will be added once schedule the meeting</p>
                      ) : (
                        <p> NA</p>
                      )}
                    </div>
                  </div>
                </div>

                {/* <div className="form-group">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                      <label className="mb-1">Link </label>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                      <>
                        {" "}
                        {meetingData?.singleMeetingDetails?.link &&
                          meetingData?.singleMeetingDetails?.hostDetails
                            ?.hostType === "MANUAL" &&
                          (meetingData.singleMeetingDetails?.meetingStatus
                            ?.status === "scheduled" ||
                            meetingData.singleMeetingDetails?.meetingStatus
                              ?.status === "rescheduled") ? (
                          <Link
                            target={"_blank"}
                            to={meetingData?.singleMeetingDetails?.link}
                          >
                            <p>Click to Join Meeting</p>
                          </Link>
                        ) : meetingData?.singleMeetingDetails?.link &&
                          (meetingData.singleMeetingDetails?.meetingStatus
                            ?.status === "scheduled" ||
                            meetingData.singleMeetingDetails?.meetingStatus
                              ?.status === "rescheduled") === true ? (
                          <>
                            <Link
                              target={"_blank"}
                              to={
                                meetingData?.singleMeetingDetails?.hostDetails
                                  ?.hostLink
                              }
                            >
                              <p>Click to Join Meeting</p>
                            </Link>
                            {employeeData?.userData?.isAdmin ||
                              (meetingData?.singleMeetingDetails?.createdById?.toString() ===
                                employeeData?.userData?._id?.toString() &&
                                (meetingData.singleMeetingDetails?.meetingStatus
                                  ?.status === "scheduled" ||
                                  meetingData.singleMeetingDetails?.meetingStatus
                                    ?.status === "rescheduled") === true) ? (
                              <p>
                                {" "}
                                ( Host Key :{" "}
                                {decryptWithAES(
                                  meetingData?.singleMeetingDetails
                                    ?.meetingHostDetails?.zoomCredentials
                                    ?.hostKey
                                )}{" "}
                                )
                              </p>
                            ) : null}
                          </>
                        ) : meetingData?.singleMeetingDetails?.link &&
                          meetingData?.singleMeetingDetails?.hostDetails
                            ?.hostType === "ZOOM" &&
                          (meetingData.singleMeetingDetails?.meetingStatus
                            ?.status === "scheduled" ||
                            meetingData.singleMeetingDetails?.meetingStatus
                              ?.status === "rescheduled") === true ? (
                          <>
                            <Link
                              target={"_blank"}
                              to={
                                meetingData?.singleMeetingDetails?.hostDetails
                                  ?.hostLink
                              }
                            >
                              <p>Click to Join Meeting</p>
                            </Link>

                            {meetingData?.singleMeetingDetails?.createdById?.toString() ===
                              employeeData?.userData?._id?.toString() &&
                              (meetingData.singleMeetingDetails?.meetingStatus
                                ?.status === "scheduled" ||
                                meetingData.singleMeetingDetails?.meetingStatus
                                  ?.status === "rescheduled") === true ? (
                              <p>
                                {" "}
                                Host Key :
                                {decryptWithAES(
                                  meetingData?.singleMeetingDetails
                                    ?.meetingHostDetails?.zoomCredentials
                                    ?.hostKey
                                )}
                              </p>
                            ) : null}
                          </>
                        ) : meetingData?.singleMeetingDetails?.hostDetails
                          ?.hostType === "ZOOM" &&
                          !meetingData?.singleMeetingDetails?.link &&
                          (meetingData.singleMeetingDetails?.meetingStatus
                            ?.status === "scheduled" ||
                            meetingData.singleMeetingDetails?.meetingStatus
                              ?.status === "rescheduled") === true ? (
                          <p>
                            Zoom link will be added once schedule the meeting
                          </p>
                        ) : (
                          <p> NA</p>
                        )}
                      </>
                    </div>
                  </div>
                </div> */}

                <div className="form-group">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                      <label className="mb-1">Recordings </label>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                      {meetingData?.singleMeetingDetails?.hostDetails
                        ?.hostType === "ZOOM" &&
                      meetingData?.singleMeetingDetails?.recordings &&
                      recordingData?.length !== 0 ? (
                        <>
                          {/* <button
                            onClick={() =>
                              setViewZoomRecordingModalStatus(true)
                            }
                            type="button"
                          >
                            View Recordings
                          </button> */}

                          <button
                            className="p-0 resp-recording-view"
                            type="button"
                            onClick={() =>
                              setViewZoomRecordingModalStatus(true)
                            }
                          >
                            <span className="recording-btn">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="#5f07b0"
                                className="bi bi-eye-fill me-2"
                                viewBox="0 0 16 16"
                              >
                                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                              </svg>
                              {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                className="bi bi-cloud-download me-2"
                                viewBox="0 0 16 16"
                              >
                                <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383" />
                                <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708z" />
                              </svg> */}
                              View
                            </span>
                          </button>
                          {/* <Link
                            target={"_blank"}
                            to={
                              meetingData?.singleMeetingDetails?.recordings
                                ?.recordingFile?.play_url
                            }
                            // style={{
                            //   textDecoration: "none",
                            //   color: "black",
                            // }}
                          >
                            <span className="recording-btn">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                className="bi bi-box-arrow-right me-2"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"
                                />
                                <path
                                  fill-rule="evenodd"
                                  d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"
                                />
                              </svg>
                              Watch
                            </span>
                          </Link>
                          <br></br>
                          <p>
                            Passcode: ({" "}
                            {
                              meetingData?.singleMeetingDetails?.recordings
                                ?.password
                            }{" "}
                            )
                          </p> */}
                        </>
                      ) : (
                        <p>NA</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                      <label className="mb-1">Date & Time</label>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                      <p>
                        {meetingData.singleMeetingDetails?.date},
                        {meetingData.singleMeetingDetails?.fromTime} to{" "}
                        {meetingData.singleMeetingDetails?.toTime}{" "}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                      <label className="mb-1">Status</label>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                      <span
                        className={`badge bg-opacity-10 ${
                          meetingData.singleMeetingDetails?.meetingStatus
                            ?.status === "scheduled"
                            ? "scheduled-color"
                            : meetingData.singleMeetingDetails?.meetingStatus
                                ?.status === "rescheduled"
                            ? "rescheduled-color"
                            : meetingData.singleMeetingDetails?.meetingStatus
                                ?.status === "closed"
                            ? "closed-color"
                            : meetingData.singleMeetingDetails?.meetingStatus
                                ?.status === "draft"
                            ? "draft-color"
                            : "canceled-color"
                        }`}
                      >
                        {meetingData.singleMeetingDetails?.meetingStatus?.status
                          .charAt(0)
                          .toUpperCase() +
                          meetingData.singleMeetingDetails?.meetingStatus?.status.slice(
                            1
                          )}
                      </span>
                    </div>
                  </div>
                </div>

                {meetingData.singleMeetingDetails?.meetingStatus?.status ===
                  "rescheduled" ||
                meetingData.singleMeetingDetails?.meetingStatus?.status ===
                  "cancelled" ? (
                  <div className="form-group">
                    <div className="row">
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                        <label className="mb-1">Reason</label>
                      </div>
                      <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                        <p>
                          {
                            meetingData.singleMeetingDetails?.meetingStatus
                              ?.remarks
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="row">
                  {meetingData.singleMeetingDetails?.attendees.length > 0 ? (
                    <div className="form-group mb-0">
                      {meetingData?.singleMeetingDetails?.attendees.filter(
                        (attendee) => attendee?.isEmployee === true
                      ).length > 0 && (
                        <div className="row align-items-center mb-2">
                          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                            <label className="pb-1"> Attendee(s)</label>
                          </div>

                          <div
                            className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                            onClick={() => {
                              setOnlyAttendeeModalStatus(
                                true,
                                meetingData?.singleMeetingDetails?.attendees
                              );
                            }}
                          >
                            <div className="attendees">
                              {meetingData?.singleMeetingDetails?.attendees
                                .filter(
                                  (attendee) => attendee?.isEmployee === true
                                )
                                .filter((item, index) => index < 5)
                                .map((guest) => (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltip(guest)}
                                    trigger={["hover", "focus"]}
                                  >
                                    <div
                                      // title={` ${
                                      //   meetingData?.singleMeetingDetails?.createdById.toString() ===
                                      //   guest._id.toString()
                                      //     ? "Organiser"
                                      //     : "Employee"
                                      // }`}
                                      // title={`${guest?.name} / ${guest?.email}`}
                                      className={`attendee-list ${
                                        meetingData?.singleMeetingDetails?.createdById.toString() ===
                                        guest._id.toString()
                                          ? "attendees-border"
                                          : ""
                                      }`}
                                      key={guest._id}
                                    >
                                      {customName(guest.name)}
                                    </div>
                                  </OverlayTrigger>
                                ))}

                              <p className="m-0">
                                {meetingData.singleMeetingDetails?.attendees.filter(
                                  (guest) => guest.isEmployee === true
                                ).length > 5
                                  ? `+${
                                      meetingData.singleMeetingDetails?.attendees.filter(
                                        (guest) => guest.isEmployee === true
                                      ).length - 5
                                    } More`
                                  : null}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}

                      {meetingData?.singleMeetingDetails?.attendees.filter(
                        (attendee) => attendee?.isEmployee === false
                      ).length > 0 && (
                        <div className="row align-items-center mb-2">
                          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                            <label className="pb-1"> Guest(s) </label>
                          </div>
                          <div
                            className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                            onClick={() => {
                              setGuestModalStatus(
                                true,
                                meetingData?.singleMeetingDetails?.attendees
                              );
                            }}
                          >
                            <div className="attendees" title="Guest">
                              {meetingData?.singleMeetingDetails?.attendees
                                .filter(
                                  (attendee) => attendee?.isEmployee === false
                                )
                                .filter((item, index) => index < 5)
                                .map((guest) => (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltip(guest)}
                                    trigger={["hover", "focus"]}
                                  >
                                    <div
                                      className="attendee-list"
                                      key={guest._id}
                                      //    title={`${guest?.name} / ${guest?.email}`}
                                    >
                                      {customName(guest.name)}
                                    </div>
                                  </OverlayTrigger>
                                ))}
                              <p className="m-0">
                                {meetingData.singleMeetingDetails?.attendees.filter(
                                  (guest) => guest.isEmployee === false
                                ).length > 5
                                  ? `+${
                                      meetingData.singleMeetingDetails?.attendees.filter(
                                        (guest) => guest.isEmployee === false
                                      ).length - 5
                                    } More`
                                  : null}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : null}
                </div>

                {meetingData.singleMeetingDetails?.agendasDetail.length !==
                0 ? (
                  <>
                    <div className="minutes-border"></div>
                    <div className="form-group mt-4 agenda">
                      <label className="mb-2">
                        <h4>Agenda(s)</h4>
                      </label>
                      {meetingData.singleMeetingDetails?.agendasDetail
                        .length !== 0 &&
                        meetingData.singleMeetingDetails?.agendasDetail.map(
                          (agenda, index) => {
                            return (
                              <div className="mt-2 agenda-box-border">
                                <Accordion defaultActiveKey="0">
                                  <Accordion.Item
                                    eventKey={index.toString()}
                                    key={index}
                                    className="agenda-accordion"
                                  >
                                    <Accordion.Header>
                                    <span>Agenda {index + 1}:&nbsp;</span> 
                                      {agenda.title?.length > 50 ? (
                                        <OverlayTrigger
                                          trigger={["hover", "focus"]}
                                          placement="bottom"
                                          overlay={popoverHoverFocus(
                                            agenda.title
                                          )}
                                        >
                                          <span className="meeting-title-hover">
                                            {` ${agenda.title.slice(
                                              0,
                                              50
                                            )}....`}
                                          </span>
                                        </OverlayTrigger>
                                      ) : (
                                        <span className="meeting-title-hover">
                                          {" "}
                                          {agenda.title}
                                        </span>
                                      )}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="">
                                        <div className="form-group">
                                          <div className="row">
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                              <label className="mt-1 mb-1">
                                                Agenda Title
                                              </label>
                                            </div>
                                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                              <p> {agenda.title}</p>
                                            </div>
                                          </div>
                                        </div>
                                        {checkEmptyString(agenda?.topic) ===
                                          null ||
                                        checkEmptyString(agenda?.topic) ===
                                          " " ||
                                        checkEmptyString(agenda?.topic) ===
                                          undefined ||
                                        agenda?.topic === null ? null : (
                                          <div className="form-group">
                                            <div className="row">
                                              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                                <label className="mt-2 topic">
                                                  Topic to Discuss
                                                </label>
                                              </div>
                                              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                                <div className="mb-2 text-editor">
                                                  {agenda.topic
                                                    ? parse(agenda.topic)
                                                    : null}
                                                  {/* {agenda.topic ? (
                                                    <>
                                                      {getStringLengthAfterHTMLParse(
                                                        agenda.topic
                                                      ) < 20 ? (
                                                        <span>
                                                          {parse(agenda.topic)}
                                                        </span>
                                                      ) : (
                                                        <div>
                                                          {`${sliceHtmlEncodedString(
                                                            agenda.topic,
                                                            20
                                                          )}....`}
                                                          <button
                                                            type="button"
                                                            className="badge bg-opacity-10 scheduled-color"
                                                            onClick={() =>
                                                              setViewAgendaModalStatus(
                                                                true,
                                                                agenda
                                                              )
                                                            }
                                                          >
                                                            {" "}
                                                            View More
                                                          </button>
                                                        </div>
                                                      )}
                                                    </>
                                                  ) : null} */}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                        <div className=" form-group">
                                          <div className="row">
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                              <label className="mt-1 mb-1">
                                                Timeline
                                              </label>
                                            </div>
                                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                              <p> {agenda.timeLine} Mins</p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              </div>
                            );
                          }
                        )}
                    </div>
                  </>
                ) : (
                  <>
                    <Alert
                      status={"info"}
                      message={"No Agenda Added"}
                      timeoutSeconds={0}
                    />
                    <div className="mt-3 agenda-box-border no-data-img">
                      <NoDataFound dataType={"agenda"} />
                    </div>
                  </>
                )}
              </form>
            </div>
            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 detail-col">
              <div className="meeting-header-text timeline-div d-none-div">
                <h4></h4>
                {meetingData?.singleMeetingDetails?.meetingStatus?.status ===
                  "closed" &&
                (meetingData?.singleMeetingDetails?.parentMeetingDetails
                  ?.length !== 0 ||
                  meetingData?.singleMeetingDetails?.parentMeetingId) ? (
                  <Link
                    className="btn rounded-pill add-btn Mom-btn d-flex align-items-center justify-content-center"
                    id="open-form-btn"
                    to={`/create-followon/${id}`}
                    state={{
                      meetingId: id,
                      isMeetingDataUpdate: true,
                      step: meetingData,
                    }}
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    <p> View Timeline</p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-calendar4-week  ms-2 me-0 filter-svg"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M2 2a1 1 0 0 0-1 1v1h14V3a1 1 0 0 0-1-1zm13 3H1v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z" />
                      <path d="M11 7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-2 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5z" />
                    </svg>
                  </Link>
                ) : (
                  <Link
                    className="btn rounded-pill add-btn Mom-btn d-flex align-items-center justify-content-center"
                    id="open-form-btn"
                    to={`/create-followon/${id}`}
                    state={{
                      meetingId: id,
                      isMeetingDataUpdate: true,
                      step: meetingData,
                    }}
                    style={{
                      textDecoration: "none",
                      color: "black",
                      visibility: "hidden",
                    }}
                  >
                    <p> View Timeline</p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="17"
                      fill="currentColor"
                      className="bi bi-pencil-square me-0 filter-svg"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path
                        fill-rule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                      />
                    </svg>
                  </Link>
                )}
                {/* <Link
                  className="btn rounded-pill add-btn Mom-btn d-flex align-items-center justify-content-center"
                  id="open-form-btn"
                  onClick={() => {
                    handleAddAgendaModal(true);
                  }}
                  state={{
                    meetingId: id,
                    isMeetingDataUpdate: true,
                    step: meetingData,
                  }}
                  style={{
                    textDecoration: "none",
                    color: "black",
                  }}
                >
                  <p>Add Agenda</p>
                </Link> */}

                {/* {(meetingData.singleMeetingDetails?.meetingStatus?.status === "scheduled" &&
                  (isMeetingOrganiser === true || employeeData?.userData?.isAdmin === true) &&
                  meetingData.singleMeetingDetails?.actionDetail?.length === 0) ||
                  (meetingData.singleMeetingDetails?.meetingStatus?.status === "rescheduled" &&
                    (isMeetingOrganiser === true || employeeData?.userData?.isAdmin === true) &&
                    meetingData.singleMeetingDetails?.actionDetail?.length === 0) ||
                  (meetingData.singleMeetingDetails?.meetingStatus?.status === "draft" &&
                    (isMeetingOrganiser === true || employeeData?.userData?.isAdmin === true) &&
                    meetingData.singleMeetingDetails?.actionDetail?.length === 0) ? (
                  <Link
                    className="btn rounded-pill add-btn Mom-btn d-flex align-items-center justify-content-center"
                    id="open-form-btn"
                    to="/edit-meeting"
                    state={{
                      meetingId: id,
                      isMeetingDataUpdate: true,
                      step: meetingData,
                    }}
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    <p>Edit</p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="17"
                      fill="currentColor"
                      className="bi bi-pencil-square me-0 filter-svg"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path
                        fillRule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                      />
                    </svg>
                  </Link>
                ) : null} */}

                {/* Added for Meeting dropdown */}
                {/* {meetingData?.singleMeetingDetails?.meetingStatus?.status !==
                  "cancelled" && (
                  <div className="d-inline-block menu-dropdown custom-dropdown">
                    <Dropdown>
                      <MeetingDropDown
                        meetingDetails={meeting}
                        meetingId={meeting._id}
                        status={meeting.meetingStatus?.status}
                        handleCancelModal={() => handleCancelModal(meeting._id)}
                        handleResendMeetingDetailsModal={() =>
                          handleResendMeetingDetailsModal(meeting)
                        }
                        handleRescheduleModal={() =>
                          handleRescheduleModal(meeting)
                        }
                        handleCloseModal={() => handleCloseModal(meeting._id)}
                        // handleDeleteDraftlModal={() => handleDeleteDraftlModal(meeting._id)}
                      />
                    </Dropdown>
                  </div>
                )} */}
              </div>
              <form className="mt-2 details-form details-form-right">
                {meetingData.singleMeetingDetails?.attendees.length > 0 ? (
                  <div className="form-group mb-2">
                    <AttendeesDetails
                      attendeesData={meetingData.singleMeetingDetails}
                    />
                  </div>
                ) : (
                  <>
                    <Alert
                      status={"info"}
                      message={"No Attendee Added"}
                      timeoutSeconds={0}
                    />
                    <div className="mt-3 agenda-box-border no-data-img">
                      <NoDataFound dataType={"attendee"} />
                    </div>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      ) : meetingData.singleMeetingDetails === null &&
        !meetingData.loading &&
        !meetingData.isSuccess ? (
        <div className="main-content">
          <div className="row">
            <div className="meeting-header-text">
              <h4>Meeting Details</h4>
            </div>
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 detail-col">
              <form className="mt-2 details-form details-form-right">
                <div className="row">
                  <Alert
                    status={"info"}
                    message={"No data available."}
                    timeoutSeconds={0}
                  />
                  <NoDataFound dataType={"meeting"} />
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div className="main-content">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 detail-col">
              <div className="meeting-header-text">
                <h4>Meeting Details</h4>
              </div>

              <form className="mt-2 details-form details-form-right">
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Loader />
                </div>
              </form>
            </div>
          </div>
        </div>
      )}{" "}
      <GuestAttendeesModal
        IsGuestModalOpen={isGuestModalOpen}
        attendees={attendeesData}
        setIsGuestModalOpen={setIsGuestModalOpen}
      />
      <OnlyAttendeeModal
        IsOnlyAttendeeModalOpen={isOnlyAttendeeModalOpen}
        attendees={attendeesData}
        setIsOnlyAttendeeModalOpen={setIsOnlyAttendeeModalOpen}
        meetingData={meetingData}
      />
      <ViewAgendaModal
        agendaData={agendaDatas}
        IsViewAgendaModal={isViewAgendaModal}
        setIsViewAgendaModal={setIsViewAgendaModal}
      />
      <ViewZoomRecordings
        singleMeetingDetails={meetingData?.singleMeetingDetails}
        IsViewZoomRecordingModal={isViewZoomRecordingModal}
        setIsViewZoomRecordingModal={setIsViewZoomRecordingModal}
        handleOpenDeleteModal={handleOpenDeleteModal}
        recordingData={recordingData}
      />
      <AddAgendaModal
        IsAddAgendaModal={isAddAgendaModal}
        setIsAddAgendaModal={setIsAddAgendaModal}
        addAgenda={onAddAgenda}
        totalAgenda={meetingData.singleMeetingDetails?.agendasDetail?.length}
        // agendaData={agendaData}
        fromViewMeeting={true}
      />
    </>
  );
};

export default ViewMeetingDetails;
